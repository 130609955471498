import { useContext } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { OnTimeDeliveryAssoc } from './OnTimeDeliveryAssoc';
import { onTimeDeliveryData } from '../../../../utils/OnTimeDelivery';
import { DataView } from '../../../../../../components/Common/DataView';
import { Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';

export const OnTimeDelivery = () => {
    const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
    const {
      isError,
      isLoading,
      results: [{ data: storeOntimeDelivery = [] }]
    } = useEnhancedQueries(['queryOnTimeDelivery'], fpToggle);
    const data = onTimeDeliveryData(storeOntimeDelivery, timeframe);

    if (isError) return <Header>Something went wrong</Header>;
    if (isLoading) return <PlaceholderLoading />
    
    return (
      <DataView className='my-4'>
        <DataView.Title as='h3'>Delivery</DataView.Title>
        <DataView.DataTable data={data} />
        <DataView.Title as='h3'>Delivery On Time</DataView.Title>
        <OnTimeDeliveryAssoc />
      </DataView>
    );
    
};