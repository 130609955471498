import { Header } from 'semantic-ui-react';
import { useSpecialtyProRentalContext } from '../hooks/useSpecialtyProRentalContext';
import { DataView } from '../../Common/DataView';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { getDataAsOf } from '../../../utils/getDataAsOf';
import { getPreviousDay } from '../../../utils/date.js';

export const SpecialtyProRental = props => {
  const { children, title, data } = props;
  const { timeframe, setTimeframe } = useSpecialtyProRentalContext();
 
  const dataAsOf = getDataAsOf({ data });
  const dateToShow = getPreviousDay({ date: dataAsOf })
  
  return (
    <>
      <DataView className='my-3'>
        <DataView.Title position='left'>
          {title}
          <Header.Subheader>Data as of { dateToShow }</Header.Subheader>
        </DataView.Title>

        <DataView.Controls>
          <TimeframeDropdown timeframe={timeframe} settimeframe={setTimeframe} />
        </DataView.Controls>
      </DataView>
      {children}
    </>
  );
};
