import { Header, Icon } from 'semantic-ui-react';
import { VOC_GET_GOAL, VOC_LTPA_GOAL, VOC_LTSA_GOAL } from '../../../constants/goals';
import { useEnhancedQueries } from '../../../hooks/useEnhancedQueries';
import { useOverviewContext } from '../../../hooks/useOverviewContext';
import { getParamByLevel, requiresCoreAccess } from '../../../utils/timeFrameUtils';
import { DataTable } from '../../Common/DataTable';
import { getCompareIconStyle, getCompareStyle } from '../../Common/TableCellUtils';
import { PlaceholderLoading } from '../../Loading/PlaceholderLoading';
import { useUserContext } from '../../../hooks/useUserContext';

export const Overview = () => {
  const { timeframe, userEmplCtgryNm, unit, level, levelName, queryParam, fpToggle } = useOverviewContext();
  const {
    isError,
    isLoading,
    results: [{ data: swmDetailsData = [] }, { data: safetyData = [] }]
  } = useEnhancedQueries(['querySwmOpsMetrics', 'querySafetyIncOshaLtGlbi'], fpToggle);
  const paramByLevel = getParamByLevel(level);
  const params = level === 'core' ? {} : { [paramByLevel]: queryParam };

  // If we are not a core user, and the timeframe requires core access, and we're at the core level, we should not display Sales data
  const { isCore } = useUserContext();
  const enableCoreSalesRestriction = level === 'core'
    && requiresCoreAccess(timeframe)
    && !isCore;

  const isNOM = unit === 'NOM';
  const isPCT = unit === 'PCT';
  const userEmplLinkAccess =
    userEmplCtgryNm.toUpperCase().includes('SALAR') ||
    userEmplCtgryNm.toUpperCase().includes('OFFICER') ||
    userEmplCtgryNm.toUpperCase().includes('CORP_CNTRC');
  if (isLoading) return <PlaceholderLoading />;
  if (isError) return <Header textAlign='center'>Error Fetching Data</Header>;
  if (!swmDetailsData[0]) return <Header textAlign='center'>Invalid or Unauthorized {level}</Header>;
  const title = level === 'core' ? `Overview - ${level}` : `Overview - ${level} ${queryParam}, ${levelName}`;
  const regionMetrics = swmDetailsData[0];
  const data = [
    [
      { stringValue: 'Days Safe', link: userEmplLinkAccess ? { to: `/${level}/safety`, params } : undefined },
      { stringValue: safetyData.length > 0 ? safetyData[0].DAYS_SAFE : '-' },
      { stringValue: '-' },
      { stringValue: '-' }
    ],
    enableCoreSalesRestriction ? [] : [
      {
        stringValue: 'Sales',
        popup: {
          content: (
            <>
              <Header textAlign='center'>Sales:</Header>
              <p>Sales Plan data includes a financial hedge, so will not add up from Store, District, and Region levels</p>
            </>
          ),
          icon: 'info circle',
          color: 'blue'
        }
      },
      { dollarValue: regionMetrics[timeframe + '_SLS_AMT_ACT'] },
      isNOM
        ? {
            compareStyle: true,
            dollarValue: isNOM ? regionMetrics[timeframe + '_SLS_VP'] : undefined
          }
        : {
            compareStyle: true,
            pctValue: isPCT ? regionMetrics[timeframe + '_SLS_AMT_VP_PCT'] : undefined,
            decimals: 2
          },
      isNOM
        ? {
            compareStyle: true,
            dollarValue: isNOM ? regionMetrics[timeframe + '_SLS_AMT_VLY'] : undefined
          }
        : {
            compareStyle: true,
            pctValue: isPCT ? regionMetrics[timeframe + '_SLS_AMT_VLY_PCT'] : undefined,
            decimals: 2
          }
    ],
    [
      { stringValue: 'Units' },
      { stringValue: regionMetrics[timeframe + '_UNTS_ACT']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        compareStyle: true,
        stringValue: isNOM
          ? regionMetrics[timeframe + '_UNTS_VLY']?.toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? regionMetrics[timeframe + '_UNTS_VLY_PCT'] : undefined,
        decimals: 2
      }
    ],
    [
      { stringValue: 'Transactions' },
      { stringValue: regionMetrics[timeframe + '_TRNS_ACT']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        compareStyle: true,
        stringValue: isNOM
          ? regionMetrics[timeframe + '_TRNS_VLY']?.toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? regionMetrics[timeframe + '_TRNS_VLY_PCT'] : undefined,
        decimals: 2
      }
    ],
    [
      { stringValue: 'Average Ticket' },
      { dollarValue: regionMetrics[timeframe + '_AVG_TCKT_ACT'], decimals: 2 },
      { stringValue: '-' },
      {
        compareStyle: true,
        dollarValue: isNOM ? regionMetrics[timeframe + '_AVG_TCKT_VLY'] : undefined,
        pctValue: isPCT ? regionMetrics[timeframe + '_AVG_TCKT_VLY_PCT'] : undefined,
        decimals: isNOM ? 2 : 3
      }
    ],
    [
      { stringValue: 'Units per Basket' },
      {
        stringValue: (
          regionMetrics['TY_' + timeframe + '_UNT_SLS'] / regionMetrics['TY_' + timeframe + '_TXN_CNT']
        ).toLocaleString(undefined, { maximumFractionDigits: 2 })
      },
      { stringValue: '-' },
      {
        compareStyle: true,
        stringValue: isNOM
          ? (
              regionMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
              regionMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['LY_' + timeframe + '_COMP_TXN_CNT']
            ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          : undefined,
        pctValue: isPCT
          ? (regionMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] -
              regionMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['LY_' + timeframe + '_COMP_TXN_CNT']) /
            (regionMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['LY_' + timeframe + '_COMP_TXN_CNT'])
          : undefined,
        decimals: 2
      }
    ],
    [
      { stringValue: 'On-Shelf Availability', link: { to: level === 'core' ? '/core/osa' : `/osa`, params } },
      { stringValue: '-' },
      { stringValue: '-' },
      { stringValue: '-' }
    ],
    [
      { stringValue: 'SMD', link: { to: `/${level}/smd`, params } },
      { pctValue: regionMetrics[timeframe + '_SMD_ACT'], decimals: 2 },
      {
        stringValue: isNOM
          ? regionMetrics[timeframe + '_SMD_VP']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
          : '-',
        customStyle: getCompareStyle(-1 * regionMetrics[timeframe + '_SMD_VP']),
        postIcon: getCompareIconStyle(Icon, -1 * regionMetrics[timeframe + '_SMD_VP'])
      },
      {
        stringValue: isNOM
          ? regionMetrics[timeframe + '_SMD_VLY']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
          : '-',
        customStyle: getCompareStyle(-1 * regionMetrics[timeframe + '_SMD_VLY']),
        postIcon: getCompareIconStyle(Icon, -1 * regionMetrics[timeframe + '_SMD_VLY'])
      }
    ],
    [
      { stringValue: 'Labor', link: { to: `/${level}/labor`, params } },
      {
        stringValue: regionMetrics[timeframe + '_LBR_ACT']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'
      },
      isNOM
        ? {
            stringValue: isNOM
              ? regionMetrics[timeframe + '_LBR_VF']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'
              : undefined,
            customStyle: {
              color:
                regionMetrics[timeframe + '_LBR_VF_PCT'] > 0.01 || regionMetrics[timeframe + '_LBR_VF_PCT'] < -0.01
                  ? 'red'
                  : 'green'
            }
          }
        : {
            pctValue: isPCT ? regionMetrics[timeframe + '_LBR_VF_PCT'] : undefined,
            decimals: 2,
            customStyle: {
              color:
                regionMetrics[timeframe + '_LBR_VF_PCT'] > 0.01 || regionMetrics[timeframe + '_LBR_VF_PCT'] < -0.01
                  ? 'red'
                  : 'green'
            }
          },
      { stringValue: '-' }
    ],
    [
      { stringValue: 'GET', link: { to: `/${level}/ltsa`, params } },
      {
        pctValue: regionMetrics[timeframe + '_GET_ACT'],
        decimals: 2,
        customStyle: { color: regionMetrics[timeframe + '_GET_ACT'] >= VOC_GET_GOAL ? 'green' : 'red' },
        postIcon: getCompareIconStyle(Icon, regionMetrics[timeframe + '_GET_ACT'], VOC_GET_GOAL)
      },
      { stringValue: '-' },
      {
        decimals: 2,
        pctValue: isNOM ? regionMetrics[timeframe + '_GET_VLY'] : '-',
        compareStyle: true,
        postIcon: getCompareIconStyle(Icon, regionMetrics[timeframe + '_GET_VLY'])
      }
    ],
    [
      { stringValue: 'LTSA', link: { to: `/${level}/ltsa`, params } },
      {
        pctValue: regionMetrics[timeframe + '_LTSA_ACT'],
        decimals: 2,
        customStyle: { color: regionMetrics[timeframe + '_LTSA_ACT'] >= VOC_LTSA_GOAL ? 'green' : 'red' },
        postIcon: getCompareIconStyle(Icon, regionMetrics[timeframe + '_LTSA_ACT'], VOC_LTSA_GOAL)
      },
      { stringValue: '-' },
      {
        pctValue: isNOM ? regionMetrics[timeframe + '_LTSA_VLY'] : '-',
        compareStyle: true,
        postIcon: getCompareIconStyle(Icon, regionMetrics[timeframe + '_LTSA_VLY']),
        decimals: 2
      }
    ],
    [
      { stringValue: 'LTPA', link: { to: `/${level}/voc`, params } },
      {
        pctValue: regionMetrics[timeframe + '_ONLINE_SAT_ACT'] ? regionMetrics[timeframe + '_ONLINE_SAT_ACT'] : undefined,
        decimals: 2,
        customStyle: {
          color:
            regionMetrics[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL
              ? 'green'
              : regionMetrics[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL
                ? 'red'
                : 'black'
        },
        postIcon: getCompareIconStyle(Icon, regionMetrics[timeframe + '_ONLINE_SAT_ACT'], VOC_LTPA_GOAL)
      },
      { stringValue: '-' },
      {
        stringValue: isNOM
          ? regionMetrics[timeframe + '_ONLINE_SAT_VLY']?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) + '%'
          : '--',
        compareStyle: true,
        decimals: 2,
        postIcon: getCompareIconStyle(Icon, regionMetrics[timeframe + '_ONLINE_SAT_VLY']),
        pctValue: isPCT ? '-' : undefined
      }
    ],
    [
      { stringValue: 'Leads', link: { to: `/${level}/leads`, params } },
      { stringValue: regionMetrics[timeframe + '_LEADS_ACT']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        stringValue: isNOM
          ? regionMetrics[timeframe + '_LEADS_VLY']?.toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? regionMetrics[timeframe + '_LEADS_VLY_PCT'] : undefined,
        decimals: 2,
        compareStyle: true
      }
    ],
    [
      { stringValue: 'Measures', link: { to: `/${level}/meas`, params } },
      { stringValue: regionMetrics[timeframe + '_MEAS_ACT']?.toLocaleString(undefined, { maximumFractionDigits: 0 }) },
      { stringValue: '-' },
      {
        stringValue: isNOM
          ? regionMetrics[timeframe + '_MEAS_VLY']?.toLocaleString(undefined, { maximumFractionDigits: 0 })
          : undefined,
        pctValue: isPCT ? regionMetrics[timeframe + '_MEAS_VLY_PCT'] : undefined,
        decimals: 2,
        compareStyle: true
      }
    ],
    [
      {
        stringValue: 'Cashier Metrics',
        link: {
          to: `https://pr-mstr-so.homedepot.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-MYNUMBER-IS-20202-1&Project=PONE-SO&Port=0&evt=2048001&documentID=9C90664A48024856B23381BCFE86A029&&currentViewMedia=2&elementsPromptAnswers=E15D73A24D9970F1A0A15CBECADE46AE;E15D73A24D9970F1A0A15CBECADE46AE:${Number(queryParam)}`
        }
      },
      { stringValue: '-' },
      { stringValue: '-' },
      { stringValue: '-' }
    ],
    [
      { stringValue: 'HDPP - MA UA' },
      { pctValue: regionMetrics[timeframe + '_MA_HDPP'], decimals: 2 },
      { pctValue: regionMetrics[timeframe + '_MA_HDPP_VG'], compareStyle: true, decimals: 2 },
      { pctValue: regionMetrics[timeframe + '_MA_HDPP_VLY'], compareStyle: true, decimals: 2 }
    ],
    [
      { stringValue: 'HDPP - GM UA' },
      { pctValue: regionMetrics[timeframe + '_GM_HDPP'], decimals: 2 },
      { pctValue: regionMetrics[timeframe + '_GM_HDPP_VG'], compareStyle: true, decimals: 2 },
      { pctValue: regionMetrics[timeframe + '_GM_HDPP_VLY'], compareStyle: true, decimals: 2 }
    ]
  ];

  const dataObj = {
    headers: [
      { name: '' },
      { name: 'Actual' },
      { name: `vP/vF ${isPCT ? '%' : '($)'}` },
      { name: `vLY ${isPCT ? '%' : '($)'}` }
    ],
    data: data
  };

  const dataAsOf = swmDetailsData[0]?.TRNSFM_DT;

  return (
    <>
      <Header className='text-capitalize' textAlign='center'>
        {title}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>
      <DataTable data={dataObj} />
    </>
  );
};
