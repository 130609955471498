import React, { useState, useEffect } from 'react';
import { Header, Loader, Grid, Segment, Table, Pagination } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getData } from '../../service/DataService';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';

export const OHMNoLocationTagsByDept = (({ timeframe, settimeframe, level, fpToggle, location, locNbr, ...props}) => {
    
    const search = new URLSearchParams(location.search);
    const deptNbrInt = search.has("deptNbr") ? search.get("deptNbr") : 0;
    const [flagVal, setFlagVal] = useState("WTD")
    const [pageNum, setPageNum] = useState(1)
    let rowsPerPage = 20
   
    const FlagSwitch = (timeframe) => {
        switch(timeframe) {
          case "WTD":
            return "WTD_FLG";
          case "LW":
            return "LW_FLG";
          case "LM":
            return "LM_FLG";
          case "MTD":
            return "MTD_FLG";
          case "QTD":
            return "QTD_FLG";
          case "HTD":
            return "HTD_FLG";
          case "YTD":
           return "YTD_FLG";
          case "L12":
            return "L12_FLG";
          default:
            return "WTD_FLG";
        }
      }

    const OHMNoLocationTagsByDeptQuery = useQuery([`queryOHMPalletTagDTL`, { strNbr: locNbr, deptNbrInt, fpToggle }], getData)

    useEffect(() => {
        setFlagVal(FlagSwitch(timeframe))
    },[timeframe])

    if (OHMNoLocationTagsByDeptQuery.isLoading) {
        return (<Loader active>Loading</Loader>)
    }

    if (!OHMNoLocationTagsByDeptQuery.data || OHMNoLocationTagsByDeptQuery.data.length === 0) {
        return (<Header textAlign='center'>Invalid Store Number</Header>)
    }

    const OHMNoLocationTagsData = OHMNoLocationTagsByDeptQuery.data
    const DEPT_NM = OHMNoLocationTagsData[0].DEPT_NM

    let OHMNoLocationTagsDataFilter = OHMNoLocationTagsData.filter((row) => {
        return row[flagVal] === 1
      })

    let dataPagination = OHMNoLocationTagsDataFilter
    .filter((row, index, self) => self.findIndex((t) => t.TAG_ID === row.TAG_ID) === index)
    .slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, OHMNoLocationTagsDataFilter.length)))

    return (
        <>
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>
                    <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                    {' / '}
                    <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                    {' / '}
                    <Link style={{ color: '#EE7125' }} to={`/osa/ohmnoloc?strNbr=${locNbr}`} data-test='b2o-link'>OHM No Loc Tags</Link>
                    {` / ${deptNbrInt}`}
                </Grid.Row>
            </Grid.Column>
        </Grid>
        <Grid>
            <Grid.Column width={8} />
            <Grid.Column width={8}>
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Column>
        </Grid>
        <Header textAlign='center'>{deptNbrInt} - {DEPT_NM}</Header>
        <p style={{ textAlign:'center' }}>No Location Tags</p>
        <Grid.Row></Grid.Row>
        <Grid>
        {OHMNoLocationTagsDataFilter.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((OHMNoLocationTagsDataFilter.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
        </Grid>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>TAG ID</Table.HeaderCell>
                    <Table.HeaderCell>CREATED DATE</Table.HeaderCell>
                    <Table.HeaderCell>CREATOR</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {dataPagination.map((data) => {
                return(
                <Table.Row>
                    <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/ohmnoloc/tag?strNbr=${locNbr}&tagID=${data['TAG_ID']}`}>{data.TAG_ID}</Link></Table.Cell>
                    <Table.Cell>{data.INSERT_DT}</Table.Cell>
                    <Table.Cell>{data.LAST_UPD_BY}</Table.Cell>
                </Table.Row>)})}
            </Table.Body>
        </Table>
        </Segment>
        </>
    )
})
