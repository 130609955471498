import { useContext } from "react";
import { useEnhancedQueries } from "../../../../../../hooks/useEnhancedQueries";
import { Header, Tab } from "semantic-ui-react";
import { DeliveryPush } from "./delivery/DeliveryPush";
import { TruckChanges } from "./delivery/TruckChanges";
import { DeliveryPull } from "./delivery/DeliveryPull";
import { Fulfillment } from "./delivery/Fulfillment";
import { DataView } from "../../../../../../components/Common/DataView";
import { StoreOrderFulfillmentContext } from "../../../../context/StoreOrderFulfillmentContext";
import { PlaceholderLoading } from "../../../../../../components/Loading/PlaceholderLoading";

export const DeliveryTabs = () => {
  const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
  const {
    isError,
    isLoading,
    results: [{ data: associateDetails = [] }, { data: orderDetails = [] }],
  } = useEnhancedQueries(['queryAssociateDetails', 'queryOrderDetails'], fpToggle);

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const pushOrders = orderDetails.filter((data) => data.MANIPULATION_TYPE === 'PUSH');
  const pullOrders = orderDetails.filter((data) => data.MANIPULATION_TYPE === 'PULL');
  const truckChangesOrders = orderDetails.filter((data) => data.MANIPULATION_TYPE === 'TRUCK CHANGE');

  const tabs = [
    {
      menuItem: 'Fulfillment',
      render: () => <Fulfillment />
    },
    {
      menuItem: 'Delivery Push',
      render: () => <DeliveryPush associateData={associateDetails} orderData={pushOrders} mainTimeframe={timeframe} />
    },
    {
      menuItem: 'Delivery Pull',
      render: () => <DeliveryPull associateData={associateDetails} orderData={pullOrders} mainTimeframe={timeframe} />
    },
    {
      menuItem: 'Truck Changes',
      render: () => <TruckChanges deliveryData={truckChangesOrders} />
    },
  ]

  return (
    <DataView className='my-4'>
      <DataView.Title className='mb-3'>Delivery Complete</DataView.Title>
      <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />
    </DataView>
  );
}