import { useState } from 'react'
import {
  Table,
  Loader,
  Header,
  Grid,
  Icon,
  Menu,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getThousandDollarString, getPercentString, getSmdCompareStyle } from '../Common/TableCellUtils'

export const SmdDetailStr = (({ timeframe, settimeframe, fpToggle, location }) => {



  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [deptRsnCd, setDeptRsnCd] = useState("dept")

  const [sortColumn, setSortColumn] = useState("MKDN_RSN_CD")
  const [sortDirection, setSortDirection] = useState("ascending")

  const [sortColumnDept, setSortColumnDept] = useState("DEPT_NBR")
  const [sortDirectionDept, setSortDirectionDept] = useState("ascending")

  const [unit, setUnit] = useState('NOM')

  const smdDetailQuery = useQuery(['querySwmSmdDtl', { strNbr, fpToggle }], getData)
  const smdOvrUndrQuery = useQuery(['querySwmSmdOvrUndrByStr', { strNbr, fpToggle }], getData)
  const smdDeptQuery = useQuery(['querySmdDeptByStr', { strNbr, fpToggle }], getData)

  if (smdDetailQuery.isLoading || smdOvrUndrQuery.isLoading || smdDeptQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let rsnCdFiltered = smdDetailQuery.data.filter(row => {
    return row && row['MKDN_RSN_CD'] !== 7
  })

  let storeUseFilter = smdDetailQuery.data.filter(row => {
    return row && row['MKDN_RSN_CD'] === 7
  })

  let rsnCdSorted = rsnCdFiltered.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'MKDN_RSN_CD') {
      result = left.MKDN_RSN_CD > right.MKDN_RSN_CD ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let deptSorted = smdDeptQuery.data.sort((left, right) => {
    let result = left[sortColumnDept] - right[sortColumnDept]
    if (sortColumnDept === timeframe + '_SMD_PCT') {
      result = (left[timeframe + '_SMD'] / left['TY_' + timeframe + '_SLS_AMT']) - (right[timeframe + '_SMD'] / right['TY_' + timeframe + '_SLS_AMT'])
    }
    if (sortColumnDept === timeframe + '_VP') {
      result = (left[timeframe + '_SMD'] - left[timeframe + '_MKDN_PLN_AMT'] * left['TY_' + timeframe + '_SLS_AMT'] / left[timeframe + '_SLS_PLN_AMT'])
       - (right[timeframe + '_SMD'] - right[timeframe + '_MKDN_PLN_AMT'] * right['TY_' + timeframe + '_SLS_AMT'] / right[timeframe + '_SLS_PLN_AMT'])
    }
    if (sortColumnDept === timeframe + '_VP_BPS') {
      result = (left[timeframe + '_SMD'] / left['TY_' + timeframe + '_SLS_AMT'] - left[timeframe + '_MKDN_PLN_AMT'] / left[timeframe + '_SLS_PLN_AMT'])
        - (right[timeframe + '_SMD'] / right['TY_' + timeframe + '_SLS_AMT'] - right[timeframe + '_MKDN_PLN_AMT'] / right[timeframe + '_SLS_PLN_AMT'])
    }
    if (sortDirectionDept === 'descending') {
      result *= -1
    }
    return result
  })

  let ovrUndrData = smdOvrUndrQuery.data[0]

  const storeName = smdDetailQuery.data[0]?.STR_NM;
  const transformDate = smdDetailQuery.data[0]?.TRNSFM_DT;


  return (
    <>

      <Header className='mb-1 text-capitalize' textAlign='left'>
        SMD - Store {strNbr}, {String(storeName).toLowerCase()}
      </Header>

      <p>Data as of {transformDate}</p>

      <span>Timeframe:</span>
      <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />

      <Table className='mt-4' textAlign='center' fixed unstackable sortable celled size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD $</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD bps vP</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SMD $ Over Under</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{getThousandDollarString(ovrUndrData[timeframe + '_SMD_AMT'], 1)}</Table.Cell>
            <Table.Cell>{getPercentString(ovrUndrData[timeframe + '_SMD_ACT'], 2)}</Table.Cell>
            <Table.Cell
              style={getSmdCompareStyle(ovrUndrData[timeframe + '_SMD_BPS_VP'])}
            >{ovrUndrData[timeframe + '_SMD_BPS_VP'] ? ovrUndrData[timeframe + '_SMD_BPS_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' : '-'}
            </Table.Cell>
            <Table.Cell
              style={getSmdCompareStyle(ovrUndrData[timeframe + '_SMD_BPS_VP'])}
            >{getThousandDollarString(ovrUndrData[timeframe + '_SMD_OVER_UNDER'], 1)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>Note: Reason Code 7 - Store Use is not part of store markdowns plan and budget.</p>

      <Grid>
        <Grid.Column>
          <Menu size='small' widths={2}>
            <Menu.Item
              name='dept'
              active={deptRsnCd === 'dept'}
              content='Departments'
              onClick={() => {
                setDeptRsnCd('dept')
              }}
            />
            <Menu.Item
              name='rsnCd'
              active={deptRsnCd === 'rsnCd'}
              content='Reason Codes'
              onClick={() => {
                setDeptRsnCd('rsnCd')
              }}
            />
          </Menu>
        </Grid.Column>
      </Grid>

      {deptRsnCd === 'rsnCd' && <Grid>
        <Grid.Column width={8}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
        </Grid.Column>
      </Grid>}

      {deptRsnCd === 'dept' && <Header textAlign='center'>SMD - Departments</Header>}
      {deptRsnCd === 'rsnCd' && <Header textAlign='center'>SMD - Reason Codes</Header>}

      {deptRsnCd === 'dept' && <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === 'DEPT_NBR' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== 'DEPT_NBR') {
                  setSortColumnDept('DEPT_NBR')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              Dept # {sortColumnDept !== 'DEPT_NBR' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_SMD' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_SMD') {
                  setSortColumnDept(timeframe + '_SMD')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD {sortColumnDept !== timeframe + '_SMD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_SMD_PCT' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_SMD_PCT') {
                  setSortColumnDept(timeframe + '_SMD_PCT')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD % {sortColumnDept !== timeframe + '_SMD_PCT' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_VP_BPS' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_VP_BPS') {
                  setSortColumnDept(timeframe + '_VP_BPS')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD bps vP {sortColumnDept !== timeframe + '_VP_BPS' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              sorted={sortColumnDept === timeframe + '_VP' ? sortDirectionDept : null}
              onClick={() => {
                if (sortColumnDept !== timeframe + '_VP') {
                  setSortColumnDept(timeframe + '_VP')
                  setSortDirectionDept('ascending')
                } else {
                  sortDirectionDept === 'ascending' ? setSortDirectionDept('descending') : setSortDirectionDept('ascending')
                }
              }}>
              SMD $ Over Under {sortColumnDept !== timeframe + '_VP' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {deptSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/smd/dept?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}`} >
                    {data['DEPT_NBR']}-{data['DEPT_NM'].toUpperCase().replace(/\//g, ' ')}
                  </Link>
                </Table.Cell>
                <Table.Cell singleLine>{getThousandDollarString(data[timeframe + '_SMD'], 1)}</Table.Cell>
                <Table.Cell singleLine>{getPercentString(data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'], 2)}</Table.Cell>
                <Table.Cell
                  singleLine
                  style={{ color: data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_MKDN_PLN_AMT'] / data[timeframe + '_SLS_PLN_AMT'] < 0 ? 'green' : 'red' }}
                >{(10000 * (data[timeframe + '_SMD'] / data['TY_' + timeframe + '_SLS_AMT'] - data[timeframe + '_MKDN_PLN_AMT'] / data[timeframe + '_SLS_PLN_AMT'])).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'}</Table.Cell>
                <Table.Cell
                  singleLine
                  style={{ color: data[timeframe + '_SMD'] - data[timeframe + '_MKDN_PLN_AMT'] * data['TY_' + timeframe + '_SLS_AMT'] / data[timeframe + '_SLS_PLN_AMT'] < 0 ? 'green' : 'red' }}
                >{getThousandDollarString(data[timeframe + '_SMD'] - data[timeframe + '_MKDN_PLN_AMT'] * data['TY_' + timeframe + '_SLS_AMT'] / data[timeframe + '_SLS_PLN_AMT'], 1)}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}

      {deptRsnCd === 'rsnCd' && <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'MKDN_RSN_CD' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== 'MKDN_RSN_CD') {
                  setSortColumn('MKDN_RSN_CD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              Reason Code {sortColumn !== 'MKDN_RSN_CD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell
              sorted={sortColumn === timeframe + '_SMD' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD') {
                  setSortColumn(timeframe + '_SMD')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              SMD {sortColumn !== timeframe + '_SMD' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
              sorted={sortColumn === timeframe + '_SMD_PCT' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_PCT') {
                  setSortColumn(timeframe + '_SMD_PCT')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              SMD {sortColumn !== timeframe + '_SMD_PCT' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell
              sorted={sortColumn === timeframe + '_SMD_VLY_BPS' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_VLY_BPS') {
                  setSortColumn(timeframe + '_SMD_VLY_BPS')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              vLY {sortColumn !== timeframe + '_SMD_VLY_BPS' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell
              sorted={sortColumn === timeframe + '_SMD_VLY_DOL' ? sortDirection : null}
              onClick={() => {
                if (sortColumn !== timeframe + '_SMD_VLY_DOL') {
                  setSortColumn(timeframe + '_SMD_VLY_DOL')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>
              vLY {sortColumn !== timeframe + '_SMD_VLY_DOL' && <Icon fitted name='sort' />}
            </Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rsnCdSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/smd/reason?strNbr=${strNbr}&mkdnRsnCd=${data['MKDN_RSN_CD']}`} >
                    {data['MKDN_RSN_CD']}-{data['MKDN_RSN_DESC'].toUpperCase()}
                  </Link>
                </Table.Cell>
                {unit === 'NOM' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD'] != null ?
                    data[timeframe + '_SMD'] >= 0 ?
                      '$' + data[timeframe + '_SMD'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                      '-$' + (-1 * data[timeframe + '_SMD']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD_PCT'] != null ?
                    (100 * data[timeframe + '_SMD_PCT']).toLocaleString(undefined, { maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_BPS'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_BPS'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_BPS'] != null ?
                    data[timeframe + '_SMD_VLY_BPS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' :
                    'Loading'}
                </Table.Cell>}
                {unit === 'NOM' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_DOL'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_DOL'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_DOL'] != null ?
                    data[timeframe + '_SMD_VLY_DOL'] >= 0 ?
                      '$' + data[timeframe + '_SMD_VLY_DOL'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                      '-$' + (-1 * data[timeframe + '_SMD_VLY_DOL']).toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                    'Loading'}
                </Table.Cell>}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}

      {deptRsnCd === 'rsnCd' && <Header textAlign='center'>Reason Codes - Store Use</Header>}
      {deptRsnCd === 'rsnCd' && <Table textAlign='center' unstackable celled size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reason Code</Table.HeaderCell>
            {unit === 'NOM' && <Table.HeaderCell>SMD</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>SMD</Table.HeaderCell>}
            {unit === 'PCT' && <Table.HeaderCell>vLY</Table.HeaderCell>}
            {unit === 'NOM' && <Table.HeaderCell>vLY</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {storeUseFilter.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/smd/reason?strNbr=${strNbr}&mkdnRsnCd=${data['MKDN_RSN_CD']}`} >
                    {data['MKDN_RSN_CD']}-{data['MKDN_RSN_DESC'].toUpperCase()}
                  </Link>
                </Table.Cell>
                {unit === 'NOM' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD'] != null ?
                    data[timeframe + '_SMD'] >= 0 ?
                      '$' + data[timeframe + '_SMD'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                      '-$' + (-1 * data[timeframe + '_SMD']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine>
                  {data[timeframe + '_SMD_PCT'] != null ?
                    (100 * data[timeframe + '_SMD_PCT']).toLocaleString(undefined, { maximumFractionDigits: 3 }) :
                    'Loading'}
                </Table.Cell>}
                {unit === 'PCT' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_BPS'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_BPS'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_BPS'] != null ?
                    data[timeframe + '_SMD_VLY_BPS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' :
                    'Loading'}
                </Table.Cell>}
                {unit === 'NOM' && <Table.Cell singleLine style={{ color: data[timeframe + '_SMD_VLY_DOL'] < 0 ? 'green' : data[timeframe + '_SMD_VLY_DOL'] > 0 ? 'red' : 'black' }}>
                  {data[timeframe + '_SMD_VLY_DOL'] != null ?
                    data[timeframe + '_SMD_VLY_DOL'] >= 0 ?
                      '$' + data[timeframe + '_SMD_VLY_DOL'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                      '-$' + (-1 * data[timeframe + '_SMD_VLY_DOL']).toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                    'Loading'}
                </Table.Cell>}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}

      {deptRsnCd === 'rsnCd' && <p>Note: Reason Code 7 - Store Use is not part of store markdowns plan and budget.</p>}
    </>
  )
})