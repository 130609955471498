import React, { useState } from 'react'
import {
  Header,
  Segment,
  Grid,
  Loader
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { TimeframeDropdown } from '../../Common/TimeframeDropdown'
import { UnitMenu } from '../../Common/UnitMenu'
import { DataTable } from '../../Common/DataTable'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { useQuery } from 'react-query'
import { getData } from '../../../service/DataService'
import { columnName, METRIC_CONSTANTS } from '../../../utils/constantsByLevel'
import { capitalizeFirstLetter } from '../../../utils/string'

const QUERIES = {
  region: {
    query: 'queryLaborDtlByDeptStrRgn'
  },
  division: {
    query: 'queryLaborDtlByDeptStrDiv'
  }
}

export const LaborDetailDept = ({ timeframe, settimeframe, fpToggle, level }) => {
  const [unit, setUnit] = useState('NOM')
  const { rgnNbr, divNbr, deptNbr: deptNbrInt } = useQueryParams();
  const locNbr = rgnNbr || divNbr;
  const {
    data: laborDetailDeptQuery = [],
    isError,
    isLoading
  } = useQuery([QUERIES[level].query, { locNbr, fpToggle }], getData);

  if (isError) return <p>Something went wrong</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const laborDetailFilter = laborDetailDeptQuery.filter((row) => {
    if (String(row['DEPT_NBR']) === deptNbrInt) {
      return row && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level].subMetric;
    }
    return false;
  })

  const useProjected = new Date().getDay() !== 1 && timeframe === "WTD"
  const laborDetailData = {
    sortable: true,
    sortableDefault: 0,
    headers: [
      { name: capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel) + ' ' },
      { name: 'Hours ' },
      {
        name: useProjected ? 'PROJ O/T ' : 'O/T ',
      },
      { name: unit === 'PCT ' ? 'AvF% ' : 'AvF ' },
      { name: unit === 'PCT ' ? 'AvS% ' : 'AvS ' },
    ],

    data: laborDetailFilter.map(data => [
      {
        stringValue: data[METRIC_CONSTANTS[level].propToRender],
        link: {
          to: `/${METRIC_CONSTANTS[level]?.urlLinkLevel}/labor`,
          params: {
            [METRIC_CONSTANTS[level].urlSubLevelParam]: String(data[METRIC_CONSTANTS[level].propToRender]).padStart(4, '0')
          }
        },
      },
      { numValue: data[timeframe + '_ACT_HRS'] || 0, decimals: 0 },
      { numValue: useProjected ? data[timeframe + '_PROJ_OT_HRS'] : data[timeframe + '_OT_HRS'] || 0, decimals: 0 },
      (unit === 'NOM' ?
        { numValue: data[timeframe + '_ACT_HRS'] - data[timeframe + '_FCST_HRS'] || 0, decimals: 2 } :
        {
          pctValue: (data[timeframe + '_ACT_HRS'] - data[timeframe + '_FCST_HRS']) / data[timeframe + '_FCST_HRS'],
          compareStyle: true,
          decimals: 2
        }
      ),
      (unit === 'NOM' ?
        { numValue: data[timeframe + '_ACT_HRS'] - data[timeframe + 'SCH_WRK_HRS'] || 0, decimals: 2 } :
        {
          pctValue: (data[timeframe + '_ACT_HRS'] - data[timeframe + 'SCH_WRK_HRS']) / data[timeframe + 'SCH_WRK_HRS'],
          compareStyle: true,
          decimals: 2
        }
      )
    ]),
  }

  const currentLevelName = String(laborDetailDeptQuery[0][columnName[level]])?.toLowerCase()
  return (
    <Segment>
      <Grid>
        <Grid.Column width={7}>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${METRIC_CONSTANTS[level].urlParam}=${locNbr}`} >Back to Overview</Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/${level}/labor?${METRIC_CONSTANTS[level].urlParam}=${locNbr}`} >Labor Overview</Link>
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={9}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
        Labor Detail, Dept {deptNbrInt} - {level} {locNbr}, {currentLevelName}
        <Header.Subheader>
          Data as of {laborDetailDeptQuery[0]?.TRNSFM_DT}
        </Header.Subheader>
      </Header>
      <DataTable data={laborDetailData} unit={unit} />
    </Segment>
  )
}

