import { useState } from 'react';
import { useQuery } from 'react-query';
import { REMOVE_REDIS_KEYS } from '../constants/url';
import { fetchData } from '../utils/fetch';
import { AddKeysInput } from './AddKeysInput';
import { Button, List, TransitionGroup } from 'semantic-ui-react';
import { Message } from '../../../../components/Message/Message';

export const KeyList = () => {
  const [keys, setKeys] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const {
    data: { deletedKeys = 0 } = {},
    isError,
    isLoading,
    refetch
  } = useQuery(
    REMOVE_REDIS_KEYS,
    () =>
      fetchData(REMOVE_REDIS_KEYS, 'POST', {
        body: JSON.stringify({
          keys
        })
      }),
    {
      enabled: false,
      onSuccess: () => {
        setKeys([]);
        setShowSuccessMessage(true);
      }
    }
  );

  const addKey = key => {
    setKeys(prev => [...prev, key]);
  };

  const onRemoveKey = key => {
    setKeys(prev => prev.filter(k => k !== key));
  };

  const getKeysString = keys => (keys > 1 ? 'keys' : 'key');

  return (
    <div>
      <AddKeysInput addKey={addKey} isLoading={isLoading} />

      {keys.length > 0 && (
        <Button className='mx-3' loading={isLoading} onClick={() => refetch()} negative>
          Remove {keys.length} {getKeysString(keys.length)} from list
        </Button>
      )}

      <TransitionGroup as={List} selection duration={200}>
        {keys.map(key => (
          <List.Item key={key}>
            <List.Content floated='left'>
              <Button icon='trash' onClick={() => onRemoveKey(key)} size='mini' />
            </List.Content>
            <List.Content verticalAlign='middle'>{key}</List.Content>
          </List.Item>
        ))}
      </TransitionGroup>

      {showSuccessMessage && (
        <Message positive onClose={() => setShowSuccessMessage(false)} timeout={3000}>
          <p>
            <b>
              {deletedKeys} {getKeysString(deletedKeys)}
            </b>{' '}
            successfully deleted.
          </p>
        </Message>
      )}

      {isError && (
        <Message negative timeout={3000}>
          <p>Something went wrong. Please try again</p>
        </Message>
      )}
    </div>
  );
};
