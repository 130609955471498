import { useCallback, useEffect } from 'react';

import { MAX_SUGGESTIONS } from '../../constants/autocomplete';
import { useSuggestions } from '../../hooks/useSuggestions';
import { scrollTo } from '../../utils/elements';
import { Suggestion } from './Suggestion';

export const Suggestions = ({ closeAutocomplete, currentSearch, onSelectSuggestions, searchInputRef, suggestions }) => {
  const onSelectOption = useCallback(
    value => {
      onSelectSuggestions(value);
      closeAutocomplete();
    },
    [closeAutocomplete, onSelectSuggestions]
  );
  const { activeSuggestion, suggestionsFormatted } = useSuggestions({
    onEscapeAction: () => {
      const searchInput = searchInputRef?.current?.inputRef.current;

      if (searchInput) {
        searchInput.blur();
      }
      closeAutocomplete();
    },
    onSelectAction: onSelectOption,
    suggestions
  });

  useEffect(() => {
    if (activeSuggestion < 0 || suggestions.length < MAX_SUGGESTIONS) return;

    const currentIndex = activeSuggestion % suggestions.length;
    scrollTo('.suggestion', currentIndex);
  }, [activeSuggestion, suggestions.length]);

  return (
    <div className='suggestions' style={{ '--autocomplete-max-suggestions': MAX_SUGGESTIONS }}>
      {suggestionsFormatted.map(({ number, value }, index) => (
        <Suggestion
          active={index === activeSuggestion}
          key={number}
          currentSearch={currentSearch}
          number={number}
          onClick={onSelectOption}
          value={value}
        />
      ))}
    </div>
  );
};
