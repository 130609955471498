import React, { useMemo, useState } from 'react';
import { Tab, Header, Menu, Loader } from 'semantic-ui-react';
import { UnitMenu } from '../Common/UnitMenu';
import { AisleBaysByStore } from './AisleBaysByStore';
import { ALL, NOM, PCT, unitPrefix } from '../../utils/constants';
import { DataView } from '../Common/DataView';
import { Link } from '../Common/Link';
import { timeframeCode } from '../Common/timeframeCode';
import { Select } from '../Common/Select';
import useQueryApi from '../../hooks/useQueryApi';
import { getRoundToDecimalString } from '../Common/TableCellUtils';
import { PlaceholderLoading } from '../Loading/PlaceholderLoading';

export const AISLES = 'aisles';
export const BAYS = 'bays';

export const aisleBayHeaders = (unit, view) => {
  const headers = [];
  if (view === AISLES) headers.push({ name: 'Aisle' });
  if (view === BAYS) headers.push({ name: 'Bay' }, { name: 'Dept' });
  headers.push(
    { name: 'Sales Amt' },
    { name: `Sales Comp ${unitPrefix(unit)}` },
    { name: 'Unit Sales' },
    { name: `Unit Comp ${unitPrefix(unit)}` }
  );
  return headers;
};

export const customStyleRow = data => ({ color: data < 0 ? 'red' : 'black' });

export const aisleBayDataByUnit = (aisleBayData, unit) => {
  switch (unit) {
    case NOM:
      return [
        { dollarValue: aisleBayData?.SLS_COMP_NOM, compareStyle: true },
        {
          numValue: getRoundToDecimalString(aisleBayData?.UNT_SLS),
          orderBy: aisleBayData?.UNT_SLS,
          customStyle: customStyleRow(aisleBayData?.UNT_SLS)
        },
        { numValue: aisleBayData?.UNT_COMP_NOM, decimals: 0, compareStyle: true }
      ];
    case PCT:
      return [
        { pctValue: aisleBayData?.SLS_COMP_PCT, compareStyle: true },
        {
          numValue: getRoundToDecimalString(aisleBayData?.UNT_SLS),
          orderBy: aisleBayData?.UNT_SLS,
          customStyle: customStyleRow(aisleBayData?.UNT_SLS)
        },
        { pctValue: aisleBayData?.UNT_COMP_PCT, compareStyle: true }
      ];
    default:
      return [];
  }
};

const aisleDataRow = (aisleData, strNbr, unit) => {
  if (!aisleData) return [];
  return [
    {
      stringValue: aisleData?.AISLE,
      link: {
        to: `/store/aisle`,
        params: {
          strNbr,
          aisle: aisleData?.AISLE
        }
      }
    },
    { dollarValue: aisleData?.SLS_AMT },
    ...aisleBayDataByUnit(aisleData, unit)
  ];
};

export const AisleBayTab = (({ timeframe, location, fpToggle, strNm }) => {
  const search = new URLSearchParams(location.search);
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0;

  const [activeTab, setActiveTab] = useState(AISLES);
  const [deptSelected, setDeptSelected] = useState(ALL);
  const [unit, setUnit] = useState(NOM);


  const {
    isError: isErrorDeptSls,
    isLoading: isLoadingDeptSls,
    data: dataSlsDeptByStr = [],
  } = useQueryApi('querySlsDeptByStr', { strNbr, fpToggle })

  const items = useMemo(
    () =>
      dataSlsDeptByStr
        .filter(dept => dept?.DEPT_NBR && dept.DEPT_NBR !== 78)
        .map(dept => ({
          value: dept.DEPT_NBR,
          text: dept.MER_DEPT_NM
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [dataSlsDeptByStr]
  );

  const {
    isError: isErrorAisleBay,
    isLoading: isLoadingAisleBay,
    data: aisleData = [],
  } = useQueryApi('querySwmLocAisleDtl', { strNbr, fpToggle })
  const aisleDataFiltered = aisleData.filter(row => row && row?.TRNSFM_TYP_SORT_ID === timeframeCode[timeframe]);
  const { 
    data: aisleBayDeptData = [], 
    isError: isErrorAisleBayDept, 
    isLoading: isLoadingAisleBayDept
  } = useQueryApi('querySwmLocAisleDept', { strNbr, deptNbrInt: deptSelected, fpToggle });
  const dataFiltered = aisleBayDeptData?.filter(row => row && row?.TRNSFM_TYP_SORT_ID === timeframeCode[timeframe]);

  const aisleDataQuery = useMemo(
    () => (deptSelected === ALL ? aisleDataFiltered : dataFiltered),
    [deptSelected, dataFiltered, aisleDataFiltered]
  );

  const isLoading = isLoadingAisleBay || isLoadingAisleBayDept || isLoadingDeptSls;
  const isError = isErrorAisleBay || isErrorAisleBayDept || isErrorDeptSls;

  if (isError) return <Header>Something went wrong fetching data.</Header>;
  if (isLoading) return <PlaceholderLoading />;

  const title =
    deptSelected === ALL ? 'All Aisles' : `Aisles - ${items?.find(item => item.value === parseInt(deptSelected)).text}`;
  const dataAsOf = `Data as of ${aisleDataFiltered[0]?.TRNSFM_DT}`;

  const aisleDataTable = {
    sortable: true,
    sortableDefault: 0,
    headers: aisleBayHeaders(unit, AISLES),
    data: aisleDataQuery?.map(row => aisleDataRow(row, strNbr, unit))
  };

  const isAislesView = activeTab === AISLES;
  const isBaysView = activeTab === BAYS;

  return (
    <Tab.Pane>
      <DataView className='my-3'>
        <DataView.Title>
          Store {strNbr}, {strNm}
          <Header.Subheader>{dataAsOf}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='d-flex flex-column gap-3'>
          <section className='d-flex justify-content-between' style={{ alignItems: 'flex-end' }}>
            <Menu compact size='small'>
              <Menu.Item name='AISLES' active={isAislesView} onClick={() => setActiveTab(AISLES)} />
              <Menu.Item name='BAYS' active={isBaysView} onClick={() => setActiveTab(BAYS)} />
            </Menu>

            <section style={{ textAlign: 'right' }}>
              <Select
                label='Dept:'
                placeholder='Dept'
                items={[{ value: ALL, text: ALL }, ...items]}
                value={deptSelected}
                onChange={setDeptSelected}
              />
            </section>
          </section>

          <section>
            <p className='my-0'>Units:</p>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </section>

          {isAislesView && (
            <section className='d-flex align-items-center flex-column'>
              <Link to={`/store/baysbystore?strNbr=${strNbr}`}>View Bays by Store</Link>
            </section>
          )}
        </DataView.Controls>
      </DataView>

      {/* Aisles */}
      {isAislesView && (
        <DataView>
          <Header textAlign='center'>{title}</Header>
          <section>
            {isLoading && (
              <Loader active inline className='full-width'>
                Loading...
              </Loader>
            )}
          </section>

          <DataView.DataTable data={aisleDataTable} />
        </DataView>
      )}

      {/* Bays */}
      {isBaysView && (
        <AisleBaysByStore
          strNbr={strNbr}
          fpToggle={fpToggle}
          unit={unit}
          deptSelected={deptSelected}
          timeframe={timeframe}
        />
      )}
    </Tab.Pane>
  );
});
