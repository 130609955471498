import { useMemo, useState } from 'react'
import {
  Loader,
  Header,
  Checkbox,
  Grid,
  Modal,
  Segment,
  Button,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { DataTable } from '../Common/DataTable'

import { useQuery } from 'react-query'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useWindowsDimensions } from '../../hooks/useWindowDimensions'

import { buildOrderViewUrl } from '../../utils/urls'
import { getData } from '../../service/DataService'
import { getDollarString } from '../Common/TableCellUtils'
import { uniqueValues } from '../../utils/array'
import { formatSku } from '../../utils/formatter'

export const SmdStrRsn = ({ fpToggle }) => {
  const { isDesktop, isMobile } = useWindowsDimensions()
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState([])
  const [deptNbr, setDeptNbr] = useState("All")
  const [pos, setPos] = useState("POS")
  const [occ, setOcc] = useState('N')
  const [timeframe, setTimeframe] = useState("WTD")

  const { strNbr = 0, mkdnRsnCd = 0 } = useQueryParams();
  const { data: smdRsnData, isLoading } = useQuery(['querySmdSkuByStrRsn', { strNbr, mkdnRsnCd, fpToggle }], getData)
  const { data: smdRsnCdData, isLoading: isRsnCdLoading } = useQuery(['querySmdStrByRsnCd', { strNbr, mkdnRsnCd, fpToggle }], getData)

  
  const dataFiltered = useMemo(() => {
    if (isLoading || isRsnCdLoading) return []

    return smdRsnData.filter((row) => {
      return row
        && (deptNbr === 'All' || row['MER_DEPT_NBR'].toString() === deptNbr)
        && (row['POS_FLG'] === pos || pos === 'NON-POS') // If POS is selected, we only was POS. 
        && (row['OCC_MKDN_FLAG'] === occ || occ === 'N') // If OCC is selected, we only want OCC.
        && row[timeframe + '_FLG'] === 1
    })
  }, [deptNbr, isLoading, occ, pos, smdRsnData, timeframe, isRsnCdLoading])

  const data = useMemo(() => {
    return dataFiltered.map(row => {
      const skuNbr = formatSku(row['SKU_NBR'].toString())
      const creator = getCreatorDisplayValue(row)
      const approver = getApproverDisplayValue(row)

      return [
        {
          customStyle: { color: '#EE7125', textDecoration: 'underline' },
          onClick: () => { setOpen(true); setModalData(row) },
          stringValue: skuNbr,
        },
        { stringValue: row['BUS_DT'] },
        { dollarValue: row['NET_MUMD_AMT'], decimals: 2 },
        {
          link: isDesktop && { // Only show the link if we're not on mobile
            to: buildOrderViewUrl(row['EXTN_HOST_ORDER_REF']),
          },
          stringValue: row['EXTN_HOST_ORDER_REF'],
        },
        { stringValue: row['UNT_CNT'] },
        { stringValue: creator },
        { stringValue: approver },
      ]
    })
  }, [dataFiltered, isDesktop])

  if (isLoading || isRsnCdLoading) {
    return <Loader active>Loading...</Loader>
  }

  const rowOne = smdRsnData[0]
  const smdStoreData = smdRsnCdData[0]
  const depts = uniqueValues(smdRsnData.map(data => data['MER_DEPT_NBR']))
  const deptsSorted = depts.sort()

  const dataObj = {
    data,
    headers: [
      { name: 'SKU #' },
      { name: 'MKDN Date' },
      { name: 'MKDN $ Total' },
      { name: 'Order* #' },
      { name: 'Qty' },
      { name: 'CRT' },
      { name: 'APR' },
    ],
    pagination: true,
    sortable: true,
    sortableDefault: 1,
  }

  return (
    <>
      <Link
        style={{ color: '#EE7125' }}
        to={`/store/smd?strNbr=${strNbr}`}
        data-test='b2o-link'>
        Back to SMD Detail
      </Link>

      <Grid>
        <Grid.Row>
          <Grid.Column className='mt-3'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Header textAlign='left'>
                SMD - Reason Codes - Store {strNbr}, {smdStoreData?.STR_NM}
                <Header.Subheader>Data as of {smdStoreData?.TRNSFM_DT}</Header.Subheader>
              </Header>
              <div>
                <Checkbox
                  label='POS?'
                  onChange={(e, data) => { data.checked ? setPos('POS') : setPos('NON-POS') }}
                  checked={pos === 'POS'}
                />
                <Grid.Row>&nbsp;</Grid.Row>
                <Checkbox
                  label='OCC?'
                  onChange={(e, data) => { data.checked ? setOcc('Y') : setOcc('N') }}
                  checked={occ === 'Y'}
                  disabled={pos === 'POS'}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '0' }}>Dept:</p>
                <div>
                  <select
                    name="depts"
                    onChange={e => {
                      setDeptNbr(e.target.value)
                    }}
                    value={deptNbr}
                    style={{ padding: 10 }}
                  >
                    <option value="All">All</option>
                    {deptsSorted.map((row) => <option key={row} value={row}>{row}</option>)}
                  </select>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: '0', marginTop: '1rem' }}>Timeframe:</p>
                <div>
                  <select
                    name="timeframe"
                    onChange={e => {
                      setTimeframe(e.target.value)
                    }}
                    value={timeframe}
                    style={{ padding: 10 }}
                  >
                    <option value="WTD">WTD</option>
                    <option value="LW">LW</option>
                    <option value="MTD">MTD</option>
                  </select>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {rowOne ? <Header textAlign='center'>{rowOne['MKDN_RSN_CD']}-{rowOne['MKDN_RSN_DESC']}</Header> : <Header textAlign='center'>Reason Code {mkdnRsnCd} - No markdowns found.</Header>}
      <DataTable data={dataObj} />
      {open && <Modal
        closeIcon
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <Segment color='orange'>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header textAlign='center'>
                  {modalData['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    modalData['SKU_NBR'].toString().substring(0, 4) + '-' + modalData['SKU_NBR'].toString().substring(4, 7) + '-' + modalData['SKU_NBR'].toString().substring(7) :
                    modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}
                </Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Header>SKU Description</Header>
              </Grid.Row>
              <Grid.Row><p>{modalData['SKU_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Markdown Details</Header></Grid.Row>
              <Grid.Row>Reason Code: {mkdnRsnCd + '-' + modalData['MKDN_RSN_DESC']}</Grid.Row>
            </Grid.Column>
            &nbsp;
            &nbsp;
            <Grid>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>MKDN Date</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{modalData['BUS_DT']}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>MKDN $ Per Item</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{getDollarString(modalData['NET_MUMD_AMT'] / modalData['UNT_CNT'], 2)}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>Item Quantity</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{modalData['UNT_CNT']}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>Total MKDN $</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{getDollarString(modalData['NET_MUMD_AMT'], 2)}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>Order Number</Grid.Column>
                <Grid.Column width={8} textAlign='left'>
                  {isMobile ?
                    modalData['EXTN_HOST_ORDER_REF'] :
                    <a style={{ color: '#EE7125' }} href={buildOrderViewUrl(modalData['EXTN_HOST_ORDER_REF'])}>{modalData['EXTN_HOST_ORDER_REF']}</a>
                  }
                </Grid.Column>

              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>Created By</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{modalData['CRT_USER_ID'] ?
                  isCreatorASystemUser(modalData['CRT_USER_ID']) ? modalData['CRT_USER_ID'] :
                    modalData['CRT_USER_NM'] + ' (' + modalData['CRT_USER_ID'] + ')' : 'N/A'}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>Approver</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{modalData['APR_USER_ID'] ?
                  isApproverASystemUser(modalData['APR_USER_ID']) ? modalData['APR_USER_ID'] :
                    modalData['APR_USER_NM'] + ' (' + modalData['APR_USER_ID'] + ')' : 'N/A'}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>Register Number</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{modalData['RGSTR_NBR']}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>Transaction Number</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{modalData['POS_TRANS_ID']}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8} textAlign='left' style={{ fontWeight: 'bold' }}>MKDN Control Number</Grid.Column>
                <Grid.Column width={8} textAlign='left'>{modalData['MKUP_MKDN_CTRL_NBR']}</Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid.Column>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='Close'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>}
    </>
  )

}

export const getApproverDisplayValue = data => {
  if (!data['APR_USER_ID'] || !data['APR_USER_NM']) return 'N/A'
  if (isApproverASystemUser(data['APR_USER_ID'])) return data['APR_USER_ID']

  return data['APR_USER_NM']
}

export const getCreatorDisplayValue = data => {
  if (!data['CRT_USER_ID']) return 'N/A'
  if (isCreatorASystemUser(data['CRT_USER_ID'])) return data['CRT_USER_ID']

  return data['CRT_USER_NM']
}
export const isApproverASystemUser = userId => ['N/A', 'SYSTEM'].some(v => userId.includes(v))
export const isCreatorASystemUser = userId => ['SELF', 'SLFL', 'SPOS01', 'PTM01'].some(v => userId.includes(v))
