import { useState } from 'react';
import { Button, Input } from 'semantic-ui-react';

export const AddKeysInput = ({ addKey, isLoading }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddKeys = () => {
    addKey(inputValue);
    setInputValue('');
  }

  return (
    <Input
      disabled={isLoading}
      label={<Button disabled={isLoading} onClick={handleAddKeys} primary>Add key</Button>}
      labelPosition='right'
      onChange={e => setInputValue(e.target.value)}
      placeholder='Type a key'
      value={inputValue}
    />
  );
};
