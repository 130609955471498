import { Component, cloneElement } from 'react';
import { Message } from 'semantic-ui-react';
import { addParamsToUrl, getUrlParam } from '../../utils/url';
import { isProduction } from '../../utils/env';

const SLACK_WEBHOOK_URL = process.env.REACT_APP_SLACK_WEBHOOK;

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, isLoadingNotification: false, succesfullyNotified: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    if (!isProduction) return;

    const notNotify = getUrlParam('notNotify');
    if (notNotify) return;

    this.setState({ ...this.state, isLoadingNotification: true });
    const errorUrl = addParamsToUrl({ notNotify: 'true' });
    fetch(SLACK_WEBHOOK_URL, {
      body: JSON.stringify({
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: ':alert: *Application Error* :alert:'
            }
          },
          {
            type: 'divider'
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `\`\`\`${error?.message || error}\n\`\`\``
            }
          },
          {
            type: 'actions',
            elements: [
              {
                type: 'button',
                text: {
                  type: 'plain_text',
                  text: 'Take me to error page'
                },
                url: errorUrl,
                style: 'primary'
              }
            ]
          }
        ]
      }),
      method: 'POST'
    }).then(response => {
      if (response.ok) this.setState({ ...this.state, isLoadingNotification: false, succesfullyNotified: true });
    });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return cloneElement(this.props.fallback, { error: this.state.error });
      }

      return (
        <Message negative>
          <Message.Header>Something went wrong.</Message.Header>
          {!isProduction && <p>{this.state.error.message}</p>}
          {isProduction && this.state.isLoadingNotification && <p>Notifying the development team</p>}
          {isProduction && this.state.succesfullyNotified && <p>Development team notified!</p>}
        </Message>
      );
    }

    return this.props.children;
  }
}
