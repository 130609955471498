import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router'
import { Container, Loader, Header } from 'semantic-ui-react';
import ReactGA from 'react-ga4';
import { ReactQueryDevtools } from 'react-query/devtools';
import './App.css';
import { EditAlert } from './components/Support/EditAlert';
import { useUserContext } from './hooks/useUserContext';
import { useLevelContext } from './hooks/useLevelContext';
import { buildBaseShrinkUrl } from './components/ShrinkContainer/utils/url';

// TODO: REMOVE THIS AFTER THE LEGACY PAGE IS SHOT DOWN
import { LegacyPageNotice } from './components/Support/LegacyPageNotice';
import { useWindowsDimensions } from './hooks/useWindowDimensions';

import { NewChangeLog } from './components/Support/NewChangeLog';
import { PageTables } from './components/Support/PageTables';

import { CacheCleaner } from './modules/admin/redis/pages/CacheCleaner';
import { DeniedMenu } from './components/Menus/DeniedMenu';
import { LoginMenu } from './components/Menus/LoginMenu';
import { TopMenu } from './components/Menus/TopMenu';
import { NotFoundPage } from './modules/level/features/notFound/pages/NotFoundPage';
import { ErrorBoundary } from './components/Common/ErrorBoundary';
import { LandingPage } from './modules/level/features/landing/pages/LandingPage';

import { Overview } from './components/Overviews/Overview';
import { ClassDrilldown } from './components/ProdHierDrilldown/ClassDrilldown';
import { DeptDrilldown } from './components/ProdHierDrilldown/DeptDrilldown';
import { SubclassDrilldown } from './components/ProdHierDrilldown/SubclassDrilldown';
import { CoreDrillDown } from './modules/core/features/overview/components/Tabs/Sales/Drilldown/CoreDrillDown';

import { AisleDrilldown } from './components/LocDrilldown/AisleDrilldown';
import { BayDrilldown } from './components/LocDrilldown/BayDrilldown';
import { BaysByStore } from './components/LocDrilldown/BaysByStore';
import { BaysByStoreBayDrilldown } from './components/LocDrilldown/BaysByStoreBayDrilldown';
import { DeptLocAisleDrilldown } from './components/LocDrilldown/DeptLocAisleDrilldown';
import { DeptLocBayDrilldown } from './components/LocDrilldown/DeptLocBayDrilldown';
import { DeptLocDrilldown } from './components/LocDrilldown/DeptLocDrilldown';

import { InvClassDrilldown } from './components/InventoryDrilldown/InvClassDrilldown';
import { InvDeptDrilldown } from './components/InventoryDrilldown/InvDeptDrilldown';
import { InvSubclassDrilldown } from './components/InventoryDrilldown/InvSubclassDrilldown';

import { LaborDetail } from './components/Details/LaborDetail';
import { LaborDetailDst } from './components/Details/LaborDetailDst';
import { LeadsAssocView } from './components/Details/LeadsAssocView';
import { LeadsDetail } from './components/Details/LeadsDetail';
import { LeadsDetailDst } from './components/Details/LeadsDetailDst';
import { LtsaDetail } from './components/Details/LtsaDetail';
import { LtsaDetailDst } from './components/Details/LtsaDetailDst';
import { MeasAssocView } from './components/Details/MeasAssocView';
import { MeasDetail } from './components/Details/MeasDetail';
import { MeasDetailDst } from './components/Details/MeasDetailDst';
import { SmdRgnRsn } from './components/Details/Region/SmdRgnRsn';
import { SmdDstRsn } from './components/Details/SmdDstRsn';
import { SmdStrRsn } from './components/Details/SmdStrRsn';
import { VocDetail } from './components/Details/VocDetail';
import { VocDetailDst } from './components/Details/VocDetailDst';
import { CoreLeads } from './modules/core/features/overview/pages/leads/CoreLeads';

import { DailyDeptView } from './components/HourlyDaily/DailyDeptView';
import { DailyView } from './components/HourlyDaily/DailyView';
import { IntradayDeptView } from './components/HourlyDaily/IntradayDeptView';
import { StoreOrderFulfillmentPage } from './modules/store/features/orderfulfillment/pages/StoreOrderFulfillmentPage';
import { SpecialtyProRentalProvider } from './components/SpecialtyProRentalContainer/context/SpecialtyProRentalContext';
import { SpecialtyProRentalPage } from './components/SpecialtyProRentalContainer/pages/SpecialtyProRentalPage';
import { ViewAlert } from './components/Support/ViewAlert';
import { ViewChangeLog } from './components/Support/ViewChangeLog';

import { Shrink } from './components/Shrink/Shrink';
import { ShrinkAgedWc } from './components/Shrink/ShrinkAgedWc';
import { ShrinkBossCancel } from './components/Shrink/ShrinkBossCancel';
import { ShrinkClearanceNh } from './components/Shrink/ShrinkClearanceNh';

import { ShrinkInvalidScans } from './components/Shrink/ShrinkInvalidScans';
import { ShrinkOhEmptPkg } from './components/Shrink/ShrinkOhEmptPkg';
import { ShrinkOhEmptPkgDept } from './components/Shrink/ShrinkOhEmptPkgDept';
import { ShrinkReducedTag } from './components/Shrink/ShrinkReducedTag';
import { ShrinkRtvCredit } from './components/Shrink/ShrinkRtvCredit';
import { ShrinkRtvDeletedTag } from './components/Shrink/ShrinkRtvDeletedTag';
import { ShrinkRtvDeletedTagsPage } from './components/ShrinkContainer/pages/ShrinkRtvDeletedTagsPage';
import { DstShrinkAgedWc } from './components/ShrinkDst/DstShrinkAgedWc';
import { DstShrinkBossCancel } from './components/ShrinkDst/DstShrinkBossCancel';
import { DstShrinkClearanceNh } from './components/ShrinkDst/DstShrinkClearanceNh';
import { DstShrinkInvalidScans } from './components/ShrinkDst/DstShrinkInvalidScans';
import { DstShrinkOhEmptPkg } from './components/ShrinkDst/DstShrinkOhEmptPkg';
import { DstShrinkReducedTag } from './components/ShrinkDst/DstShrinkReducedTag';
import { ShrinkReducedTagPage } from './components/ShrinkContainer/pages/ShrinkReducedTagPage';
import { CoreReducedTagPage } from './modules/core/features/shrink/components/CoreReducedTagPage';
import { DstShrinkRtvCredit } from './components/ShrinkDst/DstShrinkRtvCredit';

import { ShrinkStoreClass } from './components/Shrink/ShrinkStoreClass';
import { ShrinkStoreDept } from './components/Shrink/ShrinkStoreDept';
import { ShrinkStoreSubClass } from './components/Shrink/ShrinkStoreSubClass';
import { DstShrinkClass } from './components/ShrinkDst/DstShrinkClass';
import { DstShrinkDept } from './components/ShrinkDst/DstShrinkDept';
import { ShrinkDeptPage } from './components/ShrinkContainer/pages/ShrinkDeptPage';
import { DstShrinkSubClass } from './components/ShrinkDst/DstShrinkSubClass';
import { ShrinkClassPage } from './components/ShrinkContainer/pages/ShrinkClassPage';
import { ShrinkSubclassPage } from './components/ShrinkContainer/pages/ShrinkSubclassPage';
import { ShrinkPage } from './components/ShrinkContainer/pages/ShrinkPage';
import { ShrinkProvider } from './components/ShrinkContainer/hooks/ShrinkProvider';
import { OhAutoRejectPage } from './components/ShrinkContainer/pages/OhAutoRejectPage';
import { OhAutoRejectCorePage } from './modules/core/features/shrink/pages/OhAutoRejectCorePage';
import { AccuracyAndReceivingMetricPage } from './components/ShrinkContainer/pages/AccuracyAndReceivingMetricPage';
import { ShrinkDamagedMarkdownsPage } from './components/ShrinkContainer/pages/ShrinkDamagedMarkdownsPage';
import { ShrinkCullMarkdownsPage } from './components/ShrinkContainer/pages/ShrinkCullMarkdownsPage';
import { ShrinkDamagedCullMarkdownsPage } from './components/ShrinkContainer/pages/ShrinkDamagedCullMarkdownsPage';

import { SmdRgnDept } from './components/Details/Region/SmdRgnDept';
import { SafetyDetail } from './components/Details/SafetyDetail';
import { SafetyDetailDst } from './components/Details/SafetyDetailDst';
import { SmdDetailDiv } from './components/Details/SmdDetailDiv';
import { SmdDetailDst } from './components/Details/SmdDetailDst';
import { SmdDetailRgn } from './components/Details/SmdDetailRgn';
import { SmdDetailStr } from './components/Details/SmdDetailStr';
import { SmdDstDept } from './components/Details/SmdDstDept';
import { SmdStrDept } from './components/Details/SmdStrDept';
import { SmdStrDeptRsn } from './components/Details/SmdStrDeptRsn';
import { Events } from './components/Events/Events';
import { EventsDetailDst } from './components/Events/EventsDetailDst';
import { EventsDetailStr } from './components/Events/EventsDetailStr';
import { HeadlinesInsights } from './components/HeadlinesInsights/HeadlinesInsights';
import { OHMNoLocationTags } from './components/OSA/OHMNoLocationTags';
import { OHMNoLocationTagsByDept } from './components/OSA/OHMNoLocationTagsByDept';
import { OHMNoLocationTagsByDeptTag } from './components/OSA/OHMNoLocationTagsByDeptTag';
import { OHMNoLocationTagsDst } from './components/OSA/OHMNoLocationTagsDst';
import { OHMNoLocationTagsLevel } from './components/OSA/OHMNoLocationTagsLevel';
import { OsaOhmNoLocTagsPage } from './modules/core/features/overview/pages/osa/OsaOhmNoLocTagsPage';
import { OSAImaging } from './components/OSA/OSAImaging';
import { OSAImagingDept } from './components/OSA/OSAImagingDept';
import { OSAImagingDeptAisle } from './components/OSA/OSAImagingDeptAisle';
import { OSAImagingDist } from './components/OSA/OSAImagingDist';
import { OSALanding } from './components/OSA/OSALanding';
import { DstAutoKR } from './components/ShrinkDst/DstAutoKR';
import { DstShrinkRtvDeletedTag } from './components/ShrinkDst/DstShrinkRtvDeletedTag';
import { DstUnmatchedXfers } from './components/ShrinkDst/DstUnmatchedXfers';
import { ServicesDtlDstPrgm } from './components/SpecialtyProRental/ServicesDtlDstPrgm';
import { SpecialtyProRentalServicesPage } from './components/SpecialtyProRentalContainer/pages/SpecialtyProRentalServicesPage';

import { LaborContextProvider } from './components/Details/LaborContextProvider';
import { LaborDetailAssoc } from './components/Details/LaborDetailAssoc';
import { LaborDetailDeptByDst } from './components/Details/LaborDetailDeptByDst';
import { LaborDetailMgrDeptByDst } from './components/Details/LaborDetailMgrDeptByDst';
import { LaborDetailDept } from './components/Details/Region/LaborDetailDept';
import { LaborDetailDeptManager } from './components/Details/Region/LaborDetailDeptManager';
import { ManagerAddedBays } from './components/OSA/ManagerAddedBays';
import { ManagerAddedBaysDetails } from './components/OSA/ManagerAddedBaysDetails';
import { SidekickSkuBayBayDetail } from './components/OSA/SidekickSkuBayBayDetail';
import { SidekickSkuBayDept } from './components/OSA/SidekickSkuBayDept';
import { SidekickSkuBayLoggedWork } from './components/OSA/SidekickSkuBayLoggedWork';
import { SideKickSkuBayStr } from './components/OSA/SideKickSkuBayStr';
import { Sidekickskubay as SidekickskubayCore } from './modules/core/features/overview/pages/osa/Sidekickskubay';

import { CoreSafety } from './modules/core/features/overview/pages/safety/CoreSafety';
import { DailySalesDepartment } from './modules/core/features/dailySales/pages/DailySalesDepartment';

import { useFetchLevelName } from './hooks/useFetchLevelName';
import { EventsPage } from './modules/core/features/events/pages/EventsPage';
import { CoreEventsDetailPage } from './modules/core/features/events/pages/CoreEventsDetailPage';
import { SubClassToSku } from './modules/core/features/overview/components/Tabs/Sales/Drilldown/SubClassToSku';

import { EventsDetailRgn } from './components/Events/EventsDetailRgn';
import { SideKickPurge } from './components/OSA/SideKickPurge';
import { SideKickPurgeDeptBay } from './components/OSA/SideKickPurgeDeptBay';
import { SideKickPurgeDeptBayByBay } from './components/OSA/SideKickPurgeDeptBayByBay';
import { SideKickPurgeDeptPurge } from './components/OSA/SideKickPurgeDeptPurge';
import { SideKickPurgeDeptPurgeByBay } from './components/OSA/SideKickPurgeDeptPurgeByBay';
import { SideKickPurgePage } from './components/OSA/SideKickPurgePage';
import { SideKickSkuBay } from './components/OSA/SideKickSkuBay';
import { RegionVocSummary } from './components/Overviews/Region/RegionVocSummary';
import IntradayContextProvider from './context/IntradayContext';
import OverviewContextProvider from './context/OverviewContext';
import { useQueryParams } from './hooks/useQueryParams';
import { DailySalesOverview } from './modules/core/features/dailySales/pages/DailySalesOverview';
import { CoreSalesDepartmentProvider } from './modules/core/features/overview/context/CoreSalesDepartmentContext';
import { IntradayDeptViewCore } from './modules/core/features/overview/pages/intradayDeptView/IntradayDeptViewCore';
import { LtpaOverviewPage } from './modules/core/features/overview/pages/Ltpa/LtpaOverviewPage';
import { LtsaPage } from './modules/core/features/overview/pages/LtsaPage';
import { MeasurePage } from './modules/core/features/overview/pages/MeasurePage';
import { OsaImagingPage } from './modules/core/features/overview/pages/osa/OsaImagingPage';
import { OsaOverviewPage } from './modules/core/features/overview/pages/osa/OsaOverviewPage';
import { OSAPurge } from './modules/core/features/overview/pages/osa/OSAPurge';
import { SmdDepartmentPage } from './modules/core/features/overview/pages/smd/SmdDepartmentPage';
import { SmdDetailPage } from './modules/core/features/overview/pages/smd/SmdDetailPage';
import { SmdReasonCodesPage } from './modules/core/features/overview/pages/smd/SmdReasonCodesPage';
import { InvalidScansPage } from './components/ShrinkContainer/pages/InvalidScansPage';
import LaborPage from './modules/core/features/overview/pages/LaborPage';
import { LaborDetailDeptDrill } from './modules/core/features/overview/components/labor/LaborDetailDeptDrill';
import { LaborDetailDeptMgr } from './modules/core/features/overview/components/labor/LaborDetailDeptMgr';
import { OrderFulfillmentPage } from './modules/level/features/orderfulfillment/pages/OrderFulfillmentPage';
import { CoreInventorySubClass } from './modules/core/features/overview/components/inventory/CoreInventorySubClass';
import { CoreInventoryClass } from './modules/core/features/overview/components/inventory/CoreInventoryClass';
import { CoreInventoryDrill } from './modules/core/features/overview/components/inventory/CoreInventoryDrill';
import { ShrinkCoreProvider } from './modules/core/context/CoreShrinkContext';
import { CoreShrinkACCRCVProvider } from './modules/core/context/CoreShrinkACCRCVContext';
import { AccuracyAndReceivingMetric } from './modules/core/features/shrink/components/ACCRCV/AccuracyAndReceivingMetric';
import ShrinkPageCore from './modules/core/features/shrink/pages/ShrinkPageCore';
import { InvalidScansCore } from './modules/core/features/shrink/components/InvalidScansCore';
import { ClearanceNoHomesPage } from './components/ShrinkContainer/pages/ClearanceNoHomesPage';
import { AgedWithCallsPage } from './components/ShrinkContainer/pages/AgedWithCallsPage';
import { RTVCreditCore } from './modules/core/features/shrink/components/RTVCreditCore';
import { RTVCreditsPage } from './components/ShrinkContainer/pages/RTVCreditsPage';
import { OnHandAdjustmentEPPage } from './components/ShrinkContainer/pages/OnHandAdjustmentEPPage';
import { AgedWillCallsCore } from './modules/core/features/shrink/components/AgedWillCallsCore';
import { CoreShrinkDeptPage } from './modules/core/features/shrink/pages/CoreShrinkDeptPage';
import { RTVDeletingCore } from './modules/core/features/shrink/components/RTVDeletingCore';
import { ShrinkBossCancel as ShrinkBossCancelRgnAndDiv } from './components/ShrinkContainer/pages/ShrinkBossCancel';
import { ShrinkBossCancelPage as CoreShrinkBossCancelPage } from './modules/core/features/shrink/pages/ShrinkBossCancelPage';
import { ClearanceNoHomesCorePage } from './modules/core/features/shrink/components/ClearanceNoHomesCorePage';
import { OnHandAdjustmentCore } from './modules/core/features/shrink/pages/OnHandAdjustmentCore';
import { CoreShrinkClassPage } from './modules/core/features/shrink/pages/CoreShrinkClassPage';
import { CoreShrinkSubClassPage } from './modules/core/features/shrink/pages/CoreShrinkSubClassPage';
import { SpecialtyProRentalServicesPrgmPage } from './components/SpecialtyProRentalContainer/pages/SpecialtyProRentalServicesPrgmPage';
import { SpecialtyProRentalServicesProvider } from './components/SpecialtyProRentalContainer/context/SpecialtyProRentalServicesContext';
import { SPEC_PRO_RENTAL_PATH } from './components/SpecialtyProRentalContainer/utils/consts';
import { CoreServicesPrgmPage } from './modules/core/features/specialtyProRental/pages/CoreServicesPrgmPage';
import { CreditPage } from './components/Credit/CreditContainer/pages/CreditPage';
import CreditContextProvider from './components/Credit/CreditContainer/context/CreditContextProvider';
import { ShrinkKeyedSkus } from './components/Shrink/ShrinkKeyedSkus';
import { KeyedSkuPage } from './components/ShrinkContainer/pages/KeyedSkuPage';
import { CoreKeyedSkuPage } from './modules/core/features/shrink/pages/CoreKeyedSkuPage';
import { ProtectedRoute } from './components/Common/ProtectedRoute';

const App = (({ location }) => {
  const myLocation = useLocation();
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  let levelFromUrl = window.location.pathname.split('/').filter(Boolean)[0];

  useEffect(() => {
    // ReactGA.pageview(location.pathname); // Record a pageview for the given page
    ReactGA.send({ hitType: 'pageview', page: myLocation.pathname }); // ReactGA4 version
  }, [myLocation, strNbr, dstNbr, rgnNbr, divNbr, levelFromUrl]);

  // levelFromUrl should be one of the following: store, district, region, division, core. Default to store if not one of those values
  if (!['store', 'district', 'region', 'division', 'core'].includes(levelFromUrl)) {
    // There are some pages, like OSA, that don't have a level in the URL. Use the number variables to determine the level
    levelFromUrl = 'store';
    if (dstNbr) {
      levelFromUrl = 'district';
    } else if (rgnNbr) {
      levelFromUrl = 'region';
    } else if (divNbr) {
      levelFromUrl = 'division';
    }
  }

  const {
    level,
    setLevel,
    levelNbr,
    setLevelNbr,
  } = useLevelContext();
  const [levelSelection, setLevelSelection] = useState(level);
  const [storeNumberField, setStoreNumberField] = useState(levelNbr);
  const [timeframe, settimeframe] = useState('WTD');
  const [metric, setMetric] = useState('sales');
  const [shrinkTimeframe, setShrinkTimeframe] = useState('LAST_INV');
  const [clearedAlerts, setClearedAlerts] = useState(false);
  const LEVEL_ACCESS = {
    store: 1,
    district: 2,
    region: 3,
    division: 4,
    core: 5
  }

  const [isLegacyAlertOpen, setIsLegacyAlertOpen] = useState(false);
  const [unit, setUnit] = useState('NOM');

  const { 
    isCorporate: userIsCorporate, 
    getsnplus1: userGetsNPlus1,
    emplctgrynm: userEmplCtgryNm,
    mgtlvlid: userMgtLvlId,
    jobprofilenm: jobProfileNm,
    isAdmin: userIsAdmin,
    userLevelAccess,
    platformStatus,
    fpToggle,
  } = useUserContext();

  const { currentLevelName: levelName } = useFetchLevelName({
    level,
    locNbr: strNbr || dstNbr || rgnNbr || divNbr,
    fpToggle
  });

  useEffect(() => {
    setStoreNumberField(levelNbr);
    setLevelSelection(level);
  }, [level, levelNbr]);

  // TODO: REMOVE THIS AFTER THE LEGACY PAGE IS SHOT DOWN
  const { isDesktop } = useWindowsDimensions();
  useEffect(() => {
    const LEGACY_PAGE = 'storewalk.homedepot.com';
    const TESTING_PAGE = 'storewalk-np.homedepot.com';
    const isLegacyPage = window.location.host === LEGACY_PAGE || window.location.host === TESTING_PAGE;
    setIsLegacyAlertOpen(isLegacyPage);
  }, []);

  const baseCoreShrinkUrl = buildBaseShrinkUrl('core');
  const baseLevelShrinkUrl = buildBaseShrinkUrl();

  switch (platformStatus) {
    case 'loading':
    case 'assumedFP':
    case 'FPLoaded':
    case 'FPloggedIn':
    case 'assumedDesktop':
      return <Loader active>Loading</Loader>;
    case 'permissionDenied':
      return <DeniedMenu />;
    case 'loggedIn':
      return (
        <Container className='App'>
          {isLegacyAlertOpen && <LegacyPageNotice isSticky={isDesktop} />}
          <TopMenu
            level={level}
            setLevel={setLevel}
            levelSelection={levelSelection}
            setLevelSelection={setLevelSelection}
            storeNumber={levelNbr}
            setStoreNumber={setLevelNbr}
            storeNumberField={storeNumberField}
            setStoreNumberField={setStoreNumberField}
            fpToggle={fpToggle}
            location={location || myLocation}
          />
          {!clearedAlerts && (
            <ViewAlert fpToggle={fpToggle} clearedAlerts={clearedAlerts} setClearedAlerts={setClearedAlerts} />
          )}
          {LEVEL_ACCESS[level] <= userLevelAccess ?  (
          <ErrorBoundary>
          <Routes>
            {/* Overview */}
            <Route
              exact
              path='/store/overview'
              element={
                <Overview
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  userGetsNPlus1={userGetsNPlus1}
                  userIsCorporate={userIsCorporate}
                  userMgtLvlId={userMgtLvlId}
                  location={myLocation}
                />
              }
            />
            <Route
              exact
              path='/core/sales/dept'
              element={<CoreSalesDepartmentProvider fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />}
            />
            <Route
              exact
              path='/core/safety'
              element={<CoreSafety fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />}
            />
            <Route
              exact
              path='/core/sales/subclass'
              element={<SubClassToSku fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />}
            />
            <Route
              exact
              path='/:level/overview'
              element={
                <ProtectedRoute exact path='/:level/overview' validParams={{ level: ['district', 'region', 'division', 'core'] }}>
                  <OverviewContextProvider
                    fpToggle={fpToggle}
                    timeframe={timeframe}
                    settimeframe={settimeframe}
                    userEmplCtgryNm={userEmplCtgryNm}
                    unit={unit}
                    setUnit={setUnit}
                    levelName={levelName?.toLowerCase()}
                    metric={metric}
                    setMetric={setMetric}
                  />
                </ProtectedRoute>
              }
            />
            {/* Order Fulfillment */}
            <Route
              exact
              path='/store/orderfulfillment'
              element={
                <StoreOrderFulfillmentPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
              }
            />
            <Route
              path='/:level/orderfulfillment'
              element={
                <ProtectedRoute
                  exact
                  path='/:level/orderfulfillment'
                  validParams={{ level: ['district', 'region', 'division', 'core'] }}>
                  {/* Add more levels as soons as we need */}
                  <OrderFulfillmentPage
                    timeframe={timeframe}
                    settimeframe={settimeframe}
                    fpToggle={fpToggle}
                    level={level}
                    unit={unit}
                    setUnit={setUnit}
                  />
                </ProtectedRoute>
              }
            />
            {/* Credit */}
            <Route path={`/:level/credit`} element={
              <ProtectedRoute exact validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
                <CreditContextProvider
                  unit={unit}
                  level={level}
                  setUnit={setUnit}
                  fpToggle={fpToggle}
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  userEmplCtgryNm={userEmplCtgryNm}>
                  <CreditPage />
                </CreditContextProvider>
              </ProtectedRoute>
            }/>
            {/* Intraday Routes */}
            <Route path='/:level/intraday'
              element={
                <ProtectedRoute validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
                  <IntradayContextProvider fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            {/* Intraday Dept Routes */}
            <Route
              path='/store/intraday/dept'
              element={<IntradayDeptView fpToggle={fpToggle} level={level} location={myLocation} />}
            />
            <Route
              path='/district/intraday/dept'
              element={<IntradayDeptView fpToggle={fpToggle} level={level} location={myLocation} />}
            />
            <Route path='/region/intraday/dept' element={<IntradayDeptView fpToggle={fpToggle} level={level} />} />
            <Route path='/division/intraday/dept' element={<IntradayDeptView fpToggle={fpToggle} level={level} />} />
            <Route path='/core/intraday/dept' element={<IntradayDeptViewCore fpToggle={fpToggle} />} />
            {/* Daily Routes */}
            <Route path='/core/daily' element={<DailySalesOverview fpToggle={fpToggle} />} />
            <Route path='/:level/daily' element={<DailyView fpToggle={fpToggle} levelName={levelName?.toLowerCase()} />} />
            <Route
              path='/store/daily/dept'
              element={<DailyDeptView fpToggle={fpToggle} level={level} location={myLocation} />}
            />
            <Route
              path='/district/daily/dept'
              element={<DailyDeptView fpToggle={fpToggle} level={level} location={myLocation} />}
            />
            <Route path='/region/daily/dept' element={<DailyDeptView fpToggle={fpToggle} level={level} />} />
            <Route path='/core/daily/dept' element={<DailySalesDepartment fpToggle={fpToggle} />} />
            <Route path='/division/daily/dept' element={<DailyDeptView fpToggle={fpToggle} level={level} />} />
            {/* Safety Detail Routes */}
            <Route
              path='/store/safety'
              element={
                <SafetyDetail
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  userMgtLvlId={userMgtLvlId}
                  userIsCorporate={userIsCorporate}
                  fpToggle={fpToggle}
                  levelName={levelName?.toLowerCase()}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/:level/safety'
              element={
                <SafetyDetailDst
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  userMgtLvlId={userMgtLvlId}
                  userIsCorporate={userIsCorporate}
                  fpToggle={fpToggle}
                  levelName={levelName?.toLowerCase()}
                />
              }
            />
            {/* Store Markdown Routes */}
            <Route
              path='/store/smd'
              element={
                <SmdDetailStr timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/district/smd'
              element={
                <SmdDetailDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/region/smd'
              element={<SmdDetailRgn level={level} timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/division/smd'
              element={<SmdDetailDiv level={level} timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/core/smd'
              element={<SmdDetailPage fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />}
            />
            <Route path='/store/smd/reason' element={<SmdStrRsn fpToggle={fpToggle} location={myLocation} />} />
            <Route path='/store/smd/dept/reason' element={<SmdStrDeptRsn fpToggle={fpToggle} location={myLocation} />} />
            <Route
              path='/district/smd/reason'
              element={
                <SmdDstRsn timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/core/smd/reason'
              element={<SmdReasonCodesPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/:level/smd/reason'
              element={<SmdRgnRsn timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/store/smd/dept'
              element={
                <SmdStrDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/district/smd/dept'
              element={
                <SmdDstDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/core/smd/dept'
              element={<SmdDepartmentPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/:level/smd/dept'
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <SmdRgnDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            {/* Labor Routes */}
            <Route
              path='/store/labor'
              element={
                <LaborDetail
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  userEmplCtgryNm={userEmplCtgryNm}
                  userMgtLvlId={userMgtLvlId}
                  jobProfileNm={jobProfileNm}
                  userIsCorporate={userIsCorporate}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/store/laborDetailAssoc'
              element={
                <LaborDetailAssoc
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  userMgtLvlId={userMgtLvlId}
                  userIsCorporate={userIsCorporate}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/labor'
              element={
                <LaborDetailDst
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  userEmplCtgryNm={userEmplCtgryNm}
                  levelName={levelName?.toLowerCase()}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/laborDetail'
              element={
                <LaborDetailDeptByDst
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/laborDetailManager'
              element={
                <LaborDetailMgrDeptByDst
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/core/labor'
              element={
                <LaborPage
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  userEmplCtgryNm={userEmplCtgryNm}
                  level={level}
                />
              }
            />
            <Route
              path='/:level/labor'
              element={
                <LaborContextProvider
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  userEmplCtgryNm={userEmplCtgryNm}
                  levelName={levelName?.toLowerCase()}
                />
              }
            />
            <Route
              path='/core/laborDetail'
              element={
                <LaborDetailDeptDrill timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} level={level} />
              }
            />
            <Route
              path='/:level/laborDetail'
              element={
                <LaborDetailDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} level={level} />
              }
            />
            <Route
              path='/core/laborDetailManager'
              element={
                <LaborDetailDeptMgr timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} level={level} />
              }
            />
            <Route
              path='/:level/laborDetailManager'
              element={
                <LaborDetailDeptManager
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  level={level}
                />
              }
            />
            {/* LTSA Routes */}
            <Route
              path='/store/ltsa'
              element={
                <LtsaDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/district/ltsa'
              element={
                <LtsaDetailDst
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/core/ltsa'
              element={<LtsaPage fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />}
            />
            <Route
              path='/:level/ltsa'
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <RegionVocSummary
                    level={level}
                    fpToggle={fpToggle}
                    locNbr={levelNbr}
                    timeframe={timeframe}
                    settimeframe={settimeframe}
                  />
                </ProtectedRoute>
              }
            />
            {/* Leads Routes */}
            <Route
              path='/store/leads'
              element={
                <LeadsDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/core/leads'
              element={<CoreLeads timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/:level/leads'
              element={
                <ProtectedRoute validParams={{ level: ['district', 'region', 'division'] }}>
                  <LeadsDetailDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route
              path='/store/leads/assoc'
              element={
                <LeadsAssocView
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            {/* Meas Routes */}
            <Route
              path='/store/meas'
              element={
                <MeasDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/district/meas'
              element={
                <MeasDetailDst
                  timeframe={timeframe}
                  level={level}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  levelName={levelName?.toLowerCase()}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/region/meas'
              element={
                <MeasDetailDst
                  timeframe={timeframe}
                  level={level}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  levelName={levelName?.toLowerCase()}
                />
              }
            />
            <Route
              path='/division/meas'
              element={
                <MeasDetailDst
                  timeframe={timeframe}
                  level={level}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  levelName={levelName?.toLowerCase()}
                />
              }
            />
            <Route
              path='/core/meas'
              element={<MeasurePage fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />}
            />
            <Route
              path='/store/meas/assoc'
              element={
                <MeasAssocView timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            {/* VOC Routes */}
            <Route
              path='/store/voc'
              element={
                <VocDetail
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  levelName={levelName?.toLowerCase()}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/core/voc'
              element={
                <ProtectedRoute validParams={{ level: ['core'] }}>
                  <LtpaOverviewPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route
              path='/:level/voc'
              element={
                <ProtectedRoute validParams={{ level: ['district', 'region', 'division'] }}>
                  <VocDetailDst timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            {/* Sales Drilldowns by Prod Hier - NOTE: unreachable, to be retired, replaced by ./sales/. endpoints */}
            <Route
              path='/store/dept'
              element={
                <DeptDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/dept'
              element={
                <DeptDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/store/dept/class/subclass'
              element={
                <SubclassDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/dept/class/subclass'
              element={
                <SubclassDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/core/sales/class'
              element={<CoreDrillDown fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />}
            />
            {/* Refined sales drilldown routes */}
            <Route
              path='/:level/sales/dept'
              element={
                <ProtectedRoute validParams={{ level: ['store', 'district', 'region', 'division'] }}>
                  <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route
              path='/:level/sales/class'
              element={
                <ProtectedRoute validParams={{ level: ['store', 'district', 'region', 'division'] }}>
                  <ClassDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route
              path='/:level/sales/subclass'
              element={
                <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
              }
            />
            {/* Aisle-Bay drilldowns */}
            <Route
              path='/store/aisle'
              element={
                <AisleDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/store/aisle/bay'
              element={
                <BayDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/store/deptLoc'
              element={
                <DeptLocDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/store/deptLoc/aisle'
              element={
                <DeptLocAisleDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/store/deptLoc/aisle/bay'
              element={
                <DeptLocBayDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/store/baysbystore'
              element={
                <BaysByStore timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} location={myLocation} />
              }
            />
            <Route
              path='/store/baysbystore/bay'
              element={
                <BaysByStoreBayDrilldown
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            {/* Inventory Drilldowns */}
            <Route
              path='/store/inventory/dept'
              element={<InvDeptDrilldown level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/store/inventory/dept/class'
              element={<InvClassDrilldown level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/store/inventory/dept/class/subclass'
              element={<InvSubclassDrilldown level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/district/inventory/dept'
              element={<InvDeptDrilldown level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/district/inventory/dept/class'
              element={<InvClassDrilldown level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/district/inventory/dept/class/subclass'
              element={<InvSubclassDrilldown level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/region/inventory/dept/class/subclass'
              element={<InvSubclassDrilldown level={level} fpToggle={fpToggle} />}
            />
            <Route path='/region/inventory/dept/class' element={<InvClassDrilldown level={level} fpToggle={fpToggle} />} />
            <Route path='/region/inventory/dept' element={<InvDeptDrilldown level={level} fpToggle={fpToggle} />} />
            <Route
              path='/division/inventory/dept/class/subclass'
              element={<InvSubclassDrilldown level={level} fpToggle={fpToggle} />}
            />
            <Route path='/division/inventory/dept/class' element={<InvClassDrilldown level={level} fpToggle={fpToggle} />} />
            <Route path='/division/inventory/dept' element={<InvDeptDrilldown level={level} fpToggle={fpToggle} />} />
            <Route path='/core/inventory/dept/class/subclass' element={<CoreInventorySubClass fpToggle={fpToggle} />} />
            <Route path='/core/inventory/dept/class' element={<CoreInventoryClass fpToggle={fpToggle} />} />
            <Route path='/core/inventory/dept' element={<CoreInventoryDrill fpToggle={fpToggle} />} />
            {/* Shrink Routes */}
            <Route
              path='/store/shrink'
              element={
                <Shrink
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/reducedtag`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <CoreReducedTagPage />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/invalidscans`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <InvalidScansCore />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/rtvcredit`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <RTVCreditCore />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/agedwc`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <AgedWillCallsCore />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/rtvdeletedtag`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <RTVDeletingCore />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/clearancenh`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <ClearanceNoHomesCorePage />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/autokr`}
              element={
                <CoreShrinkACCRCVProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <AccuracyAndReceivingMetric />
                </CoreShrinkACCRCVProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/unmatchedxfers`}
              element={
                <CoreShrinkACCRCVProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <AccuracyAndReceivingMetric />
                </CoreShrinkACCRCVProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}`}
              validParams={{ level: ['core'] }}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <ShrinkPageCore />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/ohemptpkg`}
              element={
                <OnHandAdjustmentCore timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}`}
              element={
                <ProtectedRoute validParams={{ level: ['district', 'region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <ShrinkPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path='/store/shrink/invalidscans'
              element={
                <ShrinkInvalidScans
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/shrink/invalidscans'
              element={
                <DstShrinkInvalidScans
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/invalidscans`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <InvalidScansPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route path='/store/shrink/agedwc' element={<ShrinkAgedWc fpToggle={fpToggle} location={myLocation} />} />
            <Route path='/store/shrink/dept' element={<ShrinkStoreDept fpToggle={fpToggle} location={myLocation} />} />
            <Route path='/store/shrink/class' element={<ShrinkStoreClass fpToggle={fpToggle} location={myLocation} />} />
            <Route
              path='/store/shrink/subclass'
              element={<ShrinkStoreSubClass fpToggle={fpToggle} location={myLocation} />}
            />
            <Route path='/district/shrink/dept' element={<DstShrinkDept fpToggle={fpToggle} location={myLocation} />} />
            <Route path={`/${baseCoreShrinkUrl}/dept`} element={<CoreShrinkDeptPage fpToggle={fpToggle} />} />
            <Route
              path={`/${baseLevelShrinkUrl}/dept`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <ShrinkDeptPage fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route path='/district/shrink/class' element={<DstShrinkClass fpToggle={fpToggle} location={myLocation} />} />
            <Route
              path={`/${baseCoreShrinkUrl}/class`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <CoreShrinkClassPage />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/class`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <ShrinkProvider fpToggle={fpToggle}>
                    <ShrinkClassPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path='/district/shrink/subclass'
              element={<DstShrinkSubClass fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path={`/${baseCoreShrinkUrl}/subclass`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <CoreShrinkSubClassPage />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/subclass`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <ShrinkProvider fpToggle={fpToggle}>
                    <ShrinkSubclassPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path='/district/shrink/agedwc'
              element={
                <DstShrinkAgedWc
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/agedwc`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <AgedWithCallsPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route
              path='/store/shrink/clearancenh'
              element={<ShrinkClearanceNh fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/district/shrink/clearancenh'
              element={
                <DstShrinkClearanceNh
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/clearancenh`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <ClearanceNoHomesPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route
              path='/store/shrink/reducedtag'
              element={<ShrinkReducedTag fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/district/shrink/reducedtag'
              element={
                <DstShrinkReducedTag
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/reducedtag`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <ShrinkReducedTagPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route exact path={`/${baseCoreShrinkUrl}/keyedsku`}
              element={
                <CoreKeyedSkuPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
              } 
            />
            <Route exact path='/store/shrink/keyedsku'
              element={
                <ProtectedRoute exact validParams={{ level: ['store'] }}>
                  <ShrinkKeyedSkus
                    jobProfileNm={jobProfileNm}
                    fpToggle={fpToggle}
                    timeframe={shrinkTimeframe}
                    settimeframe={setShrinkTimeframe}
                    userMgtLvlId={userMgtLvlId}
                    userIsCorporate={userIsCorporate}
                  />
                </ProtectedRoute> 
              } 
            />
            <Route exact path={`/${baseLevelShrinkUrl}/keyedsku`}
              element={
                <ProtectedRoute exact validParams={{ level: ['district', 'region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <KeyedSkuPage />
                  </ShrinkProvider>
                </ProtectedRoute>              }
            />
            <Route
              path='/store/shrink/rtvdeletedtag'
              element={
                <ShrinkRtvDeletedTag
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/shrink/rtvdeletedtag'
              element={
                <DstShrinkRtvDeletedTag
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/rtvdeletedtag`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <ShrinkRtvDeletedTagsPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path='/store/shrink/bosscancel'
              element={
                <ShrinkBossCancel
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/shrink/bosscancel'
              element={
                <DstShrinkBossCancel
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseCoreShrinkUrl}/bosscancel`}
              element={
                <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <CoreShrinkBossCancelPage />
                </ShrinkProvider>
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/bosscancel`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <ShrinkBossCancelRgnAndDiv />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path='/store/shrink/ohemptpkg'
              element={
                <ShrinkOhEmptPkg
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/store/shrink/ohemptpkg/dept'
              element={
                <ShrinkOhEmptPkgDept
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/shrink/ohemptpkg'
              element={
                <DstShrinkOhEmptPkg
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/ohemptpkg`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <OnHandAdjustmentEPPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
                </ProtectedRoute>
              }
            />
            <Route
              path='/store/shrink/rtvcredit'
              element={
                <ShrinkRtvCredit
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route path='/district/shrink/rtvcredit'
              element={
                <DstShrinkRtvCredit
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route path={`/${baseLevelShrinkUrl}/rtvcredit`}
              element={
                <ProtectedRoute validParams={{ level: ['region', 'division'] }}>
                  <RTVCreditsPage timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
                </ProtectedRoute> 
              }
            />
            {/* Shrink Accuracy & Receiving */}
            <Route path='/:level/shrink/damagedmd'
            element={ 
            <ProtectedRoute exact validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
              <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                <ShrinkDamagedMarkdownsPage />
              </ShrinkProvider>
            </ProtectedRoute>
            }
            />
            <Route path='/:level/shrink/cullmd' element={
              <ProtectedRoute
                exact validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
                <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <ShrinkCullMarkdownsPage />
                </ShrinkProvider>
              </ProtectedRoute>
              }
            />
            <Route path='/:level/shrink/damagedcullmd' element={
              <ProtectedRoute exact
                validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
                <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <ShrinkDamagedCullMarkdownsPage />
                </ShrinkProvider>
              </ProtectedRoute>
              } 
            />
            <Route exact path='/core/services/program' element={
                <CoreServicesPrgmPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
              } 
            />
            <Route
              path={`/:level/services`}
            element={
                <ProtectedRoute
                  exact
                  validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
                  <SpecialtyProRentalProvider timeframe={timeframe} setTimeframe={settimeframe} fpToggle={fpToggle}>
                    <SpecialtyProRentalServicesPage />
                  </SpecialtyProRentalProvider>
                </ProtectedRoute>
            } />
            <Route exact path='/:level/services/program' element={
              <ProtectedRoute exact validParams={{ level: ['region', 'division'] }}>
                <SpecialtyProRentalServicesProvider timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle}>
                  <SpecialtyProRentalServicesPrgmPage />
                </SpecialtyProRentalServicesProvider>
              </ProtectedRoute>
              } 
            />
            <Route
              path={`/${baseCoreShrinkUrl}/ohautorej`}
              element={
                <ShrinkCoreProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                  <OhAutoRejectCorePage />
                </ShrinkCoreProvider>
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/ohautorej`}
              element={
                <ProtectedRoute validParams={{ level: ['district', 'region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <OhAutoRejectPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/autokr`}
              element={
                <ProtectedRoute validParams={{ level: ['district', 'region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <AccuracyAndReceivingMetricPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/${baseLevelShrinkUrl}/unmatchedxfers`}
              element={
                <ProtectedRoute validParams={{ level: ['district', 'region', 'division'] }}>
                  <ShrinkProvider timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle}>
                    <AccuracyAndReceivingMetricPage />
                  </ShrinkProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path='/district/autokr'
              element={
                <DstAutoKR
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/district/unmatchedxfers'
              element={
                <DstUnmatchedXfers
                  timeframe={shrinkTimeframe}
                  settimeframe={setShrinkTimeframe}
                  fpToggle={fpToggle}
                  location={myLocation}
                />
              }
            />
            {/* Spec Pro Rental */}
            <Route
              path={`/:level/${SPEC_PRO_RENTAL_PATH}`}
              element={
                <ProtectedRoute validParams={{ level: ['store', 'district', 'region', 'division', 'core'] }}>
                  <SpecialtyProRentalProvider timeframe={timeframe} setTimeframe={settimeframe} fpToggle={fpToggle}>
                    <SpecialtyProRentalPage />
                  </SpecialtyProRentalProvider>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path='/district/services/program'
              element={<ServicesDtlDstPrgm timeframe={timeframe} location={myLocation} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            {/* Events */}
            <Route path='/store/events' element={<Events level='store' fpToggle={fpToggle} location={myLocation} />} />
            <Route path='/district/events' element={<Events level='district' fpToggle={fpToggle} location={myLocation} />} />
            <Route path='/region/events' element={<Events level='region' fpToggle={fpToggle} />} />
            <Route path='/division/events' element={<Events level='division' fpToggle={fpToggle} />} />
            <Route path='/core/events' element={<EventsPage fpToggle={fpToggle} />} />
            <Route path='/core/events/detail' element={<CoreEventsDetailPage fpToggle={fpToggle} />} />
            <Route path='/store/events/detail' element={<EventsDetailStr fpToggle={fpToggle} location={myLocation} />} />
            <Route path='/district/events/detail' element={<EventsDetailDst fpToggle={fpToggle} location={myLocation} />} />
            <Route path='/:level/events/detail' element={<EventsDetailRgn fpToggle={fpToggle} level={level} />} />
            {/* Headlines Insights */}
            <Route
              path='/store/headlinesinsights'
              element={<HeadlinesInsights level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            <Route
              path='/district/headlinesinsights'
              element={<HeadlinesInsights level={level} fpToggle={fpToggle} location={myLocation} />}
            />
            {/* OSA */}
            <Route
              path='/core/osa'
              element={
                <OsaOverviewPage
                  fpToggle={fpToggle}
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  userMgtLvlId={userMgtLvlId}
                  jobProfileNm={jobProfileNm}
                  userIsCorporate={userIsCorporate}
                />
              }
            />
            <Route
              path='/osa'
              element={
                <OSALanding
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  userMgtLvlId={userMgtLvlId}
                  jobProfileNm={jobProfileNm}
                  userIsCorporate={userIsCorporate}
                />
              }
            />
            <Route
              path='/osa/store/imaging'
              element={
                <OSAImaging
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/core/osa/imaging'
              element={<OsaImagingPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/core/osa/Purge'
              element={<OSAPurge fpToggle={fpToggle} settimeframe={settimeframe} timeframe={timeframe} />}
            />
            <Route
              path='/osa/:level/imaging'
              element={
                <ProtectedRoute validParams={{ level: ['district', 'region', 'division'] }}>
                  <OSAImagingDist
                    timeframe={timeframe}
                    settimeframe={settimeframe}
                    level={level}
                    fpToggle={fpToggle}
                    locNbr={levelNbr}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path='/osa/imaging/dept'
              element={
                <OSAImagingDept
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/osa/imaging/dept/aisle'
              element={
                <OSAImagingDeptAisle
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/osa/ohmnoloc'
              element={
                <OHMNoLocationTags
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/ohmnoloc/district'
              element={
                <OHMNoLocationTagsDst
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/osa/ohmnoloc/region'
              element={
                <OHMNoLocationTagsLevel
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  levelName={levelName?.toLowerCase()}
                />
              }
            />
            <Route
              path='/osa/ohmnoloc/division'
              element={
                <OHMNoLocationTagsLevel
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  levelName={levelName?.toLowerCase()}
                />
              }
            />
            <Route
              path='/core/osa/ohmnoloc'
              element={<OsaOhmNoLocTagsPage timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />}
            />
            <Route
              path='/osa/ohmnoloc/dept'
              element={
                <OHMNoLocationTagsByDept
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/osa/ohmnoloc/tag'
              element={
                <OHMNoLocationTagsByDeptTag
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/Purge/store'
              element={
                <SideKickPurge
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/Purge/deptPurge'
              element={
                <SideKickPurgeDeptPurge
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/Purge/deptPurgeByBay'
              element={
                <SideKickPurgeDeptPurgeByBay
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/Purge/deptBay'
              element={
                <SideKickPurgeDeptBay
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/Purge/deptBayByBay'
              element={
                <SideKickPurgeDeptBayByBay
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/Purge/:level'
              element={
                <SideKickPurgePage
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/sidekickskubay/store'
              element={
                <SideKickSkuBayStr
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/osa/sidekickskubay/dept'
              element={
                <SidekickSkuBayDept
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                  location={myLocation}
                />
              }
            />
            <Route
              path='/osa/sidekickskubay/logged'
              element={
                <SidekickSkuBayLoggedWork
                  level={level}
                  timeframe={timeframe}
                  locNbr={levelNbr}
                  fpToggle={fpToggle}
                  settimeframe={settimeframe}
                />
              }
            />
            <Route
              path='/osa/sidekickskubay/:level'
              element={
                <SideKickSkuBay timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} locNbr={levelNbr} />
              }
            />
            <Route
              path='/core/osa/sidekickskubay'
              element={<SidekickskubayCore fpToggle={fpToggle} timeframe={timeframe} settimeframe={settimeframe} />}
            />
            <Route
              path='/osa/sidekickskubay/dept/baypkdn'
              element={
                <SidekickSkuBayBayDetail
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/manageraddedbays'
              element={
                <ManagerAddedBays
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route
              path='/osa/manageraddedbays/baydetails'
              element={
                <ManagerAddedBaysDetails
                  timeframe={timeframe}
                  settimeframe={settimeframe}
                  level={level}
                  fpToggle={fpToggle}
                  locNbr={levelNbr}
                />
              }
            />
            <Route path='/admin/redis' element={<CacheCleaner />} />
            <Route path='/' element={<LandingPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
          </ErrorBoundary>
          ) : (
            <Header textAlign='center'>Invalid Access Level, Please Select From Dropdown</Header>
          )}

          <ReactQueryDevtools initialIsOpen={false} />
          <ViewChangeLog fpToggle={fpToggle} />
          {fpToggle === 'storeops' && userIsAdmin && (
            <>
              <EditAlert />
              <NewChangeLog />
              <PageTables />
            </>
          )}
          {/* <Scanner /> */}
          {isLegacyAlertOpen && <LegacyPageNotice isSticky={isDesktop} isBottom />}
        </Container>
      );
    case 'loggedOut':
      return <LoginMenu />;
    default:
      // return <LoginMenu />
      return <Loader active>Loading</Loader>;
  }
});

export default App;
