import React, { useContext } from 'react';
import { Header, Tab } from 'semantic-ui-react';
import { Boss } from './assoc/Boss';
import { WillCall } from './assoc/WillCall';
import { BopisAssoc } from './assoc/BopisAssoc';
import { Curbside } from './assoc/Curbside';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';

export const OnTimePickupAssoc = () => {
  const { fpToggle } = useContext(StoreOrderFulfillmentContext);
  // nonCompliance used for Non-Compliance Orders
  // associateView used for associateView

  const {
    isError,
    isLoading,
    results: [{ data: nonCompliance = [] }, { data: associateView = [] }, { data: queryMissedSLA = [] }]
  } = useEnhancedQueries(['queryNonComplianceView', 'queryAssociateView', 'queryMissedSLA'], fpToggle);

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const propsToRender = tab => ({
    associate: associateView.filter(row => row.CHANNEL === tab),
    nonCompliance: nonCompliance.filter(row => row.ORDER_TYPE === tab),
    missedSLA: queryMissedSLA.filter(row => row.CHANNEL === tab)
  });
  const tabs = [
    {
      menuItem: 'BOPIS',
      render: () => <BopisAssoc {...propsToRender('BOPIS')} />
    },
    {
      menuItem: 'BOSS',
      render: () => <Boss props={propsToRender('BOSS')} />
    },
    {
      menuItem: 'WILL CALL',
      render: () => <WillCall {...propsToRender('WILL CALL')} />
    },
    {
      menuItem: 'CURBSIDE',
      render: () => <Curbside />
    }
  ];
  return <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />;
};
