import { useMemo, useState } from 'react';

import { timeframeCode } from '../Common/timeframeCode';

import { Tab, Dropdown, Header } from 'semantic-ui-react';
import { DataView } from '../Common/DataView';
import { MenuItems } from '../Common/MenuItems';
import { UnitMenu } from '../Common/UnitMenu'
import { PlaceholderLoading } from '../Loading/PlaceholderLoading';
import { useEnhancedQueries } from '../../hooks/useEnhancedQueries';

const SORT_OPTIONS = [
  { key: 'TY_SLS_AMT', text: 'Sales', value: 'TY_SLS_AMT' },
  { key: 'TY_UNT_SLS', text: 'Units', value: 'TY_UNT_SLS' },
];
const UNIT_CHAR = { NOM: '$', PCT: '%' };

export const Top40Btm40Skus = ({ timeframe, fpToggle }) => {
  const [sortOption, setSortOption] = useState('TY_SLS_AMT');
  const [rankOrder, setRankOrder] = useState('Top');
  const [unit, setUnit] = useState("NOM")

  const {
    isError,
    isLoading,
    results: [{ data = [] }]
  } = useEnhancedQueries(['querySalesSku'], fpToggle);

  const dataFiltered = useMemo(() => {
    return (data)?.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]);
  }, [data, timeframe]);

  const sortedData = useMemo(() => {
    if (!dataFiltered) return [];
  
    return [...dataFiltered].sort((a, b) => {
      if (sortOption === 'TY_SLS_AMT') {
        if (b.TY_SLS_AMT !== a.TY_SLS_AMT) {
          return rankOrder === 'Top' ? b.TY_SLS_AMT - a.TY_SLS_AMT : a.TY_SLS_AMT - b.TY_SLS_AMT; // primary sort by TY_SLS_AMT
        }
        return rankOrder === 'Top' ? b.TY_UNT_SLS - a.TY_UNT_SLS : a.TY_UNT_SLS - b.TY_UNT_SLS; // secondary sort by TY_UNT_SLS
      } else if (sortOption === 'TY_UNT_SLS') {
        if (b.TY_UNT_SLS !== a.TY_UNT_SLS) {
          return rankOrder === 'Top' ? b.TY_UNT_SLS - a.TY_UNT_SLS : a.TY_UNT_SLS - b.TY_UNT_SLS; // primary sort by TY_UNT_SLS
        }
        return rankOrder === 'Top' ? b.TY_SLS_AMT - a.TY_SLS_AMT : a.TY_SLS_AMT - b.TY_SLS_AMT; // secondary sort by TY_SLS_AMT
      } else {
        return 0;
      }
    }).slice(0, 40); // limit to the first 40 items
  }, [dataFiltered, sortOption, rankOrder]);

  const isMetricSales = sortOption === 'TY_SLS_AMT';
  const isUnitNom = unit === 'NOM';
  const top40Btm40Data = {
    sortable: true,
    headers: [
      { name: 'Rank' },
      { name: 'SKU#' }, 
      { name: 'SKU DESC' }, 
      { name: 'DEPT' }, 
      { name: isMetricSales ? 'Sales Amt $' : 'Units' },
      { name: `${isMetricSales ? 'Sales' : 'Units'} vLY ${!isMetricSales && isUnitNom ? '#' : UNIT_CHAR[unit]}` }
    ],
    data: sortedData
    .map((row, index) => {
        let vLY;
        let metric;
        switch (unit + sortOption) {
            case 'NOMTY_SLS_AMT':
                vLY = {dollarValue: row.SLS_COMP_NOM, compareStyle: true};
                metric = {dollarValue: row.TY_SLS_AMT, compareStyle: true};
                break;
            case 'PCTTY_SLS_AMT':
                vLY = {pctValue: row.SLS_COMP_PCT, compareStyle: true};
                metric = {dollarValue: row.TY_SLS_AMT, compareStyle: true};
                break;
            case 'NOMTY_UNT_SLS':
                vLY = {
                  compareStyle: true,
                  orderBy: row.UNT_COMP_NOM,
                  stringValue: row.UNT_COMP_NOM.toLocaleString('en-US', { maximumFractionDigits: 0 }),
                };
                metric = {
                  orderBy: row.TY_UNT_SLS,
                  stringValue: row.TY_UNT_SLS.toLocaleString('en-US', { maximumFractionDigits: 0 })
                };
                break;
            case 'PCTTY_UNT_SLS':
                vLY = {pctValue: row.UNT_COMP_PCT, compareStyle: true};
                metric = {
                  orderBy: row.TY_UNT_SLS,
                  stringValue: row.TY_UNT_SLS.toLocaleString('en-US', { maximumFractionDigits: 0 }),
                };
                break;
            default:
                console.error(`Invalid unit: ${unit}. Unit can only be 'NOM' or 'PCT'.`);
                break;
            }

      return [
        {numValue: index + 1}, // Rank
        {numValue: row.SKU_NBR}, // SKU#
        {stringValue: row.SKU_DESC}, // SKU DESC
        {numValue: row.DEPT_NBR}, // DEPT#
        metric,
        vLY // Sales vLY %
      ]
    })
  }


  if (isError) return <Header>Something went wrong fetching data.</Header>;
  if (isLoading) return <PlaceholderLoading />;

  return (
    <Tab.Pane attached={false}>
      <DataView>
        <DataView.Title as="h3">Top 40 & Bottom 40 Skus</DataView.Title>

        <DataView.Controls>
          <UnitMenu unit={unit} setUnit={setUnit} />

          <Dropdown
            className='mx-3'
            compact
            options={SORT_OPTIONS}
            placeholder='Metric'
            selection
            value={sortOption}
            onChange={(e, { value }) => setSortOption(value)}
            style={{ width: '150px' }}
          />

          <MenuItems
            activeItem={rankOrder}
            dataTest='rankOrder'
            items={['Top', 'Bottom']}
            setItem={setRankOrder}
            size='small'
          />
        </DataView.Controls>

        <DataView.DataTable data={top40Btm40Data} />
      </DataView>
    </Tab.Pane>
  );
};
