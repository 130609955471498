import { Loader, Grid, Header } from "semantic-ui-react";
import { useShrinkQueries } from "../hooks/useShrinkQueries";
import { buildBaseShrinkUrl } from "../utils/url";
import { columnName, METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { TimeframeDropdownShrink } from "../../Common/TimeframeDropdownShrink";
import { Link } from "react-router-dom";
import { OnHandAdjustment } from "../components/OnHandAdjustment";
import { onHandAdjustment } from "../utils/dataObjectBuilder";

export const OnHandAdjustmentEPPage = ({ fpToggle, timeframe, settimeframe }) => {
  const queries = ['query/queryShrinkMetricsBySubLevel']

  const { isError, isLoading, level, locNbr, results } = useShrinkQueries({ fpToggle, queries })

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const [shrinkScoreData] = results
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${baseLevelShrinkUrl}?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`} data-test='b2o-link'>
              Shrink
            </Link> / On Hand Adjustments
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <OnHandAdjustment
        locNbr={locNbr}
        level={level}
        dataAsOf={shrinkScoreData.data[0]?.TRNSFM_DT}
        levelName={String(shrinkScoreData.data[0][columnName[level]]).toLowerCase()}
        dataObject={onHandAdjustment({ level, shrinkData: shrinkScoreData.data, timeframe })}
      />
    </>
  );
}