import React, { useState, useContext } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { CustomDropDown } from '../CustomDropdown';
import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../../components/Common/DataView';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';
import { formatAssociateName } from '../../../../utils/utils';

export default function OnTimeAverage() {
  const [view, setView] = useState('Associate View');
  const [localtimeframe, settimeframe] = useState('WTD')
  const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
  const showCustomTimeframe = ['# Missed SLA']
  const {
    isError,
    isLoading,
    results: [{ data: storeOnTimeAvgAssocView = [] }, { data: storeOnTimeAvgMissedSLA = [] }]
  } = useEnhancedQueries(['queryOnTimeAvgAssocView', 'queryOnTimeAvgMissedSLA'], fpToggle)

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const dataAssoc = {
    headers: [
      { name: 'Associate' },
      { name: 'Pick On Time %' },
      { name: '# of Orders Missed' },
    ],
    data: storeOnTimeAvgAssocView
    .filter(data => data[timeframe + '_ON_TIME_DEN'] || data[timeframe + '_ORDERS_MISSED'])
    .map(data => [
      { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
      { pctValue: data[timeframe + '_ON_TIME_NUM'] / data[timeframe + '_ON_TIME_DEN'] },
      { numValue: data[timeframe + '_ORDERS_MISSED'], orderBy: data[timeframe + '_ORDERS_MISSED'] },
    ]),
    pagination: true,
    sortable: true
  }

  const filterByTimeframe = storeOnTimeAvgMissedSLA.filter(row => row[localtimeframe + '_FLG'] > 0)

  const dataMissedSLA = {
    headers: [
      { name: 'Truck Type' },
      { name: 'Associate Name' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'Fulfill Date' }
    ],
    data: filterByTimeframe.map(data => [
      { stringValue: data.TRUCK_TYPE },
      { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
      { stringValue: data.CUST_ORD_NBR },
      { numValue: data.WORK_ORDER_NO, orderBy: data.WORK_ORDER_NO },
      { stringValue: data.PICK_DATE }
    ]),
    sortable: true,
    pagination: true
  };

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return dataAssoc;
      case '# Missed SLA':
        return dataMissedSLA;
      default:
        return dataAssoc; // or handle the default case appropriately
    }
  }

  return (
    <DataView className='mt-4'>
      <CustomDropDown
        view={view}
        setView={setView}
        showCustomTimeframe={showCustomTimeframe}
        extTimeframe={localtimeframe}
        setExtTimeframe={settimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'LM', text: 'LM', value: 'LM' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
}
