import { useQuery, useQueryClient } from 'react-query';
import { REMOVE_ALL_REDIS_KEYS } from '../../constants/url';
import { fetchData } from '../../utils/fetch';
import { Modal as SemanticModal } from 'semantic-ui-react';
import { ModalActions } from './ModalActions';
import { ModalContent } from './ModalContent';

export const Modal = ({ isOpen, onCloseModal }) => {
  const {
    data: { result } = {},
    isError,
    isLoading,
    refetch,
  } = useQuery(REMOVE_ALL_REDIS_KEYS, () => fetchData(REMOVE_ALL_REDIS_KEYS, 'GET'), { enabled: false });
  const queryClient = useQueryClient();

  const isSuccess = result === 'OK';

  const handleOnCloseModal = () => {
    queryClient?.removeQueries({ queryKey: REMOVE_ALL_REDIS_KEYS });
    onCloseModal();
  }

  return (
    <SemanticModal open={isOpen}>
      <SemanticModal.Content className='text-center'>
        <ModalContent isError={isError} isLoading={isLoading} isSuccess={isSuccess} />
      </SemanticModal.Content>

      <SemanticModal.Actions>
        <ModalActions
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onCancel={handleOnCloseModal}
          onConfirm={refetch}
        />
      </SemanticModal.Actions>
    </SemanticModal>
  );
};
