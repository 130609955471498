import { METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { formatLevelNumber } from "../../../utils/level";
import { divide } from "../../../utils/numbers";
import { capitalizeFirstLetter } from "../../../utils/string";

export const keyedSkuObject = (timeframe, level, metrics, scores) => {
  const levelUsed = level ?? "core"
  const { subLevel, propToRender } = METRIC_CONSTANTS[levelUsed] || {}
  return {
  headers: [
    { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[levelUsed].urlLinkLevel)} #` },
    { name: 'Value %' },
    { name: 'Goal %' },
    { name: '% of Stores Passing' }
  ],
  data: metrics.map(row => { 
    const rowScore = scores.find(score => score && score[propToRender] === row[propToRender] && timeframe === score.TIME_FRAME && score.METRIC_LEVEL === subLevel.METRIC_LEVEL)
    const pctValue = divide(row[`${timeframe}_KEYED_SKU_NUM`], row[`${timeframe}_KEYED_SKU_DEN`])

    return [
      {
        stringValue: row[METRIC_CONSTANTS[levelUsed].propToRender],
        link: {
          to: `/${METRIC_CONSTANTS[levelUsed].urlLinkLevel}/shrink/keyedsku`,
          params: {
            [METRIC_CONSTANTS[levelUsed].subLevel.urlParam]: formatLevelNumber(row[METRIC_CONSTANTS[levelUsed].propToRender])
          }
        }
      },
      {
        compareValue: rowScore.KEYED_SKU_GOAL,
        compareStyleInvert: true,
        pctValue,
        decimals: 2
      },
      {
        decimals: 2,
        pctValue: rowScore.KEYED_SKU_GOAL,
        prefix: '≤ '
      },
      {
        pctValue: rowScore.KEYED_SKU_SCORE
      }
    ]
  }),
  sortable: true,
  sortableDefault: 0
}}
