import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router
} from "react-router-dom"
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./semantic/dist/semantic.css";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ErrorBoundary } from './components/Common/ErrorBoundary';
import { ErrorScreen } from './components/Error/ErrorScreen';
import UserContextProvider from './context/UserContext';
import LevelContextProvider from './context/LevelContext';

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  // // NOTE: include disable linter 'cause the change is create a new function for replaceAll that is not used as primitive function
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function(str, newStr){

    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }

    // If a string
    // Append the escape // to any regexp special characters
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
    let strf = str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return this.replace(new RegExp(strf, 'g'), newStr);

  };
}

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA4_MEASUREMENT_ID,
  },
  {
    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_CLIENT_ID,
  },
]);

ReactGA.send({ hitType: "pageview", page: window.location.pathname, search: window.location.search });


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15 // 15 minutes
    },
  },
})

ReactDOM.render(
  <ErrorBoundary fallback={<ErrorScreen />}>
    <Router>
      <QueryClientProvider client={queryClient}>
        <LevelContextProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </LevelContextProvider>
      </QueryClientProvider>
    </Router>
  </ErrorBoundary>
  , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
