import { useContext } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { DataView } from '../../../../../../components/Common/DataView';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { divide } from '../../../../../../utils/numbers';
import { WILL_CALL_INFO } from '../../../../constants/OrderFulfillmentConstants';
import { formatNumberWithCommas } from '../../../../../../utils/formatter';
import { Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';

export const PickUpDetailsMethod = () => {
  const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
  const {
    isError,
    isLoading,
    results: [{ data: pickupData = [] }]
  } = useEnhancedQueries(['queryOnTimePickUp'], fpToggle)

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const [bopisData = {}] = pickupData.filter(row => row['CHANNEL'] === 'BOPIS');
  const [willCallData = {}] = pickupData.filter(row => row['CHANNEL'] === 'WILL CALL');

  const bopisFullfillmentPct = divide(bopisData[timeframe + '_FULFILL_NUM'], bopisData[timeframe + '_FULFILL_DEN']);

  const willCallFullfillmentPct = divide(willCallData[timeframe + '_FULFILL_NUM'], willCallData[timeframe + '_FULFILL_DEN']);

  const totalFullfillmentPct = divide(willCallData[timeframe + '_FULFILL_NUM'] + bopisData[timeframe + '_FULFILL_NUM'], willCallData[timeframe + '_FULFILL_DEN'] + bopisData[timeframe + '_FULFILL_DEN']);


  const dataObj = {
    headers: [
      { name: 'Method' },
      { name: 'Fulfillment %' },
      { name: 'Units Unfulfilled' },
      { name: 'Not-in-Stock Units' },
      { name: '$ Unfulfilled' }
    ],
    data: [
      [
        {
          stringValue: 'BOPIS'
        },
        {
          pctValue: bopisFullfillmentPct,
          decimals: 1,
          orderBy: bopisData['FULFILL_' + timeframe]
        },
        {
          numValue: formatNumberWithCommas(bopisData[timeframe + '_CNCL_NIS_DEN']),
          orderBy: +bopisData[timeframe + '_CNCL_NIS_DEN']
        },
        {
          numValue: formatNumberWithCommas(bopisData[timeframe + '_CNCL_NIS_NUM']),
          orderBy: +(bopisData[timeframe + '_CNCL_NIS_NUM'])
        },
        {
          dollarValue: bopisData[timeframe + '_UNFULFILLED_TOTAL'],
          orderBy: +bopisData[timeframe + '_UNFULFILLED_TOTAL']
        }
      ],
      [
        {
          stringValue: 'Will Call ',
          popup: {
            content: WILL_CALL_INFO,
            icon: 'info circle',
            color: 'blue' //Semantic-UI doesn't have Circle Info Icon without background
          }
        },
        {
          pctValue: willCallFullfillmentPct,
          decimals: 1,
          orderBy: willCallData['FULFILL_' + timeframe]
        },
        {
          numValue: formatNumberWithCommas(willCallData[timeframe + '_CNCL_NIS_DEN']),
          orderBy: +(willCallData[timeframe + '_CNCL_NIS_DEN'])
        },
        {
          numValue: formatNumberWithCommas(willCallData[timeframe + '_CNCL_NIS_NUM']),
          orderBy: Number(willCallData[timeframe + '_CNCL_NIS_NUM'])
        },
        {
          dollarValue: willCallData[timeframe + '_UNFULFILLED_TOTAL'],
          orderBy: +willCallData[timeframe + '_UNFULFILLED_TOTAL']
        }
      ],
      [
        {
          stringValue: 'Total'
        },
        {
          pctValue: totalFullfillmentPct,
          decimals: 1
        },
        {
          numValue: formatNumberWithCommas(
            bopisData[timeframe + '_CNCL_NIS_DEN'] + willCallData[timeframe + '_CNCL_NIS_DEN']
          ),
          orderBy: +(bopisData[timeframe + '_CNCL_NIS_DEN'] + willCallData[timeframe + '_CNCL_NIS_DEN'])
        },
        {
          numValue: formatNumberWithCommas(
            bopisData[timeframe + '_CNCL_NIS_NUM'] + willCallData[timeframe + '_CNCL_NIS_NUM']
          ),
          orderBy: Number(bopisData[timeframe + '_CNCL_NIS_NUM']) + Number(willCallData[timeframe + '_CNCL_NIS_NUM'])
        },
        {
          dollarValue: bopisData[timeframe + '_UNFULFILLED_TOTAL'] + willCallData[timeframe + '_UNFULFILLED_TOTAL'],
          orderBy: +(bopisData[timeframe + '_UNFULFILLED_TOTAL'] + willCallData[timeframe + '_UNFULFILLED_TOTAL'])
        }
      ]
    ]
  };

  return (
    <DataView className='mt-4'>
      <DataView.Title>Pickup Details by Method</DataView.Title>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
