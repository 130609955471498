import React, { useState, useEffect } from 'react'
import { Header, Grid, Table, Loader, Icon, Pagination, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getDollarString } from '../Common/TableCellUtils'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'

export const ShrinkBossCancel = (({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [rtvFlag, setRtvFlag] = useState(0)

  const [sortColumn, setSortColumn] = useState('LINE_CANCEL_DATE')
  const [sortDirection, setSortDirection] = useState("descending")
  const [pageNum, setPageNum] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const shrinkBossCancelQuery = useQuery(['queryShrinkBossCancel', { strNbr, fpToggle }], getData)

  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection, rowsPerPage])

  if (shrinkBossCancelQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkBossCancelQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const data = shrinkBossCancelQuery.data

  let dataFilter = data.filter((row) => {
    return row && row[timeframe + '_FLG'] === 1 && row['RTV_FLAG'] === rtvFlag
  })

  let dataSorted = dataFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'LINE_CANCEL_DATE') {
      result = left.LINE_CANCEL_DATE > right.LINE_CANCEL_DATE ? 1 : -1
    }
    if (sortColumn === 'EXTN_HOST_ORDER_REF') {
      result = left.EXTN_HOST_ORDER_REF > right.EXTN_HOST_ORDER_REF ? 1 : -1
    }
    if (sortColumn === 'EXTN_HOST_ORDER_LINE_REF') {
      result = left.EXTN_HOST_ORDER_LINE_REF > right.EXTN_HOST_ORDER_LINE_REF ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = dataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSorted.length)))

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row><Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>
            Shrink
          </Link> / Boss RTV %
        </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
        &nbsp;
          <Menu compact size='small'>
            <Menu.Item
              name='RTVed'
              active={rtvFlag === 1}
              content='RTVed'
              onClick={() => {
                setRtvFlag(1)
              }}
            />
            <Menu.Item
              name='Not RTVed'
              active={rtvFlag === 0}
              content='Not RTVed'
              onClick={() => {
                setRtvFlag(0)
              }}
            />
          </Menu>
        </Grid.Column>
        <Grid.Column textAlign='justified' width={8}>
          <Grid.Row>
            Rows per Page:
          </Grid.Row>
          <Grid.Row>
            <div>
              <select
                name="rowsPerPage"
                onChange={e => {
                  setRowsPerPage(e.target.value)
                }}
                value={rowsPerPage}
                style={{ padding: 10 }}
              >
                <option value="20" selected>20</option>
                <option value="40">40</option>
              </select>
            </div>
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Grid>
        {dataSorted.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((dataSorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
      </Grid>
      <Header textAlign='center'>BOSS RTVs - Store {strNbr}</Header> 
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === 'LINE_CANCEL_DATE' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'LINE_CANCEL_DATE') {
                  setSortColumn('LINE_CANCEL_DATE')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Action Date {sortColumn !== 'LINE_CANCEL_DATE' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'EXTN_HOST_ORDER_REF' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'EXTN_HOST_ORDER_REF') {
                  setSortColumn('EXTN_HOST_ORDER_REF')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Order # {sortColumn !== 'EXTN_HOST_ORDER_REF' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'EXTN_HOST_ORDER_LINE_REF' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'EXTN_HOST_ORDER_LINE_REF') {
                  setSortColumn('EXTN_HOST_ORDER_LINE_REF')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Order Line Ref {sortColumn !== 'EXTN_HOST_ORDER_LINE_REF' && <Icon fitted name='sort' />}</Table.HeaderCell>
            <Table.HeaderCell
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            >SKU #</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'CNCL_TOTAL' ? sortDirection : null}
              style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
              onClick={() => {
                if (sortColumn !== 'CNCL_TOTAL') {
                  setSortColumn('CNCL_TOTAL')
                  setSortDirection('ascending')
                } else {
                  sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                }
              }}>Cancel Total $ {sortColumn !== 'CNCL_TOTAL' && <Icon fitted name='sort' />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataPagination.map((data) => {
            return (
              <Table.Row key={data['EXTN_HOST_ORDER_REF']}>
                <Table.Cell>{data['LINE_CANCEL_DATE']}</Table.Cell>
                <Table.Cell>
                  <a
                    href={`https://orders.apps.homedepot.com/view/${data['EXTN_HOST_ORDER_REF']}`}
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{data['EXTN_HOST_ORDER_REF']}</a></Table.Cell>
                <Table.Cell>{data['EXTN_HOST_ORDER_LINE_REF']}</Table.Cell>
                <Table.Cell>{data['SKU_NBR']}</Table.Cell>
                <Table.Cell>{getDollarString(data['CNCL_TOTAL'])}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )

})