import React, { useContext, useState } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';
import { formatNumberWithCommas } from '../../../../../../../utils/formatter';
import { getTimeframeOptions } from '../../../../../../../utils/timeFrameUtils';

export const WillCall = (props) => {
  const { associate, missedSLA } = props
  const { timeframe } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const [localtimeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['# Missed SLA']
  const missedSLAFiltered = missedSLA.filter(row => row[localtimeframe + '_FLG'] > 0)

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate' },
            { name: 'Pick On Time %' },
            { name: ' # of Orders Missed ' },
          ],
          data: associate
          .filter(data => 
            data[`${timeframe}_ON_TIME_DEN`] > 0 ||
            data[`${timeframe}_MISS_SLA_CNT`] > 0
          ).map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
            { pctValue: data[`${timeframe}_ON_TIME_DEN`] ? data[`${timeframe}_ON_TIME_NUM`] / data[`${timeframe}_ON_TIME_DEN`] : '--' },
            { numValue: formatNumberWithCommas(data[`${timeframe}_MISS_SLA_CNT`]),
              orderBy: +data[`${timeframe}_MISS_SLA_CNT`]
             },
          ]),
          sortable: true,
        };
      case '# Missed SLA':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Scheduled Pickup Date' },
          ],
          data: missedSLAFiltered.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
            { numValue: data.ORDER_NUMBER, orderBy: data.ORDER_NUMBER },
            { numValue: String(data.ORDER_DATE).split('T')[0] },
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        }
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        extTimeframe={localtimeframe}
        setExtTimeframe={settimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' }
        ]}
        timeframeOptions={getTimeframeOptions(4)}
      />
      <DataTable
        data={getData()}
      />
    </>
  );
};
