const DATA_TABLE_HEADERS = [{ name: 'Channel' }, { name: 'Total' }, { name: 'Consumer' }, { name: 'Commercial' }];

const generateRows = (rows, timeframe) => {
  return rows.map(row => [
    { stringValue: row['ASSOC_CHNL'] },
    { numValue: row[`${timeframe}_TOT_APPS`] },
    { numValue: row[`${timeframe}_CONS_APPS`] },
    { numValue: row[`${timeframe}_COMM_APPS`] }
  ]);
};

export const createObjectSchema = (data, timeframe, pagination) => ({
  data: generateRows(data, timeframe),
  headers: DATA_TABLE_HEADERS,
  sortable: true,
  pagination: pagination,
  sortableDefault: 0
});
