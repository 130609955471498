import { KeyList } from '../components/KeyList';
import { ClearAllCacheButton } from '../components/ClearAllCacheButton';
import { Header } from 'semantic-ui-react';

export const CacheCleaner = () => {
  return (
    <div className='my-4'>
      <div className='d-flex justify-content-between'>
        <Header>
          Redis Cache Cleaner
          <Header.Subheader>
            Most redis keys are the api url, such as `/api/storeops/myStoreData/1234`. The loginCheck keys looks like
            `LDAP_NPLUS1_CHECK`
          </Header.Subheader>
        </Header>

        <ClearAllCacheButton />
      </div>

      <KeyList />
    </div>
  );
};
