import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import { NUMBER_REGEX } from '../../constants/regex';
import { useAutocompleteSuggestions } from '../../hooks/useAutocompleteSuggestions';
import { ACTIVE_SUGGESTION_INITIAL_VALUE } from '../../hooks/useSuggestions';
import { isClassNameInScreen } from '../../utils/elements';
import { MENU_ITEMS } from '../../utils/menu';
import { LEVEL_OPTIONS } from '../../utils/search';
import { formatLevelNumber } from '../../utils/level';
import { getParamByLevel } from '../../utils/timeFrameUtils';
import { Autocomplete } from './Autocomplete';

export const SearchInput = ({
  active,
  fpToggle,
  level,
  levelSelection,
  setActiveItem,
  setLevel,
  setLevelSelection,
  storeNumberField
}) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(storeNumberField);
  const searchInputRef = useRef(null);
  const { closeAutocomplete, isAutocompleteOpen, isError, isLoading, suggestions } = useAutocompleteSuggestions(
    levelSelection,
    fpToggle,
    searchValue,
    searchInputRef
  );

  const buildNumberFromSearchValue = useCallback(() => {
    const numbers = searchValue.match(NUMBER_REGEX);
    if (!numbers) return;

    const number = formatLevelNumber(numbers[0]?.slice(0, 4));
    return number;
  }, [searchValue]);

  const onRedirect = number => {
    const paramByLevel = getParamByLevel(levelSelection);
    navigate(`/${levelSelection}/${active}?${paramByLevel}=${number}`);
  };

  const onSearch = () => {
    if (levelSelection === 'core') return navigate(`/core/${active}`);

    const number = buildNumberFromSearchValue();
    if (!number) return;

    onSelectSuggestions({ number, value: number });
    closeAutocomplete();
  };

  const onSelectSuggestions = ({ number, value } = { number: null, value: null }) => {
    if (number === ACTIVE_SUGGESTION_INITIAL_VALUE) {
      const number = buildNumberFromSearchValue();
      if (!number) return;

      return onSelectSuggestions({ number, value: number });
    }

    setSearchValue(value);
    setLevel(levelSelection);
    setActiveItem(MENU_ITEMS.includes(active) ? active : 'overview');
    onRedirect(number);
  };

  useEffect(() => {
    const numberFromSearchValue = buildNumberFromSearchValue();
    if (!numberFromSearchValue) return;

    const number = formatLevelNumber(storeNumberField);
    if (number === numberFromSearchValue) return;

    setSearchValue(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeNumberField]);

  return (
    <div className='d-flex gap-3 mt-3 position-relative'>
      <Input
        className='flex-grow-1'
        ref={searchInputRef}
        loading={isLoading}
        label={
          <Dropdown
            defaultValue={level}
            value={levelSelection}
            options={LEVEL_OPTIONS}
            onChange={(_, { value }) => {
              if (value !== 'core') return setLevelSelection(value);

              const activeItem = MENU_ITEMS.includes(active) ? active : 'overview';

              setSearchValue('');
              setLevel(value);
              setLevelSelection(value);
              setActiveItem(activeItem);
              navigate(`/core/${activeItem}`);
            }}
          />
        }
        labelPosition='left'
        style={{ fontSize: '16px' }}
        fluid
        value={searchValue}
        onChange={(_, { value }) => setSearchValue(value)}
        onBlur={() => setTimeout(() => closeAutocomplete(), 200)}
        onKeyDown={e => {
          if (e.key !== 'Enter' || isClassNameInScreen('suggestions')) return;

          onSearch();
        }}
      />

      <Button size='large' icon='search' style={{ color: 'white', backgroundColor: '#EE7125' }} onClick={onSearch} />

      {isAutocompleteOpen && (
        <Autocomplete
          closeAutocomplete={closeAutocomplete}
          currentSearch={searchValue}
          isError={isError}
          isLoading={isLoading}
          onSelectSuggestions={onSelectSuggestions}
          searchInputRef={searchInputRef}
          suggestions={suggestions}
        />
      )}
    </div>
  );
};
