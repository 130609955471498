import { DataView } from '../../Common/DataView';
import { getDamagedMarkdownsDataTable } from '../utils/shrinkDamagedMarkdowns';

export const DamagedMarkdowns = params => {
  const { level, timeframe, to, damagedMarkdownsData, scoreDamagedMarkdownsData } = params;
  const damagedMarkdownsDataTable = getDamagedMarkdownsDataTable({
    level,
    timeframe,
    to,
    damagedMarkdownsData,
    scoreDamagedMarkdownsData
  });

  return <DataView.DataTable data={damagedMarkdownsDataTable} />;
};
