import React from 'react';

import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';

export const HelpersLinks = ({ level }) => {
  const { fpToggle } = useShrinkContext();
  const { locNbr } = useShrinkQueries({ fpToggle });

  return (
    <Grid>
      <Grid.Column width={8}>
        <Link
          style={{ color: '#EE7125', textDecoration: 'underline' }}
          to={`/${level}/overview?${level === 'division' ? 'div' : 'rgn'}Nbr=${locNbr}`}
          data-test='b2o-link'>
          Back to Overview
        </Link>
      </Grid.Column>
      <Grid.Column width={8} textAlign='right'>
        <a
          href="https://portal.homedepot.com/sites/myapron_en_us/SitePages/Asset-Protection.aspx"
          style={{ color: '#EE7125', textDecoration: 'underline' }}
          target='_blank'
          rel='noopener noreferrer'>
          Shrink Resources
        </a>
      </Grid.Column>
    </Grid>
  );
};
