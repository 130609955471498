import { Header, Loader, Grid } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink';
import { useQueryParams } from '../../hooks/useQueryParams';
import { DataView } from '../Common/DataView';
import { useState } from 'react';
import { SkuDetailsModal } from '../Sku/SkuDetailsModal';
import { formatSku } from '../../utils/formatter';
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { ACCESS_LEVELS } from '../Common/AccessChecks';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import { formatAssociateName } from '../../modules/store/utils/utils';

export const ShrinkKeyedSkus = ({ timeframe, settimeframe, fpToggle, userMgtLvlId, userIsCorporate, jobProfileNm }) => {
  const [skuData, setSkuData] = useState(null);
  const { strNbr } = useQueryParams();
  const { currentLevelName, isLoadingName } = useFetchLevelName({ fpToggle, level: 'store', locNbr: strNbr });
  const shrinkKeyedSkusTopSkusQuery = useQuery(['query/queryShrinkKeyedskuTopSkus/store', { strNbr, fpToggle }], getData)
  const getsAssocAccess = userMgtLvlId <= ACCESS_LEVELS.DEPT_SUP || jobProfileNm === 'HEAD CASHIER' || userIsCorporate;  
  const shrinkKeyedSkusAssocQuery = useQuery(['query/queryShrinkKeyedskuAssoc/store', { strNbr, fpToggle }], getData)
  if (shrinkKeyedSkusTopSkusQuery.isLoading || isLoadingName || (shrinkKeyedSkusAssocQuery.isLoading && getsAssocAccess)) {
    return (<Loader active>Loading...</Loader>)
  }
  if (!shrinkKeyedSkusTopSkusQuery.data || (!shrinkKeyedSkusAssocQuery.data && getsAssocAccess)) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }
  const DATA_AS_OF = shrinkKeyedSkusAssocQuery?.data[0]?.TRNSFM_DT;
  const topSkusTimeframe = shrinkKeyedSkusTopSkusQuery.data.filter(row => row.TIMEFRAME === (timeframe === 'R12M' ? 'L12' : timeframe));
  const topTenSkusData = {
    headers: [
      { name: 'SKU #' },
      { name: 'Unit Price $' },
      { name: 'Keyed SKU Count' },
      { name: 'Amount $' },
      { name: 'Keyed SKU %' }
    ],
    data: topSkusTimeframe.map(row => [
      {
        stringValue: formatSku(row.SKU_NBR),
        customStyle: {
          color: '#EE7125',
          textDecoration: 'underline',
          cursor: 'pointer'
        },
        onClick: () => setSkuData(row)
      },
      {
        dollarValue: row.CURR_RETL_AMT,
        decimals: 2
      },
      {
        numValue: row.MANUALENTRY_ITEM_QTY,
        decimals: 0
      },
      {
        dollarValue: row.MANUALENTRY_NET_SALES_AMT
      },
      {
        pctValue: row.MANUALENTRY_ITEM_QTY / row.TOTAL_ITEM_QTY
      }
    ]),
    sortable: true,
    sortableDefault: 2,
    sortDirection: 'ascending'
  };

  const assocData = getsAssocAccess ? shrinkKeyedSkusAssocQuery.data : [];
  const associateDetailsObj = {
    headers: [{ name: 'Associates' }, { name: 'Amount $' }, { name: 'Keyed SKU Qty' }, { name: 'Keyed SKU %' }],
    data: assocData
    .filter(item => item[`${timeframe}_MANUALENTRY_ITEM_QTY`] > 0)
    .map(item => [
      {
        dollarValue: formatAssociateName(item.ASSOC_NM) || item.LDAP_ID
      },
      {
        dollarValue: item[`${timeframe}_MANUALENTRY_NET_SALES_AMT`]
      },
      {
        decimals: 0,
        numValue: item[`${timeframe}_MANUALENTRY_ITEM_QTY`]
      },
      {
        decimals: 2,
        pctValue: item[`${timeframe}_MANUALENTRY_ITEM_QTY`] / item[`${timeframe}_TOTAL_ITEM_QTY`]
      }
    ]),
    sortable: true,
    sortableDefault: 2,
    sortDirection: 'ascending'
  };

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>
              Shrink
            </Link> / Keyed SKUs
          </Grid.Row>
        </Grid.Column>
        </Grid>
      <Header style={{ textTransform: 'capitalize' }}>
        Shrink Keyed Skus - {'Store'} {strNbr}, {currentLevelName}
        <Header.Subheader>Data as of {DATA_AS_OF}</Header.Subheader>
      </Header>
      <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
      <DataView className='my-4'>
        <DataView.Title>Top 10 SKUs</DataView.Title>
        <DataView.DataTable data={topTenSkusData} />
        {getsAssocAccess && (
          <>
            <DataView.Title>Associate Details</DataView.Title>
            <DataView.DataTable data={associateDetailsObj} />
          </>
        )}
      </DataView>
      <SkuDetailsModal isOpen={!!skuData} onClose={() => setSkuData(null)} skuData={skuData} />
    </>
  );
};
