import { Tab } from 'semantic-ui-react';
import { useOverviewContext } from '../../../hooks/useOverviewContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { withOverviewHeader } from '../../HOC/withHeader';
import { OverviewTabs } from './OverviewTabs';
import { SalesBySubLevelList } from './SalesBySubLevelList';

const OverviewBody = withOverviewHeader(OverviewTabs);
const SubLevelList = withOverviewHeader(SalesBySubLevelList);

const tabsMenu = {
  region: [
    {
      menuItem: 'Region',
      render: () => <OverviewBody />
    },
    {
      menuItem: 'District List',
      render: () => <SubLevelList />
    }
  ],
  division: [
    {
      menuItem: 'Division',
      render: () => <OverviewBody />
    },
    {
      menuItem: 'Region List',
      render: () => <SubLevelList />
    }
  ],
  core: [
    {
      menuItem: 'Core',
      render: () => <OverviewBody />
    },
    {
      menuItem: 'Division List',
      render: () => <SubLevelList />
    }
  ],
  district: [
    {
      menuItem: 'District',
      render: () => <OverviewBody />
    },
    {
      menuItem: 'Store List',
      render: () => <SubLevelList />
    }
  ]
};

export const OverviewMenu = () => {
  const { level } = useOverviewContext();
  const { showList } = useQueryParams();

  return (
    <Tab
      panes={tabsMenu[level]}
      menu={{ secondary: true, pointing: true }}
      style={{ marginBottom: '1em' }}
      defaultActiveIndex={showList ? 1 : 0}
    />
  );
};
