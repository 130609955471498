const REGEX_SEPARATOR = /\s|, /g

const getFirstLetter = string => string.slice(0, 1)

const getFirstAndLastName = name => {
  const arrayName = String(name ?? 'DEPOT, HOMER').split(REGEX_SEPARATOR);
  if (name.includes(', ')) return [arrayName[1], arrayName[0]]

  return [arrayName[0], arrayName[1]]
}

const formatName = (letter, name) => `${letter}. ${name}`

export const formatAssociateName = (name, charNumber = 5, seperator = REGEX_SEPARATOR) => {
  if (!name) return '';
  if (name.length < 9) return name;

  const [firstName, lastName] = getFirstAndLastName(name)
  const firstLeterFromName = getFirstLetter(firstName)
  if (!lastName) return name

  const lastNameSliced = lastName.slice(0, charNumber)
  const arrayName = String(name ?? 'DEPOT, HOMER').split(seperator);

  if (arrayName.length === 2 && lastName.includes('-'))
    return formatName(firstLeterFromName, lastName.split('-')[1].slice(0, charNumber));

  return formatName(firstLeterFromName, lastNameSliced)
}

export const formatManagerName = (name) => {
  if (name === "AUTOMATED") return name;
  return formatAssociateName(name, 5)
}