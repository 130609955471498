import { useShrinkACCRCVContext } from '../../hooks/useShrinkACCRCVContext';
import { Grid, Tab, Header } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { TimeframeDropdownShrink } from '../../../../../../components/Common/TimeframeDropdownShrink';
import { AutoKeyRecsTable } from './AutoKeyRecsTable';
import { UnmatchedXfersTable } from './UnmatchedXfersTable';
import { buildBaseShrinkUrl } from '../../../../../../components/ShrinkContainer/utils/url';

export const AccuracyAndReceivingMetric = () => {
  const { queryACCRCVByCore, timeframe, settimeframe } = useShrinkACCRCVContext();
  const location = useLocation();
  const path = location.pathname;
  const hasAutokr = path.includes('autokr');
  const accRecTitle = hasAutokr ? 'Auto Key-recs' : 'Unmatched Xfers';
  const title = `${accRecTitle} - Core`;
  const baseCoreShrinkUrl = buildBaseShrinkUrl('core')
  let metricData = queryACCRCVByCore;  

  return (
      <Tab.Pane className='mt-4'>
        <Link style={{ color: '#EE7125' }} to={`/${baseCoreShrinkUrl}`}>
          Shrink
        </Link> / {accRecTitle}
        <Header>{title}</Header>
        <Grid>
          <Grid.Column width={4}>
            <Grid.Row>
              <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Row>
          </Grid.Column>
        </Grid>
        {hasAutokr && <AutoKeyRecsTable autoKeyRecData={metricData} timeframe={timeframe}/>}
        {!hasAutokr && <UnmatchedXfersTable unmatchedXfersData={metricData} timeframe={timeframe}/>}
      </Tab.Pane>
  );
};
