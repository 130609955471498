import { Header, Modal, Button } from "semantic-ui-react";

export const WorkOrderDetails = ({ isOpen, onClose, dataRender }) => {
    return (
    dataRender && <Modal closeIcon open={isOpen} onClose={onClose} role='dialog'>
      <Header textAlign='left'>Work Order #{dataRender.WORK_ORD_NBR}</Header>
      <div style={{ marginLeft: '3rem' }}>
        <Header as='h4' className="mt-2 mb-1">Push To Location</Header>
        <span>{dataRender.PUSH_TO_LOCATION}</span>
        <Header as='h4' className="mt-4 mb-1">Truck Type</Header>
        <span>{dataRender.ACTUAL_TRUCK_TYPE}</span>
      </div>
      <Button content='Close' onClick={onClose} role='button' style={{ color: 'white', backgroundColor: '#EE7125', margin: '1rem 0 1rem 2rem' }} />
    </Modal>
  );
}