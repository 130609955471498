import { useContext, useState } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { DataView } from '../../../../../../components/Common/DataView';
import { Dropdown, Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';
import { onTimeDeliveryData, onTimeDeliveryHeaders, selectTrunkTypeOptions } from '../../../../utils/onTimeDelivery';
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext';
import { FLATBED, TOTAL } from '../../../../utils/consts';

export default function OnTimeDelivery() {
  const { fpToggle, level, timeframe } = useContext(OrderFulfillmentContext);
  const {
    isError,
    isLoading,
    results: [{ data: onTimeDelivery = [] }, { data: totalDelivery = [] }]
  } = useEnhancedQueries(['queryOnTimeDelivery', 'queryOnTimeTotalDelivery'], fpToggle);
  const [view, setView] = useState(TOTAL);

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const dataFilter = onTimeDelivery.filter(data => data.TRUCK_TYPE === String(view === FLATBED ? 'Flat' : view).toUpperCase());
  const headers = onTimeDeliveryHeaders(level);
  const data = {
    headers,
    data: view !== TOTAL ? dataFilter.map(data => onTimeDeliveryData(data, level, timeframe)) : totalDelivery.map(data => onTimeDeliveryData(data, level, timeframe)),
    sortable: true,
    sortableDefault: 0,
    pagination: true
  };

  return (
    <DataView className='my-4'>
      <DataView.Controls>
        <p className='my-0'>Select Truck Type</p>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={selectTrunkTypeOptions}
          onChange={(_, { value }) => setView(value)}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Delivery Details by {view}</DataView.Title>
      <DataView.DataTable data={data} />
    </DataView>
  );
}
