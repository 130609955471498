import { useEffect, useState } from 'react';
import { Message as SemanticMessage } from 'semantic-ui-react';

export const Message = ({ children, onClose, timeout, ...props }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose && onClose();
    }, timeout);

    return () => clearTimeout(timer);
  }, [onClose, timeout]);

  return (
    <SemanticMessage hidden={!visible} role='alert' {...props}>
      {children}
    </SemanticMessage>
  );
};
