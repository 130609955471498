import { createContext, useEffect, useState } from 'react';
import { useLevelContext } from '../hooks/useLevelContext';
import ReactGA from 'react-ga4';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const { setLevelNbr } = useLevelContext();
  const [platformStatus, setPlatformStatus] = useState('loading');
  const [fpToggle, setFpToggle] = useState('storeops');
  const [loginCheckData, setLoginCheckData] = useState({});

  useEffect(() => {
    if (window.MscJS !== undefined && window.MscJS !== null) {
      setPlatformStatus('FPLoaded');
    } else {
      // We don't detect android, so we serve up the desktop experience (which serves desktop and other mobile platforms)
      setPlatformStatus('assumedDesktop');
    }
  }, []);

  useEffect(() => {
    if (platformStatus === 'FPLoaded') {
      if (window.MscJS !== undefined && window.MscJS !== null) {
        const currentUserSessionData = JSON?.parse(
          window?.MscJS?.getCurrentUserSession() ?? '{"error":"Failed to get current user session data"}'
        );
        const currentDeviceSessionData = JSON?.parse(
          window?.MscJS?.getCurrentDeviceSession() ?? '{"error":"Failed to get current device session data"}'
        );
        if (
          currentUserSessionData &&
          currentUserSessionData?.status === 'success' &&
          currentDeviceSessionData &&
          currentDeviceSessionData?.status === 'success'
        ) {
          const currentUserSessionDataMessage = JSON?.parse(
            currentUserSessionData?.message ?? '{"message":"Unknown Error, line 182"}'
          );
          const currentDeviceSessionDataMessage = JSON?.parse(
            currentDeviceSessionData?.message ?? '{"message":"Unknown Error, line 186"}'
          );
          if (
            currentUserSessionDataMessage &&
            currentUserSessionDataMessage.pingUserAuthToken &&
            currentUserSessionDataMessage.pingUserAuthToken !== '' &&
            currentUserSessionDataMessage.pingUserRefreshToken &&
            currentUserSessionDataMessage.pingUserRefreshToken !== ''
          ) {
            setFpToggle('storeopsFP');
            setPlatformStatus('FPloggedIn');
          } else {
            setPlatformStatus('loggedOut');
          }

          if (currentDeviceSessionDataMessage && currentDeviceSessionDataMessage.storeNumber) {
            setLevelNbr(currentDeviceSessionDataMessage.storeNumber);
          } else {
            setFpToggle('storeops');
            setPlatformStatus('assumedDesktop');
          }
        }
      } else {
        // MSc not found, so assuming desktop.
        setFpToggle('storeops');
        setPlatformStatus('assumedDesktop');
      }
    } else if (platformStatus === 'assumedDesktop' || platformStatus === 'FPloggedIn') {
      fetch(`${process.env.REACT_APP_BACKEND}/api/${fpToggle}/loginCheck`, { credentials: 'include' })
        .then(resp => {
          if (resp.ok) {
            return resp.json();
          } else if (resp.status === 403) {
            return resp.json();
          } else {
            setPlatformStatus('loggedOut');
          }
        })
        .then(data => {
          if (data) {
            const { code, message } = data;
            if (
              code === 403 &&
              (message.includes('Permission denied') || message.includes('Missing required permissions'))
            ) {
              setPlatformStatus('permissionDenied');
              setLoginCheckData(data);
            } else if (code === 200) {
              ReactGA.set({ userId: data.user });
              setLoginCheckData(data);
              setPlatformStatus('loggedIn');
            }
          } else {
            setPlatformStatus('loggedOut');
          }
        });
    }
  }, [platformStatus, fpToggle, setLevelNbr]);

  const {
    code: loginCheckCode,
    message: loginCheckMessage,
    user,
    iscorporate: isCorporate = false,
    getsnplus1 = false,
    emplctgrynm,
    mgtlvlid = 1000,
    jobprofilenm,
    level: userLevel,
    levelnbr: userLevelNbr,
    isAdmin = false,
    isCore = false,
    PRNT_ORG_CD: userLevelAccess
  } = loginCheckData;

  const value = {
    loginCheckCode,
    loginCheckMessage,
    user,
    isCorporate,
    isLoggedIn: platformStatus === 'loggedIn',
    getsnplus1,
    emplctgrynm,
    mgtlvlid,
    jobprofilenm,
    userLevel,
    userLevelNbr,
    isAdmin,
    isCore,
    userLevelAccess,
    platformStatus,
    fpToggle
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
