import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { buildBaseShrinkUrl, buildBaseDrillLink } from './url';
import { formatLevelNumber } from '../../../utils/level';
import { capitalizeFirstLetter } from '../../../utils/string';
import { formatColor, getCompareStyle, getPercentString } from '../../Common/TableCellUtils';
import { getRowSubLevelNm } from '../../../utils/getRowLevelNm';
import { calculateRtvDeletedTagValue, calculateRtvDeletedTagVersusLy } from '../../../utils/shrinkCalculator';
import { divide } from '../../../utils/numbers';

export const focusMetricsData = ({ level, locNbr, timeframe, shrinkMetricsData, scoresData }) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const scoresDataFiltered = scoresData.filter(
    row => row && row['TIME_FRAME'] === timeframe && row.METRIC_LEVEL === METRIC_CONSTANTS[level].METRIC_LEVEL
  );
  return {
    headers: [
      { name: 'Metric' },
      { name: 'Value' },
      {
        name: 'vLY ',
        popup: {
          content:
            'If the metric is meeting the goal, but worse than last year, there is still an opportunity for shrink exposure. Always try to meet the goal first, then strive for improvement year-over-year.',
          icon: 'info circle',
          color: 'blue'
        }
      },
      { name: 'Goal' },
      { name: '% of Stores Passing ' }
    ],
    footer: [
      [
        { stringValue: 'Total Score' },
        { stringValue: '-' },
        { stringValue: '-' },
        { stringValue: '-' },
        {
          pctValue: scoresDataFiltered[0].SHRINK_EXEC_SCORE,
          decimals: scoresDataFiltered[0].SHRINK_EXEC_SCORE === 1 ? 0 : 2
        }
      ]
    ],
    data: [
      [
        {
          stringValue: 'Invalid Scans',
          link: {
            to: `/${baseLevelShrinkUrl}/invalidscans`,
            params: { [METRIC_CONSTANTS[level]['urlParam']]: locNbr }
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'],
          compareValue: scoresDataFiltered[0].INV_SCN_GOAL,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
            shrinkMetricsData[timeframe + '_LY_INVAL_SCN_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'],
          compareStyle: true,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].INV_SCN_GOAL,
          decimals: 2,
          prefix: '≤ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].INVALID_SCAN_SCORE
        }
      ],
      [
        {
          stringValue: 'RTV Credits',
          link: {
            to: `/${baseLevelShrinkUrl}/rtvcredit`,
            params: { [METRIC_CONSTANTS[level]['urlParam']]: locNbr }
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].RTV_CREDIT_GOAL
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_RTV_CREDIT_DEN'] -
            shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_NUM'] / shrinkMetricsData[timeframe + '_LY_RTV_CREDIT_DEN'],
          compareStyle: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].RTV_CREDIT_GOAL,
          decimals: 2,
          prefix: '≥ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].RTV_CREDIT_SCORE
        }
      ],
      [
        // Keyed SKUs
        {
          stringValue: 'Keyed SKUs',
          link: {
            to: `/${baseLevelShrinkUrl}/keyedsku`,
            params: { [METRIC_CONSTANTS[level]['urlParam']]: locNbr }
          },
          popup: {
            content: 'Keyed SKU = SKU that was manually keyed in at the register and not scanned.',
            icon: 'info circle',
            color: 'blue'
          }
        },
        {
          pctValue: divide(shrinkMetricsData[timeframe + '_KEYED_SKU_NUM'], shrinkMetricsData[timeframe + '_KEYED_SKU_DEN']),
          decimals: 2,
          customStyle: formatColor(0.0053, divide(shrinkMetricsData[timeframe + '_KEYED_SKU_NUM'], shrinkMetricsData[timeframe + '_KEYED_SKU_DEN'])),
        },
        {
          pctValue: divide(shrinkMetricsData[timeframe + '_KEYED_SKU_NUM'], shrinkMetricsData[timeframe + '_KEYED_SKU_DEN']) - divide(shrinkMetricsData[timeframe + '_LY_KEYED_SKU_NUM'], shrinkMetricsData[timeframe + '_LY_KEYED_SKU_DEN']),
          compareStyle: true,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].KEYED_SKU_GOAL,
          decimals: 2,
          prefix: '≤ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].KEYED_SKU_SCORE
        }
      ],
      [
        {
          stringValue: 'Clearance No-Homes',
          link: {
            to: `/${baseLevelShrinkUrl}/clearancenh`,
            params: { [METRIC_CONSTANTS[level]['urlParam']]: locNbr }
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].CLRNH_GOAL,
          compareStyleInvert: true
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_CLRNH_DEN'] -
            shrinkMetricsData[timeframe + '_LY_CLRNH_NUM'] / shrinkMetricsData[timeframe + '_LY_CLRNH_DEN'],
          compareStyle: true,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].CLRNH_GOAL,
          decimals: 2,
          prefix: '≤ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].CLEAR_NH_SCORE
        }
      ],
      [
        {
          stringValue: 'Damaged Markdowns',
          link: {
            to: `/${baseLevelShrinkUrl}/damagedcullmd`,
            params: { [METRIC_CONSTANTS[level]['urlParam']]: locNbr }
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].DAMAGED_MD_GOAL
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
            shrinkMetricsData[timeframe + '_LY_DAMAGED_MD_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'],
          compareStyle: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].DAMAGED_MD_GOAL,
          decimals: 2,
          prefix: '≥ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].DAMAGED_MD_SCORE
        }
      ],
      [
        {
          stringValue: 'Aged Will Calls',
          link: {
            to: `/${baseLevelShrinkUrl}/agedwc`,
            params: { [METRIC_CONSTANTS[level]['urlParam']]: locNbr }
          },
          popup: {
            content:
              'Aged Will Calls: Average(# of Aged Will Call Lines) / Average(Total # Open Will Call Lines) per timeframe selected.',
            icon: 'info circle',
            color: 'blue'
          }
        },
        {
          pctValue: shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'],
          decimals: 2,
          compareValue: scoresDataFiltered[0].AGED_WC_GOAL,
          compareStyleInvert: true
        },
        {
          pctValue:
            shrinkMetricsData[timeframe + '_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_AGED_WC_DEN'] -
            shrinkMetricsData[timeframe + '_LY_AGED_WC_NUM'] / shrinkMetricsData[timeframe + '_LY_AGED_WC_DEN'],
          compareStyle: true,
          compareStyleInvert: true,
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].AGED_WC_GOAL,
          decimals: 2,
          prefix: '≤ '
        },
        {
          decimals: 2,
          pctValue: scoresDataFiltered[0].AGED_WC_SCORE
        }
      ]
    ]
  };
};

export const monitorMetricsData = ({ level, locNbr, shrinkMetricsData, timeframe }) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);

  const headers = [
    { name: 'Metric' },
    { name: 'Value' },
    {
      name: 'vLY ',
      popup: {
        content:
          'Although there are no goals for the Monitor Metrics, it is important to continually strive for improvement compared to last year.',
        icon: 'info circle',
        color: 'blue'
      }
    }
  ];
  if (shrinkMetricsData.length) return { headers, data: [] };

  const rtvDeletedTagValue = calculateRtvDeletedTagValue(shrinkMetricsData, timeframe);
  const rtvDeletedTagVersusLY = calculateRtvDeletedTagVersusLy(shrinkMetricsData, timeframe);

  return {
    headers,
    data: [
      [
        {
          // RTV Deleted Tags
          stringValue: 'RTV Deleted Tags',
          link: {
            to: `/${baseLevelShrinkUrl}/rtvdeletedtag`,
            params: {
              [METRIC_CONSTANTS[level]['urlParam']]: locNbr
            }
          }
        },
        {
          decimals: 2,
          pctValue: rtvDeletedTagValue
        },
        {
          decimals: 2,
          pctValue: rtvDeletedTagVersusLY,
          customStyle: getCompareStyle(-1 * rtvDeletedTagVersusLY)
        }
      ],
      [
        {
          // Reduced Tags
          stringValue: 'Reduced Tags',
          link: {
            to: `/${baseLevelShrinkUrl}/reducedtag`,
            params: {
              [METRIC_CONSTANTS[level]['urlParam']]: locNbr
            }
          }
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'],
            2,
            2
          )
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
              shrinkMetricsData[timeframe + '_LY_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'],
            2,
            2
          ),
          customStyle: getCompareStyle(
            -1 *
              (shrinkMetricsData[timeframe + '_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_CORE_SLS'] -
                shrinkMetricsData[timeframe + '_LY_REDUCED_TAG_NUM'] / shrinkMetricsData[timeframe + '_LY_CORE_SLS'])
          )
        }
      ],
      [
        {
          // BOSS RTV %
          stringValue: 'BOSS RTV %',
          link: {
            to: `/${baseLevelShrinkUrl}/bosscancel`,
            params: {
              [METRIC_CONSTANTS[level]['urlParam']]: locNbr
            }
          }
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'],
            2,
            2
          )
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'] -
              shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_DEN'],
            2,
            2
          ),
          customStyle: getCompareStyle(
            shrinkMetricsData[timeframe + '_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_BOSS_CNCL_DEN'] -
              shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_NUM'] / shrinkMetricsData[timeframe + '_LY_BOSS_CNCL_DEN']
          )
        }
      ],
      [
        {
          // On Hand Adjustments & Empty Packages
          stringValue: 'On Hand Adjustments & Empty Packages',
          link: {
            to: `/${baseLevelShrinkUrl}/ohemptpkg`,
            params: {
              [METRIC_CONSTANTS[level]['urlParam']]: locNbr
            }
          }
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'],
            2,
            2
          )
        },
        {
          stringValue: getPercentString(
            shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'] -
              shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_DEN'],
            2,
            2
          ),
          compareStyle: getCompareStyle(
            -1 *
              (shrinkMetricsData[timeframe + '_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_OH_EMPT_PKG_DEN'] -
                shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_NUM'] / shrinkMetricsData[timeframe + '_LY_OH_EMPT_PKG_DEN'])
          )
        }
      ]
    ]
  };
};

export const accuracyAndReceivingData = ({ level, locNbr, queryACCRCVByLevel, timeframe }) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  let dstAccRcvDataFilter = queryACCRCVByLevel.filter(row => {
    return row && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL'];
  })[0];

  const GOAL_OH_AUTO_REJ_RATE = 0.03;
  const GOAL_AUTO_KR_COUNT = 0;
  const GOAL_UNMATCHED_XFER_CNT = 0;

  return {
    headers: [{ name: 'Metric' }, { name: 'Value' }, { name: 'Goal' }],
    data: [
      [
        {
          stringValue: 'OH-Auto Rej Rate',
          link: {
            to: `/${baseLevelShrinkUrl}/ohautorej`,
            params: {
              [METRIC_CONSTANTS[level]['urlParam']]: locNbr
            }
          }
        },
        {
          stringValue: getPercentString(
            dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] > 0
              ? dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] /
                  dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT']
              : 0
          ),
          customStyle: {
            color:
              dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_AUTO_RJT_CNT'] /
                dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_OH_NEED_APPRV_CNT'] >
              GOAL_OH_AUTO_REJ_RATE
                ? 'red'
                : 'green'
          }
        },
        {
          stringValue: `< ${getPercentString(GOAL_OH_AUTO_REJ_RATE)}`
        }
      ],
      [
        {
          stringValue: 'Auto Key-recs',
          link: {
            to: `/${baseLevelShrinkUrl}/autokr`,
            params: {
              [METRIC_CONSTANTS[level]['urlParam']]: locNbr
            }
          }
        },
        {
          stringValue:
            dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'] > 0
              ? dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT']
              : 0,
          customStyle: getCompareStyle(dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_AUTO_KR_COUNT'])
        },
        {
          stringValue: `${getPercentString(GOAL_AUTO_KR_COUNT)}`
        }
      ],
      [
        {
          stringValue: 'Unmatched Transfers',
          link: {
            to: `/${baseLevelShrinkUrl}/unmatchedxfers`,
            params: {
              [METRIC_CONSTANTS[level]['urlParam']]: locNbr
            }
          }
        },
        {
          stringValue:
            dstAccRcvDataFilter[timeframe.replace('R12M', 'L12').replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT'] > 0
              ? dstAccRcvDataFilter[timeframe.replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT']
              : 0,
          customStyle: getCompareStyle(
            dstAccRcvDataFilter[timeframe.replace('R12M', 'L12').replace('R12M', 'L12') + '_UNMATCHED_XFER_CNT']
          )
        },
        {
          stringValue: `${getPercentString(GOAL_UNMATCHED_XFER_CNT)}`
        }
      ]
    ]
  };
};

export const invalidScandData = ({ shrinkData, shrinkScoreData, timeframe, level }) => ({
  headers: [
    { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
    { name: 'Value ' },
    { name: 'Goal ' },
    { name: '% Stores Passing' }
  ],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink(level);
    return [
      {
        stringValue: row[METRIC_CONSTANTS[level]['propToRender']],
        link: {
          to: `/${baseLevelShrinkUrl}/invalidscans`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(row[METRIC_CONSTANTS[level]['propToRender']])
          }
        }
      },
      {
        stringValue: getPercentString(row[timeframe + '_INVAL_SCN_NUM'] / row[timeframe + '_CORE_SLS'], 2)
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
          )[0]['INV_SCN_GOAL'],
          2
        ),
        prefix: '≤ '
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
          )[0]['INVALID_SCAN_SCORE']
        )
      }
    ];
  })
});

export const clearanceNoHomesData = ({ shrinkData, shrinkScoreData, timeframe, level }) => ({
  headers: [
    { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
    { name: 'Value ' },
    { name: 'Goal ' },
    { name: '% Stores Passing' }
  ],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink(level);

    return [
      {
        stringValue: row[METRIC_CONSTANTS[level]['propToRender']],
        link: {
          to: `/${baseLevelShrinkUrl}/clearancenh`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(row[METRIC_CONSTANTS[level]['propToRender']])
          }
        }
      },
      {
        stringValue: getPercentString(row[timeframe + '_CLRNH_NUM'] / row[timeframe + '_CLRNH_DEN'], 2),
        orderBy: row[timeframe + '_CLRNH_NUM'] / row[timeframe + '_CLRNH_DEN']
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
          )[0]['CLRNH_GOAL'],
          2
        ),
        prefix: '≤ ',
        orderBy: shrinkScoreData.filter(
          score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
        )[0]['CLRNH_GOAL']
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
          )[0]['CLEAR_NH_SCORE']
        ),
        orderBy: shrinkScoreData.filter(
          score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
        )[0]['CLEAR_NH_SCORE']
      }
    ];
  })
});

export const shrinkRTVCreditsData = ({ level, timeframe, shrinkMetricsData, scoresData }) => {
  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  let scoresDataFiltered = scoresData.filter(row => {
    return row && row['TIME_FRAME'] === timeframe;
  });

  return {
    headers: [
      { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)} #` },
      { name: 'Value' },
      { name: 'Goal' },
      { name: '% of Stores Passing' }
    ],
    data: shrinkMetricsData.map(row => {
      const shrinkScoreData = scoresDataFiltered.find(
        item => item[METRIC_CONSTANTS[level].propToRender] === row[METRIC_CONSTANTS[level].propToRender]
      );

      return [
        {
          stringValue: row[METRIC_CONSTANTS[level]['propToRender']],
          link: {
            to: `/${baseLevelShrinkUrl}/rtvcredit`,
            params: {
              [METRIC_CONSTANTS[level].urlSubLevelParam]: row[METRIC_CONSTANTS[level].propToRender]
            }
          }
        },
        {
          pctValue: row[timeframe + '_RTV_CREDIT_NUM'] / row[timeframe + '_RTV_CREDIT_DEN'],
          decimals: 2
        },
        {
          pctValue: shrinkScoreData?.RTV_CREDIT_GOAL,
          prefix: '≥'
        },
        {
          pctValue: shrinkScoreData?.RTV_CREDIT_SCORE
        },
        {
          pctValue: row[timeframe + '_RTV_CREDIT_NUM'] / row[timeframe + '_RTV_CREDIT_DEN'],
          decimals: 2
        },
        {
          pctValue: scoresDataFiltered[0].RTV_CREDIT_GOAL,
          prefix: '≥'
        },
        {
          pctValue: scoresDataFiltered[0].RTV_CREDIT_SCORE
        }
      ];
    }),
    sortable: true,
    sortableDefault: 0
  };
};

export const agedWithCallsData = ({ shrinkData, shrinkScoreData, timeframe, level }) => ({
  headers: [
    { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
    { name: 'Value ' },
    { name: 'Goal ' },
    { name: '% of Stores Passing ' }
  ],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink(level);

    return [
      {
        stringValue: row[METRIC_CONSTANTS[level]['propToRender']],
        link: {
          to: `/${baseLevelShrinkUrl}/agedwc`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(row[METRIC_CONSTANTS[level]['propToRender']])
          }
        }
      },
      {
        stringValue: getPercentString(row[timeframe + '_AGED_WC_NUM'] / row[timeframe + '_AGED_WC_DEN'])
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
          )[0]['AGED_WC_GOAL'],
          2
        ),
        prefix: '≤ '
      },
      {
        stringValue: getPercentString(
          shrinkScoreData.filter(
            score => score && score[METRIC_CONSTANTS[level]['propToRender']] === row[METRIC_CONSTANTS[level]['propToRender']]
          )[0]['AGED_WC_SCORE']
        )
      }
    ];
  })
});

export const onHandAdjustment = ({ shrinkData, timeframe, level }) => ({
  headers: [{ name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' }, { name: 'Value ' }],
  sortable: true,
  sortableDefault: 0,
  data: shrinkData.map(row => {
    const baseLevelShrinkUrl = buildBaseDrillLink(level);

    const previousLocNbr = row[METRIC_CONSTANTS[level].propToRender];
    let sublevelNm = getRowSubLevelNm({ row, level, previousLocNbr });
    if (level === 'region') {
      sublevelNm = `DST ${previousLocNbr}`;
    }
    return [
      {
        stringValue: sublevelNm,
        link: {
          to: `/${baseLevelShrinkUrl}/ohemptpkg`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubLevelParam']]: formatLevelNumber(previousLocNbr)
          }
        }
      },
      {
        stringValue: getPercentString(row[timeframe + '_OH_EMPT_PKG_NUM'] / row[timeframe + '_OH_EMPT_PKG_DEN'], 2)
      }
    ];
  })
});
