import {useEffect} from 'react'
import {
    Grid,
    Header,
    Loader,
    Divider,
  } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { useQuery } from 'react-query'
import { DataTable } from '../Common/DataTable'

import {
    getData
  } from '../../service/DataService'

export const SidekickSkuBayLoggedWork = (({ timeframe, settimeframe, level, fpToggle, locNbr, location }) => {

  useEffect(() => {
    if (timeframe !== 'WTD') {
      settimeframe('WTD')
    }
  }, [timeframe, settimeframe])
  
  const osaSidekickSkuBayQuery = useQuery([`query/queryOSASidekickSkuBay/${level}`, { locNbr, fpToggle }], getData)
  if (osaSidekickSkuBayQuery.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!osaSidekickSkuBayQuery.data || osaSidekickSkuBayQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const bayData = osaSidekickSkuBayQuery.data
  .filter(({DEPT}) => DEPT !== '38')
  .filter(({TASK_TYPE}) => TASK_TYPE === 'LOGGED')

  const lastTimeframeMap = {
    'WTD': 'LW',
    'LW': null, // We don't have comparisons for last week or month, so they'll be null
    'LM': null,
    'MTD': 'LM',
    'QTD': 'LQ',
    'HTD': 'LH',
    'YTD': 'YTD',
    'L12': 'L12',
  }

  const ltf = lastTimeframeMap[timeframe]

  const loggedWorkData = {
    sortable: true,
    headers: [
      {name: 'Dept'}, {name: 'Logged SKUs #'}, {name: `v${ltf} SKUs #`}
    ],
    data: bayData
    .map(row => {
      const value = row[`${timeframe}_SKUS_PACKED_DOWN`] ? row[`${timeframe}_SKUS_PACKED_DOWN`] : 0
      const comp = row[`${ltf}_SKUS_PACKED_DOWN`] ? row[`${ltf}_SKUS_PACKED_DOWN`] : 0 
      return [
        {
            stringValue: `${row.DEPT} ${row.DEPT_NM}`,
            link: {
                to: `/osa/sidekickskubay/dept`,
                params: {
                    strNbr: locNbr,
                    deptNbr: row.DEPT,
                    active: 'LOGGED'
                }
            }
        }, 
        {stringValue: value}, 
        ltf ? {numValue: value - comp, compareStyle: true, prefix: value - comp > 0 ? '+' : ''} : {stringValue: '-'}
      ]
    })
  }


  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa/sidekickskubay/store?strNbr=${locNbr}`} data-test='b2osa-link'>SKU/Bay</Link>
          {` / Logged Work`}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
      </Grid.Column>
    </Grid>

    <Divider />

    <Header textAlign='center' >{`Logged Work`}</Header>
    <DataTable data={loggedWorkData} />
    </>
)
})
