import React, { useState, useEffect } from 'react'
import { Header, Dropdown, Icon, Grid, Table, Tab, Pagination } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { getCalloutStyle, getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'
import { UnitMenu } from '../Common/UnitMenu'
import { timeframeCode } from '../Common/timeframeCode'
import useQueryApi from '../../hooks/useQueryApi'
import { PlaceholderLoading } from '../Loading/PlaceholderLoading'

export const ClassesByStoreTab = (({ strNbr, strNm, timeframe, fpToggle }) => {

  const [metric, setMetric] = useState('SLS')
  const [sortColumn, setSortColumn] = useState("TY_SLS_AMT")
  const [sortDirection, setSortDirection] = useState("descending")
  const [dept, setDept] = useState('All')
  const [pageNum, setPageNum] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [unit, setUnit] = useState("NOM")

  const {
    isError: isErrorSlsClsByStr,
    isLoading: isLoadingSlsClsByStr,
    data: dataSlsClsByStr = [],
  } = useQueryApi('querySlsClsByStr', { strNbr, fpToggle })

  let dataFiltered = dataSlsClsByStr.filter((row) => {
    return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]
      && (dept === 'All' || row['DEPT_NBR'].toString() === dept)
  })

  let dataSorted = dataFiltered.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'MER_CLASS_NM') {
      result = left.MER_CLASS_NM > right.MER_CLASS_NM ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let dataPagination = dataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, dataSorted.length)))

  useEffect(() => {
    setPageNum(1)
  }, [timeframe, sortColumn, sortDirection, rowsPerPage, dept])

  if (isErrorSlsClsByStr) return <Header>Something went wrong fetching data.</Header>;
  if (isLoadingSlsClsByStr) return <PlaceholderLoading />;
  
  return (
    <Tab.Pane attached={false}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>
              Metric:
            </Grid.Row>
            <Grid.Row>
              <Dropdown
                defaultValue={metric}
                selection
                scrolling
                compact
                size='tiny'
                options={[{ key: 'SLS', text: 'Sales', value: 'SLS' },
                { key: 'UNT', text: 'Units', value: 'UNT' },
                { key: 'TXN', text: 'Trans', value: 'TXN' },
                { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' },
                ]}
                onChange={(_, { value }) => {
                  setMetric(value)
                }} />
            </Grid.Row>
            &nbsp;
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>
              Dept:
              <div>
                <select
                  name="dept"
                  onChange={e => {
                    setDept(e.target.value)
                  }}
                  value={dept}
                  style={{ padding: 10 }}
                >
                  <option value="All" selected>All</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="59">59</option>
                  <option value="78">78</option>
                </select>
              </div>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              Rows per Page:
            </Grid.Row>
            <Grid.Row>
              <div>
                <select
                  name="rowsPerPage"
                  onChange={e => {
                    setRowsPerPage(e.target.value)
                  }}
                  value={rowsPerPage}
                  style={{ padding: 10 }}
                >
                  <option value="20" selected>20</option>
                  <option value="40">40</option>
                </select>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        {dataSorted.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((dataSorted.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            {
              <>
                <Header textAlign='center'>Store {strNbr}, {strNm}, Classes</Header>
                <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={sortColumn === 'MER_CLASS_NM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'MER_CLASS_NM') {
                            setSortColumn('MER_CLASS_NM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Class <Icon fitted name='sort' /></Table.HeaderCell>
                      {metric === 'SLS' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_SLS_AMT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_SLS_AMT') {
                            setSortColumn('TY_SLS_AMT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales Amt ($) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {/* {metric === 'SLS' && <Table.HeaderCell
                        sorted={sortColumn === 'LY_COMP_SLS_AMT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'LY_COMP_SLS_AMT') {
                            setSortColumn('LY_COMP_SLS_AMT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales Comp ($) <Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'SLS' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'SLS_VP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SLS_VP_NOM') {
                            setSortColumn('SLS_VP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales vP ($) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'SLS' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'SLS_VP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SLS_VP_PCT') {
                            setSortColumn('SLS_VP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales vP (%) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'SLS' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'SLS_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SLS_COMP_NOM') {
                            setSortColumn('SLS_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales vLY ($) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'SLS' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'SLS_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'SLS_COMP_PCT') {
                            setSortColumn('SLS_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Sales vLY (%) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'UNT' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_UNT_SLS' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_UNT_SLS') {
                            setSortColumn('TY_UNT_SLS')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Unit Sales (#) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {/* {metric === 'UNT' && <Table.HeaderCell
                        sorted={sortColumn === 'LY_COMP_UNT_SLS' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'LY_COMP_UNT_SLS') {
                            setSortColumn('LY_COMP_UNT_SLS')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Unit Sales Comp (#) <Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'UNT' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'UNT_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'UNT_COMP_NOM') {
                            setSortColumn('UNT_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Units vLY (#) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'UNT' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'UNT_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'UNT_COMP_PCT') {
                            setSortColumn('UNT_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Units vLY (%) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'TXN' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_TXN_CNT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_TXN_CNT') {
                            setSortColumn('TY_TXN_CNT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Trans Cnt (#) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {/* {metric === 'TXN' && <Table.HeaderCell
                        sorted={sortColumn === 'LY_COMP_TXN_CNT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'LY_COMP_TXN_CNT') {
                            setSortColumn('LY_COMP_TXN_CNT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Trans Cnt Comp (#) <Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'TXN' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'TXN_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TXN_COMP_NOM') {
                            setSortColumn('TXN_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Trans vLY (#) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'TXN' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'TXN_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TXN_COMP_PCT') {
                            setSortColumn('TXN_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Trans vLY (%) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'AVG_TCKT' && <Table.HeaderCell
                        sorted={sortColumn === 'TY_AVG_TCKT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'TY_AVG_TCKT') {
                            setSortColumn('TY_AVG_TCKT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Avg Ticket ($) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {/* {metric === 'AVG_TCKT' && <Table.HeaderCell
                        sorted={sortColumn === 'LY_COMP_AVG_TCKT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'LY_COMP_AVG_TCKT') {
                            setSortColumn('LY_COMP_AVG_TCKT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Avg Ticket Comp ($) <Icon fitted name='sort' /></Table.HeaderCell>} */}
                      {metric === 'AVG_TCKT' && unit === 'NOM' && <Table.HeaderCell
                        sorted={sortColumn === 'AVG_TCKT_COMP_NOM' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'AVG_TCKT_COMP_NOM') {
                            setSortColumn('AVG_TCKT_COMP_NOM')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Avg Ticket vLY ($) <Icon fitted name='sort' /></Table.HeaderCell>}
                      {metric === 'AVG_TCKT' && unit === 'PCT' && <Table.HeaderCell
                        sorted={sortColumn === 'AVG_TCKT_COMP_PCT' ? sortDirection : null}
                        style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                        onClick={() => {
                          if (sortColumn !== 'AVG_TCKT_COMP_PCT') {
                            setSortColumn('AVG_TCKT_COMP_PCT')
                            setSortDirection('ascending')
                          } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                          }
                        }}>Avg Ticket vLY (%)<Icon fitted name='sort' /></Table.HeaderCell>}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {dataPagination.map((data) => {
                      return (
                        <Table.Row>
                          <Table.Cell>
                            <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/sales/class?strNbr=${strNbr}&classNbr=${data['EXT_CLASS_NBR']}`}>
                              {data['MER_CLASS_NM'].replaceAll('/', '/\u200b')}
                            </Link>
                          </Table.Cell>
                          {/* Sales metrics */}
                          {metric === 'SLS' && <Table.Cell singleLine style={getCalloutStyle(data['TY_SLS_AMT'])}>
                            {getDollarString(data['TY_SLS_AMT'])}
                          </Table.Cell>}
                          {/* {metric === 'SLS' && <Table.Cell singleLine style={getCalloutStyle(data['LY_COMP_SLS_AMT'])}>
                            {getDollarString(data['LY_COMP_SLS_AMT'])}
                          </Table.Cell>} */}
                          {metric === 'SLS' && unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data['SLS_VP_NOM'])}>
                            {getDollarString(data['SLS_VP_NOM'])}
                          </Table.Cell>}
                          {metric === 'SLS' && unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data['SLS_VP_PCT'])}>
                            {getPercentString(data['SLS_VP_PCT'])}
                          </Table.Cell>}
                          {metric === 'SLS' && unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data['SLS_COMP_NOM'])}>
                            {getDollarString(data['SLS_COMP_NOM'])}
                          </Table.Cell>}
                          {metric === 'SLS' && unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data['SLS_COMP_PCT'])}>
                            {getPercentString(data['SLS_COMP_PCT'])}
                          </Table.Cell>}
                          {/* Units metrics */}
                          {metric === 'UNT' && <Table.Cell singleLine style={getCalloutStyle(data['TY_UNT_SLS'])}>
                            {data['TY_UNT_SLS'] != null ?
                              data['TY_UNT_SLS'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* {metric === 'UNT' && <Table.Cell singleLine style={getCalloutStyle(data['LY_COMP_UNT_SLS'])}>
                            {data['LY_COMP_UNT_SLS'] != null ?
                              data['LY_COMP_UNT_SLS'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>} */}
                          {metric === 'UNT' && unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data['UNT_COMP_NOM'])}>
                            {data['UNT_COMP_NOM'] != null ?
                              data['UNT_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {metric === 'UNT' && unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data['UNT_COMP_PCT'])}>
                            {getPercentString(data['UNT_COMP_PCT'])}
                          </Table.Cell>}
                          {/* Transactions metrics */}
                          {metric === 'TXN' && <Table.Cell singleLine style={getCalloutStyle(data['TY_TXN_CNT'])}>
                            {data['TY_TXN_CNT'] != null ?
                              data['TY_TXN_CNT'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {/* {metric === 'TXN' && <Table.Cell singleLine style={getCalloutStyle(data['LY_COMP_TXN_CNT'])}>
                            {data['LY_COMP_TXN_CNT'] != null ?
                              data['LY_COMP_TXN_CNT'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>} */}
                          {metric === 'TXN' && unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data['TXN_COMP_NOM'])}>
                            {data['TXN_COMP_NOM'] != null ?
                              data['TXN_COMP_NOM'].toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
                              'Loading'
                            }
                          </Table.Cell>}
                          {metric === 'TXN' && unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data['TXN_COMP_PCT'])}>
                            {getPercentString(data['TXN_COMP_PCT'])}
                          </Table.Cell>}
                          {/* Average Ticket Metrics */}
                          {metric === 'AVG_TCKT' && <Table.Cell singleLine style={getCalloutStyle(data['TY_AVG_TCKT'])}>
                            {getDollarString(data['TY_AVG_TCKT'])}
                          </Table.Cell>}
                          {/* {metric === 'AVG_TCKT' && <Table.Cell singleLine style={getCalloutStyle(data['LY_COMP_AVG_TCKT'])}>
                            {getDollarString(data['LY_COMP_AVG_TCKT'])}
                          </Table.Cell>} */}
                          {metric === 'AVG_TCKT' && unit === 'NOM' && <Table.Cell singleLine style={getCompareStyle(data['AVG_TCKT_COMP_NOM'])}>
                            {getDollarString(data['AVG_TCKT_COMP_NOM'])}
                          </Table.Cell>}
                          {metric === 'AVG_TCKT' && unit === 'PCT' && <Table.Cell singleLine style={getCompareStyle(data['AVG_TCKT_COMP_PCT'])}>
                            {getPercentString(data['AVG_TCKT_COMP_PCT'])}
                          </Table.Cell>}
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              </>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  )
})
