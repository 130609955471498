import { useQuery } from 'react-query';
import { useUserContext } from './useUserContext';
import { handleFetch } from '../service/DataService';

export const useFetchLevelName = ({ level, locNbr, fpToggle }) => {
  const { isLoggedIn } = useUserContext()
  const { data, isLoading } = useQuery([`query/levelName/${level}/${locNbr}`, { fpToggle }], handleFetch, {
    enabled: isLoggedIn
  });

  return {
    currentLevelName: data?.levelName,
    isLoadingName: isLoading
  };
};
