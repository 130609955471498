import React from 'react';
import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { FocusMetricsTimeFrame } from './FocusMetricsTimeFrame';
import { RTVCreditCoreTable } from './RTVCreditCoreTable';

export const RTVCreditCore = () => {
  const title = 'RTV Credit'
  const { timeframe, settimeframe, fpToggle } = useShrinkCoreContext();

  const defaultParams = {
    fpToggle,
    isCore: true
  };
  const {
    data: shrinkRTVData = [],
    isLoading: shrinkRTVDataLoading,
    error: shrinkRTVError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', defaultParams);
  const {
    data: shrinkRTVScoreData = [],
    isLoading: shrinkRTVScoreDataLoading,
    error: shrinkRTVScoreDataError
  } = useQueryApi('queryShrinkMetricsScoresCore', defaultParams);

  if (shrinkRTVDataLoading || shrinkRTVScoreDataLoading) return <Loader active>Loading...</Loader>;
  if (shrinkRTVError || shrinkRTVScoreDataError) return <Header textAlign='center'>Something went wrong.</Header>;

  const shrinkRTVScoreDataFilter = shrinkRTVScoreData.filter(row => {
    return row && row['TIME_FRAME'] === timeframe;
  });

  return (
    <>
      <FocusMetricsTimeFrame timeframe={timeframe} settimeframe={settimeframe} title={title}/>

      <RTVCreditCoreTable
        shrinkData={shrinkRTVData}
        shrinkScoreData={shrinkRTVScoreDataFilter}
        dataAsOf={shrinkRTVScoreDataFilter[0]?.TRNSFM_DT}
        timeframe={timeframe}
      />
    </>
  );
};
