import React from 'react';
import { Tab } from 'semantic-ui-react';
import OnTimeAverage from './OnTimeAverage';
import OnTimeDeliveryMissed from './OnTimeDeliveryMissed';

export const OnTimeDeliveryAssoc = () => {
  const tabs = [
    {
      menuItem: 'PICK PERFORMANCE',
      render: () => <OnTimeAverage />
    },
    {
      menuItem: 'DELIVERY MISSES',
      render: () => <OnTimeDeliveryMissed />
    }
  ];

  return <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />;
};
