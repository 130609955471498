import React, { useMemo } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { timeframeCode } from '../Common/timeframeCode';
import { DataView } from '../Common/DataView';
import { ALL } from '../../utils/constants';
import useQueryApi from '../../hooks/useQueryApi';
import { aisleBayDataByUnit, aisleBayHeaders, BAYS, customStyleRow } from './AisleBayTab';

const bayDataRow = (bayData, strNbr, unit) => {
  if (!bayData) return [];
  return [
    {
      stringValue: bayData?.BAY,
      link: {
        to: `/store/baysbystore/bay`,
        params: {
          strNbr,
          bay: bayData?.BAY
        }
      }
    },
    { stringValue: bayData.DEPT_NBR },
    { dollarValue: bayData?.SLS_AMT, customStyle: customStyleRow(bayData?.SLS_AMT) },
    ...aisleBayDataByUnit(bayData, unit)
  ];
};

export const AisleBaysByStore = ({ strNbr, fpToggle, unit, deptSelected, timeframe }) => {
  const { data, isError, isLoading } = useQueryApi('querySwmLocStrBay', { strNbr, fpToggle });
 
  const dataFiltered = data && data.filter(row => row && row.TRNSFM_TYP_SORT_ID === timeframeCode[timeframe]);
  const dataFilteredByDept = useMemo(() => {
    if (isLoading) return []
    
    return deptSelected === ALL ? dataFiltered : dataFiltered.filter(row => row?.DEPT_NBR?.toString() === deptSelected) ||[];
  }, [isLoading, dataFiltered , deptSelected]);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header>Something went wrong fetching data.</Header>;

  const title = 'Top Bays';
  const baysDataTable = {
    sortable: true,
    sortableDefault: 2,
    sortDirection: 'ascending',
    headers: aisleBayHeaders(unit, BAYS),
    pagination: true,
    data: dataFilteredByDept?.map(row => bayDataRow(row, strNbr, unit))
  };

  return (
    <DataView>
      <Header textAlign='center'>{title}</Header>
      <section>
        {isLoading && (
          <Loader active inline className='full-width'>
            Loading...
          </Loader>
        )}
      </section>

      <DataView.DataTable data={baysDataTable} />
    </DataView>
  );
};
