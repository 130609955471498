import { Header } from 'semantic-ui-react';
import { DataTable } from '../../../../../components/Common/DataTable';

export const VocSummary = ({ data, timeframe }) => {
  const consumerSummary = data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'CONSUMER')[0];
  const proSummary = data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'PRO')[0];

  const bold = { fontWeight: 'bold' };
  const summaryObj = {
    headers: [{ name: '' }, { name: 'Consumer ' }, { name: 'Pro ' }, { name: 'Total ' }],
    data: [
      [
        { stringValue: 'LTSA', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_LTSA_SCR_CNT`] / consumerSummary?.[`${timeframe}_LTSA_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_LTSA_SCR_CNT`] / proSummary?.[`${timeframe}_LTSA_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_LTSA_SCR_CNT`] + proSummary?.[`${timeframe}_LTSA_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_LTSA_SVY_CNT`] + proSummary?.[`${timeframe}_LTSA_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Shelf Availability', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_SHLF_AVL_CNT`] / consumerSummary?.[`${timeframe}_SHLF_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_SHLF_AVL_CNT`] / proSummary?.[`${timeframe}_SHLF_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_SHLF_AVL_CNT`] + proSummary?.[`${timeframe}_SHLF_AVL_CNT`]) /
            (consumerSummary?.[`${timeframe}_SHLF_SVY_CNT`] + proSummary?.[`${timeframe}_SHLF_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'GET', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_GET_SCR_CNT`] / consumerSummary?.[`${timeframe}_GET_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_GET_SCR_CNT`] / proSummary?.[`${timeframe}_GET_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_GET_SCR_CNT`] + proSummary?.[`${timeframe}_GET_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_GET_SVY_CNT`] + proSummary?.[`${timeframe}_GET_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Greet', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_GRT_SCR_CNT`] / consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_GRT_SCR_CNT`] / proSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_GRT_SCR_CNT`] + proSummary?.[`${timeframe}_GRT_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] + proSummary?.[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Engage', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_ENG_SCR_CNT`] / consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_ENG_SCR_CNT`] / proSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_ENG_SCR_CNT`] + proSummary?.[`${timeframe}_ENG_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] + proSummary?.[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Thank', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_THK_SCR_CNT`] / consumerSummary?.[`${timeframe}_THK_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_THK_SCR_CNT`] / proSummary?.[`${timeframe}_THK_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_THK_SCR_CNT`] + proSummary?.[`${timeframe}_THK_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_THK_SVY_CNT`] + proSummary?.[`${timeframe}_THK_SVY_CNT`])
        }
      ]
    ]
  };

  return (
    <>
      <Header textAlign='center'>VOC Summary - Core</Header>
      <DataTable data={summaryObj} />
    </>
  );
};
