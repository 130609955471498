import { useContext, useState } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { DataView } from '../../../../../../components/Common/DataView';
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext';
import { Dropdown, Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';
import { completeDeliveryData, selectTruckTypeOptions } from '../../../../utils/completeDelivery';
import { FLATBED, TOTAL } from '../../../../utils/consts';

export const CompleteDelivery = () => {
  const { fpToggle, level, timeframe } = useContext(OrderFulfillmentContext);
  const {
    isError,
    isLoading,
    results: [{ data: queryDeliveryComplete = [] }, { data: completeTotalDelivery = [] }]
  } = useEnhancedQueries(['queryDeliveryComplete', 'queryCompleteTotalDelivery'], fpToggle)
  const [view, setView] = useState(TOTAL);

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const dataFilter = view !== TOTAL ? (
    queryDeliveryComplete.filter(row => row.TRUCK_TYPE === String(view === FLATBED ? 'Flat' : view).toUpperCase())
  ) : completeTotalDelivery
  const data = completeDeliveryData(view, level, dataFilter, timeframe);

  return (
    <DataView className='my-4'>
      <DataView.Controls>
        <p className='my-0'>Select Truck Type</p>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={selectTruckTypeOptions}
          onChange={(_, { value }) => setView(value)}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Delivery Details by {view}</DataView.Title>
      <DataView.DataTable data={data} />
    </DataView>
  );
};
