import { createContext, useMemo } from 'react';
import { useQueryParams } from '../hooks/useQueryParams';
import { IntradayMenu } from '../components/HourlyDaily/IntradayMenu';
import { findBiggestObject } from '../utils/array';
import { useParams } from 'react-router-dom';
import { useFetchLevelName } from '../hooks/useFetchLevelName';
import { useEnhancedQueries } from '../hooks/useEnhancedQueries';

export const IntradayContext = createContext();

const IntradayContextProvider = ({ fpToggle }) => {
  const { strNbr, dstNbr, rgnNbr, divNbr } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const { level } = useParams();

  const queries = ['salesIntraday', 'salesIntradayByLevel', 'salesIntradayDeptLatestHour'];
  const queryResults = useEnhancedQueries(queries, fpToggle);

  const { currentLevelName, isLoadingName } = useFetchLevelName({ fpToggle, level, locNbr });
  const { isError, isLoading } = queryResults;

  const [hourlySalesData, totalSalesByLevel, deptOverviewQuery] = queryResults.results;
  const latestHourData = useMemo(() => {
    if (isLoading) return {};

    return findBiggestObject(hourlySalesData.data, 'SLS_HR') || {};
  }, [hourlySalesData.data, isLoading]);

  const value = {
    hourlySalesData: hourlySalesData.data,
    currentLevelName: level === 'core' ? 'Core' : currentLevelName?.toLowerCase(),
    deptOverviewQuery: deptOverviewQuery.data,
    latestHourData: latestHourData,
    totalSalesByLevel: totalSalesByLevel.data,
    isLoading: isLoading || isLoadingName,
    isError,
    level,
    locNbr
  };

  return (
    <IntradayContext.Provider value={value}>
      <IntradayMenu />
    </IntradayContext.Provider>
  );
};

export default IntradayContextProvider;
