export const groupByName = (records = [], nameField, metricField) => {
  const view = records.reduce((acc, current) => {
    if (acc[current[nameField]]) {
      acc[current[nameField]][metricField] += current[metricField];
    }
    else {
      acc[current[nameField]] = {
        name: current[nameField],
        [metricField]: current[metricField],
      }
    }
    return acc;
  }, {})
  return Object.values(view)
}