import useQueryApi from '../../../hooks/useQueryApi';
import { CORE, STORE } from '../../../utils/constantsByLevel';

const { levelName: coreLevelName } = CORE;
const { levelName: storeLevelName } = STORE;

export const useShrinkCullMarkdownsQueries = params => {
  const { level, locNbr, fpToggle } = params;
  const { levelName } = level;
  const queryParamsByLevel = levelName === coreLevelName ? { isCore: true } : { locNbr };
  const queryParams = { ...queryParamsByLevel, fpToggle };

  const {
    data: cullMarkdownsStrData,
    isLoading: cullMarkdownsStrIsLoading,
    isError: cullMarkdownsStrIsError
  } = useQueryApi(`queryShrinkCullMdByStr`, {
    ...queryParams,
    enabled: levelName === storeLevelName
  });

  const {
    data: cullMarkdownsData,
    isLoading: cullMarkdownsIsLoading,
    isError: cullMarkdownsIsError
  } = useQueryApi(`query/queryShrinkMetricsByLevelPlusOne/${levelName}`, {
    ...queryParams,
    enabled: levelName !== storeLevelName
  });

  const {
    data: scoreCullMarkdownsData,
    isLoading: scoreCullMarkdownsIsLoading,
    isError: scoreCullMarkdownsIsError
  } = useQueryApi(`query/queryScoreMarkdowns/${levelName}`, {
    ...queryParams,
    enabled: levelName !== storeLevelName
  });

  const isLoading = cullMarkdownsStrIsLoading || cullMarkdownsIsLoading || scoreCullMarkdownsIsLoading;
  const isError = cullMarkdownsStrIsError || cullMarkdownsIsError || scoreCullMarkdownsIsError;

  return {
    cullMarkdownsData: levelName !== storeLevelName ? cullMarkdownsData : cullMarkdownsStrData,
    scoreCullMarkdownsData,
    isLoading,
    isError
  };
};
