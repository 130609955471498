import { buildSublevelLinkColumn } from '../../../utils/dataTable';
import { CORE, STORE, LEVEL_NBR } from '../../../utils/constantsByLevel';
import { TIME_FRAME } from '../../../utils/constants';
import { formatLevelNumber } from '../../../utils/level';
import { capitalizeFirstLetter } from '../../../utils/string';
import { getCompareStyle } from '../../Common/TableCellUtils';

const { levelName: coreLevelName } = CORE;
const { levelName: storeLevelName } = STORE;

const DAMAGED_MD_NUM = 'DAMAGED_MD_NUM';
const CORE_SLS = 'CORE_SLS';
const DAMAGED_MD_GOAL = 'DAMAGED_MD_GOAL';
const DAMAGED_MD_SCORE = 'DAMAGED_MD_SCORE';
const EXT_CLASS_NBR = 'EXT_CLASS_NBR';
const CLASS_DESC = 'CLASS_DESC';
const DAMAGED_MD = 'DAMAGED_MD';
const SLS_AMT = 'SLS_AMT';

export const getBreadcrumbItems = params => {
  const { level, locNbr } = params;
  const { levelName, urlParam } = level;
  const baseUrl = `/${levelName}/shrink`;
  const urlWithParams = `${baseUrl}?${urlParam}=${locNbr}`;

  const to = levelName === coreLevelName ? baseUrl : urlWithParams;
  const text = 'Back to Shrink Overview';

  return [{ to, text }];
};

export const getTitle = params => {
  const { level, locNbr, currentLevelName } = params;
  const { levelName } = level;
  const baseTitle = 'Damaged Markdowns';
  const locNbrFormated = formatLevelNumber(locNbr);

  const title = `${baseTitle} - ${capitalizeFirstLetter(levelName)}`;
  const titleSufix = locNbr ? `${locNbrFormated}, ${currentLevelName}` : '';

  return `${title} ${titleSufix}`;
};

export const getSubLevelColumn = params => {
  const { row, level, to } = params;
  const { levelName } = level;
  const subLevelColumn = buildSublevelLinkColumn({ level: levelName, row, to });

  return [subLevelColumn];
};

// Damaged Markdowns Table

const getDamagedMarkdownsStrTableHeaders = () => [
  { name: 'Class Number' },
  { name: 'Class Description' },
  { name: 'Damaged MD $' },
  { name: 'Damaged MD %' }
];

const getDamagedMarkdownsStrRowValues = params => {
  const { timeframe, row } = params;

  const damagedMd = row[`${timeframe}_${DAMAGED_MD}`];
  const slsAmt = row[`${timeframe}_${SLS_AMT}`];

  const classNumber = row[EXT_CLASS_NBR];
  const classDescription = row[CLASS_DESC];
  const damagedMdMoney = damagedMd;
  const damagedMdPct = damagedMd / slsAmt;

  return { classNumber, classDescription, damagedMdMoney, damagedMdPct };
};

const getDamagedMarkdownsStrRow = params => {
  const { classNumber, classDescription, damagedMdMoney, damagedMdPct } = params;

  return [
    { stringValue: classNumber },
    { stringValue: classDescription },
    { dollarValue: damagedMdMoney },
    { pctValue: damagedMdPct, decimals: 2 }
  ];
};

const getDamagedMarkdownsStrTableData = params => {
  const { timeframe, damagedMarkdownsData } = params;
  const damagedMarkdownsDataWithoutNull = damagedMarkdownsData.filter(row => row && row[EXT_CLASS_NBR] !== null);

  return damagedMarkdownsDataWithoutNull.map(row => {
    const damagedMarkdownsStrRowValues = getDamagedMarkdownsStrRowValues({ timeframe, row });

    return getDamagedMarkdownsStrRow(damagedMarkdownsStrRowValues);
  });
};

const getDamagedMarkdownsTableHeaders = params => {
  const { level } = params;
  const { subLevel } = level;
  const { levelName: subLevelName } = subLevel;

  return [
    { name: `${capitalizeFirstLetter(subLevelName)} #` },
    { name: 'Value %' },
    { name: 'Goal %' },
    { name: 'Score %' }
  ];
};

export const getDamagedMarkdownsRowValues = params => {
  const { level, timeframe, row, scoreDamagedMarkdownsData } = params;
  const { subLevel } = level;
  const { [LEVEL_NBR]: subLevelNbr } = subLevel;

  const damagedMdNum = row[`${timeframe}_${DAMAGED_MD_NUM}`];
  const coreSls = row[`${timeframe}_${CORE_SLS}`];
  const { [DAMAGED_MD_GOAL]: damagedMdGoal, [DAMAGED_MD_SCORE]: damagedMdScore } = scoreDamagedMarkdownsData.find(
    score => score[subLevelNbr] === row[subLevelNbr]
  );

  const valuePct = damagedMdNum / coreSls;
  const goalPct = damagedMdGoal;
  const scorePct = damagedMdScore;

  return { valuePct, goalPct, scorePct };
};

export const getDamagedMarkdownsRow = params => {
  const { valuePct, goalPct, scorePct } = params;

  return [
    { pctValue: valuePct, decimals: 2, customStyle: (getCompareStyle(parseFloat(valuePct)  - parseFloat(goalPct))) }, 
    { pctValue: goalPct, decimals: 2, prefix: '≥ ' }, 
    { pctValue: scorePct }
  ];
};

export const getDamagedMarkdownsTableData = params => {
  const { level, timeframe, to, damagedMarkdownsData, scoreDamagedMarkdownsData } = params;
  const scoreDamagedMarkdownsDataByTimeframe = scoreDamagedMarkdownsData.filter(row => row[TIME_FRAME] === timeframe);

  return damagedMarkdownsData.map(row => {
    const damagedMarkdownsRowValues = getDamagedMarkdownsRowValues({
      level,
      timeframe,
      row,
      scoreDamagedMarkdownsData: scoreDamagedMarkdownsDataByTimeframe
    });
    const subLevelColumn = getSubLevelColumn({ row, level, to });

    return [...subLevelColumn, ...getDamagedMarkdownsRow(damagedMarkdownsRowValues)];
  });
};

export const getDamagedMarkdownsDataTable = params => {
  const { level, timeframe, to, damagedMarkdownsData, scoreDamagedMarkdownsData } = params;
  const { levelName } = level;

  const headers =
    levelName !== storeLevelName ? getDamagedMarkdownsTableHeaders({ level }) : getDamagedMarkdownsStrTableHeaders();
  const data =
    levelName !== storeLevelName
      ? getDamagedMarkdownsTableData({ level, timeframe, to, damagedMarkdownsData, scoreDamagedMarkdownsData })
      : getDamagedMarkdownsStrTableData({ timeframe, damagedMarkdownsData });

  return {
    headers,
    data,
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending',
    pagination: true
  };
};
