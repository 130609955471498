import { Header, Icon, Segment } from 'semantic-ui-react';

export const ErrorScreen = () => (
  <Segment className='full-height full-width' color='red' placeholder>
    <Header icon>
      <Icon name='times circle' />
      Something went wrong
    </Header>
  </Segment>
);
