import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { TimeframeDropdownShrink } from '../../../../../components/Common/TimeframeDropdownShrink';
import { buildBaseShrinkUrl } from '../../../../../components/ShrinkContainer/utils/url';

export const FocusMetricsTimeFrame = ({ timeframe, settimeframe , title}) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl('core');

  return (
    <Grid>
      <Grid.Column width={12} textAlign='left'>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${baseLevelShrinkUrl}`} data-test='b2o-link'>
            Shrink
          </Link> / {title}
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={8} textAlign='left'>
        <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
      </Grid.Column>
    </Grid>
  );
};
