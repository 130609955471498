import React, { useState } from 'react'
import {
  Header,
  Loader,
  Grid
} from 'semantic-ui-react'
import parse from "html-react-parser";

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

export const HeadlinesInsights = (({ fpToggle, level, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

  const queryKey = level === 'store' ? ['queryHeadlinesInsightsByStr', { strNbr, fpToggle }] :
                   level === 'district' ? ['queryHeadlinesInsightsByDst', { dstNbr, fpToggle }] :
                   null;
  const headlinesInsightsQuery = useQuery(queryKey, getData, { enabled: !!queryKey });
  const [fiscalWeekIndex, setFiscalWeekIndex] = useState(0)

  if (headlinesInsightsQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  if (!headlinesInsightsQuery.data[0]) {
    return (<Header textAlign='center'>Invalid {level} number</Header>)
  }
  let data = headlinesInsightsQuery.data.sort((left, right) => {
    return right['FSCL_YR_WK_KEY_VAL'] - left['FSCL_YR_WK_KEY_VAL']
  })
  let fiscalWeeks = []
  data.map((row) => {
    return fiscalWeeks.push(row['FSCL_YR_WK_KEY_VAL'])
  })
  let fiscalWeeksSorted = fiscalWeeks

  let dataRow = data[fiscalWeekIndex]

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <b>Note:</b><br/>Latest fiscal week report added every Monday ~9am ET.
        </Grid.Column>
        <Grid.Column width={8} textAlign='center'>
          Fiscal Week:
          <div>
            <select
              name="fiscalWeek"
              onChange={e => {
                setFiscalWeekIndex(e.target.value)
              }}
              value={fiscalWeekIndex}
              style={{ padding: 10 }}
            >
              {fiscalWeeksSorted.map((row, index) => {
                return <option value={index}>{row}</option>
              })}
            </select>
          </div>
        </Grid.Column>
      </Grid>
      {parse(dataRow['RPT_OUTPUT'])}
    </>
  )

})