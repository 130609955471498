import { useState } from "react";
import { DataView } from "../../../../../../../components/Common/DataView";
import { CustomDropDown } from "../../CustomDropdown";
import { groupByName } from "../../../utils/groupByName";
import { formatAssociateName, formatManagerName } from '../../../../../utils/utils';

export const DeliveryPull = ({ associateData, orderData, mainTimeframe }) => {

  const [view, setView] = useState('Manager View');
  const [timeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['Pulled Work Orders'];

  const field = mainTimeframe + '_PULL_MANIPULATIONS';
  const filterByTimeframe = associateData.filter((data) => data[field] > 0);
  const ordersByTimeframe = orderData.filter((data) => data[timeframe + '_FLG'] === 1);
  
  const getData = () => {
    switch (view) {
      case 'Manager View':
        return {
          headers: [
            { name: 'Manager Name' },
            { name: 'Work Orders Pulled' },
          ],
          data: groupByName(filterByTimeframe, 'MANAGER_NAME', field).map(data => [
            { stringValue: formatManagerName(data.name) },
            { numValue: data[field] }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Work Orders Pulled' },
          ],
          data: groupByName(filterByTimeframe, 'ASSOC_NAME', field).map(data => [
            { stringValue: formatManagerName(data.name) },
            { numValue: data[field] }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case 'Pulled Work Orders':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Manager Name' },
            { name: 'Customer Order #' },
            { name: 'Work Order #' },
            { name: 'Pull $' },
            { name: 'Pull Date' },
          ],
          data: ordersByTimeframe.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NAME) },
            { stringValue: formatManagerName(data.MANAGER_NAME) },
            { numValue: data.CUST_ORD_NBR },
            { numValue: data.WORK_ORD_NBR, orderBy: data.WORK_ORD_NBR },
            { dollarValue: data.AMOUNT },
            { numValue: data.MANIPULATION_DATE },
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        }
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <DataView className='my-4'>
      <CustomDropDown
        view={view}
        setView={setView}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Manager View', text: 'Manager View', value: 'Manager View' },
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: 'Pulled Work Orders', text: 'Pulled Work Orders', value: 'Pulled Work Orders' }
        ]}
        timeframeOptions={[
          { key: 'WTD', text: 'WTD', value: 'WTD' },
          { key: 'LW', text: 'LW', value: 'LW' },
          { key: 'LM', text: 'LM', value: 'LM' },
          { key: 'MTD', text: 'MTD', value: 'MTD' }
        ]}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
}