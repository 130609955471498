import { DataView } from '../../Common/DataView';
import { getCullMarkdownsDataTable } from '../utils/shrinkCullMarkdowns';

export const CullMarkdowns = params => {
  const { level, timeframe, to, cullMarkdownsData, scoreCullMarkdownsData } = params;
  const cullMarkdownsDataTable = getCullMarkdownsDataTable({
    level,
    timeframe,
    to,
    cullMarkdownsData,
    scoreCullMarkdownsData
  });

  return <DataView.DataTable data={cullMarkdownsDataTable} />;
};
