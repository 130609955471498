import { ActiveEvents } from './ActiveEvents';
import useQueryApi from '../../hooks/useQueryApi';
import { Header, Loader } from 'semantic-ui-react';
import { CompletedEvents } from './CompletedEvents';
import { useQueryParams } from '../../hooks/useQueryParams';
import { capitalizeFirstLetter as capitalize } from '../../utils/string';

const ORG_NAME = {
  store: 'STR_NM',
  district: 'DST_NM',
  region: 'RGN_NM',
  division: 'DIV_NM'
};

export const Events = ({ fpToggle, level }) => {
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();

  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const endPoint = `query/eventsSummary/${level}`;
  const params = { locNbr, fpToggle };
  const { data = [], isLoading } = useQueryApi(endPoint, params);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (data.length === 0) {
    return <Header textAlign='center'>Invalid {level} number</Header>;
  }

  return (
    <>
      <Header textAlign='center'>
        Events - {capitalize(level)} {locNbr}, {data[0]?.[ORG_NAME[level]]}
      </Header>

      <ActiveEvents events={data} level={level} locNbr={locNbr} />
      <CompletedEvents events={data} level={level} locNbr={locNbr} />
    </>
  );
};
