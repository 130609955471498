import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { useCreditContext } from '../hooks/useCreditContext';
import { Title } from '../../../Common/Title';
import { DataTable } from '../../../Common/DataTable';
import { TimeframeDropdown } from '../../../Common/TimeframeDropdown';
import { ChannelAssociate } from '../../ChannelAssociate';
import { creditStorePageBuilder } from '../utils/creditStorePageBuilder';

export const CreditStorePage = ({ creditPerfStrByStr, creditSlsPenStrByStr }) => {
  const { settimeframe, timeframe, fpToggle } = useCreditContext();
  const { applicationData, efficiencyData, usageData } = creditStorePageBuilder(
    creditPerfStrByStr,
    creditSlsPenStrByStr,
    timeframe
  );

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <Title text='Credit' data={creditPerfStrByStr} fpToggle={fpToggle} />
      {/* Applications Summary */}
      <Header textAlign='center'>Applications</Header>
      <DataTable data={applicationData} />
      <p>Note: If % vGoal ≥ 0%, then the goal is met.</p>
      {/* Efficiency Summary */}
      <Header textAlign='center'>Efficiency</Header>
      <DataTable data={efficiencyData} />
      {/* Usage Summary */}
      <Header textAlign='center'>Usage</Header>
      <DataTable data={usageData} />
      {/* Channel-Associate Tab Toggle */}
      <ChannelAssociate timeframe={timeframe} />
    </>
  );
};

export default CreditStorePage;
