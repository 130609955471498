import { getTimeframeOptions } from "../../utils/timeFrameUtils"
import { useUserContext } from "../../hooks/useUserContext"
import { useLevelContext } from "../../hooks/useLevelContext"

export const TimeframeDropdown = ({ timeframe, settimeframe, enableCoreValidation }) => {
  const { isCore } = useUserContext();
  const { level } = useLevelContext();
  const options = getTimeframeOptions(99, isCore, level, enableCoreValidation);

  return (
    <div>
      <select
        name="timeframe"
        onChange={e => {
          settimeframe(e.target.value)
        }}
        value={timeframe}
        style={{ padding: 10 }}
      >
        {options.map(option => (
          <option key={option.key} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  )
}
