import React from 'react'
import { Header, Loader, Grid, Tab } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { AllClassesTab } from './AllClassesTab'
import { TopBtmClassesTab } from './TopBtmClassesTab'
import { TopBtmSubclassesTab } from './TopBtmSubclassesTab'
import { TopBtmSkusTab } from './TopBtmSkusTab'
import { timeframeCode } from '../Common/timeframeCode'
import { useQueryParams } from '../../hooks/useQueryParams'
import { getPrevAndNextElement } from '../../utils/array'
import { TimeframeMenu } from '../Menus/TimeFrameMenu'
import { LEVEL_KEYS } from '../../constants/deptDrilldown'
import { useParams } from 'react-router-dom'
import { useFetchLevelName } from '../../hooks/useFetchLevelName'

const QUERIES = {
  store: {
    dept: 'querySlsDeptByStr',
    classes: 'querySlsClsByStrDept',
    salesTopBottom: 'querySlsTopBtmByStrDept'
  },
  district: {
    dept: 'querySlsDeptByDst',
    classes: 'querySlsClsByDstDept',
    salesTopBottom: 'querySlsTopBtmByDstDept'
  },
  region: {
    dept: 'querySlsDeptByRgn',
    classes: 'querySlsClsByRgnDept',
    salesTopBottom: 'querySlsTopBtmByRgnDept'
  },
  division: {
    dept: 'querySlsDeptByDiv',
    classes: 'querySlsClsByDivDept',
    salesTopBottom: 'querySlsTopBtmByDivDept'
  },
}

export const DeptDrilldown = ({ timeframe, settimeframe, fpToggle }) => {
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, deptNbr: deptNbrInt = 0, divNbr = 0 } = useQueryParams()
  const { level } = useParams()

  const locNbr = strNbr || dstNbr || rgnNbr || divNbr || '0'.padStart(4, 0)
  const { currentLevelName, isLoadingName } = useFetchLevelName({ fpToggle, locNbr, level })
  const { data: deptQuery, isLoading: isLoadingdeptQuery } = useQuery([QUERIES[level]['dept'], { locNbr, fpToggle }], getData)
  const { data: clsQuery, isLoading: isLoadingclsQuery } = useQuery([QUERIES[level]['classes'], { locNbr, deptNbrInt, fpToggle }], getData)
  const { data: slsTopBtmQuery, isLoading: isLoadingslsTopBtmQuery } = useQuery([QUERIES[level]['salesTopBottom'], { locNbr, deptNbrInt, fpToggle }], getData)

  if (isLoadingdeptQuery || isLoadingclsQuery || isLoadingslsTopBtmQuery || isLoadingName) {
    return (<Loader active>Loading...</Loader>)
  }

  // Load, extract and sort department numbers
  let deptDataSorted = deptQuery.sort((left, right) => left.DEPT_NBR - right.DEPT_NBR)

  let deptNbrArr = []
  deptDataSorted.map((data) => deptNbrArr.push(data['DEPT_NBR'].toString()))

  let deptNmArr = []
  deptDataSorted.map((data) => deptNmArr.push(data['MER_DEPT_NM']))

  let deptNm = deptNmArr[deptNbrArr.indexOf(deptNbrInt)]
  const currentIndex = deptDataSorted.findIndex(dept => dept['DEPT_NBR'] === parseInt(deptNbrInt))
  const [previousDept, nextDept] = getPrevAndNextElement(deptDataSorted, currentIndex)
  const dataAsOf = slsTopBtmQuery[0]?.TRNSFM_DT || clsQuery[0]?.TRNSFM_DT // If the TRNSFM_DT field is not available in slsTopBtmQuery, use the other one from clsQuery

  // Filter all classes by timeframe
  const allClasses = clsQuery.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe])

  // Filter top-btm classes by timeframe
  const topBtmClasses = slsTopBtmQuery.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] && row['PROD_HIER'] === 'CLASSES')

  // Filter top-btm subclasses by timeframe
  const topBtmSubclasses = slsTopBtmQuery.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] && row['PROD_HIER'] === 'SUB_CLASSES')

  // Filter top-btm classes by timeframe
  const topBtmSkus = slsTopBtmQuery.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] && row['PROD_HIER'] === 'SKUS')

  const allClassesTab = <AllClassesTab allClasses={allClasses} level={level} orgNbr={locNbr} deptNbrInt={deptNbrInt} name={currentLevelName} dataAsOf={dataAsOf} />
  const topBtmClassesTab = <TopBtmClassesTab topBtmClasses={topBtmClasses} level={level} orgNbr={locNbr} deptNbrInt={deptNbrInt} name={currentLevelName} dataAsOf={dataAsOf} />
  const topBtmSubclassesTab = <TopBtmSubclassesTab topBtmSubclasses={topBtmSubclasses} level={level} orgNbr={locNbr} deptNbrInt={deptNbrInt} name={currentLevelName} dataAsOf={dataAsOf} />
  const topBtmSkusTab = <TopBtmSkusTab topBtmSkus={topBtmSkus} name={currentLevelName} dataAsOf={dataAsOf} level={level} orgNbr={locNbr} />

  const tabs = [
    {
      menuItem: 'All Classes',
      render: () => allClassesTab
    },
    {
      menuItem: 'Top/Btm Classes',
      render: () => topBtmClassesTab
    },
    {
      menuItem: 'Top/Btm Subclasses',
      render: () => topBtmSubclassesTab
    },
    {
      menuItem: 'Top/Btm SKUs',
      render: () => topBtmSkusTab
    }
  ]

  return (
    <>
      <Grid>
        <Grid.Column width={16} textAlign='left'>
          <Grid.Row>
            <a
              href={`/${level}/overview?${LEVEL_KEYS[level]}=${locNbr}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >Overview</a>
            {' / '}
            {deptNm}
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>{deptNm}</Header>
      {/* Buttons for adjacent departments */}
      <Grid>
        <Grid.Row fluid>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/${level}/sales/dept?${LEVEL_KEYS[level]}=${locNbr}&deptNbr=${previousDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >
              {previousDept?.MER_DEPT_NM}
            </Link>
          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/${level}/sales/dept?${LEVEL_KEYS[level]}=${locNbr}&deptNbr=${nextDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >
              {nextDept?.MER_DEPT_NM}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <TimeframeMenu
        timeframe={timeframe}
        settimeframe={settimeframe}
      />
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Tab menu={{ attached: false, pointing: false, secondary: false, borderless: false, compact: true, size: 'small', widths: 4 }} panes={tabs} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </>
  )
}
