import { Header, Popup, Icon } from 'semantic-ui-react';
import { DataView } from '../Common/DataView';
import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited';
import { OSA_SIDEKICK_SKUBAY_GOAL } from '../../constants/goals';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { buildSublevelLinkColumn } from '../../utils/dataTable';
import { capitalizeFirstLetter } from '../../utils/string';

const getHeaders = ({ level, ltf }) => [
  { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].subLevel.levelName)}s` },
  { name: 'SKU/Bay Execution Score' },
  { name: 'Goal' },
  { name: ltf ? `v${ltf} bps` : '-' }
];

const getOverviewData = ({ level, ltf, directedTaskSummary }) => {
  const subLevel = METRIC_CONSTANTS[level].subLevel;
  const subLevelName = subLevel.levelName;

  return Object.entries(directedTaskSummary)
    .map(([_, data]) => {
      const ltfValue =
        ltf && Math.round((data.totalCompletedTasks / data.totalTasksGoal - data.ttlCompLTF / data.ttlTasksLTF) * 10000);
      return [
        {
          ...buildSublevelLinkColumn({ level, row: data, path: `/osa/sidekickskubay/${subLevelName}` })
        },
        {
          compareValue: OSA_SIDEKICK_SKUBAY_GOAL,
          pctValue: data.completedTasks / (data.taskGoal || 1)
        },
        { pctValue: OSA_SIDEKICK_SKUBAY_GOAL, decimals: 1 },
        ltf
          ? {
              compareStyle: true,
              stringValue: ltfValue.toLocaleString(undefined, { maximumFractionDigits: 0 }),
              orderBy: ltfValue,
              postfix: ' bps'
            }
          : { stringValue: '-' }
      ];
    })
    .filter(row => row !== null); // Filter out any null rows
};

const getOverviewDataTable = ({ level, ltf, directedTaskSummary }) => ({
  headers: getHeaders({ level, ltf }),
  sortable: true,
  sortableDefault: 0,
  data: getOverviewData({ level, ltf, directedTaskSummary })
});

export const SidekickExecSkuBay = props => {
  const { level, locNbr, currentLevelName, osaSidekickSkuBayData, timeframe, settimeframe, ltf, directedTaskSummary } =
    props;
  const overviewDataTable = getOverviewDataTable({ level, ltf, directedTaskSummary });

  return (
    <DataView className='mt-2'>
      <DataView.Title>
        <span>
          Sidekick Execution - SKU/Bay - {level} {locNbr}, {currentLevelName}
          <Popup
            on='click'
            position='bottom left'
            content='Execution Rate= Directed Tasks Completed/Directed Tasks Goal.  Directed Tasks Goals is an estimate of the number of tasks that can be done by the store given the labor hours.'
            trigger={<Icon name={'info circle'} color={'blue'} />}
          />
        </span>
        <Header.Subheader>Data as of {osaSidekickSkuBayData[0]?.TRNSFM_DT}</Header.Subheader>
      </DataView.Title>

      <DataView.Controls className='align-items-center d-flex flex-column'>
        Timeframe:
        <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />
      </DataView.Controls>
      <DataView.DataTable data={overviewDataTable} />
    </DataView>
  );
};
