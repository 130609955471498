import { Header, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DataView } from '../../../../../components/Common/DataView';
import { TimeframeDropdownShrink } from '../../../../../components/Common/TimeframeDropdownShrink';
import { PlaceholderLoading } from '../../../../../components/Loading/PlaceholderLoading';
import { keyedSkuObject } from '../../../../../components/ShrinkContainer/utils/keyedSkuBuilder';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { METRIC_CONSTANTS } from '../../../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../../../utils/string';

export const CoreKeyedSkuPage = ({ timeframe, settimeframe, fpToggle }) => {
  const params = { isCore: true, fpToggle };
  const {
    data: shrinkMetricsScores = [],
    isLoading: isLoadingShrinkMetricsScores,
    error: shrinkMetricsScoresError
  } = useQueryApi('queryShrinkMetricsScoresByCore', params);
  const {
    data: shrinkMetrics = [],
    isLoading: isLoadingShrinkMetrics,
    error: shrinkMetricsError
  } = useQueryApi('queryShrinkMetricsByCoreLevel', params);

  const DATA_AS_OF = shrinkMetricsScores[0]?.TRNSFM_DT;
  const CURRENT_LEVEL = capitalizeFirstLetter(METRIC_CONSTANTS['core'].levelName);
  const CURRENT_SUB_LEVEL = capitalizeFirstLetter(METRIC_CONSTANTS['core'].urlLinkLevel);

  if (isLoadingShrinkMetrics || isLoadingShrinkMetricsScores) return <PlaceholderLoading />;
  if (shrinkMetricsScoresError || shrinkMetricsError) return <Header textAlign='center'>Something went wrong.</Header>;

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/core/shrink`} data-test='b2o-link'>
              Shrink
            </Link> / Keyed SKUs 
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header>
        Keyed SKUs - {CURRENT_LEVEL}
        <Header.Subheader>Data As Of {DATA_AS_OF}</Header.Subheader>
      </Header>
      <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
      <DataView className='my-4'>
        <DataView.Title>Keyed SKUs By {CURRENT_SUB_LEVEL}</DataView.Title>
        <DataView.DataTable data={keyedSkuObject(timeframe, 'core', shrinkMetrics, shrinkMetricsScores)} />
      </DataView>
    </>
  );
};
