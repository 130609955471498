import { useParams } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useFetchLevelName } from '../../../hooks/useFetchLevelName';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { Breadcrumb } from '../../Common/Breadcrumb';
import { useShrinkDamagedCullMarkdownsQueries } from '../hooks/useShrinkDamagedCullMarkdownsQueries';
import { DataView } from '../../Common/DataView';
import { TimeframeDropdownShrink } from '../../Common/TimeframeDropdownShrink';
import { DamagedCullMarkdownsSummary } from '../components/DamagedCullMarkdownsSummary';
import { DamagedMarkdowns } from '../components/DamagedMarkdowns';
import { CullMarkdowns } from '../components/CullMarkdowns';
import { getBreadcrumbItems, getTitle } from '../utils/shrinkDamagedCullMarkdowns';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../utils/level';
import { getDataAsOf } from '../../../utils/getDataAsOf';

export const ShrinkDamagedCullMarkdownsPage = () => {
  const { timeframe, settimeframe, fpToggle } = useShrinkContext();
  const { level: levelName } = useParams();
  const level = METRIC_CONSTANTS[levelName];
  const { subLevel } = level;
  const { levelName: subLevelName } = subLevel;
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const locNbrFormated = formatLevelNumber(locNbr);
  const { currentLevelName, isLoadingName: isLoadingCurrentLevelName } = useFetchLevelName({
    level: levelName,
    locNbr: locNbrFormated,
    fpToggle
  });
  const {
    summaryData,
    damagedMarkdownsData,
    scoreDamagedMarkdownsData,
    cullMarkdownsData,
    scoreCullMarkdownsData,
    isLoading: queriesIsLoading,
    isError
  } = useShrinkDamagedCullMarkdownsQueries({
    level,
    locNbr,
    fpToggle
  });

  const isLoading = isLoadingCurrentLevelName || queriesIsLoading;

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const breadcrumbItems = getBreadcrumbItems({ level, locNbr });
  const title = getTitle({ level, locNbr, currentLevelName });
  const dataAsOf = getDataAsOf({ data: summaryData });
  const to = '/shrink/damagedcullmd';

  return (
    <DataView className='my-3'>
      <Breadcrumb items={breadcrumbItems} />

      <DataView.Title>
        {title}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </DataView.Title>

      <DataView.Controls>
        <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
      </DataView.Controls>

      <DataView className='my-4'>
        <DataView.Title position='left'>Summary</DataView.Title>

        <DamagedCullMarkdownsSummary timeframe={timeframe} summaryData={summaryData} />
      </DataView>

      <DataView className='my-4'>
        <DataView.Title>Damaged Markdowns by {subLevelName === 'store' ? 'Class' : subLevelName}</DataView.Title>

        <DamagedMarkdowns
          level={level}
          timeframe={timeframe}
          to={to}
          damagedMarkdownsData={damagedMarkdownsData}
          scoreDamagedMarkdownsData={scoreDamagedMarkdownsData}
        />
      </DataView>

      <DataView className='my-4'>
        <DataView.Title>Cull Markdowns by {subLevelName === 'store' ? 'Class' : subLevelName}</DataView.Title>

        <CullMarkdowns
          level={level}
          timeframe={timeframe}
          to={to}
          cullMarkdownsData={cullMarkdownsData}
          scoreCullMarkdownsData={scoreCullMarkdownsData}
        />
      </DataView>
    </DataView>
  );
};
