import { useQueryParams } from '../../../hooks/useQueryParams';
import { getThousandDollarString } from '../../Common/TableCellUtils';

import { DataView } from '../../Common/DataView';

export const SmdDetailDeptTab = ({ data, timeframe }) => {
  const { rgnNbr } = useQueryParams();

  const dataObj = {
    data: data.map(row => {
      const pctSmdValue = row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT'];
      const smdBpsValue = pctSmdValue - row[timeframe + '_MKDN_PLN_AMT'] / row[timeframe + '_SLS_PLN_AMT'];
      const smdOverUnder =
        row[timeframe + '_SMD'] -
        (row[timeframe + '_MKDN_PLN_AMT'] * row['TY_' + timeframe + '_SLS_AMT']) / row[timeframe + '_SLS_PLN_AMT'];

      return [
        {
          link: {
            params: {
              rgnNbr: rgnNbr,
              deptNbr: row.DEPT_NBR
            },
            to: '/region/smd/dept'
          },
          stringValue: `${row['DEPT_NBR']}-${row['DEPT_NM']?.toUpperCase()?.replace(/\//g, ' ')}`
        },
        {
          orderBy: row[timeframe + '_SMD'],
          stringValue: getThousandDollarString(row[timeframe + '_SMD'], 1)
        },
        {
          decimals: 2,
          pctValue: pctSmdValue
        },
        {
          compareStyle: true,
          compareStyleInvert: true,
          orderBy: 10000 * smdBpsValue,
          postfix: ' bps',
          stringValue: (10000 * smdBpsValue).toLocaleString(undefined, { maximumFractionDigits: 0 })
        },
        {
          compareStyle: true,
          compareStyleInvert: true,
          customStyle: { color: smdOverUnder > 0 ? 'green' : 'red' },
          stringValue: getThousandDollarString(smdOverUnder, 1)
        }
      ];
    }),
    headers: [
      { name: 'Dept # ' },
      { name: 'SMD ' },
      { name: 'SMD % ' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-3' testId='data-view-smd-detail-departments'>
      <DataView.Title>SMD - Departments</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
