import React, { useState } from 'react'
import { Link} from 'react-router-dom'
import {
  Table,
  Header,
  Grid,
  Loader,
  Menu
} from 'semantic-ui-react'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

import { getDollarString, getPercentString, getCalloutStyle, getCompareStyle } from '../Common/TableCellUtils'

export const ServicesDtlDstPrgm = (({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
  const smryPrgmNm = search.has('smryPrgmNm') ? search.get('smryPrgmNm') : ''

  const [salesPipeline, setSalesPipeline] = useState('Sales')
  const [pipelineFilter, setPipelineFilter] = useState('TOTAL')

  const sortColumn = 'STR_NBR';

  const query = useQuery(['queryServicesDtlByDstPrgm', { dstNbr, smryPrgmNm, fpToggle }], getData)

  if (query.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  if (!query.data[0]) {
    return (<Header textAlign='center'>Invalid or Unauthorized District Number</Header>)
  }

  let data = query.data

  // Process data at store level
  let dataStrFilter = data.filter(row => {
    return row && row.METRIC_LEVEL === 'STR'
  })

  let dataStrSorted = dataStrFilter.sort((left, right) => {
    return left[sortColumn] - right[sortColumn]
  })

  // Process data at district level
  let dataDstFilter = data.filter(row => {
    return row && row.METRIC_LEVEL === 'DST'
  })[0]

  return (
    <>
      <Header textAlign='center'>{dataDstFilter.MER_DEPT_CD}-{dataDstFilter.SUMMARY_PROGRAM_NAME} - District #{dstNbr}</Header>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/district/services?dstNbr=${dstNbr}`}>Back to Services</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='right'>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          &nbsp;
        </Grid.Column>
      </Grid>

      <Menu widths={2} size='small' color='orange'>
          <Menu.Item
            name='Sales'
            active={salesPipeline === 'Sales'}
            content='Sales'
            onClick={() => {
              setSalesPipeline('Sales')
            }}
          />
          <Menu.Item
            name='Pipeline'
            active={salesPipeline === 'Pipeline'}
            content='Pipeline'
            onClick={() => {
              setSalesPipeline('Pipeline')
            }}
          />
        </Menu>

        {salesPipeline === 'Pipeline' && <Grid>
          <Grid.Column width={8}></Grid.Column>
          <Grid.Column width={8} textAlign='right'>
            <div>
              <select
                name="pipeline filter"
                onChange={e => {
                  setPipelineFilter(e.target.value)
                }}
                value={pipelineFilter}
                style={{ padding: 10 }}
              >
                <option value="TOTAL" selected>All</option>
                <option value="STR">Store</option>
                <option value="WEB">Web</option>
                <option value="SP">Service Provider</option>
                <option value="OTHER">Other</option>
              </select>
            </div>
            &nbsp;
          </Grid.Column>
        </Grid>}
        <Table textAlign='center' celled unstackable size='small' compact>
          <Table.Header>
          <Table.Row>
              <Table.HeaderCell>Store #</Table.HeaderCell>
              {salesPipeline === 'Sales' && <Table.HeaderCell>Actual</Table.HeaderCell>}
              {salesPipeline === 'Sales' && <Table.HeaderCell>vP $</Table.HeaderCell>}
              {salesPipeline === 'Pipeline' && <Table.HeaderCell>Lead</Table.HeaderCell>}
              {salesPipeline === 'Pipeline' && <Table.HeaderCell>Meas</Table.HeaderCell>}
              <Table.HeaderCell>Comp %</Table.HeaderCell>
              {salesPipeline === 'Pipeline' && <Table.HeaderCell>Conv %</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {dataStrSorted.map(data => {
              return (
                <Table.Row key={data.STR_NBR}>
                  <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/services?strNbr=${data.STR_NBR}`}>STR {data.STR_NBR}</Link></Table.Cell>
                  {salesPipeline === 'Sales' && <Table.Cell style={getCalloutStyle(data[timeframe + '_TY_SLS_AMT'])}>{getDollarString(data[timeframe + '_TY_SLS_AMT'])}</Table.Cell>}
                  {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(data[timeframe + '_TY_SLS_AMT'] - data[timeframe + '_SLS_PLN_AMT'])}>{getDollarString(data[timeframe + '_TY_SLS_AMT'] - data[timeframe + '_SLS_PLN_AMT'])}</Table.Cell>}
                  {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(data[timeframe + '_TY_COMP_SLS_AMT'] - data[timeframe + '_LY_COMP_SLS_AMT'])}>{getPercentString((data[timeframe + '_TY_COMP_SLS_AMT'] - data[timeframe + '_LY_COMP_SLS_AMT']) / data[timeframe + '_LY_COMP_SLS_AMT'])}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell>{data[timeframe + '_LEAD_COUNT_' + pipelineFilter] ? data[timeframe + '_LEAD_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell>{data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] ? data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell style={getCompareStyle(data[timeframe + '_LEAD_COUNT_' + pipelineFilter] + data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - data[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - data[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}>{getPercentString((data[timeframe + '_LEAD_COUNT_' + pipelineFilter] + data[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - data[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - data[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) / (data[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] + data[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]))}</Table.Cell>}
                  {salesPipeline === 'Pipeline' && <Table.Cell>{getPercentString((data[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] + data[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter]) / (data[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] + data[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter]))}</Table.Cell>}
                </Table.Row>
              )
            })}
            <Table.Row style={{ fontWeight: 'bold' }}>
              <Table.Cell>Total</Table.Cell>
              {salesPipeline === 'Sales' && <Table.Cell style={getCalloutStyle(dataDstFilter[timeframe + '_TY_SLS_AMT'])}>{getDollarString(dataDstFilter[timeframe + '_TY_SLS_AMT'])}</Table.Cell>}
              {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(dataDstFilter[timeframe + '_TY_SLS_AMT'] - dataDstFilter[timeframe + '_SLS_PLN_AMT'])}>{getDollarString(dataDstFilter[timeframe + '_TY_SLS_AMT'] - dataDstFilter[timeframe + '_SLS_PLN_AMT'])}</Table.Cell>}
              {salesPipeline === 'Sales' && <Table.Cell style={getCompareStyle(dataDstFilter[timeframe + '_TY_COMP_SLS_AMT'] - dataDstFilter[timeframe + '_LY_COMP_SLS_AMT'])}>{getPercentString((dataDstFilter[timeframe + '_TY_COMP_SLS_AMT'] - dataDstFilter[timeframe + '_LY_COMP_SLS_AMT']) / dataDstFilter[timeframe + '_LY_COMP_SLS_AMT'])}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{dataDstFilter[timeframe + '_LEAD_COUNT_' + pipelineFilter] ? dataDstFilter[timeframe + '_LEAD_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{dataDstFilter[timeframe + '_MEASURE_COUNT_' + pipelineFilter] ? dataDstFilter[timeframe + '_MEASURE_COUNT_' + pipelineFilter] : 0}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell style={getCompareStyle(dataDstFilter[timeframe + '_LEAD_COUNT_' + pipelineFilter] + dataDstFilter[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - dataDstFilter[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - dataDstFilter[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter])}>{getPercentString((dataDstFilter[timeframe + '_LEAD_COUNT_' + pipelineFilter] + dataDstFilter[timeframe + '_MEASURE_COUNT_' + pipelineFilter] - dataDstFilter[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] - dataDstFilter[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]) / (dataDstFilter[timeframe + '_LY_LEAD_COUNT_' + pipelineFilter] + dataDstFilter[timeframe + '_LY_MEASURE_COUNT_' + pipelineFilter]))}</Table.Cell>}
              {salesPipeline === 'Pipeline' && <Table.Cell>{getPercentString((dataDstFilter[timeframe + '_LEAD_CONV_NUM_' + pipelineFilter] + dataDstFilter[timeframe + '_MEASURE_CONV_NUM_' + pipelineFilter]) / (dataDstFilter[timeframe + '_LEAD_CONV_DEN_' + pipelineFilter] + dataDstFilter[timeframe + '_MEASURE_CONV_DEN_' + pipelineFilter]))}</Table.Cell>}
            </Table.Row>
          </Table.Body>
        </Table>

    </>
  )
})