import { aggregateObjectKeys } from "../../../utils/array";
import { formatNumberWithCommas } from "../../../utils/formatter";

export const summaryData = (sumData, timeframe, isNOM) => {
  const [deliveryData = {}] = sumData.filter(data => {
    return data['METHOD'] === 'DELIVERY';
  });
  const [pickupData = {}] = sumData.filter(data => {
    return data['METHOD'] === 'PICKUP';
  });
  const totalDataToAggregate = sumData.filter(data => data.METHOD === 'PICKUP' || data.METHOD === 'DELIVERY');
  const total = aggregateObjectKeys(totalDataToAggregate)

  return {
    headers: [
      { name: 'Method' },
      { name: 'Pick On Time %' },
      { name: 'Fulfillment %' },
      { name: isNOM ? 'Orders Fulfilled TY' : 'Sales TY $' },
      { name: isNOM ? 'Orders Fulfilled LY' : '$ Unfulfilled' }
    ],
    data: [
      [
        { stringValue: 'Pickup' },
        {
          pctValue: pickupData[`ON_TIME_` + timeframe]
        },
        { pctValue: pickupData['FULFILLMENT_' + timeframe] },
        {
          numValue: isNOM ? formatNumberWithCommas(pickupData[`${timeframe}_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? pickupData[`${timeframe}_FULFILL_SALES`] : undefined
        },
        {
          numValue: isNOM ? formatNumberWithCommas(pickupData[`${timeframe}_LY_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? pickupData[`${timeframe}_CANCELED_SALES`] : undefined
        }
      ],
      [
        { stringValue: 'Delivery' },
        { pctValue: deliveryData['ON_TIME_' + timeframe] },
        { pctValue: deliveryData['FULFILLMENT_' + timeframe] },
        {
          numValue: isNOM ? formatNumberWithCommas(deliveryData[`${timeframe}_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? deliveryData[`${timeframe}_FULFILL_SALES`] : undefined
        },
        {
          numValue: isNOM ? formatNumberWithCommas(deliveryData[`${timeframe}_LY_ORDERS_FULFILLED`]) : undefined,
          dollarValue: !isNOM ? deliveryData[`${timeframe}_CANCELED_SALES`] : undefined
        }
      ]
    ],
    footer: [
      [
        { stringValue: 'Total' },
        {
          pctValue: total[timeframe + '_ON_TIME_COUNT_NUM'] / total[timeframe + '_ON_TIME_COUNT_DEN']
        },
        {
          pctValue: total[timeframe + '_FULFIL_QTY'] / total[timeframe + '_TOTAL_QTY']
        },
        {
          numValue: isNOM
            ? formatNumberWithCommas(
                pickupData[`${timeframe}_ORDERS_FULFILLED`] + deliveryData[`${timeframe}_ORDERS_FULFILLED`]
              )
            : undefined,
          dollarValue: !isNOM
            ? pickupData[`${timeframe}_FULFILL_SALES`] + deliveryData[`${timeframe}_FULFILL_SALES`]
            : undefined
        },
        {
          numValue: isNOM
            ? formatNumberWithCommas(
                pickupData[`${timeframe}_LY_ORDERS_FULFILLED`] + deliveryData[`${timeframe}_LY_ORDERS_FULFILLED`]
              )
            : undefined,
          dollarValue: !isNOM
            ? pickupData[`${timeframe}_CANCELED_SALES`] + deliveryData[`${timeframe}_CANCELED_SALES`]
            : undefined
        }
      ]
    ]
  };
};
