import React from 'react';
import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { FocusMetricsTimeFrame } from './FocusMetricsTimeFrame';
import { InvalidScansCoreTable } from './InvalidScansCoreTable';

export const InvalidScansCore = () => {
  const title = "Invalid Scans";
  const { timeframe, settimeframe, fpToggle } = useShrinkCoreContext();
  const defaultParams = {
    fpToggle,
    isCore: true
  };
  const {
    data: shrinkData = [],
    isLoading: shrinkDataLoading,
    error: shrinkError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', defaultParams);
  const {
    data: shrinkScoreData = [],
    isLoading: shrinkScoreDataLoading,
    error: shrinkScoreDataError
  } = useQueryApi('queryShrinkMetricsScoresCore', defaultParams);

  if (shrinkDataLoading || shrinkScoreDataLoading) return <Loader active>Loading...</Loader>;
  if (shrinkError || shrinkScoreDataError) return <Header textAlign='center'>Something went wrong.</Header>;

  const recordsByLevel = shrinkScoreData.filter(row => row['TIME_FRAME'] === timeframe);
  return (
    <>
      <FocusMetricsTimeFrame timeframe={timeframe} settimeframe={settimeframe} title={title}/>
      <InvalidScansCoreTable
        shrinkData={shrinkData}
        shrinkScoreData={recordsByLevel}
        dataAsOf={recordsByLevel[0]?.TRNSFM_DT}
        timeframe={timeframe}
      />
    </>
  );
};
