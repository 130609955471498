import { createContext, useState, useEffect } from 'react';
import { useQueryParams } from '../hooks/useQueryParams';
import { useLocation } from 'react-router-dom';

export const LevelContext = createContext();

const LevelContextProvider = ({ children }) => {
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  let levelFromUrl = window.location.pathname.split('/').filter(Boolean)[0];

  // levelFromUrl should be one of the following: store, district, region, division, core. Default to store if not one of those values
  if (!['store', 'district', 'region', 'division', 'core'].includes(levelFromUrl)) {
    // There are some pages, like OSA, that don't have a level in the URL. Use the number variables to determine the level
    levelFromUrl = 'store';
    if (dstNbr) {
      levelFromUrl = 'district';
    } else if (rgnNbr) {
      levelFromUrl = 'region';
    } else if (divNbr) {
      levelFromUrl = 'division';
    }
  }

  const [level, setLevel] = useState(levelFromUrl);
  const [levelNbr, setLevelNbr] = useState(strNbr || dstNbr || rgnNbr || divNbr || '');

  const myLocation = useLocation();

  useEffect(() => {
    setLevel(levelFromUrl);
    setLevelNbr(strNbr || dstNbr || rgnNbr || divNbr || '');
  }, [myLocation, strNbr, dstNbr, rgnNbr, divNbr, levelFromUrl]);

  const value = {
    level,
    setLevel,
    levelNbr,
    setLevelNbr,
  };

  return <LevelContext.Provider value={value}>{children}</LevelContext.Provider>;
};

export default LevelContextProvider;