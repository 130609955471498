import React from 'react'
import {
    Grid,
    Header,
    Loader,
  } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { DataTable } from '../Common/DataTable'

import {
    getData
  } from '../../service/DataService'


export const OSAImagingDeptAisle = (({ timeframe, settimeframe, level, fpToggle, locNbr, location }) => {

  const search = new URLSearchParams(location.search)
  const deptNbrInt = search.has('deptNbr') ? search.get('deptNbr') : 0
  const aisle = search.has('aisle') ? search.get('aisle') : 0

  const osaImagingQuery = useQuery([`query/osaImageCapture/${level}`, { locNbr, fpToggle }], getData)
  if (osaImagingQuery.isLoading) {
    return (<Loader active>Loading</Loader>)
  }
  if (!osaImagingQuery.data || osaImagingQuery.data.length === 0) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }
  
  let dept_nm = ''
  const bayData = {
    headers: [{name: 'Bays'},{name: 'Image Needed'},{name: 'Last Imaged'},{name: 'Days Old'}],
    data: 
    osaImagingQuery.data
    .filter(row => row.MER_DEPT_NBR.toString() === deptNbrInt && row.AISLE === aisle && row[`${timeframe}_IMAGES_EXPECTED`] > 0)
    .map(({AISLE_BAY, IMAGE_DAY_AGE, LAST_IMAGED_DT, DEPT_NM}) => {
        if (DEPT_NM) {
          dept_nm = DEPT_NM
        }
        return [
          {stringValue: AISLE_BAY},
          {
            stringValue: IMAGE_DAY_AGE < 7 && IMAGE_DAY_AGE != null ? 'NO' : 'YES',
            customStyle: {color: IMAGE_DAY_AGE < 7 && IMAGE_DAY_AGE != null ? 'green' : 'red'}
          },
          {stringValue: LAST_IMAGED_DT},
          {stringValue: IMAGE_DAY_AGE, compareValue: 7, compareStyleInvert: true},
        ]
      })
  }

  return (
    <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>
          <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa/store/imaging?strNbr=${locNbr}`} data-test='b2osa-link'>Imaging%</Link>
          {' / '}
          <Link style={{ color: '#EE7125' }} to={`/osa/imaging/dept?strNbr=${locNbr}&deptNbr=${deptNbrInt}`} data-test='b2osa-link'>{`D${deptNbrInt}`}</Link>
          {` / Aisle ${aisle}`}
        </Grid.Row>
        <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
        Timeframe:
        <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
      </Grid.Column>
    </Grid>
    <Header textAlign='center' >{`Bays - Aisle ${aisle} - ${deptNbrInt} ${dept_nm}`}</Header>
    <DataTable data={bayData} />
    </>
)
})