import { useState } from 'react';
import { DataView } from '../../../../../../../components/Common/DataView';
import { CustomDropDown } from '../../CustomDropdown';
import { formatAssociateName } from '../../../../../utils/utils';
import { getTimeframeOptions } from '../../../../../../../utils/timeFrameUtils';

const CHANGE_WORK_ORDERS_VIEW = 'Changed Work Orders';

export const TruckChanges = ({ deliveryData = [] }) => {
  const [view, setView] = useState(CHANGE_WORK_ORDERS_VIEW);
  const [timeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = [CHANGE_WORK_ORDERS_VIEW];
  const filterByTimeframe = deliveryData.filter(data => {
    return data[timeframe + '_FLG'] === 1;
  });

  const data = {
    headers: [
      { name: 'Associate Name' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'Original Truck' },
      { name: 'New Truck' },
      { name: 'Change Date' }
    ],
    data: filterByTimeframe.map(data => [
      { stringValue: formatAssociateName(data.ASSOC_NAME) },
      { stringValue: data.CUST_ORD_NBR },
      { numValue: data.WORK_ORD_NBR, orderBy: data.WORK_ORD_NBR },
      { stringValue: data.INTIAL_TRUCK_TYPE ? data.INTIAL_TRUCK_TYPE : '-' },
      { dollarValue: data.ACTUAL_TRUCK_TYPE },
      { numValue: data.MANIPULATION_DATE }
    ]),
    pagination: true
  };

  const viewOptions = [CHANGE_WORK_ORDERS_VIEW].map(view => ({ key: view, text: view, value: view }));
  const timeframeOptions = getTimeframeOptions(4);

  return (
    <DataView className='my-4'>
      <CustomDropDown
        view={view}
        setView={setView}
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={viewOptions}
        timeframeOptions={timeframeOptions}
      />
      <DataView.DataTable data={data} />
    </DataView>
  );
};
