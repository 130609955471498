import { RegionTab } from './Region/RegionTab';
import useQueryApi from '../../hooks/useQueryApi';
import { Header, Loader, Tab } from 'semantic-ui-react';
import { DistrictListTab } from './Region/DistrictListTab';
import { useQueryParams } from '../../hooks/useQueryParams';

const EVENTS_QUERIES = {
  district: {
    details: 'queryEvntDtlByDivEvnt',
    summary: 'queryEvntSumryByDivEvnt',
    summaryAggregation: 'queryEvntSmryByDivEvnt'
  },
  division: {
    details: 'queryEvntDtlByDivEvnt',
    summary: 'queryEvntSumryByDivEvnt',
    summaryAggregation: 'queryEvntSmryByDivEvnt'
  },
  core: {
    details: 'queryEvntDtlByDivEvnt',
    summary: 'queryEvntSumryByDivEvnt',
    summaryAggregation: 'queryEvntSmryByDivEvnt'
  },
  region: {
    details: 'queryEvntDtlByRgnEvnt',
    summary: 'queryEvntSumryByRgnEvnt',
    summaryAggregation: 'queryEvntSmryByRgnEvnt'
  }
};

export const EventsDetailRgn = ({ fpToggle, level }) => {
  const { evntId = 0, divNbr = 0, rgnNbr = 0 } = useQueryParams();
  const params = { evntId, locNbr: divNbr || rgnNbr, fpToggle };
  const orgNbr = divNbr || rgnNbr;

  const levelLabel = level === 'division' ? 'Division' : 'Region';

  const details = useQueryApi(EVENTS_QUERIES[level].details, params);
  const summary = useQueryApi(EVENTS_QUERIES[level].summary, params);
  const summaryAggregation = useQueryApi(EVENTS_QUERIES[level].summaryAggregation, params);

  if (summary.isLoading || details.isLoading || summaryAggregation.isLoading) return <Loader active>Loading...</Loader>;

  const summaryData = summary.data[0];
  if (!summaryData) return <Header textAlign='center'>Invalid district number</Header>;

  const tabs = [
    {
      menuItem: levelLabel,
      render: () => <RegionTab eventDtlData={details.data} eventSummaryData={summaryData} fpToggle={fpToggle} />
    },
    {
      menuItem: level === 'division' ? 'Region List' : 'District List',
      render: () => <DistrictListTab events={summaryAggregation.data} level={level} />
    }
  ];

  const levelName = level === 'division' ? summaryData?.DIV_NM : summaryData?.RGN_NM;

  return (
    <div>
      <Header>
        {summaryData?.EVNT_NM} - {levelLabel} {orgNbr}, {levelName}
      </Header>

      <div className='d-flex mb-4 gap-3'>
        <span>Begin: {summaryData['EVNT_EFF_BGN_DT']}</span>
        <span>End: {summaryData['EVNT_EFF_END_DT']}</span>
      </div>

      <Tab className='mt-3' panes={tabs} />
    </div>
  );
};
