import { useQuery } from 'react-query';
import { RegionLtsa } from './RegionLtsa';
import { DataTable } from '../../Common/DataTable';
import { getData } from '../../../service/DataService';
import { Loader, Header, Segment, Grid } from 'semantic-ui-react';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { capitalizeFirstLetter as capitalize } from '../../../utils/string';

export const RegionVocSummary = ({ timeframe, settimeframe, fpToggle, level, locNbr }) => {
  const { data = [], isError, isLoading } = useQuery([`query/ltsaSummary/${level}`, { locNbr, fpToggle }], getData);
  const levelName = level === 'division' ? data[0]?.DIV_NM : data[0]?.RGN_NM;

  if (isLoading) {
    return <Loader active>Loading...</Loader>;
  }
  if (isError) {
    return <p>Something went wrong.</p>;
  }

  const consumerSummary = data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'CONSUMER')[0];
  const proSummary = data.filter(({ CUSTOMER_TYPE }) => CUSTOMER_TYPE === 'PRO')[0];

  const bold = { fontWeight: 'bold' };
  const summaryObj = {
    headers: [{ name: '' }, { name: 'Consumer' }, { name: 'Pro' }, { name: 'Total' }],
    data: [
      [
        { stringValue: 'LTSA', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_LTSA_SCR_CNT`] / consumerSummary?.[`${timeframe}_LTSA_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_LTSA_SCR_CNT`] / proSummary?.[`${timeframe}_LTSA_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_LTSA_SCR_CNT`] + proSummary?.[`${timeframe}_LTSA_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_LTSA_SVY_CNT`] + proSummary?.[`${timeframe}_LTSA_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Shelf Availability', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_SHLF_AVL_CNT`] / consumerSummary?.[`${timeframe}_SHLF_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_SHLF_AVL_CNT`] / proSummary?.[`${timeframe}_SHLF_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_SHLF_AVL_CNT`] + proSummary?.[`${timeframe}_SHLF_AVL_CNT`]) /
            (consumerSummary?.[`${timeframe}_SHLF_SVY_CNT`] + proSummary?.[`${timeframe}_SHLF_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'GET', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_GET_SCR_CNT`] / consumerSummary?.[`${timeframe}_GET_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_GET_SCR_CNT`] / proSummary?.[`${timeframe}_GET_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_GET_SCR_CNT`] + proSummary?.[`${timeframe}_GET_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_GET_SVY_CNT`] + proSummary?.[`${timeframe}_GET_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Greet', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_GRT_SCR_CNT`] / consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_GRT_SCR_CNT`] / proSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_GRT_SCR_CNT`] + proSummary?.[`${timeframe}_GRT_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] + proSummary?.[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Engage', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_ENG_SCR_CNT`] / consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_ENG_SCR_CNT`] / proSummary?.[`${timeframe}_ENG_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_ENG_SCR_CNT`] + proSummary?.[`${timeframe}_ENG_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_ENG_SVY_CNT`] + proSummary?.[`${timeframe}_ENG_SVY_CNT`])
        }
      ],
      [
        { stringValue: 'Thank', customStyle: bold },
        { pctValue: consumerSummary?.[`${timeframe}_THK_SCR_CNT`] / consumerSummary?.[`${timeframe}_THK_SVY_CNT`] },
        { pctValue: proSummary?.[`${timeframe}_THK_SCR_CNT`] / proSummary?.[`${timeframe}_THK_SVY_CNT`] },
        {
          pctValue:
            (consumerSummary?.[`${timeframe}_THK_SCR_CNT`] + proSummary?.[`${timeframe}_THK_SCR_CNT`]) /
            (consumerSummary?.[`${timeframe}_THK_SVY_CNT`] + proSummary?.[`${timeframe}_THK_SVY_CNT`])
        }
      ]
    ]
  };

  return (
    <Segment fluid>
      <Header textAlign='left' style={{ marginTop: '1rem' }}>
        Overview - GET / LTSA - {capitalize(level)} {locNbr}, {levelName}
        <Header.Subheader>Data as of {data[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>

      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            Timeframe:
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>
        VOC Summary - {capitalize(level)} #{locNbr}
      </Header>
      <DataTable data={summaryObj} />

      <RegionLtsa fpToggle={fpToggle} level={level} locNbr={locNbr} timeframe={timeframe} />
    </Segment>
  );
};
