import React, { useContext, useState } from 'react';
import { useEnhancedQueries } from '../../../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';
import { DataView } from '../../../../../../../components/Common/DataView';
import { Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../../components/Loading/PlaceholderLoading';
import { formatNumberWithCommas } from '../../../../../../../utils/formatter';
import { divide } from '../../../../../../../utils/numbers';

const ASSOCIATE_VIEW = 'Associate View';
const MISSED_SLA = '# Missed SLA';

export const Curbside = () => {
  const [localtimeframe, settimeframe] = useState('WTD');
  const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState(ASSOCIATE_VIEW);
  const {
    isError,
    isLoading,
    results: [{ data: curbSideAssocView = [] }, { data: curbsideMissedSLA = [] }],
  } = useEnhancedQueries(['queryCurbSideAssocView', 'queryCurbSideMissedSLA'], fpToggle);
  const showCustomTimeframe = [MISSED_SLA];

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const getData = () => {
    switch (view) {
      case ASSOCIATE_VIEW:
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Avg Customer Wait Time Mins' },
            { name: '# Orders Missed SLA' },
            { name: '% Orders Missed SLA' }
          ],
          data: curbSideAssocView
            .filter(data => data[`${timeframe}_ORDER_NUMBER`] > 0 && data[`${timeframe}_TOTAL_TIME_ADJ`] > 0)
            .map(data => [
              { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
              {
                numValue: formatNumberWithCommas(+data[`${timeframe}_TOTAL_TIME_ADJ`], 2),
                orderBy: +data[`${timeframe}_TOTAL_TIME_ADJ`]
              },
              { numValue: +data[`${timeframe}_MISS_SLA_NUM`], orderBy: +data[`${timeframe}_MISS_SLA_NUM`] },
              {
                pctValue: divide(+data[`${timeframe}_MISS_SLA_NUM`], +data[`${timeframe}_ORDER_NUMBER`])
              }
            ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case MISSED_SLA:
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Pickup Date' },
            { name: 'Customer Wait Time (mins)' }
          ],
          data: curbsideMissedSLA
            .filter(data => parseFloat(data[`${localtimeframe}_CUSTOM_WAIT_TIME`]) >= 5)
            .map(data => [
              { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
              { numValue: data.ORDER_NUMBER, orderBy: data.ORDER_NUMBER },
              { numValue: String(data.ORDER_DT).split('T')[0] },
              { numValue: parseFloat(data[`${localtimeframe}_CUSTOM_WAIT_TIME`]) }
            ]),
          pagination: true,
          sortable: true,
          sortableDefault: 3,
          sortDirection: 'ascending'
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  const viewOptions = [ASSOCIATE_VIEW, MISSED_SLA].map(view => ({ key: view, text: view, value: view }));
  const timeframeOptions = ['WTD', 'LW', 'LM', 'MTD'].map(timeframe => ({ key: timeframe, text: timeframe, value: timeframe }));

  return (
    <DataView className='my-3'>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        extTimeframe={localtimeframe}
        setExtTimeframe={settimeframe}
        viewOptions={viewOptions}
        timeframeOptions={timeframeOptions}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
};
