import { useState } from 'react';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { getRoundToDecimalString } from '../../../../../../components/Common/TableCellUtils';
import { Grid, Header, Loader, Segment } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { TimeframeDropdown } from '../../../../../../components/Common/TimeframeDropdown';
import { UnitMenu } from '../../../../../../components/Common/UnitMenu';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { Link } from 'react-router-dom';

const CLASSIFICATION_ENUM = { CXM: 0, NRM: 1, Total: 2 };

export const LaborDetailDeptMgr = ({ fpToggle, settimeframe, timeframe, level }) => {
  const [unit, setUnit] = useState('NOM');
  const { deptNbr: deptNbrInt } = useQueryParams();
  const [unitCXM, setUnitCXM] = useState('NOM')
  const [unitNRM, setUnitNRM] = useState('NOM')

  const {
    data: laborDptManagerData = [],
    isError,
    isLoading
  } = useQueryApi('queryLaborDtlByMgnDeptCore', { deptNbrInt, fpToggle, isCore: true });

  if (isError) return <p>Something went wrong</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const isUnitNom = unit === 'NOM';
  const isUnitCXMNom = unitCXM === 'NOM';
  const isUnitNRMNom = unitNRM === 'NOM';

  const isTimeframeWeekToDate = timeframe === 'WTD';
  const isMonday = new Date().getDay() === 1;

  const laborDptManagerDataFiltered = laborDptManagerData.filter(row => row[`${timeframe}_FLG`] === 1);
  const updatedResultMgrOverView = laborDptManagerDataFiltered.reduce((acc, curr) => {
    const classification = curr.CLASSIFICATION === 'Customer Experience Manager' ? 'CXM' : 'NRM';
    const item = acc.find(item => item.CLASSIFICATION === classification);

    if (item) {
      item.ACTUAL_HOURS += curr.ACTUAL_HOURS;
      item.OVERTIME_HOURS += curr.OVERTIME_HOURS;
      item.SCHEDULED_HOURS += curr.SCHEDULED_HOURS;
      item.AvS = item.ACTUAL_HOURS - item.SCHEDULED_HOURS;
      item.AvSPCT = (item.ACTUAL_HOURS - item.SCHEDULED_HOURS) / item.SCHEDULED_HOURS;
      item.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS;
    } else {
      acc.push({
        CLASSIFICATION: classification,
        ACTUAL_HOURS: curr.ACTUAL_HOURS,
        OVERTIME_HOURS: curr.OVERTIME_HOURS,
        SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
        AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
        AvSPCT: (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS) / curr.SCHEDULED_HOURS,
        PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS
      });
    }

    const totalItem = acc.find(item => item.CLASSIFICATION === 'Total');
    if (totalItem) {
      totalItem.ACTUAL_HOURS += curr.ACTUAL_HOURS;
      totalItem.OVERTIME_HOURS += curr.OVERTIME_HOURS;
      totalItem.SCHEDULED_HOURS += curr.SCHEDULED_HOURS;
      totalItem.AvS = totalItem.ACTUAL_HOURS - totalItem.SCHEDULED_HOURS;
      totalItem.AvSPCT = (totalItem.ACTUAL_HOURS - totalItem.SCHEDULED_HOURS) / totalItem.SCHEDULED_HOURS;
      totalItem.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS;
    } else {
      acc.push({
        CLASSIFICATION: 'Total',
        ACTUAL_HOURS: curr.ACTUAL_HOURS,
        OVERTIME_HOURS: curr.OVERTIME_HOURS,
        SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
        AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
        AvSPCT: (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS) / curr.SCHEDULED_HOURS,
        PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS
      });
    }

    return acc;
  }, []);

  const updatedResultDiv = laborDptManagerDataFiltered.reduce((acc, curr) =>{
    const item = acc.find(item => item.DIV_NBR === curr.DIV_NBR && item.classification === curr.CLASSIFICATION);
    if (item) {
      item.ACTUAL_HOURS += curr.ACTUAL_HOURS
      item.OVERTIME_HOURS += curr.OVERTIME_HOURS
      item.SCHEDULED_HOURS += curr.SCHEDULED_HOURS
      item.AvS = item.ACTUAL_HOURS - item.SCHEDULED_HOURS
      item.AvSPCT = (item.ACTUAL_HOURS - item.SCHEDULED_HOURS)/item.SCHEDULED_HOURS
      item.PROJECTED_OVERTIME_HOURS += curr.PROJECTED_OVERTIME_HOURS
    } else {
      acc.push({
        DIV_NBR: curr.DIV_NBR,
        ACTUAL_HOURS: curr.ACTUAL_HOURS,
        OVERTIME_HOURS: curr.OVERTIME_HOURS,
        SCHEDULED_HOURS: curr.SCHEDULED_HOURS,
        AvS: curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS,
        classification: curr.CLASSIFICATION,
        AvSPCT : (curr.ACTUAL_HOURS - curr.SCHEDULED_HOURS)/curr.SCHEDULED_HOURS,
        PROJECTED_OVERTIME_HOURS: curr.PROJECTED_OVERTIME_HOURS,
      });
    }
    return acc;
  },[]);

  const laborDetailCXMFilter = updatedResultDiv.filter((row) => {
    return row['classification'] === "Customer Experience Manager"
  })

  const laborDetailNRMFilter = updatedResultDiv.filter((row) => {
    return row['classification'] === "Night Replenishment Manager"
  })

  const laborDptManagerDataSorted = updatedResultMgrOverView.sort((left, right) =>
    CLASSIFICATION_ENUM[left['CLASSIFICATION']] > CLASSIFICATION_ENUM[right['CLASSIFICATION']] ? 1 : -1
  );

  const dataObj = {
    data: laborDptManagerDataSorted.map(row => {
      const OTHours = isTimeframeWeekToDate && !isMonday ? row.PROJECTED_OVERTIME_HOURS : row.OVERTIME_HOURS;

      return [
        { stringValue: row.CLASSIFICATION },
        { stringValue: getRoundToDecimalString(row.ACTUAL_HOURS || 0) },
        { stringValue: getRoundToDecimalString(OTHours || 0, 2) },
        {
          decimals: !isUnitNom ? 2 : undefined,
          pctValue: !isUnitNom ? row.AvSPCT : undefined,
          stringValue: isUnitNom ? getRoundToDecimalString(row.AvS || 0, 2) : undefined
        }
      ];
    }),
    headers: [
      { name: '' },
      { name: 'Hours' },
      { name: `${isTimeframeWeekToDate && !isMonday ? 'PROJ ' : ''}O/T` },
      { name: `AvS ${isUnitNom ? '' : '%'}` }
    ]
  };

  const dataCXM = {
    data: laborDetailCXMFilter.map(row => {
      const OTHours = isTimeframeWeekToDate && !isMonday ? row.PROJECTED_OVERTIME_HOURS : row.OVERTIME_HOURS;

      return [
        { link: {to: `/division/labor`, params: {divNbr: row.DIV_NBR}}, stringValue: row.DIV_NBR },
        { stringValue: getRoundToDecimalString(row.ACTUAL_HOURS || 0) },
        { stringValue: getRoundToDecimalString(OTHours || 0, 2) },
        {
          decimals: !isUnitCXMNom ? 2 : undefined,
          pctValue: !isUnitCXMNom ? row.AvSPCT : undefined,
          stringValue: isUnitCXMNom ? getRoundToDecimalString(row.AvS || 0, 2) : undefined
        }
      ];
    }),
    headers: [
      { name: '' },
      { name: 'Hours' },
      { name: `${isTimeframeWeekToDate && !isMonday ? 'PROJ ' : ''}O/T` },
      { name: `AvS ${isUnitCXMNom ? '' : '%'}` }
    ],
    sortableDefault: 0
  };

  const dataNRM = {
    data: laborDetailNRMFilter.map(row => {
      const OTHours = isTimeframeWeekToDate && !isMonday ? row.PROJECTED_OVERTIME_HOURS : row.OVERTIME_HOURS;

      return [
        { link: {to: `/division/labor`, params: {divNbr: row.DIV_NBR}}, stringValue: row.DIV_NBR },
        { stringValue: getRoundToDecimalString(row.ACTUAL_HOURS || 0) },
        { stringValue: getRoundToDecimalString(OTHours || 0, 2) },
        {
          decimals: !isUnitNRMNom ? 2 : undefined,
          pctValue: !isUnitNRMNom ? row.AvSPCT : undefined,
          stringValue: isUnitNRMNom ? getRoundToDecimalString(row.AvS || 0, 2) : undefined
        }
      ];
    }),
    headers: [
      { name: '' },
      { name: 'Hours' },
      { name: `${isTimeframeWeekToDate && !isMonday ? 'PROJ ' : ''}O/T` },
      { name: `AvS ${isUnitNRMNom ? '' : '%'}` }
    ],
    sortableDefault: 0
  };
 
  const { DEPT_NM } = laborDptManagerData[0] || {};

  return (
    <Segment>
      <Grid columns={2}>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview`} >Back to Overview</Link>
            {' / '}
            <Link style={{ color: '#EE7125' }} to={`/${level}/labor`} >Labor Overview</Link>
          </Grid.Column>

          <Grid.Column>
            <span>Timeframe:</span>
            <TimeframeDropdown settimeframe={settimeframe} timeframe={timeframe} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ paddingBottom: 0, paddingTop: '1rem' }}>
          <Grid.Column>
            <Grid.Row>
              <span>Units:</span>
            </Grid.Row>

            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header textAlign='center'>Labor Overview - {DEPT_NM} </Header>
      <DataTable data={dataObj} />

      <Grid>
        <Grid.Column width={7}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unitCXM} setUnit={setUnitCXM} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>Labor Details - {DEPT_NM} - CXM </Header>
      <DataTable data={dataCXM} />

      <Grid>
        <Grid.Column width={7}>
          <Grid.Row>Units:</Grid.Row>
          <Grid.Row>
            <UnitMenu unit={unitNRM} setUnit={setUnitNRM} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>Labor Details - {DEPT_NM} -NRM </Header>
      <DataTable data={dataNRM} />

    </Segment>
  );
};
