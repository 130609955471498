// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4';

export const handleFetch = ({ queryKey }) => {
  const [endpoint, { fpToggle }] = queryKey;
  const url = `${process.env.REACT_APP_BACKEND}/api/${fpToggle}/${endpoint}`;

  return fetchParseData(url, endpoint);
};

export const getData = ({ queryKey }) => {
  const [
    endpoint,
    {
      fpToggle,
      strNbr,
      dstNbr,
      divNbr,
      deptNbrInt,
      smryPrgmNm,
      classNbr,
      subClassNbr,
      ldap,
      aisle,
      bay,
      evntId,
      mkdnRsnCd,
      locNbr,
      isCore = false,
      varPresent = false,
      tagID
    }
  ] = queryKey;
  let url = `${process.env.REACT_APP_BACKEND}/api/${fpToggle}/${endpoint}`;

  // Every call that includes a parameter should have a non-empty value
  if (locNbr) {
    if (locNbr === '') {
      return Promise.resolve();
    }
    url += `/${locNbr}`;
  }
  if (strNbr) {
    if (strNbr === '') {
      return Promise.resolve();
    }
    url += `/${strNbr}`;
  }
  if (dstNbr) {
    if (dstNbr === '') {
      return Promise.resolve();
    }
    url += `/${dstNbr}`;
  }
  if (divNbr) {
    if (divNbr === '') {
      return Promise.resolve();
    }
    url += `/${divNbr}`;
  }
  if (deptNbrInt) {
    if (deptNbrInt === '') {
      return Promise.resolve();
    }
    url += `/${deptNbrInt}`;
  }
  if (smryPrgmNm) {
    if (smryPrgmNm === '') {
      return Promise.resolve();
    }
    url += `/${smryPrgmNm}`;
  }
  if (classNbr) {
    if (classNbr === '') {
      return Promise.resolve();
    }
    url += `/${classNbr}`;
  }
  if (subClassNbr) {
    if (subClassNbr === '') {
      return Promise.resolve();
    }
    url += `/${subClassNbr}`;
  }
  if (ldap) {
    if (ldap === '') {
      return Promise.resolve();
    }
    url += `/${ldap}`;
  }
  if (aisle) {
    if (aisle === '') {
      return Promise.resolve();
    }
    url += `/${aisle}`;
  }
  if (bay) {
    if (bay === '') {
      return Promise.resolve();
    }
    url += `/${bay}`;
  }
  if (evntId) {
    if (evntId === '') {
      return Promise.resolve();
    }
    url += `/${evntId}`;
  }
  if (mkdnRsnCd) {
    if (mkdnRsnCd === '') {
      return Promise.resolve();
    }
    url += `/${mkdnRsnCd}`;
  }
  if (tagID) {
    if (tagID === '') {
      return Promise.resolve();
    }
    url += `/${tagID}`;
  }

  if (
    // If the endpoint is not a the alert or changelog, or the store/district/location number is not set, return an empty array
    !varPresent &&
    !isCore &&
    endpoint !== 'queryGetStorePulseAlert' &&
    endpoint !== 'queryGetChangeLog' &&
    (!strNbr || strNbr === '' || strNbr === '0000') &&
    (!dstNbr || dstNbr === '' || dstNbr === '0000') &&
    (!locNbr || locNbr === '' || locNbr === '0000')
  ) {
    return Promise.resolve([]);
  }
  return fetchParseData(url, endpoint, rawData => {
    return rawData.map(data => {
      return JSON.parse(data.JSON_ROWS);
    });
  });
};

const fetchParseData = (url, label, resolveCb) => {
  return new Promise((resolve, reject) => {
    const fetchStart = Date.now();
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 403) {
            window.location.reload();
          }
          return [];
        }
      })
      .then(rawData => {
        const parsedData = resolveCb ? resolveCb(rawData) : rawData;

        const timing = Date.now() - fetchStart;
        ReactGA.event({
          category: 'Timing',
          action: 'load',
          value: timing, // in milliseconds
          label: `${label} fetch time`
        });
        resolve(parsedData);
      })
      .catch(() => reject(new Error('Failed to fetch data')));
  });
};

export const getAdminData = ({ queryKey }) => {
  const [endpoint] = queryKey;
  return new Promise((resolve, reject) => {
    const fetchStart = Date.now();
    fetch(`${process.env.REACT_APP_BACKEND}/api/support/${endpoint}`, { credentials: 'include' })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          reject(new Error(response.status, response.statusText));
        }
      })
      .then(rawData => {
        if (rawData) {
          var parsedData = rawData.map(data => {
            return JSON.parse(data.JSON_ROWS);
          });

          const timing = Date.now() - fetchStart;
          ReactGA.event({
            category: 'Timing',
            action: 'load',
            value: timing, // in milliseconds
            label: `${endpoint} fetch time`
          });
          resolve(parsedData);
        }
      });
  });
};
