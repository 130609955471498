import { useContext } from 'react';
import { useEnhancedQueries } from '../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../context/StoreOrderFulfillmentContext';
import { DataView } from '../../../../../components/Common/DataView';
import { Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../components/Loading/PlaceholderLoading';
import { summaryData } from '../../../utils/Summary';

const Summary = ({unit}) => {
    const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
    const {
      isError,
      isLoading,
      results: [{ data: storeOrderSummary = [] }]
    } = useEnhancedQueries(['storeOrderSummary'], fpToggle)
    const isNOM = unit === 'NOM';
    const data = summaryData(storeOrderSummary, timeframe, isNOM);

    if (isError) return <Header>Something went wrong</Header>;
    if (isLoading) return <PlaceholderLoading />

    return (
      <DataView className='my-4'>
        <DataView.Title className='mb-4' as='h3'>
          Summary
        </DataView.Title>

        <DataView.DataTable data={data} />
      </DataView>
    );
};

export default Summary;