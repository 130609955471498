import { Header, Loader, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useShrinkQueries } from "../hooks/useShrinkQueries";
import { METRIC_CONSTANTS } from "../../../utils/constantsByLevel";
import { capitalizeFirstLetter } from "../../../utils/string";
import { TimeframeDropdownShrink } from "../../Common/TimeframeDropdownShrink";
import { DataView } from "../../Common/DataView";
import { keyedSkuObject } from "../utils/keyedSkuBuilder";
import { useShrinkContext } from "../hooks/useShrinkContext";

export const KeyedSkuPage = () => {

  const { timeframe, fpToggle, settimeframe } = useShrinkContext();
  const queries = ['query/queryShrinkMetricsScoresByLevel', 'query/queryShrinkMetricsBySubLevel']
  const { isError, isLoading, level, locNbr, results } = useShrinkQueries({ fpToggle, queries })
  const CURRENT_LEVEL = capitalizeFirstLetter(METRIC_CONSTANTS[level].levelName)
  const CURRENT_SUB_LEVEL = capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)
  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;
  const [{data: shrinkScoreData}, {data: shrinkData}] = results
  const DATA_AS_OF = shrinkData[0]?.TRNSFM_DT
  const LEVELNAME = `${shrinkData[0][METRIC_CONSTANTS[level].LEVEL_NM]}`
  return (
    <>
    <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/shrink?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`} data-test='b2o-link'>
              Shrink
            </Link> / Keyed SKUs
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header>
        Keyed SKUs - {CURRENT_LEVEL} {locNbr}, {LEVELNAME}
        <Header.Subheader>
          Data As Of {DATA_AS_OF}
        </Header.Subheader>
      </Header>
      <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
      <DataView className='my-4'>
        <DataView.Title>Keyed SKUs By {CURRENT_SUB_LEVEL}</DataView.Title>
        <DataView.DataTable data={keyedSkuObject(timeframe, level, shrinkData, shrinkScoreData)} />
      </DataView>
    </>
  );
}
