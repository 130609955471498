import {  useState } from 'react';
import { Header, Loader, Grid, Segment, Table, Pagination } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getData } from '../../service/DataService';

export const OHMNoLocationTagsByDeptTag = (({ timeframe, settimeframe, level, fpToggle, location, locNbr, ...props}) => {

    const search = new URLSearchParams(location.search);
    const tagID = search.has("tagID") ? search.get("tagID") : 0;
    const [pageNum, setPageNum] = useState(1)
    let rowsPerPage = 20

    const OHMNoLocationTagsByDeptTagQuery = useQuery([`queryOHMPalletTagDTLByTag`, { strNbr: locNbr, tagID, fpToggle }], getData)
    
    if (OHMNoLocationTagsByDeptTagQuery.isLoading) {
        return (<Loader active>Loading</Loader>)
    }

    if (!OHMNoLocationTagsByDeptTagQuery.data || OHMNoLocationTagsByDeptTagQuery.data.length === 0) {
        return (<Header textAlign='center'>Invalid Store Number</Header>)
    }

    const OHMNoLocationTagsData = OHMNoLocationTagsByDeptTagQuery.data

    const deptNbr = OHMNoLocationTagsData[0].DEPT_NBR

    const OHMNoLocationTagsDataFilter = OHMNoLocationTagsData.filter((row) => {
        return row
    })

    let dataPagination = OHMNoLocationTagsDataFilter.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, OHMNoLocationTagsDataFilter.length)))

    const skuSum = OHMNoLocationTagsDataFilter.length > 0 ? OHMNoLocationTagsData.length : '-'
    const DEPT_NBR = OHMNoLocationTagsDataFilter.length > 0 ? OHMNoLocationTagsData[0].DEPT_NBR : '-'
    const INSERT_DT = OHMNoLocationTagsDataFilter.length > 0 ? OHMNoLocationTagsData[0].INSERT_DT : '-'
    const LAST_UPD_BY = OHMNoLocationTagsDataFilter.length > 0 ? OHMNoLocationTagsData[0].LAST_UPD_BY : '-'
 
    return (
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>

                    <Link style={{ color: '#EE7125' }} to={`/${level}/overview?strNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                    {' / '}
                    <Link style={{ color: '#EE7125' }} to={`/osa?strNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                    {' / '}
                    <Link style={{ color: '#EE7125' }} to={`/osa/ohmnoloc?strNbr=${locNbr}`} data-test='b2o-link'>OHM No Loc Tags</Link>
                    {' / '}
                    <Link style={{ color: '#EE7125' }} to={`/osa/ohmnoloc/dept?strNbr=${locNbr}&deptNbr=${deptNbr}`} data-test='b2o-link'>Dept {deptNbr}</Link>
                    {` / ${tagID}`}
                </Grid.Row>
            </Grid.Column>
        </Grid>
        <Header textAlign='center'>Tag - {tagID}</Header>
        <p style={{ textAlign:'center'}}>No Location Tags</p>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>DEPT</Table.HeaderCell>
                    <Table.HeaderCell>SKU</Table.HeaderCell>
                    <Table.HeaderCell>CREATED DATE</Table.HeaderCell>
                    <Table.HeaderCell>CREATOR</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{DEPT_NBR ? DEPT_NBR : 0 }</Table.Cell>
                    <Table.Cell>{skuSum ? skuSum : 0 }</Table.Cell>
                    <Table.Cell>{INSERT_DT ? INSERT_DT : 0 }</Table.Cell>
                    <Table.Cell>{LAST_UPD_BY ? LAST_UPD_BY : 0 }</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
        <Header textAlign='center'>Tag Details</Header>
        <Grid>
        {OHMNoLocationTagsDataFilter.length > rowsPerPage && <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Pagination
              size='mini'
              defaultActivePage={1}
              totalPages={Math.floor((OHMNoLocationTagsDataFilter.length - 1) / rowsPerPage) + 1}
              activePage={pageNum}
              boundaryRange={0}
              siblingRange={1}
              onPageChange={(e, { activePage }) => {
                setPageNum(activePage)
              }}
            />
          </Grid.Column>
        </Grid.Row>}
        </Grid>
        <Grid.Row>&nbsp;</Grid.Row>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>SKU</Table.HeaderCell>
                    <Table.HeaderCell>SKU DES</Table.HeaderCell>
                    <Table.HeaderCell>QTY</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {dataPagination.map((data) => {
                return (
                <Table.Row>
                    <Table.Cell>{data.SKU_NBR}</Table.Cell>
                    <Table.Cell>{data.SKU_DESC}</Table.Cell>
                    <Table.Cell>{data.OH_QTY}</Table.Cell>
                </Table.Row>)})}
            </Table.Body>
        </Table>
        </Segment>
    )
})
