import React from 'react'
import { Header, Loader, Grid, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { DataTable } from '../Common/DataTable'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'


export const LeadsAssocView = (({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0
  const ldap = search.has('ldap') ? search.get('ldap') : 0

  const assocLeadsQuery = useQuery(['querySwmOpsLdAsscDtl', {strNbr, ldap, fpToggle}], getData)

  if (assocLeadsQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let dataFilter = assocLeadsQuery.data.filter((row) => {
    return (
      row &&
      row[timeframe + '_LEAD_COUNT'] !== 0
    )
  })

  const dataObj = {
    sortable: true,
    headers: [
      {name: 'Summary Program Name'},
      {name: 'Leads'}
    ],
    data: dataFilter.map(row => ([
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      {
        numValue: row[timeframe + '_LEAD_COUNT']
      }
    ]) )

  }

  return (
    <>
      <Segment>
        <Grid>
          <Grid.Column width={8} textAlign='justified'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/store/leads?strNbr=${strNbr}`}>Back to Leads</Link>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>
      </Segment>
        <Segment>
          <Header textAlign='center'>Associate {ldap}'s Leads</Header>
          <DataTable data={dataObj} />
        </Segment>
    </>
  )
})