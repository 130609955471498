import useQueryApi from '../../../hooks/useQueryApi';
import { CORE, STORE } from '../../../utils/constantsByLevel';

const { levelName: coreLevelName } = CORE;
const { levelName: storeLevelName } = STORE;

export const useShrinkDamagedMarkdownsQueries = params => {
  const { level, locNbr, fpToggle } = params;
  const { levelName } = level;
  const queryParamsByLevel = levelName === coreLevelName ? { isCore: true } : { locNbr };
  const queryParams = { ...queryParamsByLevel, fpToggle };

  const {
    data: damagedMarkdownsStrData,
    isLoading: damagedMarkdownsStrIsLoading,
    isError: damagedMarkdownsStrIsError
  } = useQueryApi(`queryShrinkDamagedMd`, { ...queryParams, enabled: levelName === storeLevelName });

  const {
    data: damagedMarkdownsData,
    isLoading: damagedMarkdownsIsLoading,
    isError: damagedMarkdownsIsError
  } = useQueryApi(`query/queryShrinkMetricsBySubLevel/${levelName}`, {
    ...queryParams,
    enabled: levelName !== storeLevelName
  });

  const {
    data: scoreDamagedMarkdownsData,
    isLoading: scoreDamagedMarkdownsDataIsLoading,
    isError: scoreDamagedMarkdownsDataIsError
  } = useQueryApi(`query/queryScoreMarkdowns/${levelName}`, {
    ...queryParams,
    enabled: levelName !== storeLevelName
  });

  const isLoading = damagedMarkdownsStrIsLoading || damagedMarkdownsIsLoading || scoreDamagedMarkdownsDataIsLoading;
  const isError = damagedMarkdownsStrIsError || damagedMarkdownsIsError || scoreDamagedMarkdownsDataIsError;

  return {
    damagedMarkdownsData: levelName !== storeLevelName ? damagedMarkdownsData : damagedMarkdownsStrData,
    scoreDamagedMarkdownsData,
    isLoading,
    isError
  };
};
