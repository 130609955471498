import { Link } from 'react-router-dom';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { buildBaseShrinkUrl } from '../utils/url';
import { columnName, METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { TimeframeDropdownShrink } from '../../Common/TimeframeDropdownShrink';
import { RTVCredits } from '../components/RTVCredits';
import { useShrinkQueries } from '../hooks/useShrinkQueries';

export const RTVCreditsPage = ({ fpToggle, timeframe, settimeframe }) => {
  const queries = ['query/queryShrinkMetricsScoresByLevel', 'query/queryShrinkMetricsBySubLevel'];

  const { isError, isLoading, level, locNbr, results } = useShrinkQueries({ fpToggle, queries });

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const [shrinkScoreData, shrinkData] = results;

  const recordsByLevel = shrinkScoreData.data.filter(
    row => row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['FILTER_BY_METRIC'] && row['TIME_FRAME'] === timeframe
  );
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link
              style={{ color: '#EE7125' }}
              to={`/${baseLevelShrinkUrl}?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`}
              data-test='b2o-link'>
              Shrink
            </Link> / RTV Credits
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8} textAlign='left'>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <RTVCredits
        level={level}
        locNbr={locNbr}
        shrinkData={shrinkData.data}
        shrinkScoreData={recordsByLevel}
        levelName={String(recordsByLevel[0][columnName[level]]).toLowerCase()}
        dataAsOf={recordsByLevel[0]?.TRNSFM_DT}
        timeframe={timeframe}
      />
    </>
  );
};
