import { SalesTab } from './SalesTab';
import { Tab } from 'semantic-ui-react';
import { ComparisonByDeparmentTab } from './ComparisonByDeparmentTab';
import { InventoryTab } from './InventoryTab';
import { Overview } from './Overview';
import { requiresCoreAccess } from '../../../utils/timeFrameUtils';
import { useUserContext } from '../../../hooks/useUserContext';
import { useLevelContext } from '../../../hooks/useLevelContext';
import { useOverviewContext } from '../../../hooks/useOverviewContext';

export const OverviewTabs = () => {

  // If we are not a core user, and the timeframe requires core access, and we're at the core level, we should not display Sales data
  const { isCore } = useUserContext();
  const { level } = useLevelContext();
  const { timeframe } = useOverviewContext();
  const enableCoreSalesRestriction = level === 'core'
    && requiresCoreAccess(timeframe)
    && !isCore;

  const tabs = [
    !enableCoreSalesRestriction && {
      menuItem: 'Sales',
      render: () => <SalesTab />
    },
    {
      menuItem: 'Comp',
      render: () => <ComparisonByDeparmentTab />
    },
    {
      menuItem: 'Inventory',
      render: () => <InventoryTab />
    }
  ];

  return (
    <>
      <Overview />
      <Tab panes={tabs} />
    </>
  );
};
