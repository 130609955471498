import { useParams } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useFetchLevelName } from '../../../hooks/useFetchLevelName';
import { useShrinkCullMarkdownsQueries } from '../hooks/useShrinkCullMarkdownsQueries';
import { useShrinkContext } from '../hooks/useShrinkContext';
import { Breadcrumb } from '../../Common/Breadcrumb';
import { TimeframeDropdownShrink } from '../../Common/TimeframeDropdownShrink';
import { DataView } from '../../Common/DataView';
import { CullMarkdowns } from '../components/CullMarkdowns';
import { getBreadcrumbItems, getTitle } from '../utils/shrinkCullMarkdowns';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../utils/level';
import { getDataAsOf } from '../../../utils/getDataAsOf';

export const ShrinkCullMarkdownsPage = () => {
  const { timeframe, settimeframe, fpToggle } = useShrinkContext();
  const { level: levelName } = useParams();
  const level = METRIC_CONSTANTS[levelName];
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const locNbrFormated = formatLevelNumber(locNbr);
  const { currentLevelName, isLoadingName: isLoadingCurrentLevelName } = useFetchLevelName({
    level: levelName,
    locNbr: locNbrFormated,
    fpToggle
  });
  const {
    cullMarkdownsData,
    scoreCullMarkdownsData,
    isLoading: queriesIsLoading,
    isError
  } = useShrinkCullMarkdownsQueries({
    level,
    locNbr,
    fpToggle
  });

  const isLoading = isLoadingCurrentLevelName || queriesIsLoading;

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header textAlign='center'>Something went wrong.</Header>;

  const breadcrumbItem = getBreadcrumbItems({ level, locNbr });
  const title = getTitle({ level, locNbr, currentLevelName });
  const dataAsOf = getDataAsOf({ data: cullMarkdownsData });
  const to = '/shrink/cullmd';

  return (
    <>
      <Breadcrumb items={breadcrumbItem} />

      <DataView className='my-4'>
        <DataView.Controls>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>

        <DataView.Title>
          {title}
          <Header.Subheader>Data as Of {dataAsOf}</Header.Subheader>
        </DataView.Title>

        <CullMarkdowns
          level={level}
          timeframe={timeframe}
          to={to}
          cullMarkdownsData={cullMarkdownsData}
          scoreCullMarkdownsData={scoreCullMarkdownsData}
        />
      </DataView>
    </>
  );
};
