import { Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useFetchLevelName } from '../../hooks/useFetchLevelName';
import { formatLevelNumber } from '../../utils/level';
import { capitalizeFirstLetter } from '../../utils/string';
import { getDataAsOf } from '../../utils/getDataAsOf';

// TODO: Get fpToggle from GlobalContext
export const Title = ({ text = '', data, fpToggle }) => {
  const { level: levelName } = useParams();
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();
  const locNbr = strNbr || dstNbr || rgnNbr || divNbr;
  const locNbrFormated = formatLevelNumber(locNbr);
  const { currentLevelName, isLoadingName: isLoadingCurrentLevelName } = useFetchLevelName({
    level: levelName,
    locNbr: locNbrFormated,
    fpToggle
  });

  if (isLoadingCurrentLevelName) return null;

  const baseTitle = `${text} - ${capitalizeFirstLetter(levelName)}`;
  const titleSufix = locNbr ? `${locNbrFormated}, ${currentLevelName}` : '';
  const title = `${baseTitle} ${titleSufix}`;
  const dataAsOf = getDataAsOf({ data });

  return (
    <Header>
      {title}
      {dataAsOf && <Header.Subheader>Data as Of {dataAsOf}</Header.Subheader>}
    </Header>
  );
};
