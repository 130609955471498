import { createContext, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { OverviewMenu } from '../components/Overviews/Region/OverviewMenu';
import { useQueryParams } from '../hooks/useQueryParams';

export const OverviewContext = createContext({
  metric: 'sales',
  setMetric: () => {}
});

const OverviewContextProvider = ({ fpToggle, levelName, metric, setMetric, userEmplCtgryNm, ...props }) => {
  const { rgnNbr, divNbr, dstNbr } = useQueryParams();
  const queryParam = rgnNbr || divNbr || dstNbr;
  const { level } = useParams();

  const value = useMemo(
    () => ({
      ...props,
      metric,
      setMetric,
      rgnNbr,
      dstNbr,
      fpToggle,
      level,
      divNbr,
      levelName,
      queryParam,
      userEmplCtgryNm
    }),
    [props, metric, setMetric, rgnNbr, fpToggle, level, divNbr, dstNbr, levelName, queryParam, userEmplCtgryNm]
  );

  return (
    <OverviewContext.Provider value={value}>
      <OverviewMenu />
    </OverviewContext.Provider>
  );
};

export default OverviewContextProvider;
