export const fetchData = async (api, method, rest = {}) => {
  const response = await fetch(api, {
    credentials: 'include',
    method,
    ...rest
  });
  if (!response.ok) {
    throw new Error('Something went wrong');
  }

  return response.json();
}
