export const addParamsToUrl = (params, currentPath = window.location.href) => {
  const url = new URL(currentPath);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
}

export const getUrlParam = (param, currentPath = window.location.href) => {
  const url = new URL(currentPath);
  return url.searchParams.get(param);
}
