import React from 'react'
import { Header, Grid, Tab } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { getPrevAndNextElement } from '../../../../../utils/array'
import { TimeframeMenu } from '../../../../../components/Menus/TimeFrameMenu'
import { useCoreSalesDeptContext } from '../../../hooks/useCoreSalesDeptContext'
import { timeframeCode } from '../../../../../components/Common/timeframeCode'
import { CoreTopBtmSubClasses } from '../components/DeptSales/CoreTopBtmSubClasses'
import { AllClassesCoreTab } from '../components/DeptSales/AllClassesCoreTab'
import { CoreTopBtmClasses } from '../components/DeptSales/CoreTopBtmClasses'
import { CoreTopBtmSkus } from '../components/DeptSales/CoreTopBtmSkus'

export const CoreDeptDrillDown = () => {

  const { timeframe, settimeframe, salesDeptData, deptNbrInt, salesByDept, topBtmSalesData } = useCoreSalesDeptContext()

  const deptNbrArr = salesDeptData.map((data) => data['DEPT_NBR'].toString())

  const deptNmArr = salesDeptData.map((data) => data['MER_DEPT_NM'])

  let deptNm = deptNmArr[deptNbrArr.indexOf(deptNbrInt)]
  const currentIndex = salesDeptData.findIndex(dept => dept['DEPT_NBR'] === parseInt(deptNbrInt))
  const [previousDept, nextDept] = getPrevAndNextElement(salesDeptData, currentIndex)

  const dataAsOf = salesDeptData[0]?.TRNSFM_DT || salesByDept[0]?.TRNSFM_DT // If the TRNSFM_DT field is not available in salesDeptData, use the other one from salesByDept

  // Filter all classes by timeframe
  const allClasses = salesByDept.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe])

  // // Filter top-btm classes by timeframe
  const topBtmClasses = topBtmSalesData.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] && row['PROD_HIER'] === 'CLASSES')

  // Filter top-btm subclasses by timeframe
  const topBtmSubClasses = topBtmSalesData.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] && row['PROD_HIER'] === 'SUB_CLASSES')

  // Filter top-btm classes by timeframe
  const topBtmSkus = topBtmSalesData.filter((row) => row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe] && row['PROD_HIER'] === 'SKUS')
  
  const tabs = [
    {
      menuItem: 'All Classes',
      render: () => <AllClassesCoreTab coreClasses={allClasses} />
    },
    {
      menuItem: 'Top/Btm Classes',
      render: () => <CoreTopBtmClasses topBtmClasses={topBtmClasses} />
    },
    {
      menuItem: 'Top/Btm Subclasses',
      render: () => <CoreTopBtmSubClasses topBtmSubClasses={topBtmSubClasses} />
    },
    {
      menuItem: 'Top/Btm SKUs',
      render: () => <CoreTopBtmSkus topBtmSkus={topBtmSkus} />
    }
  ]

  return (
    <>
      <Grid>
        <Grid.Column width={16} textAlign='left'>
          <Grid.Row>
            <a
              href={`/core/overview`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >Overview</a>
            {' / '}
            {deptNm}
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>
        {deptNm}
        <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>
      </Header>
      {/* Buttons for adjacent departments */}
      <Grid>
        <Grid.Row fluid>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/core/sales/dept?deptNbr=${previousDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >
              {previousDept?.MER_DEPT_NM}
            </Link>
          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/core/sales/dept?deptNbr=${nextDept?.DEPT_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
            >
              {nextDept?.MER_DEPT_NM}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <TimeframeMenu
        timeframe={timeframe}
        settimeframe={settimeframe}
        enableCoreValidation={true}
      />
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Tab menu={{ attached: false, pointing: false, secondary: false, borderless: false, compact: true, size: 'small', widths: 4 }} panes={tabs} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </>
  )
}
