import { useState } from 'react';
import { useQueryParams } from '../../../../../../../../hooks/useQueryParams';
import useQueryApi from '../../../../../../../../hooks/useQueryApi';
import { Grid, Loader, Dropdown, Tab, Header } from 'semantic-ui-react';
import { timeframeCode } from '../../../../../../../../components/Common/timeframeCode';
import { TabMenu } from '../../../../../../../../components/Common/TabMenu';
import { CLASSES_METRIC_OPTIONS } from '../../../../../../utils/sales';
import { SubClassTable } from './SubClassTable';
import { SkuTable } from './SkuTable';
import { Breadcrumb } from '../../../../../../../../components/Common/Breadcrumb';
import { TimeframeDropdown } from '../../../../../../../../components/Common/TimeframeDropdown';

export const CoreDrillDown = ({ fpToggle, timeframe, settimeframe }) => {
  const { classNbr = 0 } = useQueryParams();
  const [metric, setMetric] = useState('SLS');
  const defaultParams = { fpToggle, isCore: true, classNbr: classNbr };
  const {
    data: SubClassesDataFromSales = [],
    isError: isErrorSubClassesDataFromSales,
    isLoading: isLoadingSubClassesDataFromSales
  } = useQueryApi('querySlsSclsByCoreCls', defaultParams);

  const {
    data: SKUDataFromSales = [],
    isError: isErrorSKUDataFromSales,
    isLoading: isLoadingSKUDataFromSales
  } = useQueryApi('querySlsSkuByCoreCls', defaultParams);

  if (isErrorSubClassesDataFromSales || isErrorSKUDataFromSales) return <p>Something went wrong.</p>;
  if (isLoadingSubClassesDataFromSales || isLoadingSKUDataFromSales) return <Loader active>Loading...</Loader>;

  const allSubclasses = SubClassesDataFromSales.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]);
  const allSKUs = SKUDataFromSales.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]);

  const getDeptName = (allSubclasses) => {
    if (allSubclasses.length > 0) {
      return {
        className: allSubclasses[0]['MER_CLASS_NM'],
        deptName: allSubclasses[0]['MER_DEPT_NM'],
        deptNbr: allSubclasses[0]['DEPT_NBR']
      };
    }
    return {};
  };
  const deptDetails = getDeptName(allSubclasses);
  const breadcrumbItems = [
    {
      text: 'Overview',
      to: '/core/overview'
    },
    {
      text: `${deptDetails.deptName}`,
      to: `/core/sales/dept?deptNbr=${deptDetails.deptNbr}`
    },
    {
      text: `${deptDetails.className}`
    }
  ];

  const dataAsOf = allSubclasses[0]?.TRNSFM_DT;
  const panes = [
    {
      menuItem: 'Subclasses',
      render: () => <SubClassTable subClassesList={allSubclasses} metric={metric} />
    },
    {
      menuItem: 'SKUs',
      render: () => <SkuTable metric={metric} skuList={allSKUs}/>
    }
  ];

  return (
    <>
      <Tab.Pane className='mt-3'>
        <Breadcrumb items={breadcrumbItems} />
        <Header>
          Overview - Sales - Subclasses
          <Header.Subheader>Data as of {dataAsOf} </Header.Subheader>
        </Header>
        <Grid>
          <Grid.Column width={2}>
            <Grid.Row>Metrics:</Grid.Row>
            <Grid.Row>
              <Dropdown
                className='mx-3 mx-0'
                compact
                defaultValue='SLS'
                options={CLASSES_METRIC_OPTIONS}
                onChange={(_, { value }) => setMetric(value)}
                selection
                scrolling
                size='tiny'
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={2}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} enableCoreValidation={true} />
            </Grid.Row>
          </Grid.Column>
        </Grid>
        <TabMenu className='mt-3' color='orange' panes={panes} />
      </Tab.Pane>
    </>
  );
};
