import { useContext } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { DeliveryTabs } from './DeliveryTabs';
import { capitalizeFirstLetter } from '../../../../../../utils/string';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { divide } from '../../../../../../utils/numbers';
import { DataView } from '../../../../../../components/Common/DataView';
import { Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';

export const CompleteDelivery = () => {
    const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
    const {
      isError,
      isLoading,
      results: [{ data: storeCompleteDlv = [] }]
    } = useEnhancedQueries(['queryCompleteDlv'], fpToggle)

    if (isError) return <Header>Something went wrong</Header>;
    if (isLoading) return <PlaceholderLoading />

    const dataDlv = storeCompleteDlv.map(data => {
      const { fulfilQty, totalQty, pushedNum, pushedDen, truckChanges, canceledQty, notInStockQty } = extractData(data, timeframe)

   
      return [
        { stringValue: capitalizeFirstLetter(data.TRUCK_TYPE) },
        { pctValue: totalQty === 0 ? '-' : divide(fulfilQty, totalQty)},
        { pctValue: pushedDen === 0 ? '-' : divide(pushedNum, pushedDen )},
        { numValue: truckChanges },
        { numValue: canceledQty },
        { numValue: notInStockQty}
    ]})

    const total = storeCompleteDlv.reduce((acc, data) => {
      const { fulfilQty, totalQty, pushedNum, pushedDen, truckChanges, canceledQty, notInStockQty } = extractData(data, timeframe)

      acc.fulfilQty += fulfilQty;
      acc.totalQty += totalQty;
      acc.pushedNum += pushedNum;
      acc.pushedDen += pushedDen;
      acc.truckChanges += truckChanges;
      acc.canceledQty += canceledQty;
      acc.notInStockQty += notInStockQty

      return acc
    }, { fulfilQty: 0, totalQty: 0, pushedNum: 0, pushedDen: 0, truckChanges: 0, canceledQty: 0, notInStockQty: 0 })

    const dataObj = {
      headers: [
        { name: 'Truck Type' },
        { name: 'Fulfillment %' },
        { name: 'Push %' },
        { name: 'Truck Changes' },
        { name: 'Unfulfilled Units' },
        { name: 'Not In Stock Units' }
      ],
      data: dataDlv,
      footer: [[
        { stringValue: 'Total Orders' },
        { pctValue: divide(total.fulfilQty, total.totalQty) },
        { pctValue: divide(total.pushedNum, total.pushedDen) },
        { numValue: total.truckChanges },
        { numValue: total.canceledQty },
        { numValue: total.notInStockQty}

      ]]
    };

    return (
      <DataView className='my-4'>
        <DataView.Title as='h3'>Delivery</DataView.Title>
        <DataView.DataTable data={dataObj} />
        <DeliveryTabs />
      </DataView>
    );
};

const extractData = (data, timeframe) => ({
  fulfilQty: data[timeframe + '_FULFIL_QTY'],
  totalQty: data[timeframe + '_TOTAL_QTY'],
  pushedNum: data[timeframe + '_PP_MANIPULATED_NUM'],
  pushedDen: data[timeframe + '_PUSH_MANIPULATIONS_DEN'],
  truckChanges: data[timeframe + '_TRUCK_CHANGES'],
  canceledQty: data[timeframe + '_CANCELED_QTY'],
  notInStockQty: data[timeframe + '_NIS']
})
