import { useShrinkContext } from '../hooks/useShrinkContext';
import { useShrinkQueries } from '../hooks/useShrinkQueries';
import { Header, Loader, Segment } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { TimeframeDropdownShrink } from '../../../components/Common/TimeframeDropdownShrink';
import { AutoKeyRecsTable } from '../components/AutoKeyRecsTable';
import { UnmatchedXfersTable } from '../components/UnmatchedXfersTable';
import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { Breadcrumb } from '../../Common/Breadcrumb';
import { DataView } from '../../Common/DataView';
import { buildBaseShrinkUrl } from '../utils/url';
import { columnName } from '../../../utils/constantsByLevel';

export const AccuracyAndReceivingMetricPage = () => {
  const { timeframe, settimeframe, fpToggle } = useShrinkContext();
  const location = useLocation();
  const path = location.pathname;
  const {
    level,
    locNbr,
    isError,
    isLoading,
    results: [queryShrinkOHAutoRejectByLevelPlusOne]
  } = useShrinkQueries({
    fpToggle,
    queries: ['query/queryShrinkOHAutoRejectByLevelPlusOne']
  });

  if (isError) return <p>Something went wrong</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const hasAutokr = path.includes('autokr');
  const accRecTitle = hasAutokr ? 'Auto Key-recs' : 'Unmatched Xfers';
  const levelName = queryShrinkOHAutoRejectByLevelPlusOne?.data[0][columnName[level]];
  const title = `${accRecTitle} - ${level} ${locNbr}, ${levelName.toLowerCase()}`;
  const dataAsOf = queryShrinkOHAutoRejectByLevelPlusOne.data[0]?.TRNSFM_DT;
  const data = queryShrinkOHAutoRejectByLevelPlusOne.data || [];
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  const breadcrumbItems = [
    { to: `/${baseLevelShrinkUrl}?${METRIC_CONSTANTS[level].urlParam}=${locNbr}`, text: 'Shrink' },
    { text: accRecTitle }
  ];

  return (
    <Segment className='mb-4'>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='my-4'>
        <section style={{ textAlign: 'center' }}>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </section>

        <DataView.Title className='mt-2'>
          {title}
          {dataAsOf && <Header.Subheader>Data as of {dataAsOf}</Header.Subheader>}
        </DataView.Title>
      </DataView>

      {hasAutokr && <AutoKeyRecsTable autoKeyRecData={data} level={level} timeframe={timeframe} />}
      {!hasAutokr && <UnmatchedXfersTable unmatchedXfersData={data} level={level} timeframe={timeframe} />}
    </Segment>
  );
};
