import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Navigate } from 'react-router-dom';


import {
  Header,
  Grid,
  Modal,
  Segment,
  Button
} from 'semantic-ui-react'



// export const Scanner = withRouter(({ fpToggle, location }) => {
export const ScannerV2 = ({ storeNumber, setStoreNumber, storeNumberField }) => {

  const [open, setOpen] = useState(false)
  const [data, setData] = useState('')
  // const search = new URLSearchParams(location.search);
  // const strNbr = search.has('strNbr') ? search.get('strNbr') : 0;
  const [aisle, setAisle] = useState('')
  const [bay, setBay] = useState('')
  const [isBayCode, setIsBayCode] = useState(false)
  const [currentScan, setCurrentScan] = useState(null)

  const fnRegister = useCallback(()=> async data => {
    if (!data || !data.barcode || currentScan === data.barcode) {
      return;
    }
    const barcode = fnGetBarcode(data);

    if (barcode) {
      setCurrentScan(barcode)
      setData(barcode)
      setOpen(true)
      let BAYLENGTH = 13;

      if (barcode.length === BAYLENGTH) {
        setAisle(barcode.slice(barcode.length - 6).split('-')[0])
        setBay(barcode.slice(barcode.length - 6).split('-')[1])
        setStoreNumber(storeNumberField.padStart(4, '0'))
        setIsBayCode(true)
      } else {
        setIsBayCode(false)
      }
    }
  }, [currentScan, storeNumberField, setStoreNumber]);

  const lastData = useRef(0);
  let delay = 500;
  const awaitStreamComplete = useCallback(async data => {
    if (lastData.current >= Date.now() - delay) {
      return;
    }
    lastData.current = Date.now();

    await fnRegister({ barcode: data });
  }, [lastData, delay, fnRegister]);

  const handleMessage = useCallback((data, type) => {
    if (type === "SCANNER_DATA") {
      awaitStreamComplete(data);
    }
  }, [awaitStreamComplete]);

  const disableScanner = useCallback(async () => {
    return new Promise((resolve, reject) => {
      try {
        const response = window.MscJS?.disableScanner();
        const data = JSON.parse(response);

        if (data.status === "success") {
          resolve(data);
        } else {
          reject(data.error);
        }
      } catch {
        reject("Error line 355");
      }
    });
  }, []);

  const enableScanner = useCallback(async () => {
    return new Promise((resolve, reject) => {
      try {
        const response = window.MscJS?.enableScanner();
        const data = JSON.parse(response);

        if (data.status === "success") {
          resolve(data);
        } else {
          reject(data.error);
        }
      } catch {
        reject("Error line 372");
      }
    });
  }, []);



  useEffect(() => {
    if (window.MscJS !== undefined && window.MscJS !== null) {
      window.removeEventListener(
        "onMscDataReceived",
        event => {
          handleMessage(event.detail.data, event.detail.type);
        },
        true
      );
      window.addEventListener(
        "onMscDataReceived",
        event => {
          handleMessage(event.detail.data, event.detail.type);
        },
        true
      );
      enableScanner()
        .then(data => {
          handleMessage(data.message, "onRegisterScanClick");
        })
        .catch(() => {
            // TODO: We are having trouble starting the scanner on this device"
          return;
        });
      return () => {
        window.removeEventListener(
          "onMscDataReceived",
          event => {
            handleMessage(event.detail.data, event.detail.type);
          },
          true
        );
        disableScanner();
      };
    }
  }, [handleMessage, enableScanner, disableScanner]);

  return (
    <>
      {isBayCode ? <Navigate to={`/store/aisle/bay?strNbr=${storeNumber}&aisle=${aisle}&bay=${aisle}-${bay}`} /> :
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Segment color='orange'>
            <Grid>
              <Grid.Column width={16} textAlign='center'>
                <Grid.Row>
                  <Header style={{ color: 'red' }}>Invalid Barcode</Header>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  {data}
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>Please scan an aisle-bay barcode.</Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => { setOpen(false) }}
                    style={{ color: 'white', backgroundColor: '#EE7125' }}
                    content='OK'
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>
      }
    </>
  )
}

/**
 * Prefix for valid in store sku's
 */


/**
 * Returns the scanned data's barcode value, truncates the first 4 charactes if they match a valid SKU
 * @method      fnGetBarcode    
 * @property    {string}        barcodePrefix     first 4 characters of the scanned value
 * @property    {object}        barcode           scanned value
 * @author      Anthony Oliver & Kalai Mani
 * @example
 * let data1 = {
 *      barcode: '9807111111'
 * }
 * fnGetBarcode(data1); // returns 111111
 * 
 * let data2 = {
 *      barcode: '123456798'
 * }
 * fnGetBarcode(data2); //returns 123456798
 * 
 * 
 *let data3 = {
 *     barcode :'9089000000067-010'
 * 
 *  }
 * fnGetBarcode(data3); //returns 000000067-010
 * 
 * 
 */
const fnGetBarcode = (data) => {
  let SKU_PREFIX = '9807';
  let BAY_PREFIX = '9809';
  let BAY_SIZE = 17;

  if (!data.barcode) {
    return console.error('No barcode value is available.', data);
  }

  let barcode = data.barcode
    , barcodePrefix = barcode.toString().substring(0, 4);

  if (barcodePrefix === SKU_PREFIX) {
    return data.barcode.toString().substring(4);
  } else if (barcodePrefix === BAY_PREFIX && barcode.length === BAY_SIZE) {
    return data.barcode.toString().substring(4);
  }

  return barcode;
}
