const LTSA_PROPS_BY_METRIC = {
  ShelfAvail: ['_SHLF_AVL_CNT', '_SHLF_SVY_CNT'],
  GET: ['_GET_SCR_CNT', '_GET_SVY_CNT'],
  Greet: ['_GRT_SCR_CNT', '_ENG_SVY_CNT'],
  Engage: ['_ENG_SCR_CNT', '_ENG_SVY_CNT'],
  Thank: ['_THK_SCR_CNT', '_THK_SVY_CNT'],
  default: ['_LTSA_SCR_CNT', '_LTSA_SVY_CNT']
};

export const getLtsaPropsByMetric = metric => {
  return LTSA_PROPS_BY_METRIC[metric] || LTSA_PROPS_BY_METRIC.default;
};

export const ltsaMenuOptions = [
  { key: 'Ltsa', text: 'LTSA Score', value: 'Ltsa' },
  { key: 'ShelfAvail', text: 'Shelf Availability', value: 'ShelfAvail' },
  { key: 'GET', text: 'GET', value: 'GET' },
  { key: 'Greet', text: 'Greet', value: 'Greet' },
  { key: 'Engage', text: 'Engage', value: 'Engage' },
  { key: 'Thank', text: 'Thank', value: 'Thank' }
];
