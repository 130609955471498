import { isMonday } from "./date";

export const BASE_OPTIONS = [
  { key: 'WTD', text: 'WTD', value: 'WTD' },
  { key: 'LW', text: 'LW', value: 'LW' },
  { key: 'LM', text: 'LM', value: 'LM' },
  { key: 'MTD', text: 'MTD', value: 'MTD' },
  { key: 'QTD', text: 'QTD', value: 'QTD', needsCoreValidation: true },
  { key: 'HTD', text: 'HTD', value: 'HTD', needsCoreValidation: true },
  { key: 'YTD', text: 'YTD', value: 'YTD', needsCoreValidation: true },
  { key: 'L12', text: 'L12', value: 'L12', needsCoreValidation: true },
]

export const MONDAY_OPTIONS = [
  { key: 'LW', text: 'LW', value: 'WTD' },
  { key: 'LLW', text: 'LLW', value: 'LW' },
  { key: 'LM', text: 'LM', value: 'LM' },
  { key: 'MTD', text: 'MTD', value: 'MTD' },
  { key: 'QTD', text: 'QTD', value: 'QTD', needsCoreValidation: true },
  { key: 'HTD', text: 'HTD', value: 'HTD', needsCoreValidation: true },
  { key: 'YTD', text: 'YTD', value: 'YTD', needsCoreValidation: true },
  { key: 'L12', text: 'L12', value: 'L12', needsCoreValidation: true },
]

export const getParamByLevel = level => {
  const paramMap = {
    store: 'strNbr',
    district: 'dstNbr',
    region: 'rgnNbr',
    division: 'divNbr',
    default: 'strNbr'
  };
  return paramMap[level] || paramMap.default;
};

export const getLastTimeframe = timeframe => {
  const lastTimeframeMap = {
    WTD: 'LW',
    LW: null,
    LM: null,
    MTD: 'LM',
    QTD: 'LQ',
    HTD: 'LH',
    YTD: 'YTD',
    L12: 'L12'
  };
  return lastTimeframeMap[timeframe];
};

export const requiresCoreAccess = timeframe => {
  const options = isMonday ? MONDAY_OPTIONS : BASE_OPTIONS;
  const coreValidationMap = options.reduce((acc, option) => {
    acc[option.value] = option.needsCoreValidation;
    return acc;
  }, {});
  return coreValidationMap[timeframe];
};

export const getTimeframeOptions = (limit, isCore = false, level = 'store', enableCoreValidation = false) => {
  const options = isMonday ? MONDAY_OPTIONS : BASE_OPTIONS;
  const optionsToShow = options.filter(option => {
      if (enableCoreValidation && level === 'core') {
          if (option.needsCoreValidation) {
              return isCore;
          }
      }
      return true;
  });

  return optionsToShow.slice(0, limit || options.length);
}

export const iconsByUnitDollar = {
  NOM: '($)',
  PCT: '(%)'
};

export const iconsByUnitHashtag = {
  NOM: '(#)',
  PCT: '(%)'
};
