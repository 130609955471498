import React, { useState } from 'react';
import { Header, Loader, Grid, Segment, Table } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getData } from '../../service/DataService';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { getPercentString } from '../Common/TableCellUtils'
import { OSA_OHM_NO_LOC_TAGS_GOAL } from '../../constants/goals';

export const OHMNoLocationTagsDst = (({ timeframe, settimeframe, level, fpToggle, location, locNbr, ...props}) => { 

    const search = new URLSearchParams(location.search)
    const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0
    const [sortColumn, setSortColumn] = useState('STR_NBR')
    const [sortDirection, setSortDirection] = useState('ascending')

    const OHMNoLocationTagsDstQuery = useQuery([`query/osaOHMNoLocTagsPlusOne/${level}`, { dstNbr  , fpToggle }], getData)

    if (OHMNoLocationTagsDstQuery.isLoading) {
        return (<Loader active>Loading</Loader>)
    }

    if (!OHMNoLocationTagsDstQuery.data || OHMNoLocationTagsDstQuery.data.length === 0) {
        return (<Header textAlign='center'>Invalid Store Number</Header>)
    }

    const OHMNoLocationTagsDstData = OHMNoLocationTagsDstQuery.data

    let overviewDstFilter = OHMNoLocationTagsDstData.filter((row) => {
        return row['METRIC_LEVEL'] === 'STR' 
    })

    const lastTimeframeMap = {
        'WTD': 'LW',
        'LW': null, // We don't have comparisons for last week or month, so they'll be null
        'LM': null,
        'MTD': 'LM',
        'QTD': 'LQ',
        'HTD': 'LH',
        'YTD': 'YTD',
        'L12': 'L12',
      }
    
      const ltf = lastTimeframeMap[timeframe]

    let updatedResultDst = overviewDstFilter.reduce((acc, curr) =>{
        let item = acc.find(item => item.STR_NBR === curr.STR_NBR);
        if (item) {
          item.STR_NBR = curr.STR_NBR
          item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT']
          item[timeframe + '_TOTAL_PALLET_COUNT'] += curr[timeframe + '_TOTAL_PALLET_COUNT']
          item.ACTUAL = item[timeframe + '_NO_LOC_PALLET_COUNT'] / item[timeframe + '_TOTAL_PALLET_COUNT']
          item[ltf + '_LY_NO_LOC_PALLET_COUNT'] += curr[ltf + '_LY_NO_LOC_PALLET_COUNT']
          item[ltf + '_LY_TOTAL_PALLET_COUNT'] += curr[ltf + '_LY_TOTAL_PALLET_COUNT']
          item.BPS = item[ltf + '_LY_TOTAL_PALLET_COUNT'] !== 0 ? item[ltf + '_LY_NO_LOC_PALLET_COUNT'] / item[ltf + '_LY_TOTAL_PALLET_COUNT'] : '-'
        } else {
          acc.push({
            STR_NBR: curr.STR_NBR,
            [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
            [timeframe + '_TOTAL_PALLET_COUNT']: curr[timeframe + '_TOTAL_PALLET_COUNT'],
            ACTUAL: curr[timeframe + '_NO_LOC_PALLET_COUNT'] / curr[timeframe + '_TOTAL_PALLET_COUNT'],
            [ltf + '_LY_NO_LOC_PALLET_COUNT'] : curr[ltf + '_LY_NO_LOC_PALLET_COUNT'],
            [ltf + '_LY_TOTAL_PALLET_COUNT'] : curr[ltf + '_LY_TOTAL_PALLET_COUNT'],
            BPS: curr[ltf + '_LY_TOTAL_PALLET_COUNT'] !== 0 ? curr[ltf + '_LY_NO_LOC_PALLET_COUNT'] / curr[ltf + '_LY_TOTAL_PALLET_COUNT'] : '-'
          });
        }  
        return acc
    },[]);

    let overviewStrSorted = updatedResultDst.sort((left, right) => {
        let result = left[sortColumn] - right[sortColumn]
        if (sortColumn === 'STR_NBR') {
          result = left.STR_NBR > right.STR_NBR ? 1 : -1
        } 
        if (sortDirection === 'descending') {
          result *= -1
        }
        return result
      })

    return (
        <>
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>
                    <Link style={{ color: '#EE7125' }} to={`/${level}/overview?dstNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                    {' / '}
                    <Link style={{ color: '#EE7125' }} to={`/osa?dstNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                    {` / OHM No Loc Tags`}
                </Grid.Row>
            </Grid.Column>
        </Grid>
        <Grid>
            <Grid.Column width={8} />
            <Grid.Column width={8}>
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Column>
        </Grid>

        <Header textAlign='center'>OHM No Loc Tags %</Header>

        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                    sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'STR_NBR') {
                            setSortColumn('STR_NBR')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                        }}}>Store</Table.HeaderCell>
                    <Table.HeaderCell>Actual</Table.HeaderCell>
                    <Table.HeaderCell>Goal</Table.HeaderCell>
                    <Table.HeaderCell>v{ltf} bps</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                    {overviewStrSorted.map((data) => {  
                    return(   
                     <Table.Row>
                        <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/ohmnoloc?strNbr=${data['STR_NBR']}`}>{data.STR_NBR}</Link></Table.Cell>
                        <Table.Cell style={{ color: data.ACTUAL > OSA_OHM_NO_LOC_TAGS_GOAL ? 'red' : 'green'}}>{getPercentString(data.ACTUAL)}</Table.Cell>
                        <Table.Cell>{getPercentString(OSA_OHM_NO_LOC_TAGS_GOAL)}</Table.Cell>
                        <Table.Cell>{data.BPS === '-' || isNaN(data.BPS) ? '-' : ((data.ACTUAL - data.BPS)*10000).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>
                     </Table.Row>)})}
            </Table.Body>
        </Table>
    </Segment>
    </>)
})