import React from 'react';
import { Header, Loader } from 'semantic-ui-react';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { clearanceNoHomesCoreData } from '../utils/clearanceNoHomesDataBuilder';
import { ClearanceNoHomesCoreTable } from './ClearanceNoHomesCoreTable';
import { FocusMetricsTimeFrame } from './FocusMetricsTimeFrame';

export const ClearanceNoHomesCorePage = () => {
  const { timeframe, settimeframe, fpToggle } = useShrinkCoreContext();
  const level = 'core';
  const title = 'Clearance No Homes';
  const defaultParams = {
    fpToggle,
    isCore: true
  };

  const {
    data: shrinkClearanceNoHomesData = [],
    isLoading: shrinkClearanceNoHomesDataLoading,
    error: shrinkClearanceNoHomesError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', defaultParams);
  const {
    data: shrinkClearanceNoHomesScoreData = [],
    isLoading: shrinkClearanceNoHomesScoreDataLoading,
    error: shrinkClearanceNoHomesScoreDataError
  } = useQueryApi('queryShrinkMetricsScoresCore', defaultParams);

  if (shrinkClearanceNoHomesDataLoading || shrinkClearanceNoHomesScoreDataLoading) return <Loader active>Loading...</Loader>;
  if (shrinkClearanceNoHomesError || shrinkClearanceNoHomesScoreDataError)
    return <Header textAlign='center'>Something went wrong.</Header>;

  const recordsByLevel = shrinkClearanceNoHomesScoreData.filter(row => row['TIME_FRAME'] === timeframe);

  return (
    <>
      <FocusMetricsTimeFrame timeframe={timeframe} settimeframe={settimeframe} title={title}/>
      <ClearanceNoHomesCoreTable
        dataAsOf={recordsByLevel[0]?.TRNSFM_DT}
        dataObject={clearanceNoHomesCoreData({
          shrinkData: shrinkClearanceNoHomesData,
          shrinkScoreData: recordsByLevel,
          timeframe,
          level
        })}
      />
    </>
  );
};
