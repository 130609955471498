import React from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { DataView } from '../../../../../components/Common/DataView';
import useQueryApi from '../../../../../hooks/useQueryApi';
import { useShrinkCoreContext } from '../hooks/useShrinkCoreContext';
import { shrinkCoreAgedWillCAllData } from '../utils/AgedWillCallCoreData';
import { FocusMetricsTimeFrame } from './FocusMetricsTimeFrame';

export const AgedWillCallsCore = () => {
  const { timeframe, settimeframe, fpToggle } = useShrinkCoreContext();
  const level = 'core';
  const title = 'Aged Will Calls';
  const defaultParams = {
    fpToggle,
    isCore: true
  };
  const {
    data: shrinkData = [],
    isLoading: shrinkDataLoading,
    error: shrinkError
  } = useQueryApi('query/queryShrinkMetricsBySubLevel/core/0000', defaultParams);
  const {
    data: shrinkScoreData = [],
    isLoading: shrinkScoreDataLoading,
    error: shrinkScoreDataError
  } = useQueryApi('queryShrinkMetricsScoresCore', defaultParams);

  if (shrinkDataLoading || shrinkScoreDataLoading) return <Loader active>Loading...</Loader>;
  if (shrinkError || shrinkScoreDataError) return <Header textAlign='center'>Something went wrong.</Header>;

  const shrinkScoreDataFilter = shrinkScoreData.filter(row => {
    return row && row['TIME_FRAME'] === timeframe;
  });

  return (
    <>
      <FocusMetricsTimeFrame timeframe={timeframe} settimeframe={settimeframe} title={title}/>
      <DataView className='mt-4'>
        <DataView.Title>
          Aged Will Calls - Core
          <Header.Subheader>Data as of {shrinkScoreDataFilter[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>
        <DataView.DataTable data={shrinkCoreAgedWillCAllData({ shrinkData, shrinkScoreData:shrinkScoreDataFilter, timeframe, level })} />
      </DataView>
    </>
  );
};
