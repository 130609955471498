import { Link } from 'react-router-dom';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { DataTable } from '../../../../../../components/Common/DataTable';
import useQueryApi from '../../../../../../hooks/useQueryApi';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { getPrevAndNextElement } from '../../../../../../utils/array';
import { coreInventorySubClassDrillBuilder } from '../../utils/inventory/CoreInventorySubClassDrillBuilder';

export const CoreInventorySubClass = ({ fpToggle }) => {
  const { deptNbr, classNbr, subClassNbr } = useQueryParams();

  const { data: inventoryDrillData = [], isLoading: isInventoryDrillData } = useQueryApi(
    `queryInvSclsByCoreCls/${classNbr}`,
    {
      fpToggle,
      isCore: true
    }
  );

  const { data: drillSubClasses = [], isLoading: isDrillSubClasses } = useQueryApi(`queryInvSkuByCoreScls/${subClassNbr}`, {
    fpToggle,
    isCore: true
  });
  const classDataSorted = inventoryDrillData.sort((left, right) => {
    const result = left.EXT_CLASS_NBR - right.EXT_CLASS_NBR;
    return result;
  });
  const currentIndex = classDataSorted.findIndex(dept => dept['EXT_SUB_CLASS_NBR'] === subClassNbr);
  const [previousDept, nextDept] = getPrevAndNextElement(classDataSorted, currentIndex);

  const { dataObj } = coreInventorySubClassDrillBuilder(drillSubClasses);

  if (isInventoryDrillData || isDrillSubClasses) return <Loader active>Loading...</Loader>;

  return (
    <>
      <Header textAlign='center'>{classDataSorted[currentIndex]?.SUB_CLASS_NM}</Header>
      <Grid>
        <Grid.Row fluid>
          <Grid.Column textAlign='left' width={8}>
            <Link
              to={`/core/inventory/dept/class/subclass?deptNbr=${deptNbr}&classNbr=${classNbr}&subClassNbr=${previousDept?.EXT_SUB_CLASS_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {previousDept?.SUB_CLASS_NM}
            </Link>
          </Grid.Column>
          <Grid.Column textAlign='right' width={8}>
            <Link
              to={`/core/inventory/dept/class/subclass?deptNbr=${deptNbr}&classNbr=${classNbr}&subClassNbr=${nextDept?.EXT_SUB_CLASS_NBR}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {nextDept?.SUB_CLASS_NM}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link
                style={{ color: '#EE7125' }}
                to={`/core/inventory/dept/class?deptNbr=${deptNbr}&classNbr=${classNbr}`}
                data-test='b2o-link'>
                Back to Class
              </Link>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header textAlign='center'>Inventory Detail</Header>
      <DataTable data={dataObj} />
    </>
  );
};
