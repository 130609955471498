import React from 'react';
import { Tab } from 'semantic-ui-react';
import { OnTimePickup } from './OnTimePickup';
import { OnTimeDelivery } from './OnTimeDelivery';
import { DataView } from '../../../../../../components/Common/DataView';

export const OnTime = () => {
  const tabs = [
    {
      menuItem: 'PICKUP',
      render: () => <OnTimePickup />
    },
    {
      menuItem: 'DELIVERY',
      render: () => <OnTimeDelivery />
    }
  ];

  return (
    <DataView className='my-4'>
      <Tab menu={{ compact: true, size: 'small' }} panes={tabs} />
    </DataView>
  );
};
