import { formatAssociateName } from "../../../utils/utils"

export const HEADERS = [
  { name: 'Associate Name' },
  { name: 'Customer Order #' },
  { name: 'Cancel Date' },
  { name: 'SKU #' },
  { name: 'Cancel Qty' },
  { name: 'Cancelled Sales $' },
  { name: 'Reason Code' }
]

export const buildRows = (data, timeframe) => data.filter(item => item[`${timeframe}_CANCELED_QTY`] > 0).map(data => [
  { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
  { numValue: data.EXTN_HOST_ORDER_REF },
  { numValue: data.CANCEL_DATE },
  { numValue: data.EXTN_SKU_CODE },
  { numValue: data[`${timeframe}_CANCELED_QTY`] },
  {
    decimals: 0,
    dollarValue: data[`${timeframe}_CANCELED_SALES`]
  },
  { numValue: data.REASON_DESC }
])
