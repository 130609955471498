import { useNavigate, useParams } from 'react-router-dom';

export const ProtectedRoute = ({ validParams, children }) => {
  const navigate = useNavigate();
  const params = useParams();
  const invalidParams = Object.keys(params).some(key => !validParams[key]?.includes(params[key]));
  if (invalidParams) {
    navigate(-1);
    return null;
  }
  return children;
};
