import { buildSublevelLinkColumn } from '../../../utils/dataTable';
import { CORE, STORE, LEVEL_NBR } from '../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../utils/level';
import { capitalizeFirstLetter } from '../../../utils/string';
import { shrinkMonitorMetricsDataFiltered } from './shrinkMonitorMetrics';
import { getCompareStyle } from '../../Common/TableCellUtils';

const { levelName: coreLevelName } = CORE;
const { levelName: storeLevelName } = STORE;

const CULL_MD_NUM = 'CULL_MD_NUM';
const CULL_MD_DEN = 'CULL_MD_DEN';
const CULL_MD_GOAL = 'CULL_MD_GOAL';
const CULL_MD_SCORE = 'CULL_MD_SCORE';
const EXT_CLASS_NBR = 'EXT_CLASS_NBR';
const CLASS_DESC = 'CLASS_DESC';
const CULL_MD_AMT = 'CULL_MD_AMT';
const SLS_AMT = 'SLS_AMT';

export const getBreadcrumbItems = params => {
  const { level, locNbr } = params;
  const { levelName, urlParam } = level;
  const baseUrl = `/${levelName}/shrink`;
  const urlWithParams = `${baseUrl}?${urlParam}=${locNbr}`;

  const to = levelName === coreLevelName ? baseUrl : urlWithParams;
  const text = 'Back to Shrink Overview';

  return [{ to, text }, { text: 'Cull Markdowns' }];
};

export const getTitle = params => {
  const { level, locNbr, currentLevelName } = params;
  const { levelName } = level;
  const baseTitle = 'Cull Markdowns';
  const locNbrFormated = formatLevelNumber(locNbr);

  const title = `${baseTitle} - ${capitalizeFirstLetter(levelName)}`;
  const titleSufix = locNbr ? `${locNbrFormated}, ${currentLevelName}` : '';

  return `${title} ${titleSufix}`;
};

export const getSubLevelColumn = params => {
  const { row, level, to } = params;
  const { levelName } = level;
  const subLevelColumn = buildSublevelLinkColumn({ level: levelName, row, to });

  return [subLevelColumn];
};

// Cull Markdowns Table

const getCullMarkdownsStrTableHeaders = () => [
  { name: 'Class Number' },
  { name: 'Class Description' },
  { name: 'Cull MD $' },
  { name: 'Cull MD %' }
];

const getCullMarkdownsStrRowValues = params => {
  const { timeframe, row } = params;

  const extClassNbr = row[EXT_CLASS_NBR];
  const classDesc = row[CLASS_DESC];
  const cullMdAmt = row[`${timeframe}_${CULL_MD_AMT}`];
  const slsAmt = row[`${timeframe}_${SLS_AMT}`];

  const classNbr = extClassNbr;
  const classDescription = classDesc;
  const cullMdMoney = cullMdAmt;
  const cullMdPct = cullMdAmt / slsAmt;

  return { classNbr, classDescription, cullMdMoney, cullMdPct };
};

const getCullMarkdownsStrRow = params => {
  const { classNbr, classDescription, cullMdMoney, cullMdPct } = params;

  return [
    { stringValue: classNbr },
    { stringValue: classDescription },
    { dollarValue: cullMdMoney },
    { pctValue: cullMdPct, decimals: 2 }
  ];
};

const getCullMarkdownsStrTabledata = params => {
  const { timeframe, cullMarkdownsData } = params;
  const cullMarkdownsDataWithoutNull = cullMarkdownsData.filter(row => row && row[EXT_CLASS_NBR] !== null);

  return cullMarkdownsDataWithoutNull.map(row => {
    const cullMarkdownsStrRowValues = getCullMarkdownsStrRowValues({ timeframe, row });

    return getCullMarkdownsStrRow(cullMarkdownsStrRowValues);
  });
};

const getCullMarkdownsTableHeaders = params => {
  const { level } = params;
  const { subLevel } = level;
  const { levelName: subLevelName } = subLevel;

  return [
    { name: `${capitalizeFirstLetter(subLevelName)} #` },
    { name: 'Value %' },
    { name: 'Goal %' },
    { name: 'Score %' }
  ];
};

const getCullMarkdownsRowValues = params => {
  const { timeframe, row, scoreCullMarkdownsData } = params;

  const cullMdNum = row[`${timeframe}_${CULL_MD_NUM}`];
  const cullMdDen = row[`${timeframe}_${CULL_MD_DEN}`];
  const cullMdGoal = scoreCullMarkdownsData[CULL_MD_GOAL];
  const cullMdScore = scoreCullMarkdownsData[CULL_MD_SCORE];

  const valuePct = cullMdNum / cullMdDen;
  const goalPct = cullMdGoal;
  const scorePct = cullMdScore;

  return { valuePct, goalPct, scorePct };
};

const getCullMarkdownsRow = params => {
  const { valuePct, goalPct = 0, scorePct = 0 } = params;

  return [
    { pctValue: valuePct, decimals: 2, customStyle: (getCompareStyle(parseFloat(valuePct) - parseFloat(goalPct))) },
    { pctValue: goalPct, decimals: 2, prefix: '≥ ' },
    { pctValue: scorePct, decimals: 2 }
  ];
};

const getCullMarkdownsTabledata = params => {
  const { level, timeframe, to, cullMarkdownsData, scoreCullMarkdownsData: defaultScoreCullMarkdownsData } = params;
  const { levelName, subLevel } = level;
  const { [LEVEL_NBR]: subLevelNbr } = subLevel;

  const scoreCullMarkdownsDataFiltered = shrinkMonitorMetricsDataFiltered(
    defaultScoreCullMarkdownsData,
    levelName,
    timeframe
  );

  return cullMarkdownsData.map(row => {
    const scoreCullMarkdownsData = scoreCullMarkdownsDataFiltered.find(
      rowScore => rowScore[subLevelNbr] === row[subLevelNbr]
    );
    const cullMarkdownsRowValues = getCullMarkdownsRowValues({ timeframe, row, scoreCullMarkdownsData });
    const subLevelColumn = getSubLevelColumn({ row, level, to });

    return [...subLevelColumn, ...getCullMarkdownsRow(cullMarkdownsRowValues)];
  });
};

export const getCullMarkdownsDataTable = params => {
  const { level, timeframe, to, cullMarkdownsData, scoreCullMarkdownsData } = params;
  const { levelName } = level;

  const headers = levelName !== storeLevelName ? getCullMarkdownsTableHeaders({ level }) : getCullMarkdownsStrTableHeaders();
  const data =
    levelName !== storeLevelName
      ? getCullMarkdownsTabledata({ level, timeframe, to, cullMarkdownsData, scoreCullMarkdownsData })
      : getCullMarkdownsStrTabledata({ timeframe, cullMarkdownsData });

  return {
    headers,
    data,
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending'
  };
};
