import { useContext, useMemo, useState } from 'react';
import { useEnhancedQueries } from '../../../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import {
  buildRows as buildCancellationRows,
  HEADERS as CANCELLATION_HEADERS
} from '../../../utils/associateCancellationTableBuilder';
import {
  ASSOCIATE_DETAILS_HEADERS,
  ASSOCIATE_VIEW,
  buildAssociateDetailsRows,
  CANCELLATIONS,
  getBopisChannels
} from '../../../utils/associateViewTableBuilder';
import { getTimeframeOptions } from '../../../../../../../utils/timeFrameUtils';
import { CustomDropDown } from '../../CustomDropdown';
import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../../../components/Common/DataView';
import { PlaceholderLoading } from '../../../../../../../components/Loading/PlaceholderLoading';

export const BopisComplete = () => {
  const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
  const [localtimeframe, settimeframe] = useState('WTD');
  const {
    isError,
    isLoading,
    results: [{ data: cancellationsData = [] }, { data: associatesData = [] }]
  } = useEnhancedQueries(['queryOrderFulfillmentCancellations', 'queryOrderFulfillmentAssociates'], fpToggle)
  const [view, setView] = useState(ASSOCIATE_VIEW);
  const bopisCancellationsData = useMemo(() => getBopisChannels(cancellationsData), [cancellationsData]);
  const bopisAssociates = useMemo(() => getBopisChannels(associatesData), [associatesData]);

  const showCustomTimeframe = [CANCELLATIONS];

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />;

  const getData = () => {
    switch (view) {
      case ASSOCIATE_VIEW:
        return {
          headers: ASSOCIATE_DETAILS_HEADERS,
          data: buildAssociateDetailsRows(bopisAssociates, timeframe),
          pagination: true,
          sortable: true,
          sortableDefault: 3,
          sortDirection: 'ascending'
        };
      case CANCELLATIONS:
        return {
          headers: CANCELLATION_HEADERS,
          data: buildCancellationRows(bopisCancellationsData, localtimeframe),
          pagination: true,
          sortable: true,
          sortableDefault: 4,
          sortDirection: 'ascending'
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  const viewOptions = [ASSOCIATE_VIEW, CANCELLATIONS].map(view => ({ key: view, text: view, value: view }));
  const timeframeOptions = getTimeframeOptions(4);

  return (
    <DataView className='my-4'>
      <CustomDropDown
        view={view}
        setView={setView}
        extTimeframe={localtimeframe}
        setExtTimeframe={settimeframe}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={viewOptions}
        timeframeOptions={timeframeOptions}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
};
