import { useState, useContext } from 'react';
import { useEnhancedQueries } from '../../../../../../../hooks/useEnhancedQueries';
import { Header } from 'semantic-ui-react';
import { DataView } from '../../../../../../../components/Common/DataView';
import { CustomDropDown } from '../../CustomDropdown';
import { PlaceholderLoading } from '../../../../../../../components/Loading/PlaceholderLoading';
import { formatAssociateName } from '../../../../../utils/utils';
import { getTimeframeOptions } from '../../../../../../../utils/timeFrameUtils';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';

export const Fulfillment = () => {
  const { fpToggle, timeframe } = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Fulfillment View');
  const {
    isError,
    isLoading,
    results: [{ data: storeCompleteDlvFulfill = [] }, { data: storeCompleteDlvFulfillAssoc = [] }]
  } = useEnhancedQueries(['queryCompleteDlvFulfill', 'storeCompleteDlvFulfillAssoc'], fpToggle)
  const [localtimeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['Fulfillment View'];

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const fulfillFilter = storeCompleteDlvFulfill.filter(row => row[localtimeframe + '_FLG'] > 0);

  const getData = () => {
  
    switch (view) {
      case 'Fulfillment View':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Customer Order #' },
            { name: 'Cancel Date' },
            { name: 'SKU #' },
            { name: 'Cancel Qty' },
            { name: 'Cancelled Sales $' },
            { name: 'Reason Code' }
          ],
          data: fulfillFilter.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM, 5) || data.LDAP },
            { stringValue: data.EXTN_HOST_ORDER_REF },
            { stringValue: data.CANCEL_DATE },
            { numValue: data.EXTN_SKU_CODE },
            { numValue: data.CANCELED_QTY },
            {
              decimals: 2,
              dollarValue: data.CANCELED_SALES
            },
            { stringValue: data.REASON_DESC }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case 'Associate View':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'Units Unfulfilled' }, { name: '$ Unfulfilled' }],
          data: storeCompleteDlvFulfillAssoc
          .filter(data => data[`${timeframe}_CANCELED_QTY`] > 0 || data[`${timeframe}_CANCELED_SLS`] > 0)
          .map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM, 5) || data.LDAP },
            { numValue: data[`${timeframe}_CANCELED_QTY`] },
            { dollarValue: data[`${timeframe}_CANCELED_SLS`], decimals: 0 }
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <DataView className='my-4'>
      <CustomDropDown
        view={view}
        setView={setView}
        extTimeframe={localtimeframe}
        setExtTimeframe={settimeframe}
        showCustomTimeframe={showCustomTimeframe}
        viewOptions={[
          { key: 'Fulfillment View', text: 'Fulfillment View', value: 'Fulfillment View' },
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' }
        ]}
        timeframeOptions={getTimeframeOptions(4)}
      />
      <DataView.DataTable data={getData()} />
    </DataView>
  );
};
