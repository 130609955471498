import useQueryApi from '../../../hooks/useQueryApi';
import { useShrinkDamagedMarkdownsQueries } from '../hooks/useShrinkDamagedMarkdownsQueries';
import { useShrinkCullMarkdownsQueries } from '../hooks/useShrinkCullMarkdownsQueries';
import { CORE } from '../../../utils/constantsByLevel';

const { levelName: coreLevelName } = CORE;

export const useShrinkDamagedCullMarkdownsQueries = params => {
  const { level, locNbr, fpToggle } = params;
  const { levelName } = level;
  const queryParamsByLevel = levelName === coreLevelName ? { isCore: true } : { locNbr };
  const queryParams = { ...queryParamsByLevel, fpToggle };

  const {
    data: summaryData,
    isLoading: summaryIsLoading,
    isError: summaryIsError
  } = useQueryApi(`query/queryShrinkMetricsByLevel/${levelName}`, queryParams);

  const {
    damagedMarkdownsData,
    scoreDamagedMarkdownsData,
    isLoading: damagedMarkdownsIsLoading,
    isError: damagedMarkdownsIsError
  } = useShrinkDamagedMarkdownsQueries({
    level,
    locNbr,
    fpToggle
  });

  const {
    cullMarkdownsData,
    scoreCullMarkdownsData,
    isLoading: cullMarkdownsIsLoading,
    isError: cullMarkdownsIsError
  } = useShrinkCullMarkdownsQueries({
    level,
    locNbr,
    fpToggle
  });

  const isLoading = summaryIsLoading || damagedMarkdownsIsLoading || cullMarkdownsIsLoading;
  const isError = summaryIsError || damagedMarkdownsIsError || cullMarkdownsIsError;

  return {
    summaryData,
    damagedMarkdownsData,
    scoreDamagedMarkdownsData,
    cullMarkdownsData,
    scoreCullMarkdownsData,
    isLoading,
    isError
  };
};
