import { Grid } from 'semantic-ui-react';
import { Link } from '../../../../../components/Common/Link';

export const HelpersCoreLinks = ({ level }) => {
  return (
    <Grid>
      <Grid.Column width={8}>
        <Link to={{ pathname: `/${level}/overview` }} target='_blank'>
          Back to Overview
        </Link>
      </Grid.Column>
      <Grid.Column width={8} textAlign='right'>
        <a
          href="https://portal.homedepot.com/sites/myapron_en_us/SitePages/Asset-Protection.aspx"
          target='_blank'
          rel='noopener noreferrer'>
          Shrink Resources
        </a>
      </Grid.Column>
    </Grid>
  );
};
