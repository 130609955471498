import { useContext, useState } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { DataView } from '../../../../../../components/Common/DataView';
import { OrderFulfillmentContext } from '../../../../context/OrderFulfillmentContext';
import { Dropdown, Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';
import { onTimeDeliveryMethods, onTimePickUpGetData } from '../../../../utils/onTimePickUp';
import { BOPIS, TOTAL, WILL_CALL, BOSS } from '../../../../utils/consts';

export const OnTimePickUp = () => {
  const { level, fpToggle, timeframe } = useContext(OrderFulfillmentContext);
  const {
    isError,
    isLoading,
    results: [{ data: queryOnTimePickUp = [] }, { data: queryPickUpCurbside = [] }]
  } = useEnhancedQueries(['queryOnTimePickUp', 'queryPickUpCurbside'], fpToggle);
  const [view, setView] = useState(TOTAL);

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const dataFIlter = {
    Curbside: queryPickUpCurbside,
    BOSS: queryOnTimePickUp.filter(data => data.CHANNEL === BOSS),
    BOPIS: queryOnTimePickUp.filter(data => data.CHANNEL === BOPIS),
    'Will Call': queryOnTimePickUp.filter(data => data.CHANNEL === String(WILL_CALL).toUpperCase()),
    Total: queryOnTimePickUp.filter(data => data.CHANNEL === 'TOTAL'),
  }
  const data = onTimePickUpGetData(view, level, dataFIlter[view], timeframe);

  return (
    <DataView className='my-4'>
      <DataView.Controls>
        <div>Select Method</div>
        <Dropdown
          defaultValue={view}
          selection
          scrolling
          options={onTimeDeliveryMethods}
          onChange={(_, { value }) => setView(value)}
        />
      </DataView.Controls>
      <DataView.Title as='h3'>Pickup Details by Method {view}</DataView.Title>
      <DataView.DataTable data={data} />
    </DataView>
  );
};
