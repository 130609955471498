import { useState } from 'react';
import { Header, Loader, Icon, Segment, Table, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useQueryApi from '../../hooks/useQueryApi';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { DeptAssocToggle } from './DeptAssocToggle';
import { OSA_OHM_NO_LOC_TAGS_GOAL } from '../../constants/goals';
import { getParamByLevel } from '../../utils/timeFrameUtils';
import { Breadcrumb } from '../Common/Breadcrumb';
import { DataView } from '../Common/DataView';
import { METRIC_CONSTANTS } from '../../utils/constantsByLevel';
import { getOHMNoLocationTagsBreadcrumbItems } from '../../utils/breadcrumbItems';

export const OHMNoLocationTags = (({ timeframe, settimeframe, level, fpToggle, locNbr }) => {
  const [DeptOrAssoc, setDeptOrAssoc] = useState('DEPARTMENT');

  const { data: OHMNoLocationTagsData = [], isLoading: deptLoading } = useQueryApi(`query/osaOHMNoLocTags/${level}`, {
    locNbr,
    fpToggle
  });

  const { data: summaryQuery = [], isLoading: summaryLoading } = useQueryApi(`query/queryOSADriverSummary/${level}`, {
    locNbr,
    fpToggle
  });

  if (deptLoading || summaryLoading) return <Loader active>Loading</Loader>;

  const summaryData = summaryQuery[0] ?? {};

  if (!OHMNoLocationTagsData || OHMNoLocationTagsData.length === 0 || !summaryData)
    return <Header textAlign='center'>Invalid Store Number</Header>;

  const NoLocSum = summaryData[timeframe + '_NO_LOC_PALLET_COUNT'];
  const TotalSum = summaryData[timeframe + '_TOTAL_PALLET_COUNT'];
  const actual = NoLocSum / TotalSum;

  //Calculate the timeframe + _NO_LOC_PALLET_COUNT for Dept tab
  const updatedResultDept = OHMNoLocationTagsData.reduce((acc, curr) => {
    let item = acc.find(item => item.DEPT_NBR === curr.DEPT_NBR);
    if (item) {
      item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT'];
      item.DEPT_NM = curr.DEPT_NM;
    } else {
      acc.push({
        DEPT_NBR: curr.DEPT_NBR,
        [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
        DEPT_NM: curr.DEPT_NM
      });
    }

    item = acc.find(item => item.DEPT_NM === 'All Departments');
    if (item) {
      item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT'];
    } else {
      acc.push({
        DEPT_NBR: '',
        [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT'],
        DEPT_NM: 'All Departments'
      });
    }
    return acc;
  }, []);

  //Calculate the timeframe + _NO_LOC_PALLET_COUNT for assoc tab
  const updatedResultAssoc = OHMNoLocationTagsData.reduce((acc, curr) => {
    let item = acc.find(item => item.CREATOR_NAME === curr.CREATOR_NAME);
    if (item) {
      item[timeframe + '_NO_LOC_PALLET_COUNT'] += curr[timeframe + '_NO_LOC_PALLET_COUNT'];
    } else {
      acc.push({
        CREATOR_NAME: curr.CREATOR_NAME,
        [timeframe + '_NO_LOC_PALLET_COUNT']: curr[timeframe + '_NO_LOC_PALLET_COUNT']
      });
    }
    return acc;
  }, []);

  const updatedResultDeptFilter = updatedResultDept.filter(row => row[timeframe + '_NO_LOC_PALLET_COUNT'] !== 0);

  const updatedResultDeptFilterSort = updatedResultDeptFilter.sort((left, right) =>
    left.DEPT_NM === 'All Departments' ? -1 : right.DEPT_NM === 'All Departments' ? 1 : 0
  );

  const updatedResultAssocFilter = updatedResultAssoc.filter(row => row[timeframe + '_NO_LOC_PALLET_COUNT'] >= 2);

  const updatedAssocList = updatedResultAssocFilter.map(data => {
    const [firstName, lastName] = data.CREATOR_NAME.split(' ');
    const updatedName = `${firstName.slice(0, 1)}.${lastName.slice(0, 3)}`;
    return { CREATOR_NAME: updatedName, [timeframe + '_NO_LOC_PALLET_COUNT']: data[timeframe + '_NO_LOC_PALLET_COUNT'] };
  });

  const currentLevelName = OHMNoLocationTagsData[0][METRIC_CONSTANTS[level].LEVEL_NM];
  const urlParam = getParamByLevel(level);
  const breadcrumbItems = getOHMNoLocationTagsBreadcrumbItems({ level, urlParam, locNbr });

  const osaOHMNoLocTagsData = {
    sortable: true,
    headers: [{ name: 'Actual' }, { name: 'Goal' }],
    data: [
      [
        { pctValue: actual, compareValue: OSA_OHM_NO_LOC_TAGS_GOAL, compareStyleInvert: true },
        { pctValue: OSA_OHM_NO_LOC_TAGS_GOAL }
      ]
    ]
  };

  return (
    <Segment>
      <Breadcrumb items={breadcrumbItems} />

      <DataView className='my-3'>
        <DataView.Title>
          <span>
            OHM No Loc Tags % - {level} {locNbr}, {currentLevelName}
            <Popup
              on='click'
              position='bottom left'
              content={
                <Segment>
                  <Header textAlign='center'>Overhead Management No Location Tags</Header>
                  <p>Store leaders must ensure that associates resolve no location tag issues within 7 days time frame.</p>
                  &nbsp;
                  <p>
                    To learn more, please review the Overhead Management:
                    <a
                      href='https://portal.homedepot.com/sites/myapron_en_us/Departments/Assets0001/ohm-how-to-manage.pdf'
                      style={{ textDecoration: 'underline', marginLeft: '5px' }}>
                      How to Manage Guide.
                    </a>
                  </p>
                </Segment>
              }
              trigger={<Icon name={'info circle'} color={'blue'} />}
            />
          </span>
          <Header.Subheader>Data as of {OHMNoLocationTagsData[0]?.TRNSFM_DT}</Header.Subheader>
        </DataView.Title>

        <DataView.Controls className='d-flex flex-column align-items-center'>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>

        <DataView.DataTable data={osaOHMNoLocTagsData} />
      </DataView>

      <Header textAlign='center'>OHM No Loc Tags Overview</Header>
      <DeptAssocToggle DeptOrAssoc={DeptOrAssoc} setDeptOrAssoc={setDeptOrAssoc} />
      {DeptOrAssoc === 'ASSOCIATES' && (
        <p style={{ textAlign: 'center' }}>This is the list of associates who made more than 2 no location tags.</p>
      )}
      <Table textAlign='center' unstackable celled sortable size='small'>
        <Table.Header>
          <Table.Row>
            {DeptOrAssoc === 'DEPARTMENT' && <Table.HeaderCell>Dept</Table.HeaderCell>}
            {DeptOrAssoc === 'DEPARTMENT' && <Table.HeaderCell>OHM No Loc Tags</Table.HeaderCell>}

            {DeptOrAssoc === 'ASSOCIATES' && <Table.HeaderCell>Creator</Table.HeaderCell>}
            {DeptOrAssoc === 'ASSOCIATES' && <Table.HeaderCell>Number of No Loc Tags</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {DeptOrAssoc === 'DEPARTMENT' &&
            updatedResultDeptFilterSort.map(data => {
              return (
                <Table.Row>
                  {data.DEPT_NM === 'All Departments' ? (
                    <Table.Cell>
                      {data.DEPT_NBR} {data.DEPT_NM}
                    </Table.Cell>
                  ) : (
                    <Table.Cell>
                      <Link
                        style={{ color: '#EE7125', textDecoration: 'underline' }}
                        to={`/osa/ohmnoloc/dept?strNbr=${locNbr}&deptNbr=${data['DEPT_NBR']}`}>
                        {data.DEPT_NBR} {data.DEPT_NM}
                      </Link>
                    </Table.Cell>
                  )}
                  <Table.Cell style={{ color: 'red' }}>{data[timeframe + '_NO_LOC_PALLET_COUNT']}</Table.Cell>
                </Table.Row>
              );
            })}
          {DeptOrAssoc === 'ASSOCIATES' &&
            updatedAssocList.map(data => {
              return (
                <Table.Row>
                  {DeptOrAssoc === 'ASSOCIATES' && <Table.Cell>{data.CREATOR_NAME}</Table.Cell>}
                  {DeptOrAssoc === 'ASSOCIATES' && (
                    <Table.Cell style={{ color: 'red' }}>{data[timeframe + '_NO_LOC_PALLET_COUNT']}</Table.Cell>
                  )}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </Segment>
  );
});
