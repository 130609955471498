import React, { useState } from 'react'
import { Table, Header, Loader, Grid, Segment, Menu, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'

export const LeadsDetail = (({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const [sortColumn, setSortColumn] = useState("SUMMARY_PROGRAM_NAME")
  const [sortDirection, setSortDirection] = useState("ascending")
  const [unit, setUnit] = useState('NOM')
  const [allWebStore, setAllWebStore] = useState('') // All = empty string, Web = '_WEB', Store = '_STR'

  const [assocSortColumn, setAssocSortColumn] = useState("USER_ID")
  const [assocSortDirection, setAssocSortDirection] = useState("ascending")

  const [sumProOrAssoc, setSumProOrAssoc] = useState('Summary Program')

  const leadDetailQuery = useQuery(['queryLeadsDtlByStr', { strNbr, fpToggle }], getData)
  const leadAssocQuery = useQuery(['querySwmOpsLdAsscTot', { strNbr, fpToggle }], getData)

  if (leadDetailQuery.isLoading || leadAssocQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let sumProgNmFilter = leadDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] !== 'TOTAL'
  })

  let totalFilter = leadDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL'
  })

  let sumProgNmSorted = sumProgNmFilter.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortColumn === 'SUMMARY_PROGRAM_NAME') {
      result = left.SUMMARY_PROGRAM_NAME > right.SUMMARY_PROGRAM_NAME ? 1 : -1
    }
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let assocDataSort = leadAssocQuery.data.sort((left, right) => {
    let result = left[assocSortColumn] - right[assocSortColumn]
    if (assocSortColumn === 'USER_ID') {
      result = left.USER_ID > right.USER_ID ? 1 : -1
    }
    if (assocSortColumn === 'ASSOC_NM') {
      result = left.ASSOC_NM > right.ASSOC_NM ? 1 : -1
    }
    if (assocSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let assocDataFilter = assocDataSort.filter((row) => {
    return (
      row &&
      row[timeframe + '_LEAD_TOTAL'] !== 0
    )
  })

  const assocView = <>
    <Header textAlign='center'>Leads by Associate</Header>
    <Table textAlign='center' unstackable celled sortable size='small'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={assocSortColumn === 'USER_ID' ? assocSortDirection : null}
            onClick={() => {
              if (assocSortColumn !== 'USER_ID') {
                setAssocSortColumn('USER_ID')
                setAssocSortDirection('ascending')
              } else {
                assocSortDirection === 'ascending' ? setAssocSortDirection('descending') : setAssocSortDirection('ascending')
              }
            }}
          >
            LDAP {assocSortColumn !== 'USER_ID' && <Icon fitted name='sort' />}
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={assocSortColumn === 'ASSOC_NM' ? assocSortDirection : null}
            onClick={() => {
              if (assocSortColumn !== 'ASSOC_NM') {
                setAssocSortColumn('ASSOC_NM')
                setAssocSortDirection('ascending')
              } else {
                assocSortDirection === 'ascending' ? setAssocSortDirection('descending') : setAssocSortDirection('ascending')
              }
            }}
          >
            Associate Name {assocSortColumn !== 'ASSOC_NM' && <Icon fitted name='sort' />}
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={assocSortColumn === timeframe + '_LEAD_TOTAL' ? assocSortDirection : null}
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            onClick={() => {
              if (assocSortColumn !== timeframe + '_LEAD_TOTAL') {
                setAssocSortColumn(timeframe + '_LEAD_TOTAL')
                setAssocSortDirection('ascending')
              } else {
                assocSortDirection === 'ascending' ? setAssocSortDirection('descending') : setAssocSortDirection('ascending')
              }
            }}
          >
            Total Leads {assocSortColumn !== timeframe + '_LEAD_TOTAL' && <Icon fitted name='sort' />}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {assocDataFilter.map((data) => {
          return (
            <Table.Row>
              <Table.Cell>
                <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/leads/assoc?strNbr=${strNbr}&ldap=${data['USER_ID']}`} >
                  {data['USER_ID']}
                </Link>
              </Table.Cell>
              <Table.Cell >
                {data['ASSOC_NM']}
              </Table.Cell>
              <Table.Cell>
                {data[timeframe + '_LEAD_TOTAL']}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  </>

  const sumProNmView = <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>Units:</Grid.Row>
        <Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={8} textAlign='right'>
        <Grid.Row>&nbsp;</Grid.Row>
        <Grid.Row>
          <Menu compact size='small'>
            <Menu.Item
              name='All'
              active={allWebStore === ''}
              content='All'
              onClick={() => {
                setAllWebStore('')
              }}
            />
            <Menu.Item
              name='Web'
              active={allWebStore === '_WEB'}
              content='Web'
              onClick={() => {
                setAllWebStore('_WEB')
              }}
            />
            <Menu.Item
              name='Store'
              active={allWebStore === '_STR'}
              content='Store'
              onClick={() => {
                setAllWebStore('_STR')
              }}
            />
          </Menu>
        </Grid.Row>
      </Grid.Column>
    </Grid>
    {/* Leads overview - aggregated summary programs */}
    <Header textAlign='center'>Total Leads</Header>
    <Table textAlign='center' unstackable celled sortable size='small'>
      <Table.Header>
        <Table.Row>
          {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.HeaderCell>TW Target</Table.HeaderCell>}
          {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.HeaderCell>LW Target</Table.HeaderCell>}
          {!(new Date().getDay() === 1 && timeframe === 'WTD') && <Table.HeaderCell>Target</Table.HeaderCell>}
          <Table.HeaderCell>Leads</Table.HeaderCell>
          {unit === 'NOM' && <Table.HeaderCell>VLY</Table.HeaderCell>}
          {unit === 'PCT' && <Table.HeaderCell>VLY (%)</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {totalFilter.map((data) => {
          return (
            <Table.Row>
              {<Table.Cell>
                {data[timeframe + allWebStore + '_LEADS_TGT'] != null ?
                  data[timeframe + allWebStore + '_LEADS_TGT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  '0'}
              </Table.Cell>}
              {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.Cell>{data['LW' + allWebStore + '_LEADS_TGT']}</Table.Cell>}
              {<Table.Cell>
                {data[timeframe + allWebStore + '_LEADS'] != null ?
                  data[timeframe + allWebStore + '_LEADS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  '0'}
              </Table.Cell>}
              {unit === 'NOM' && <Table.Cell style={{ color: data[timeframe + allWebStore + '_VLY'] > 0 ? 'green' : data[timeframe + allWebStore + '_VLY'] < 0 ? 'red' : 'black' }}>
                {data[timeframe + allWebStore + '_VLY'] != null ?
                  data[timeframe + allWebStore + '_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  '0'}
              </Table.Cell>}
              {unit === 'PCT' && <Table.Cell style={{ color: data[timeframe + allWebStore + '_VLY_PCT'] > 0 ? 'green' : data[timeframe + allWebStore + '_VLY_PCT'] < 0 ? 'red' : 'black' }}>
                {data[timeframe + allWebStore + '_VLY_PCT'] != null ?
                  (100 * data[timeframe + allWebStore + '_VLY_PCT']).toLocaleString(undefined, { maximumFractionDigits: 2 }) + '%' :
                  '0'}
              </Table.Cell>}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
    <p>*Target is reported as the total leads generated last year through the current period. For example, YTD target is all leads generated last year through the upcoming Sunday. Actual Lead counts and vLY are reported through the previous day.</p>
    {/* Leads detail - by individual summary program */}
    <Header textAlign='center'>Leads by Summary Program</Header>
    <Table textAlign='center' unstackable celled sortable size='small'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortColumn === 'SUMMARY_PROGRAM_NAME' ? sortDirection : null}
            style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
            onClick={() => {
              if (sortColumn !== 'SUMMARY_PROGRAM_NAME') {
                setSortColumn('SUMMARY_PROGRAM_NAME')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>
            Summary Program Name {sortColumn !== 'SUMMARY_PROGRAM_NAME' && <Icon fitted name='sort' />}
          </Table.HeaderCell>
          {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.HeaderCell
            sorted={sortColumn === 'WTD' + allWebStore + '_LEADS_TGT' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== 'WTD' + allWebStore + '_LEADS_TGT') {
                setSortColumn('WTD' + allWebStore + '_LEADS_TGT')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>
            TW Target {sortColumn !== 'WTD' + allWebStore + '_LEADS_TGT' && <Icon fitted name='sort' />}
          </Table.HeaderCell>}
          {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.HeaderCell
            sorted={sortColumn === 'LW' + allWebStore + '_LEADS_TGT' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== 'LW' + allWebStore + '_LEADS_TGT') {
                setSortColumn('LW' + allWebStore + '_LEADS_TGT')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>
            LW Target {sortColumn !== 'LW' + allWebStore + '_LEADS_TGT' && <Icon fitted name='sort' />}
          </Table.HeaderCell>}
          {!(new Date().getDay() === 1 && timeframe === 'WTD') && <Table.HeaderCell
            sorted={sortColumn === timeframe + allWebStore + '_LEADS_TGT' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + allWebStore + '_LEADS_TGT') {
                setSortColumn(timeframe + allWebStore + '_LEADS_TGT')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>
            Target {sortColumn !== timeframe + allWebStore + '_LEADS_TGT' && <Icon fitted name='sort' />}
          </Table.HeaderCell>}
          <Table.HeaderCell
            sorted={sortColumn === timeframe + allWebStore + '_LEADS' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + allWebStore + '_LEADS') {
                setSortColumn(timeframe + allWebStore + '_LEADS')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>
            Leads {sortColumn !== timeframe + allWebStore + '_LEADS' && <Icon fitted name='sort' />}
          </Table.HeaderCell>
          {unit === 'NOM' && <Table.HeaderCell
            sorted={sortColumn === timeframe + allWebStore + '_VLY' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + allWebStore + '_VLY') {
                setSortColumn(timeframe + allWebStore + '_VLY')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>
            VLY {sortColumn !== timeframe + allWebStore + '_VLY' && <Icon fitted name='sort' />}
          </Table.HeaderCell>}
          {unit === 'PCT' && <Table.HeaderCell
            sorted={sortColumn === timeframe + allWebStore + '_VLY_PCT' ? sortDirection : null}
            onClick={() => {
              if (sortColumn !== timeframe + allWebStore + '_VLY_PCT') {
                setSortColumn(timeframe + allWebStore + '_VLY_PCT')
                setSortDirection('ascending')
              } else {
                sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
              }
            }}>
            VLY (%) {sortColumn !== timeframe + allWebStore + '_VLY_PCT' && <Icon fitted name='sort' />}
          </Table.HeaderCell>}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sumProgNmSorted.map((data) => {
          return (
            <Table.Row>
              <Table.Cell>
                {data['SUMMARY_PROGRAM_NAME']}
              </Table.Cell>
              {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.Cell>{data['WTD' + allWebStore + '_LEADS_TGT']}</Table.Cell>}
              {new Date().getDay() === 1 && timeframe === 'WTD' && <Table.Cell>{data['LW' + allWebStore + '_LEADS_TGT']}</Table.Cell>}
              {!(new Date().getDay() === 1 && timeframe === 'WTD') && <Table.Cell>
                {data[timeframe + allWebStore + '_LEADS_TGT'] != null ?
                  data[timeframe + allWebStore + '_LEADS_TGT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  '0'}
              </Table.Cell>}
              {<Table.Cell>
                {data[timeframe + allWebStore + '_LEADS'] != null ?
                  data[timeframe + allWebStore + '_LEADS'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  '0'}
              </Table.Cell>}
              {unit === 'NOM' && <Table.Cell style={{ color: data[timeframe + allWebStore + '_VLY'] > 0 ? 'green' : data[timeframe + allWebStore + '_VLY'] < 0 ? 'red' : 'black' }}>
                {data[timeframe + allWebStore + '_VLY'] != null ?
                  data[timeframe + allWebStore + '_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) :
                  '0'}
              </Table.Cell>}
              {unit === 'PCT' && <Table.Cell style={{ color: data[timeframe + allWebStore + '_VLY_PCT'] > 0 ? 'green' : data[timeframe + allWebStore + '_VLY_PCT'] < 0 ? 'red' : 'black' }}>
                {data[timeframe + allWebStore + '_VLY_PCT'] != null ?
                  (100 * data[timeframe + allWebStore + '_VLY_PCT']).toLocaleString(undefined, { maximumFractionDigits: 2 }) + '%' :
                  '0'}
              </Table.Cell>}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  </>

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/store/overview?strNbr=${strNbr}`} data-test='b2o-link' >Back to Overview</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
      <Menu attached='top' tabular>
        <Menu.Item
          name='Summary Program'
          active={sumProOrAssoc === 'Summary Program'}
          onClick={() => {
            setSumProOrAssoc('Summary Program')
          }}
        />
        <Menu.Item
          name='Associate'
          active={sumProOrAssoc === 'Associate'}
          onClick={() => {
            setSumProOrAssoc('Associate')
          }}
        />
      </Menu>
      <Segment attached='bottom'>
        {sumProOrAssoc === 'Summary Program' && sumProNmView}
        {sumProOrAssoc === 'Associate' && assocView}
      </Segment>
    </>
  )
})