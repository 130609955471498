import { useSpecialtyProRentalServicesContext } from '../hooks/useSpecialtyProRentalServicesContext';
import { buildFooter, buildHeaders, buildRows } from '../utils/servicesPrgmSalesTable';
import { DataView } from '../../Common/DataView';

export const ServicesPrgmSales = ({ data }) => {
  const { level, locNbr, timeframe } = useSpecialtyProRentalServicesContext();
 

  const dataObj = {
    data: buildRows(data, level, locNbr, timeframe),
    headers: buildHeaders(level),
    footer: buildFooter(data, timeframe),
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-4'>
      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
