// Timeframe

export const TIME_FRAME = 'TIME_FRAME';

// Department
export const DEPT_NM = 'DEPT_NM';

// Unit Menu
export const NOM = 'NOM';
export const PCT = 'PCT';
export const USD = 'USD';

// Data
export const TRNSFM_DT = 'TRNSFM_DT';
export const EXT_CLASS_NBR = 'EXT_CLASS_NBR';
export const EXT_SUB_CLASS_NBR = 'EXT_SUB_CLASS_NBR';
export const CLASS_DESC = 'CLASS_DESC';
export const SUB_CLASS_DESC = 'SUB_CLASS_DESC';
export const SKU_DESC = 'SKU_DESC';
export const SKU_NBR = 'SKU_NBR';
export const WTD = 'WTD';
export const LW = 'LW';
export const LLW = 'LLW';
export const STOREOPS = 'storeops';

// Format
export const EN_US = 'en-US';

export const ALL = 'ALL';
export const unitPrefix = unit => (unit === NOM ? '$' : '%');
