import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { capitalizeFirstLetter } from '../../../utils/string';
import { formatNumberWithCommas } from '../../../utils/formatter';
import { TOTAL, BOPIS, WILL_CALL, completePickUpSelectMethodOptions } from './consts';

export const completePickUpData = (view, level, completeData, timeframe) => {
  const  bopisData  = completeData.filter(row => {
    return row['CHANNEL'] === 'BOPIS';
  });

  const  willCallData  = completeData.filter(row => {
    return row['CHANNEL'] === 'WILL CALL';
  });

  const totalData = completeData.filter(row => {
    return row['CHANNEL'] === 'BOPIS' || row['CHANNEL'] === 'WILL CALL';
  });

  switch (view) {
    case TOTAL:
      return {
        sortable: true,
        sortableDefault: 0,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Fulfillment %' },
          { name: 'Units Unfulfilled' },
          { name: 'Not-in-Stock Units' },
          { name: '$ Unfulfilled' }
        ],
        data: totalData
          .reduce((acc, current) => {
            const existing = acc.find(data => data[METRIC_CONSTANTS[level]['propToRender']] === current[METRIC_CONSTANTS[level]['propToRender']]);
            if (existing) {
              existing[timeframe + '_FULFILL_NUM'] += current[timeframe + '_FULFILL_NUM'];
              existing[timeframe + '_FULFILL_DEN'] += current[timeframe + '_FULFILL_DEN'];
              existing[timeframe + '_CNCL_NIS_NUM'] += current[timeframe + '_CNCL_NIS_NUM'];
              existing[timeframe + '_CNCL_NIS_DEN'] += current[timeframe + '_CNCL_NIS_DEN'];
              existing[timeframe + '_UNFULFILLED_TOTAL'] += current[timeframe + '_UNFULFILLED_TOTAL'];
            } else {
              acc.push({
                [METRIC_CONSTANTS[level]['propToRender']]: current[METRIC_CONSTANTS[level]['propToRender']],
                [timeframe + '_FULFILL_NUM']: current[timeframe + '_FULFILL_NUM'],
                [timeframe + '_FULFILL_DEN']: current[timeframe + '_FULFILL_DEN'],
                [timeframe + '_CNCL_NIS_NUM']: current[timeframe + '_CNCL_NIS_NUM'],
                [timeframe + '_CNCL_NIS_DEN']: current[timeframe + '_CNCL_NIS_DEN'],
                [timeframe + '_UNFULFILLED_TOTAL']: current[timeframe + '_UNFULFILLED_TOTAL']
              });
            }
            return acc;
          }, [])
          .map(data => [
            {
              stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
              link: {
                to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
                params: {
                  [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
                }
              }
            },
            {
              pctValue: data[timeframe + '_FULFILL_NUM'] / (data[timeframe + '_FULFILL_DEN']),
              decimals: 2,
            },
            {
              numValue: formatNumberWithCommas(data[timeframe + '_CNCL_NIS_DEN']),
              orderBy: Number(data[timeframe + '_CNCL_NIS_DEN'])
            },
            { numValue: formatNumberWithCommas(data[timeframe + '_CNCL_NIS_NUM']),
              orderBy: Number(data[timeframe + '_CNCL_NIS_NUM'])
             },
            {
              dollarValue: data[timeframe + '_UNFULFILLED_TOTAL'],
              orderBy: data[timeframe + '_UNFULFILLED_TOTAL']
            }
          ]),
      };
    case WILL_CALL:
      return {
        sortable: true,
        sortableDefault: 0,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Fulfillment %' },
          { name: 'Units Unfulfilled' }
        ],
        data: willCallData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
              }
            }
          },
          {
            pctValue: data[timeframe + '_FULFILL_NUM'] / (data[timeframe + '_FULFILL_DEN']),
            decimals: 2,
          },
          {
            numValue: formatNumberWithCommas(data[timeframe + '_CNCL_NIS_DEN']),
            orderBy: Number(data[timeframe + '_CNCL_NIS_DEN'])
          }
        ])
      };
    case BOPIS:
      return {
        sortable: true,
        sortableDefault: 0,
        headers: [
          { name: capitalizeFirstLetter(METRIC_CONSTANTS[level]['urlLinkLevel']) + ' # ' },
          { name: 'Fulfillment %' },
          { name: 'Units Unfulfilled' },
          { name: 'Not-in-Stock Units' },
          { name: '$ Unfulfilled' }
        ],
        data: bopisData.map(data => [
          {
            stringValue: data[METRIC_CONSTANTS[level]['propToRender']],
            link: {
              to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/orderfulfillment`,
              params: {
                [METRIC_CONSTANTS[level]['urlSubLevelParam']]: data[METRIC_CONSTANTS[level]['propToRender']]
              }
            }
          },
          {
            pctValue: data[timeframe + '_FULFILL_NUM'] / (data[timeframe + '_FULFILL_DEN']),
            decimals: 2,
          },
          {
            numValue: formatNumberWithCommas(data[timeframe + '_CNCL_NIS_DEN']),
            orderBy: Number(data[timeframe + '_CNCL_NIS_DEN'])
          },
          { numValue: formatNumberWithCommas(data[timeframe + '_CNCL_NIS_NUM']),
            orderBy: Number(data[timeframe + '_CNCL_NIS_NUM'])
           },
          {
            dollarValue: data[timeframe + '_UNFULFILLED_TOTAL'],
            orderBy: data[timeframe + '_UNFULFILLED_TOTAL']
          }
        ])
      };
    default:
      return {
        headers: [],
        data: []
      };
  }
};

export const selectMethodOptions = completePickUpSelectMethodOptions.map(completePickUpSelectMethodOption => ({
  key: completePickUpSelectMethodOption,
  text: completePickUpSelectMethodOption,
  value: completePickUpSelectMethodOption
}));
