import React from 'react'
import {
  Tab,
} from 'semantic-ui-react'
import { CompTab } from './CompTab'
import { SalesTab } from './SalesTab'
import { AisleBayTab } from './AisleBayTab'
import { InventoryTab } from './InventoryTab'
import { useLocation } from 'react-router-dom'

export const OverviewTabs = (({ timeframe, fpToggle, strNm }) => {

  const location = useLocation();
  const aisleBayView = <AisleBayTab timeframe={timeframe} fpToggle={fpToggle} strNm={strNm} location={location} />;

  const salesView = <SalesTab
                      timeframe={timeframe} 
                      strNm={strNm}
                      fpToggle={fpToggle} 
                      location={location}
                      />

  const compView = <CompTab 
                      timeframe={timeframe}
                      fpToggle={fpToggle}
                      location={location} />

  const inventoryView = <InventoryTab fpToggle={fpToggle} location={location} />;


  const tabs = [
    {
      menuItem: 'Sales',
      render: () => salesView
    },

    {
      menuItem: 'Comp',
      render: () => compView,
    },

    {
      menuItem: 'Aisle-Bay',
      render: () => aisleBayView,
    },

    {
      menuItem: 'Inventory',
      render: () => inventoryView,
    }
  ]

  return <Tab panes={tabs} />
})