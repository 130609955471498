import { useContext, useState } from 'react';
import { useEnhancedQueries } from '../../../../../../hooks/useEnhancedQueries';
import { StoreOrderFulfillmentContext } from '../../../../context/StoreOrderFulfillmentContext';
import { getTimeframeOptions } from '../../../../../../utils/timeFrameUtils';
import { DataTable } from '../../../../../../components/Common/DataTable';
import { CustomDropDown } from '../CustomDropdown';
import { Header } from 'semantic-ui-react';
import { PlaceholderLoading } from '../../../../../../components/Loading/PlaceholderLoading';

export default function OnTimeDeliveryMissed() {
  const timeframeOptions = getTimeframeOptions(4);
  const [timeframe, settimeframe] = useState(timeframeOptions[0].value);
  const { fpToggle } = useContext(StoreOrderFulfillmentContext);
  const {
    isError,
    isLoading,
    results: [{ data: storeOnTimeDlvMissed = [] }]
  } = useEnhancedQueries(['queryOnTimeDlvMissed'], fpToggle)

  if (isError) return <Header>Something went wrong</Header>;
  if (isLoading) return <PlaceholderLoading />

  const filterByTimeframe = storeOnTimeDlvMissed.filter(row => row[timeframe + '_FLG'] > 0);

  const dataObj = {
    headers: [
      { name: 'Truck Type' },
      { name: 'Customer Order #' },
      { name: 'Work Order #' },
      { name: 'Delivery Miss Bucket' },
      { name: 'Miss Code' },
      { name: 'Miss Date' }
    ],
    data: filterByTimeframe.map(data => [
      { stringValue: data.TRUCK_TYPE },
      { numValue: data.CUST_ORD_NBR },
      { numValue: data.WORK_ORDER_NO, orderBy: data.WORK_ORDER_NO },
      { stringValue: data.MISS_BUCKET },
      { stringValue: data.REASON },
      { stringValue: data.FULFILL_DATE }
    ]),
    pagination: true,
    sortable: true
  };

  return (
    <>
      <CustomDropDown
        view='ShowDropDown'
        extTimeframe={timeframe}
        setExtTimeframe={settimeframe}
        timeframeOptions={timeframeOptions}
        showCustomTimeframe={['ShowDropDown']}
      />
      <DataTable data={dataObj} />
    </>
  );
}
