import { CreditUsage } from './CreditUsage';
import { CreditEfficiency } from './CreditEfficiency';
import { CreditApplications } from './CreditApplications';
import { Title } from '../../../Common/Title';
import { useCreditContext } from '../hooks/useCreditContext';
import { TimeframeDropdown } from '../../../Common/TimeframeDropdown';
import { useEnhancedQueries } from '../../../../hooks/useEnhancedQueries';
import { ChannelAssociate } from '../../ChannelAssociate';
import { Header, Loader, Grid } from 'semantic-ui-react';

export const CreditMainTab = () => {
  const { settimeframe, timeframe, fpToggle } = useCreditContext();
  const {
    isLoading,
    isError,
    results: [{ data: creditApps }, { data: creditUsage }]
  } = useEnhancedQueries(['creditApplications', 'creditUsage', 'creditChannelByLvl'], fpToggle);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <Header>Something went wrong</Header>;

  return (
    <>
      <Title text='Credit' data={creditApps} fpToggle={fpToggle} />
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign='left'>
            <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CreditApplications data={creditApps} timeframe={timeframe} />
      <p>Note: If % vGoal ≥ 0%, then the goal is met.</p>
      <CreditEfficiency data={creditApps} timeframe={timeframe} />
      <CreditUsage data={creditUsage} timeframe={timeframe} />
      <ChannelAssociate />
    </>
  );
};
