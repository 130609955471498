import { useState, useContext } from 'react';
import { StoreOrderFulfillmentContext } from '../../../../../context/StoreOrderFulfillmentContext';
import { DataTable } from '../../../../../../../components/Common/DataTable';
import { formatAssociateName } from '../../../../../utils/utils';
import { CustomDropDown } from '../../CustomDropdown';
import { formatNumberWithCommas } from '../../../../../../../utils/formatter';
import { getTimeframeOptions } from '../../../../../../../utils/timeFrameUtils';

export const Boss = ({ props: { associate, nonCompliance, missedSLA } }) => {
  const { timeframe} = useContext(StoreOrderFulfillmentContext);
  const [view, setView] = useState('Associate View');
  const [localtimeframe, settimeframe] = useState('WTD');
  const showCustomTimeframe = ['# Missed SLA', 'Non-Compliant Orders'];
  const nonComplianceFiltered = nonCompliance.filter(row => row[localtimeframe + '_FLG'] > 0)
  const missedSLAFiltered = missedSLA.filter(row => row[localtimeframe + '_FLG'] > 0)

  const getData = () => {
    switch (view) {
      case 'Associate View':
        return {
          headers: [
            { name: 'Associate' },
            { name: 'Pick On Time %' },
            { name: '# Orders Missed' },
            { name: 'Avg Mins to Pick' },
            { name: 'DS Compliance %' }
          ],
          data: associate
          .filter(data => 
            data[`${timeframe}_ON_TIME_DEN`] > 0 ||
            data[`${timeframe}_MISS_SLA_CNT`] > 0 ||
            data[`${timeframe}_AVG_MINS_TO_PICK`] > 0 ||
            data[`${timeframe}_DIRECTED_ORD_CT`] > 0
          )
          .map(data => {
            return [
            { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
            { pctValue: data[`${timeframe}_ON_TIME_DEN`] ? data[`${timeframe}_ON_TIME_NUM`] / data[`${timeframe}_ON_TIME_DEN`] : '--' },
            { numValue: formatNumberWithCommas(data[`${timeframe}_MISS_SLA_CNT`]), orderBy: +data[`${timeframe}_MISS_SLA_CNT`] },
            { numValue: formatNumberWithCommas(data[`${timeframe}_AVG_MINS_TO_PICK`]), orderBy: +data[`${timeframe}_AVG_MINS_TO_PICK`] },
            { pctValue: data[`${timeframe}_DIRECTED_ORD_CT`] ? data[`${timeframe}_STAGED_DIRECTED_ORD_CT`] / data[`${timeframe}_DIRECTED_ORD_CT`] : '--',
              orderBy: data[`${timeframe}_STAGED_DIRECTED_ORD_CT`] / data[`${timeframe}_DIRECTED_ORD_CT`]
             }
          ]}),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case '# Missed SLA':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'Order #' }, { name: 'Order Date' }, { name: 'Mins To Pick' }],
          data: missedSLAFiltered.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
            { numValue: data.ORDER_NUMBER },
            { numValue: String(data.ORDER_DATE).split('T')[0] },
            { numValue: formatNumberWithCommas(data.MINS_TO_PICK) },
          ]),
          pagination: true,
          sortable: true,
          defaultSortable: 0
        };
      case 'Non-Compliant Orders':
        return {
          headers: [
            { name: 'Associate Name' },
            { name: 'Order #' },
            { name: 'Order Date' },
            { name: 'Directed Location' },
            { name: 'Staged Location' }
          ],
          data: nonComplianceFiltered.map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
            { numValue: data.EXTN_HOST_ORDER_REF },
            { numValue: data.CAL_DT },
            { numValue: data.DIRECTED_LOCATION },
            { numValue: data.STAGED_LOCATION },
          ]),
          sortable: true,
        };
      case 'DS Compliance':
        return {
          headers: [{ name: 'Associate Name' }, { name: 'DS Compliance %' }, { name: '# Non-Compliance Orders' }],
          data: associate
          .filter(data => data[`${timeframe}_DIRECTED_ORD_CT`] > 0)
          .map(data => [
            { stringValue: formatAssociateName(data.ASSOC_NM) || data.LDAP },
            { pctValue: data[timeframe + '_STAGED_DIRECTED_ORD_CT'] / data[timeframe + '_DIRECTED_ORD_CT'] },
            { numValue: data[timeframe + '_DIRECTED_ORD_CT'] - data[timeframe + '_STAGED_DIRECTED_ORD_CT'] }
          ]),
          sortable: true,
        };
      default:
        return {
          headers: [],
          data: []
        };
    }
  };

  return (
    <>
      <CustomDropDown
        showCustomTimeframe={showCustomTimeframe}
        view={view}
        setView={setView}
        extTimeframe={localtimeframe}
        setExtTimeframe={settimeframe}
        viewOptions={[
          { key: 'Associate View', text: 'Associate View', value: 'Associate View' },
          { key: '# Missed SLA', text: '# Missed SLA', value: '# Missed SLA' },
          { key: 'Non-Compliant Orders', text: 'Non-Compliant Orders', value: 'Non-Compliant Orders' },
          { key: 'DS Compliance', text: 'DS Compliance', value: 'DS Compliance' }
        ]}
        timeframeOptions={getTimeframeOptions(4)}
      />
      <DataTable data={getData()} />
    </>
  );
};
