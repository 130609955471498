import { buildSublevelLinkColumn } from '../../../utils/dataTable';
import { DataView } from '../../Common/DataView';
import { getThousandDollarString } from '../../Common/TableCellUtils';

export const SmdDetailRegionListTab = ({ data, timeframe }) => {
  const dataObj = {
    data: data.map(row => [
      {
        ...buildSublevelLinkColumn({ level: 'division', row, to: '/smd' })
      },
      {
        dollarValue: getThousandDollarString(row[timeframe + '_SMD_AMT'], 1),
        orderBy: row[timeframe + '_SMD_AMT']
      },
      {
        decimals: 2,
        pctValue: row[timeframe + '_SMD_ACT']
      },
      {
        compareStyle: true,
        compareStyleInvert: true,
        postfix: ' bps',
        orderBy: row[timeframe + '_SMD_BPS_VP'],
        stringValue: row[timeframe + '_SMD_BPS_VP']
          ? row[timeframe + '_SMD_BPS_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 })
          : '-'
      },
      {
        compareStyle: true,
        compareStyleInvert: true,
        orderBy: row[timeframe + '_SMD_OVER_UNDER'],
        stringValue: getThousandDollarString(row[timeframe + '_SMD_OVER_UNDER'], 1)
      }
    ]),
    headers: [
      { name: 'Org #' },
      { name: 'SMD $' },
      { name: 'SMD %' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' }
    ],
    sortable: true,
    sortableDefault: 0
  };

  return (
    <DataView className='mt-3' testId='data-view-smd-detail-region-comps'>
      <DataView.Title>SMD - Regions Comps</DataView.Title>

      <DataView.DataTable data={dataObj} />
    </DataView>
  );
};
