import { Container, Header, Segment, Grid, Image } from 'semantic-ui-react';
import { useUserContext } from '../../hooks/useUserContext';

export const DeniedMenu = () => {
  const { loginCheckMessage } = useUserContext();
  let message = 'You do not have permission to use this application.';

  if (loginCheckMessage?.includes('Missing required permissions')) {
    // When the Ping detects that the user is missing the required ARP group, they get the above message.
    // So we want to catch that and replace it with our own, clearer message
    message = (
      <>
        <p>Access for all non-store/corporate users should be requested through MyTHDAccess.</p>
        <p>Corporate users need to request the following group:</p>
        <p>gg_pr_storepulse_authorize_user_access_adhoc</p>
        <br />
        <p>
          Store users need to reach out to HelpDesk to get CyberSecurity Provisioning L2 engaged in order to get assigned to
          the Store LDAP group:
        </p>
        <p>gg_pr_storepulse_authorize_store_user_access</p>
      </>
    );
  }

  return (
    <Container>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <Image src='/hd_logo.jpg' size='mini' />
            </Grid.Column>
            <Grid.Column textAlign='center' width={10}>
              <Header textAlign='center'>Store Pulse</Header>
            </Grid.Column>
            <Grid.Column width={3}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='center'>{message}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};
