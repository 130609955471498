import { formatAssociateName } from "../../../utils/utils";

export const ASSOCIATE_VIEW = 'Associate View';
export const CANCELLATIONS = 'Cancellations';

export const ASSOCIATE_DETAILS_HEADERS = [{ name: 'Associate Name' }, { name: 'Units Unfulfilled' }, { name: 'Not-in-Stock Units' }, { name: '$ Unfulfilled' }]
export const ASSOCIATE_DETAILS_WILL_CALL_HEADERS = [{ name: 'Associate Name' }, { name: 'Units Unfulfilled' }, { name: '$ Unfulfilled' }]

export const buildAssociateDetailsRows = (data, timeframe) => {
  const associatesToShow = limitAssociateWithUnfulfilledUnits(data, timeframe)

  return associatesToShow.map(associate => {
    const { associateName, dollarUnfulfilled, nisUnits, unitsUnfulfilled } = extractAssociateData(associate, timeframe)

    return [
      { stringValue: formatAssociateName(associateName) },
      {
        numValue: unitsUnfulfilled || '-',
        orderBy: unitsUnfulfilled
      },
      {
        numValue: nisUnits,
        orderBy: nisUnits
      },
      {
        decimals: 0,
        dollarValue: dollarUnfulfilled || '-',
        orderBy: dollarUnfulfilled
      }
    ]
  })
}

export const buildWillAssociateDetailsRows = (data, timeframe) => {
  const associatesToShow = limitAssociateWithUnfulfilledUnits(data, timeframe)

  return associatesToShow.map(associate => {
    const { associateName, dollarUnfulfilled, unitsUnfulfilled } = extractAssociateData(associate, timeframe)

    return [
      { stringValue: formatAssociateName(associateName) },
      {
        numValue: unitsUnfulfilled || '-',
        orderBy: unitsUnfulfilled || 0,
        decimals: 0
      },
      {
        decimals: 0,
        dollarValue: dollarUnfulfilled || '-',
        orderBy: dollarUnfulfilled || 0
      },
    ]
  })
}

export const getBopisChannels = data => data.filter(row => row.CHANNEL === 'BOPIS');

export const getWillCallChannels = data => data.filter(row => row.CHANNEL === 'WILL CALL');

const extractAssociateData = (data, timeframe) => {
  return {
    associateName: formatAssociateName(data.ASSOC_NM) || data.LDAP,
    dollarUnfulfilled: data[`${timeframe}_CANCELED_SALES`],
    nisUnits: data[`${timeframe}_NIIS_UNITS`],
    unitsUnfulfilled: data[`${timeframe}_CANCELED_QTY`],
  }
}

const limitAssociateWithUnfulfilledUnits = (data, timeframe) => data.filter(associate => {
  const { unitsUnfulfilled } = extractAssociateData(associate, timeframe)
  return unitsUnfulfilled > 0
})
