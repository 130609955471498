import { CORE } from '../../../utils/constantsByLevel';
import { formatLevelNumber } from '../../../utils/level';
import { capitalizeFirstLetter } from '../../../utils/string';

const { levelName: coreLevelName } = CORE;

const CULL_MD_NUM = 'CULL_MD_NUM';
const CULL_MD_DEN = 'CULL_MD_DEN';
const LY_CULL_MD_NUM = 'LY_CULL_MD_NUM';
const LY_CULL_MD_DEN = 'LY_CULL_MD_DEN';
const DAMAGED_MD_NUM = 'DAMAGED_MD_NUM';
const CORE_SLS = 'CORE_SLS';
const LY_DAMAGED_MD_NUM = 'LY_DAMAGED_MD_NUM';
const LY_CORE_SLS = 'LY_CORE_SLS';

export const getBreadcrumbItems = params => {
  const { level, locNbr } = params;
  const { levelName, urlParam } = level;
  const baseUrl = `/${levelName}/shrink`;
  const urlWithParams = `${baseUrl}?${urlParam}=${locNbr}`;

  const to = levelName === coreLevelName ? baseUrl : urlWithParams;
  const text = 'Shrink';

  return [{ to, text }, { text: 'DamagedCull Markdowns' }];
};

export const getTitle = params => {
  const { level, locNbr, currentLevelName } = params;
  const { levelName } = level;
  const baseTitle = 'Damaged / Cull Markdowns';
  const locNbrFormated = formatLevelNumber(locNbr);

  const title = `${baseTitle} - ${capitalizeFirstLetter(levelName)}`;
  const titleSufix = locNbr ? `${locNbrFormated}, ${currentLevelName}` : '';

  return `${title} ${titleSufix}`;
};

// Summary Table

const getSummaryTableHeaders = () => [{ name: 'Metric' }, { name: 'Value%' }, { name: 'vLY%' }];

const getSummaryCullMarkdownsRow = params => {
  const { timeframe, row } = params;

  const cullMdNum = row[`${timeframe}_${CULL_MD_NUM}`];
  const cullMdDen = row[`${timeframe}_${CULL_MD_DEN}`];
  const lyCullMdNum = row[`${timeframe}_${LY_CULL_MD_NUM}`];
  const lyCullMdDen = row[`${timeframe}_${LY_CULL_MD_DEN}`];

  const valuePct = cullMdNum / cullMdDen;
  const vlyPct = cullMdNum / cullMdDen - lyCullMdNum / lyCullMdDen;

  return [{ stringValue: 'Cull Markdowns' }, { pctValue: valuePct, decimals: 2 }, { pctValue: vlyPct, decimals: 2 }];
};

const getSummaryDamagedMarkdownsRow = params => {
  const { timeframe, row } = params;

  const damagedMdNum = row[`${timeframe}_${DAMAGED_MD_NUM}`];
  const coreSls = row[`${timeframe}_${CORE_SLS}`];
  const lyDamagedMdNum = row[`${timeframe}_${LY_DAMAGED_MD_NUM}`];
  const lyCoreSls = row[`${timeframe}_${LY_CORE_SLS}`];

  const valuePct = damagedMdNum / coreSls;
  const vlyPct = damagedMdNum / coreSls - lyDamagedMdNum / lyCoreSls;

  return [{ stringValue: 'Damaged Markdowns' }, { pctValue: valuePct, decimals: 2 }, { pctValue: vlyPct, decimals: 2 }];
};

const getSummaryTableData = params => {
  const { timeframe, summaryData } = params;
  const [row] = summaryData;

  return [getSummaryCullMarkdownsRow({ timeframe, row }), getSummaryDamagedMarkdownsRow({ timeframe, row })];
};

export const getSummaryDataTable = params => {
  const { timeframe, summaryData } = params;

  return {
    headers: getSummaryTableHeaders(),
    data: getSummaryTableData({ timeframe, summaryData }),
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending'
  };
};
