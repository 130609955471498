import React, { useState, useEffect } from 'react'
import { Header, Grid, Table, Loader, Menu, Modal, Segment, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from '../../service/DataService'
import { getDollarString, getPercentString } from '../Common/TableCellUtils'
import { TimeframeDropdownShrink } from '../Common/TimeframeDropdownShrink'

export const ShrinkOhEmptPkg = (({ timeframe, settimeframe, fpToggle, location }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const sortColumnDept = 'DEPT_NBR';
  const sortDirectionDept = 'ascending';
  const sortColumn = timeframe + "_OH_EMPT_PKG"
  let sortDirection = "descending"
  let skuSortColumn = "RNK"
  let skuSortDirection = 'ascending'
  const [deptCls, setDeptCls] = useState('dept')

  const [pageNum, setPageNum] = useState(1)
  let rowsPerPage = 10

  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState([])

  const shrinkOhEmptPkgDeptByStrQuery = useQuery(['queryShrinkOhEmptPkgDeptByStr', { strNbr, fpToggle }], getData)
  const shrinkOhEmptPkgClsByStrQuery = useQuery(['queryShrinkOhEmptPkgClsByStr', { strNbr, fpToggle }], getData)
  const shrinkOhEmptPkgTopSkusByStrQuery = useQuery(['queryShrinkOhEmptPkgTopSkusByStr', { strNbr, fpToggle }], getData)



  useEffect(() => {
    setPageNum(1)
  }, [sortColumn, sortDirection, rowsPerPage])

  if (shrinkOhEmptPkgDeptByStrQuery.isLoading || shrinkOhEmptPkgClsByStrQuery.isLoading || shrinkOhEmptPkgTopSkusByStrQuery.isLoading) {
    return (<Loader active>Loading...</Loader>)
  }

  if (!shrinkOhEmptPkgClsByStrQuery.data) {
    return (<Header textAlign='center'>Invalid Store Number</Header>)
  }

  const deptData = shrinkOhEmptPkgDeptByStrQuery.data
  const classData = shrinkOhEmptPkgClsByStrQuery.data
  const skuData = shrinkOhEmptPkgTopSkusByStrQuery.data

  let deptDataFiltered = deptData.filter(row => {
    return row && [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 59].includes(row['DEPT_NBR'])
  })

  let deptDataSorted = deptDataFiltered.sort((left, right) => {
    let result = left[sortColumnDept] - right[sortColumnDept]
    if (sortDirectionDept === 'descending') {
      result *= -1
    }
    return result
  })

  let classDataSorted = classData.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn]
    if (sortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let skuDataFiltered = skuData.filter((row) => {
    return row && row['TIMEFRAME'] === timeframe
  })

  let skuDataSorted = skuDataFiltered.sort((left, right) => {
    let result = left[skuSortColumn] - right[skuSortColumn]
    if (skuSortDirection === 'descending') {
      result *= -1
    }
    return result
  })

  let classDataPagination = classDataSorted.slice(rowsPerPage * (pageNum - 1), parseInt(Math.min(rowsPerPage * pageNum, classDataSorted.length)))

  return (
    <>
      <Grid>
        <Grid.Column width={8} textAlign='left'>
          <Grid.Row>
            <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/shrink?strNbr=${strNbr}`} data-test='b2o-link'>
              Shrink
            </Link> / On Hand Adjustments
          </Grid.Row>
        </Grid.Column>
        <Grid.Column textAlign='justified' width={8}>
          <TimeframeDropdownShrink timeframe={timeframe} settimeframe={settimeframe} />
        </Grid.Column>
      </Grid>
      <Header textAlign='center'>On Hand Adjustments & Empty Packages - Store {strNbr}</Header>
      <Header textAlign='center'>Top SKUs</Header>
      <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SKU #</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>SKU Description</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg $</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Empty Pkg Retail Amt</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Net OHA Retail Amt</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {skuDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell
                  style={{ color: '#EE7125', textDecoration: 'underline' }}
                  onClick={() => {
                    setOpen(true)
                    setModalData(data)
                  }}>
                  {data['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    data['SKU_NBR'].toString().substring(0, 4) + '-' + data['SKU_NBR'].toString().substring(4, 7) + '-' + data['SKU_NBR'].toString().substring(7) :
                    data['SKU_NBR'].toString().substring(0, 3) + '-' + data['SKU_NBR'].toString().substring(3)}
                </Table.Cell>
                <Table.Cell>{data['SKU_DESC']}</Table.Cell>
                <Table.Cell>{getDollarString(data['OH_EMPT_PKG'])}</Table.Cell>
                <Table.Cell>{data['SLS_AMT'] !== 0 ? getPercentString(data['OH_EMPT_PKG'] / data['SLS_AMT'], 2) : '--'}</Table.Cell>
                <Table.Cell>{getDollarString(data['EMP_PKG_RETL_AMT'])}</Table.Cell>
                <Table.Cell>{getDollarString(data['NET_OH_RETL_AMT'])}</Table.Cell>

              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <Menu compact size='small'>
        <Menu.Item
          name='dept'
          active={deptCls === 'dept'}
          content='Departments'
          onClick={() => {
            setDeptCls('dept')
          }}
        />
        <Menu.Item
          name='cls'
          active={deptCls === 'cls'}
          content='Top Classes'
          onClick={() => {
            setDeptCls('cls')
          }}
        />
      </Menu>
      {deptCls === 'dept' && <Header textAlign='center'>Department Overview</Header>}
      {deptCls === 'dept' && <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Dept #</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg $</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Empty Pkg Retail Amt</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Net OHA Retail Amt</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {deptDataSorted.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/store/shrink/ohemptpkg/dept?strNbr=${strNbr}&deptNbr=${data['DEPT_NBR']}`} >
                    {data['DEPT_NBR'] + '-' + data['DEPT_NM']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_OH_EMPT_PKG'])}</Table.Cell>
                <Table.Cell>{data[timeframe + '_SLS_AMT'] !== 0 ? getPercentString(data[timeframe + '_OH_EMPT_PKG'] / data[timeframe + '_SLS_AMT'], 2) : '--'}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_EMP_PKG_RETL_AMT'])}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_NET_OH_RETL_AMT'])}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}
      {deptCls === 'cls' && <Header textAlign='center'>Top Classes</Header>}
      {deptCls === 'cls' && <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Class #</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Class Description</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg $</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>OHA + Empty Pkg %</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Empty Pkg Retail Amt</Table.HeaderCell>
            <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: 'break-word' }}>Net OHA Retail Amt</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {classDataPagination.map((data) => {
            return (
              <Table.Row>
                <Table.Cell>{data['EXT_CLASS_NBR']}</Table.Cell>
                <Table.Cell>{data['CLASS_DESC']}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_OH_EMPT_PKG'])}</Table.Cell>
                <Table.Cell>{data[timeframe + '_SLS_AMT'] !== 0 ? getPercentString(data[timeframe + '_OH_EMPT_PKG'] / data[timeframe + '_SLS_AMT'], 2) : '--'}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_EMP_PKG_RETL_AMT'])}</Table.Cell>
                <Table.Cell>{getDollarString(data[timeframe + '_NET_OH_RETL_AMT'])}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>}
      {open && <Modal
        closeIcon
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <Segment color='orange'>
          <Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Header textAlign='center'>
                  {modalData['SKU_NBR'].toString().length > 6 ? // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                    modalData['SKU_NBR'].toString().substring(0, 4) + '-' + modalData['SKU_NBR'].toString().substring(4, 7) + '-' + modalData['SKU_NBR'].toString().substring(7) :
                    modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}
                </Header>
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Class</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_CLASS_NBR'] + '-' + modalData['CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>Subclass</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['EXT_SUB_CLASS_NBR'] + '-' + modalData['SUB_CLASS_DESC']}</p></Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row><Header>SKU Description</Header></Grid.Row>
              <Grid.Row><p textAlign='center'>{modalData['SKU_DESC']}</p></Grid.Row>
            </Grid.Column>
            &nbsp;
            &nbsp;

            <Grid.Column>
              <Grid.Row>&nbsp;</Grid.Row>
              <Grid.Row>
                <Button
                  onClick={() => { setOpen(false) }}
                  style={{ color: 'white', backgroundColor: '#EE7125' }}
                  content='Close'
                />
              </Grid.Row>
              <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Modal>}
    </>
  )
})