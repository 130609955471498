import React, { useState } from 'react'
import { Header, Loader, Grid, Menu, Segment } from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { ACCESS_LEVELS } from '../Common/AccessChecks'

import { getCompareStyle } from '../Common/TableCellUtils'
import { useQueryParams } from '../../hooks/useQueryParams'
import { DataView } from '../Common/DataView'
import { columnName, METRIC_CONSTANTS } from '../../utils/constantsByLevel'
import { buildSublevelLinkColumn } from '../../utils/dataTable'
import { useEnhancedQueries } from '../../hooks/useEnhancedQueries'

export const SafetyDetailDst = (({ timeframe, settimeframe, fpToggle, userMgtLvlId, userIsCorporate, levelName }) => {
  const { level } = useParams()
  const { dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams()
  const {
    isError,
    isLoading,
    results: [{ data: safetyLevelData = [] }, { data: safetySubLevelData = [] }]
  } = useEnhancedQueries(['querySafetyIncOshaLtGlbi', 'querySafetyIncOshaLtGlbiLevelPlusOne'], fpToggle)
  const [incOshaLt, setIncOshaLt] = useState('_INCIDENT_')
  const locNbr = dstNbr || rgnNbr || divNbr

  if (isError) return <Header>Something went wrong</Header>
  if (isLoading) return <Loader active>Loading...</Loader>

  const daySafesData = [{
    ...safetyLevelData[0],
    [METRIC_CONSTANTS[level]['FILTER_BY_METRIC'] + '_NBR']: locNbr,
    DAYS_SAFE: safetyLevelData[0]?.DAYS_SAFE,
  }, ...safetySubLevelData]

  const daysSafeDataObj = {
    headers: [
      { name: 'Org # ' },
      { name: 'Day Safe ' }
    ],
    sortable: true,
    sortableDefault: 0,
    data: daySafesData.map((row, index) => [
      {
        ...buildSublevelLinkColumn({ level, row, to: `/safety` })
      },
      {
        stringValue: row['DAYS_SAFE']
      }
    ])

  }

  const safetySummaryDataObj = {
    headers: [
      { name: 'Org # ' },
      { name: 'Actual ' },
      { name: 'vP ' },
      { name: 'vLY ' }
    ],
    sortable: true,
    sortableDefault: 0,
    data: daySafesData.map((row, index) => [
      {
        ...buildSublevelLinkColumn({ level, row, to: `/safety` })
      },
      {
        numValue: row[timeframe + incOshaLt + 'COUNT_WC_TOTAL']
      },
      {
        stringValue: incOshaLt === '_INCIDENT_' ? '-' : undefined,
        pctValue: incOshaLt === '_OSHA_' ?
          (row[timeframe + '_OSHA_COUNT_WC_TOTAL'] - row[timeframe + '_WC_COUNTS_PLAN']) / row[timeframe + '_WC_COUNTS_PLAN'] :
          (row[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - row[timeframe + '_LT_COUNTS_PLAN']) / row[timeframe + '_LT_COUNTS_PLAN'],
        customStyle: incOshaLt === '_INCIDENT_' ? undefined : incOshaLt === '_OSHA_' ?
          getCompareStyle(row[timeframe + '_WC_COUNTS_PLAN'] - row[timeframe + '_OSHA_COUNT_WC_TOTAL']) :
          getCompareStyle(row[timeframe + '_LT_COUNTS_PLAN'] - row[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'])
      },
      {
        pctValue: (row[timeframe + incOshaLt + 'COUNT_WC_TOTAL'] - row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']) / row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'],
        customStyle: getCompareStyle(row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'] - row[timeframe + incOshaLt + 'COUNT_WC_TOTAL'])
      }
    ])
  }

  const menuOptions = [{ name: 'INCIDENT', value: '_INCIDENT_' }, { name: 'OSHA', value: '_OSHA_' }, { name: 'LOST TIME', value: '_LOST_TIME_' }]
  return (
    (userMgtLvlId < ACCESS_LEVELS.DEPT_SUP || userIsCorporate) ?
      <>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`}>Back to List</Link>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} />
        </Grid>
        <Header textAlign='center' style={{ textTransform: 'capitalize' }}>Safety - {level} - {locNbr}, {levelName || daySafesData[0][columnName[level]]}</Header>
        <div style={{ display: 'flex', justifyContent: 'center' }}>Data as of {safetyLevelData[0]?.TRNSFM_DT}</div>
        <Header textAlign='center'>Days Safe: {safetyLevelData[0]?.DAYS_SAFE}</Header>
        <Segment>
          <DataView testId={'daysSafe' + level}>
            <DataView.Title position='center'>Days Safe</DataView.Title>
            <DataView.DataTable data={daysSafeDataObj} />
          </DataView>
        </Segment>
        <Grid>
          <Grid.Column width={8} />
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>
        <Segment>
          <DataView testId={'safetySumary' + level} className={''}>
            <DataView.Title position='center'>Safety Summary</DataView.Title>
            <Menu fluid attached='top' widths={3} color='orange'>
              {menuOptions.map(({ name, value }) => (
                <Menu.Item
                  key={value}
                  name={value}
                  active={incOshaLt === value}
                  content={name}
                  onClick={() => {
                    setIncOshaLt(value)
                  }}
                />
              ))}
            </Menu>
            <DataView.DataTable data={safetySummaryDataObj} />
          </DataView>
        </Segment>
      </> :
      <>
        <Header textAlign='center'>User does not have permission to access Safety Detail page. Please return to Overview.</Header>
      </>

  )

})