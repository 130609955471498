import { Button } from 'semantic-ui-react';

export const ModalActions = ({ isError, isLoading, isSuccess, onCancel, onConfirm }) => {
  if (isSuccess) return <Button onClick={onCancel}>Close</Button>;

  return (
    <>
      <Button loading={isLoading} onClick={onCancel}>
        Cancel
      </Button>
      <Button loading={isLoading} onClick={onConfirm} negative>
        {isError ? 'Try again' : "Yes, I'm sure"}
      </Button>
    </>
  );
};
