import React from 'react';
import { Header } from 'semantic-ui-react';
import { DataTable } from '../Common/DataTable';
import { getPercentString, getThousandDollarString } from '../Common/TableCellUtils';

const getCustomColorStyle = (value = 0) => (value < 0 ? 'red' : 'black');

const getPlannedComp = (SLS_PLN_AMT, LY_COMP_SLS_AMT) => {
  if (SLS_PLN_AMT == null || LY_COMP_SLS_AMT == null) return '-';
  return getPercentString(SLS_PLN_AMT / LY_COMP_SLS_AMT - 1, 1, 1);
};

export const CurrentDaySales = ({ level, locNbr, currentLevelName, latestHourData }) => {
  const compPlanVar = level === 'core' || level === 'division' ? 'SLS_PLN_COMP_AMT' : 'SLS_PLN_AMT';

  const currentDaySalesObj = {
    headers: [
      { name: 'Date' },
      { name: 'Sales $ (k)' },
      { name: '$ vPlan (k)' },
      { name: '% to Daily Plan' },
      { name: 'Comp %' },
      { name: 'Planned Comp %' }
    ],
    data: [
      [
        { stringValue: latestHourData['LOCAL_DATE'] },
        {
          customStyle: { color: getCustomColorStyle(latestHourData['CUMMU_SLS']) },
          stringValue: getThousandDollarString(latestHourData['CUMMU_SLS'], 1)
        },
        {
          customStyle: { color: getCustomColorStyle(latestHourData['DOL_TO_DAILY_PLN']) },
          stringValue: getThousandDollarString(latestHourData['DOL_TO_DAILY_PLN'], 1)
        },
        {
          customStyle: { color: getCustomColorStyle(latestHourData['PCT_TO_DAILY_PLN']) },
          stringValue: getPercentString(latestHourData['PCT_TO_DAILY_PLN'], 1, 1)
        },
        {
          customStyle: { color: getCustomColorStyle(latestHourData['CUMMU_COMP_PCT']) },
          stringValue: getPercentString(latestHourData['CUMMU_COMP_PCT'], 1, 1)
        },
        {
          customStyle: { color: getCustomColorStyle(latestHourData[compPlanVar] - latestHourData['LY_COMP_SLS_AMT']) },
          stringValue: getPlannedComp(latestHourData[compPlanVar], latestHourData['LY_COMP_SLS_AMT'])
        }
      ]
    ]
  };

  const levelName = level === 'core' ? 'Core' : `${level} ${locNbr}, ${currentLevelName}`;

  return (
    <>
      <Header className='text-capitalize' textAlign='center'>
        Current Day Sales - {levelName}
      </Header>
      <DataTable data={currentDaySalesObj} data-testid='current-day-sales' />
    </>
  );
};
