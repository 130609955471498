import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Modal } from './ClearAllCacheModal/Modal';

export const ClearAllCacheButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = () => setIsModalOpen(false);

  return (
    <div>
      <Button onClick={() => setIsModalOpen(true)} negative>
        Clear All Redis Cache
      </Button>

      <Modal isOpen={isModalOpen} onCloseModal={onCloseModal} />
    </div>
  );
};
