import { useState } from 'react';
import { Grid, Dropdown, Tab, Header, Loader } from 'semantic-ui-react';
import { Breadcrumb } from '../../../../../../../../components/Common/Breadcrumb';
import { CLASSES_METRIC_OPTIONS } from '../../../../../../utils/sales';
import { TimeframeDropdown } from '../../../../../../../../components/Common/TimeframeDropdown';
import { useQueryParams } from '../../../../../../../../hooks/useQueryParams';
import useQueryApi from '../../../../../../../../hooks/useQueryApi';
import { UnitMenu } from '../../../../../../../../components/Common/UnitMenu';
import { SKUS_TABLE_HEADER_NOM, SKUS_TABLE_HEADER_PCT, propsByMetric } from '../../../../../../utils/sales';
import { formatSku } from '../../../../../../../../utils/formatter';
import { SkuDetailsModal } from '../../../../../../../../components/Sku/SkuDetailsModal';
import { DataView } from '../../../../../../../../components/Common/DataView';
import { timeframeCode } from '../../../../../../../../components/Common/timeframeCode';

export const SubClassToSku = ({ fpToggle, timeframe, settimeframe }) => {
  const { subClassNbr = 0 } = useQueryParams();
  const [metric, setMetric] = useState('SLS');
  const [unit, setUnit] = useState('NOM');
  const [modalData, setModalData] = useState(null);
  const isModalOpen = !!modalData;
  const defaultParams = { fpToggle, isCore: true, subClassNbr: subClassNbr };
  const {
    data: skuDataFromSubClass = [],
    isError: isErrorSkuDataFromSubClass,
    isLoading: isLoadingSkuDataFromSubClass
  } = useQueryApi('querySlsSkuByCoreScls', defaultParams);

  if (isErrorSkuDataFromSubClass) return <p>Something went wrong.</p>;
  if (isLoadingSkuDataFromSubClass) return <Loader active>Loading...</Loader>;

  const tableHeaders = unit === 'NOM' ? SKUS_TABLE_HEADER_NOM[metric] : SKUS_TABLE_HEADER_PCT[metric];
  const skusData = skuDataFromSubClass.filter(row => {
    return row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe];
  });

  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    sortDirection: 'descending',
    pagination: true,
    headers: [{ name: 'SKU' }, { name: 'SKU Description' }, ...tableHeaders],

    data: skusData.map(data => [
      {
        onClick: () => setModalData(data),
        stringValue: formatSku(data['SKU_NBR']),
        customStyle: { color: '#EE7125', textDecoration: 'underline' }
      },
      {
        stringValue: data['SKU_DESC']
      },
      {
        dollarValue: data[propsByMetric[metric][0]]
      },
      unit === 'NOM'
        ? {
            compareStyle: true,
            dollarValue: metric === 'SLS' || metric === 'AVG_TCKT' ? data[propsByMetric[metric][1]] : undefined,
            numValue: metric === 'TXN' || metric === 'UNT' ? data[propsByMetric[metric][1]] : undefined
          }
        : {
            compareStyle: true,
            pctValue: data[propsByMetric[metric][2]]
          },
    ])
  };
  const dataAsOf = skuDataFromSubClass[0]?.TRNSFM_DT;
  const onCloseModal = () => setModalData(null);
  const getBreadcrumbData = skuData => {
    if (skuData.length > 0) {
      return {
        deptNbr: skuData[0]['DEPT_NBR'],
        deptText: skuData[0]['DEPT_NBR']+'-'+skuData[0]['DEPT_NM'],
        classNbr: skuData[0]['EXT_CLASS_NBR'],
        classText: skuData[0]['EXT_CLASS_NBR']+'-'+skuData[0]['CLASS_DESC'],
        subClassText: skuData[0]['EXT_SUB_CLASS_NBR']+'-'+skuData[0]['SUB_CLASS_DESC'],
      };
    }
    return {};
  };
  const deptDetails = getBreadcrumbData(skusData);
  const breadcrumbItems = [
    {
      text: 'Overview',
      to: '/core/overview'
    },
    {
      text: `${deptDetails.deptText}`,
      to: `/core/sales/dept?deptNbr=${deptDetails.deptNbr}`
    },
    {
      text: `${deptDetails.classText}`,
      to: `/core/sales/class?classNbr=${deptDetails.classNbr}`
    },
    {
      text: `${deptDetails.subClassText}`
    }
  ];
  return (
    <>
      <Tab.Pane className='mt-3'>
        <Breadcrumb items={breadcrumbItems} />
        <Header>
          Overview - Sales - Skus
          <Header.Subheader>Data as of {dataAsOf} </Header.Subheader>
        </Header>
        <Grid>
          <Grid.Column width={2}>
            <Grid.Row>Metrics:</Grid.Row>
            <Grid.Row>
              <Dropdown
                className='mx-3 mx-0'
                compact
                defaultValue='SLS'
                options={CLASSES_METRIC_OPTIONS}
                onChange={(_, { value }) => setMetric(value)}
                selection
                scrolling
                size='tiny'
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={2}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} enableCoreValidation={true} />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={2} textAlign='justified'>
            <Grid.Row>Units:</Grid.Row>
            <UnitMenu unit={unit} setUnit={setUnit} />
          </Grid.Column>
        </Grid>
        <DataView.DataTable data={dataObj} />
        <SkuDetailsModal isOpen={isModalOpen} onClose={onCloseModal} skuData={modalData} />
      </Tab.Pane>
    </>
  );
};
