import React from 'react';
import { CurrentDaySales } from './CurrentDaySales';
import { IntradayDepartmentViewComponent } from './IntradayDeparmentViewComponent';
import { IntradayTable } from './IntradayTable';
import { useIntradayContext } from '../../hooks/useIntradayContext';
import { DataView } from '../Common/DataView';

export const IntradayView = () => {
  const { currentLevelName, latestHourData, locNbr, level, deptOverviewQuery, hourlySalesData } = useIntradayContext();

  return (
    <DataView className='my-4'>
      <CurrentDaySales level={level} currentLevelName={currentLevelName} latestHourData={latestHourData} locNbr={locNbr} />

      <IntradayTable hourlySalesData={hourlySalesData} />

      <IntradayDepartmentViewComponent queryData={deptOverviewQuery} level={level} levelNumber={locNbr} />
    </DataView>
  );
};
